import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@oneboard/ui-components';
import { VideoList } from 'containers';

export default function MainPageHeader({ videoAttendeesList }) {

  return <Box width="100%" height="100%">
    <VideoList videoAttendeesList={videoAttendeesList}></VideoList>
  </Box>;
}

VideoList.propTypes = {
  videoAttendeesList: PropTypes.array
};