import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useRemoteVideoTileState,
  useAttendeeStatus,
  useRosterState
} from 'amazon-chime-sdk-component-library-react';
import { VideoPopover } from 'components';
import { VideoBox } from 'containers';
import { Tooltip } from 'antd';
import { Box, Popover } from '@oneboard/ui-components';
import {
  LocalVideo,
  RemoteVideo,
  useMeetingState,
  useMeetingDispatch,
} from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { Roles } from 'constants/index';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useGroupContext } from 'providers/GroupProvider';
import { StyledSubRoomVideoList, VideoBoxWrap, PopoverIcon } from './SubRoomVideoList.style';

const StudentVideoPopoverContainer = ({ attendeeId }) => {
  const {
    usersMuteHandler,
  } = useGroupContext();
  const { muted, videoEnabled } = useAttendeeStatus(attendeeId);

  const {
    openVideoAttendee,
    closeVideoAttendee,
    muteAttendee,
    unMuteAttendee,
  } = useMeetingDispatch();

  const muteHandler = () => {
    muted ? unMuteAttendee({ attendeeId }) : muteAttendee({ attendeeId });

    usersMuteHandler({
      [attendeeId]: !muted
    });
  };

  const videoEnabledHandler = () => {
    videoEnabled ? closeVideoAttendee({ attendeeId }) : openVideoAttendee({ attendeeId });
  };

  return (
    <VideoPopover>
      <PopoverIcon onClick={videoEnabledHandler}>
        {
          videoEnabled ? <Icon name="VideoSolid" /> :
            <Icon name="VideoSlashSolid" color="#F94144" />
        }
      </PopoverIcon>
      <PopoverIcon onClick={muteHandler}>
        {
          muted ?
            <Icon name="MicrophoneAltSlashSolid" color="#F94144" /> :
            <Icon name="MicrophoneAltSolid" />
        }
      </PopoverIcon>
    </VideoPopover>
  );
};

StudentVideoPopoverContainer.propTypes = {
  attendeeId: PropTypes.string
};

export const SubRoomVideoList = ({ className }) => {
  const { videoList: videoStudentList } = useVideoListContext();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { context } = useMeetingState();
  const { attendeeId: selfAttendeeId, videoIsMirroring, stagedAttendeeIds } = context;
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = attendees.filter(attendee => attendee.role === Roles.Student);
  const mapStudents = students.filter(attendee => stagedAttendeeIds.includes(attendee.chimeAttendeeId));

  return (
    <StyledSubRoomVideoList className={className} data-testid="SubRoomVideoList">
      { stagedAttendeeIds.length > 0 && <div className="stagedTipWrap">
        <Tooltip placement="bottomLeft" title={
          <span>釘選上台的學生<br />將同步顯示於其他學生的視窗中</span>
        }>
          <div className="stagedTip">
            <Icon name="ThumbtackSolid" color="#fff" size="xxs" />
          </div>
        </Tooltip>
      </div>}
      {
        videoStudentList
          .filter(attendee => attendee.chimeAttendeeId !== selfAttendeeId)
          .filter((tile, index) => index < MAX_STUDENT_VIDEO_COUNT)
          .map((attendee, index) => {
            const attendeeId = attendee.chimeAttendeeId;
            const tileId = attendeeIdToTileId[attendeeId];
            const isLast = mapStudents[stagedAttendeeIds.length - 1]?.chimeAttendeeId === attendeeId;

            return (
              <Box display="flex" key={attendeeId}>
                <Popover
                  placement="bottom"
                  className="customPopover"
                  content={<StudentVideoPopoverContainer
                    attendeeId={attendeeId} />}
                >
                  <VideoBoxWrap isStage={stagedAttendeeIds.includes(attendeeId)} isLast={isLast}>
                    {selfAttendeeId === attendeeId ? <VideoBox
                      attendeeId={attendeeId}
                      isStudent>
                      <LocalVideo
                        isShowNameplate={stagedAttendeeIds.includes(attendeeId)}
                        className={!videoIsMirroring ? 'isFlip' : ''}
                        attendeeId={attendeeId}
                      />
                    </VideoBox> : <VideoBox
                      attendeeId={attendeeId}
                      isStudent>
                      <RemoteVideo
                        attendeeId={attendeeId}
                        tileId={tileId}
                      />
                    </VideoBox>}
                  </VideoBoxWrap>
                </Popover>
              </Box>
            );
          })
      }
    </StyledSubRoomVideoList>
  );
};

SubRoomVideoList.propTypes = {
  className: PropTypes.string
};