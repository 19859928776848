import styled from 'styled-components';

export const StyledSyncSingle = styled.div`
  height: 100%;
    
    .auditListLayout {
      position: absolute;
      top: 0;
      right: 0;
      z-index:100;
    }
`;


export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
  color: ${({ active }) => active ? '#EC7963' : '#212324'};
  background-color: #fff;
  
  &:hover {
    background: rgba(33, 35, 36, 0.1);
  }
`;


export const ContentShareContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const StyledPrivateMessageModal = styled.div`
  .content {
    >textarea {
      width: 100%;
      height: 160px;
      padding: 14px 24px;
      border-radius: 12px;
      outline: none;
      background-color: #232A37;
      border: 2px solid #232A37;
      resize: none;
      &:focus {
        border: 2px solid #EC7963;
      }
    }
    .tip {
      margin-top: 8px;
      text-align: right;
      font-size: 14px;
      color: #8A94A6;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 12px;
  border: none;
  cursor: pointer;

  &.primary {
    color: #fff;
    background-color: #EC7963;
  }

  &.secondly {
    color: #8A94A6;
    background-color: #232A37;
  }
  
  &:disabled {
    color: #2F3746;
    cursor: not-allowed;
    background-color: #232A37;
  }
`;

export const StyledPrivateMessageButton = styled.button`
  width: 56px;
  height: 56px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

export const StyleUsersModalLayout = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(${({ active }) => active ? '0' : '360px'});
  width: 360px;
  height: 100%;
  z-index: 2001;
  transition: .3s;
`;