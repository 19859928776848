import styled from 'styled-components';

export const StyledMediaMetrics = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;
  border-radius: 200px;
  padding: 4px;
  background: rgba(69, 79, 91, 0.6);
  z-index: 2;
  right: 8px;
  top: 8px;
  > .iconBtn {
    display: flex;
    align-items: center;
    justify-content: center;

    &.isGood {
      color: #5eb762;
    }

    &.isPoor {
      color: #fff;
    }

    &.isStop {
      color: #ffa31a;
    }
  }
`;

export const StyledMediaMetricsInfo = styled.div`
  width: 140px;
  padding: 16px 18px;
  border-radius: 8px;
  background-color: #232323;
  font-size: 11px;

  .infoList {
    display: flex;
    align-items: center;
    justify-content: space-between;

    + .infoList {
      margin-top: 8px;
    }
  }

  .infoTitle {
    color: #8b8b8b;
  }

  .infoContent {
    color: #fff;
    &.isGood {
      color: #5eb762;
    }

    &.isPoor {
      color: #fff;
    }

    &.isStop {
      color: #ffa31a;
    }
  }
`;
