import styled from 'styled-components';

export const StyledReward = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;
