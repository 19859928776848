import styled from 'styled-components';

const DeviceSelection = styled.select`
  display: flex;
  height: 40px;
  padding: 8px 8px 7px 14px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 75px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  color: #919eab;
`;

export default DeviceSelection;
