import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ModalTitleContainer from 'components/NewOneBoard/common/ModalTitleContainer';
import ToolTitle from 'components/NewOneBoard/common/ToolTitle';
import Icon from '@onedesign/icon';

const ModalBackground = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
`;

const StyleModalLayout = styled.div`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: ${({ zIndex }) => zIndex || 6};
`;

const ModalContainer = styled.div`
  display: flex;
  width: ${({ width }) => width || '600px'};
  height: ${({ height }) => height || '244px'};
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: ${({ footerHeight }) => (footerHeight ? `calc(100% - ${footerHeight})` : '100%')};
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`;

const ModalBottomContainer = styled.div`
  width: 100%;
  height: ${({ footerHeight }) => footerHeight || '54px'};
  border-top: 0.5px solid #919eab52;
  padding: 0 24px;
  display: flex;
  align-items: flex-end;
`;

const ToolModal = ({
  active,
  onClose,
  children,
  title,
  width,
  height,
  zIndex,
  footerContent,
  footerHeight,
  showBottomContainer = true,
  justifyContent,
  showCloseButton = true,
}) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <>
      <ModalBackground active={active} />
      <StyleModalLayout active={active} zIndex={zIndex}>
        <ModalContainer width={width} height={height} ref={modalRef}>
          <ModalTitleContainer>
            <ToolTitle>{title}</ToolTitle>
            {showCloseButton && (
              <div className='close' onClick={onClose} style={{ cursor: 'pointer' }}>
                <Icon name='XmarkOutline' size='xs' color={'#637381'} />
              </div>
            )}
          </ModalTitleContainer>
          <ContentContainer justifyContent={justifyContent} footerHeight={footerHeight}>
            {children}
          </ContentContainer>
          {showBottomContainer && (
            <ModalBottomContainer footerHeight={footerHeight}>{footerContent}</ModalBottomContainer>
          )}
        </ModalContainer>
      </StyleModalLayout>
    </>
  );
};

export default ToolModal;
