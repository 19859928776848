import styled from 'styled-components';
import { mediaQueries } from '../../utils/theme';

export const StyledStepsBar = styled.div.attrs(props => ({
className: props.className
}))`
  /* 當下樣式 */
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #fff;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: #EC7963;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #454B5C;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-color: #EC7963;
    
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: rgba(236, 121, 99, .16);
    }
  }
  
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #242C3F;
  }

  /* 等待的樣式 */
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #454B5C;
    border-color: #454B5C;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #161C27;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #454B5C;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #fff;
  }

  /* 完成的樣式 */
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #EC7963; 
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #fff;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #232A37;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #EC7963;
    border-color: #EC7963;
  }

  ${mediaQueries.small} {
    .ant-steps-label-vertical .ant-steps-item-tail {
      margin-left: 15px;
    }
    .ant-steps-label-vertical .ant-steps-item-icon {
      margin-left: 0;
    }
    .ant-steps-label-vertical .ant-steps-item-content {
      display: none;
    }
  }
`;