import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMeetingDispatch, useMeetingState, MeetingStates } from '@oneboard/meeting';
import { useParams } from 'react-router-dom';
import { useInterval } from 'ahooks';
import Cookies from 'js-cookie';
import { Loading } from '@oneboard/ui-components';
import {
  useAttendeeStatus,
  useMeetingStatus,
  useAudioInputs,
  useVideoInputs,
  MeetingStatus,
  useAudioOutputs,
  useAudioVideo,
  useSelectVideoQuality,
} from 'amazon-chime-sdk-component-library-react';
import { updateUserRecord, getMultiUserRecord } from 'services/userRecord';
import { ClassType, Roles } from 'constants/index';
import { useQuery } from 'utils/hooks/useQuery';
import { QuizServiceProvider } from 'utils/hooks/useQuiz';
import { useUserAgent } from 'utils/hooks/useUserAgent';
import { useBitrix } from 'utils/hooks/useBitrix';
import { useMicrophoneActivity } from 'utils/hooks/useMicrophoneActivity';
import TeacherPage from './teacher';
import StudentPage from './student';
import AdvisorPage from './advisor';
import { MeetingFailurePage } from 'components/MeetingFailurePage/MeetingFailurePage';
import { WarningModal } from 'components/WarningModal/WarningModal';
import { GroupProvider } from 'providers/GroupProvider';
import { VideoListProvider } from 'providers/VideoListProvider';
import { RewardsProvider } from 'providers/RewardsProvider';
import { BreakoutMeetingProvider } from 'providers/BreakoutMeetingProvider';
import { RecordVideoProvider } from 'providers/RecordProvider';
import { NetworkProvider } from 'providers/NetworkProvider';
import { StudentInfoProvider } from 'providers/StudentInfoProvider';
import { CleanConfirmModal } from 'containers';
import { StyledMainPage } from './MainPage.style';
import { RoomProvider } from 'providers/RoomProvider';
import { InsertPictureProvider } from 'providers/InsertPictureProvider';
import { ResourceModalProvider } from 'providers/ResourceModalProvider';
import { FooterStatusProvider } from 'providers/FooterStatusProvider';

const createTeacherPageByClassType = (classType) => {
  switch (classType) {
    case ClassType.Single:
      return <TeacherPage.Single />;
    case ClassType.Group:
      return <TeacherPage.Group />;
    case ClassType.SyncSingle:
      return <TeacherPage.SyncSingleV2 />;
    case ClassType.SyncMultiple:
      return <TeacherPage.SyncMultipleV2 />;
    case ClassType.SyncStreaming:
      return <TeacherPage.SyncMultipleV2 />;
    case ClassType.Multiple:
    default:
      return <TeacherPage.MultipleV2 />;
  }
};

const createAdvisorPageByClassType = (classType) => {
  switch (classType) {
    case ClassType.Single:
      return <AdvisorPage.Single role={Roles.Advisor} />;
    case ClassType.Group:
      return <TeacherPage.Group role={Roles.Advisor} />;
    case ClassType.SyncSingle:
      return <AdvisorPage.SyncSingleV2 role={Roles.Advisor} />;
    case ClassType.SyncMultiple:
      return <AdvisorPage.SyncMultipleV2 role={Roles.Advisor} />;
    case ClassType.SyncStreaming:
      return <AdvisorPage.SyncMultipleV2 role={Roles.Advisor} />;
    case ClassType.Multiple:
    default:
      return <TeacherPage.Multiple />;
  }
};

const FilterStudentPage = () => {
  const { breakoutId } = useParams();
  return breakoutId ? <StudentPage.SyncMultiple /> : <StudentPage.SyncSingleV2 />;
};

const createStudentPageByClassType = (classType) => {
  switch (classType) {
    case ClassType.Single:
      return <StudentPage.Single />;
    case ClassType.Group:
      return <StudentPage.Group />;
    case ClassType.SyncSingle:
    case ClassType.SyncMultiple:
      return <FilterStudentPage />;
    case ClassType.SyncStreaming:
      return <FilterStudentPage />;
    case ClassType.Multiple:
    default:
      return <TeacherPage.Multiple />;
  }
};

const createPage = (role, classType) => {
  switch (role) {
    case Roles.Teacher:
      return createTeacherPageByClassType(classType);
    case Roles.Advisor:
      return createAdvisorPageByClassType(classType);
    case Roles.Student:
    default:
      return createStudentPageByClassType(classType);
  }
};

// disabled effect
// const useDefaultEffect = () => {
//   const state = useMeetingState();
//   const { clearVideoBackgroundEffect } = useMeetingDispatch();
//   const { isVideoEnabled } = useLocalVideo();

//   const isJoined = state.matches(MeetingStates.Joined);

//   useEffect(() => {
//     if (isVideoEnabled && isJoined) {
//       clearVideoBackgroundEffect();
//     }
//   }, [isVideoEnabled, isJoined]);
// };

const intervalSeconds = 30;
const useUserRecord = () => {
  const audioVideo = useAudioVideo();
  const { assignMultiUserRewards } = useMeetingDispatch();
  const state = useMeetingState();
  const { context } = state;
  const { attendeeId: selfAttendeeId, userId, roomId, userName, courseType } = context;
  const lastRecordTime = useRef(new Date().getTime());
  const { videoEnabled, muted } = useAttendeeStatus(selfAttendeeId);
  const { os, currentBrowser, currentUserAgent } = useUserAgent();
  const { selectedDevice: videoInputsDevices } = useVideoInputs();
  const { devices: audioInputDevices, selectedDevice: audioInputsDevice } = useAudioInputs();
  const { devices: audioOutputDevices, selectedDevice: audioOutputsDevice } = useAudioOutputs();

  const audioInputDeviceLabel = useMemo(
    () => (audioInputDevices || []).find((item) => item.deviceId === audioInputsDevice)?.label || '',
    [audioInputsDevice]
  );

  const audioOutputDeviceLabel = useMemo(
    () => (audioOutputDevices || []).find((item) => item.deviceId === audioOutputsDevice)?.label || '',
    [audioOutputDevices]
  );

  useEffect(() => {
    // get 獎盃紀錄
    const init = async () => {
      const multiRecord = await getMultiUserRecord({
        courseId: roomId,
      });
      const rewardsMap = {};

      if (!multiRecord) return;
      multiRecord.forEach((item) => (rewardsMap[item.userId] = item.summary?.trophy || 0));

      assignMultiUserRewards({ rewardsMap });
    };

    init();
  }, []);

  const { speakerSecond, clearSpeakerSecond } = useMicrophoneActivity();
  const [networkHealth, setNetworkHealth] = useState({
    green: 1,
    yellow: 0,
    red: 0,
  });

  useEffect(() => {
    if (!audioVideo) return;
    const observer = {
      connectionDidBecomeGood: () => {
        setNetworkHealth({
          green: 1,
          yellow: 0,
          red: 0,
        });
      },
      connectionDidBecomePoor: () => {
        setNetworkHealth({
          green: 0,
          yellow: 1,
          red: 0,
        });
      },
      connectionDidSuggestStopVideo: () => {
        setNetworkHealth({
          green: 0,
          yellow: 0,
          red: 1,
        });
      },
    };

    if (audioVideo) {
      audioVideo.addObserver(observer);
    }

    return () => {
      audioVideo.removeObserver(observer);
    };
  }, [audioVideo]);

  useEffect(() => {
    intervalRecord();
  }, [networkHealth, videoInputsDevices?.label, audioInputDeviceLabel, audioOutputDeviceLabel, videoEnabled, muted]);

  const intervalRecord = async () => {
    const currentTime = new Date().getTime();
    const duration = currentTime - lastRecordTime.current;

    updateUserRecord({
      courseId: roomId,
      userId,
      userName,
      role: context.role,
      userAgent: `${os}_${currentBrowser} ${currentUserAgent}`,
      devices: {
        cameraName: videoInputsDevices?.label,
        cameraEnabled: videoEnabled,
        micName: audioInputDeviceLabel,
        micEnabled: !muted,
        audioOutputName: audioOutputDeviceLabel,
      },
      duration,
      speakerSecond: speakerSecond.current,
      networkHealth,
    });

    lastRecordTime.current = currentTime;
    clearSpeakerSecond();
  };

  useInterval(intervalRecord, window.location.hostname === 'localhost' ? undefined : intervalSeconds * 1000, {
    immediate: true,
  });
};

const Main = ({ role, classType }) => {
  return <CleanConfirmModal>{createPage(role, classType)}</CleanConfirmModal>;
};

Main.propTypes = {
  role: PropTypes.string,
  classType: PropTypes.string,
};

export const MainPage = () => {
  const meetingState = useMeetingState();
  const meetingStatus = useMeetingStatus();
  const state = useMeetingState();
  const { context } = state;
  const { roomId } = context;
  const isJoined = meetingState.matches(MeetingStates.Joined);
  const isFailure = meetingState.matches(MeetingStates.Failure);
  const { removeCRMChat } = useBitrix();
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const query = useQuery();
  const { role, classType } = query;
  const handleCloseWarningModal = () => {
    setIsWarningModalOpen(false);
  };
  // useDefaultEffect();
  useUserRecord();
  //學生在等待畫面也使用CRMChat
  useEffect(() => {
    if (role !== Roles.Student) {
      removeCRMChat();
    }
  }, []);

  const selectVideoQuality = useSelectVideoQuality();

  useEffect(() => {
    selectVideoQuality('360p');
  }, []);

  useEffect(() => {
    if (isJoined && role === Roles.Teacher && meetingStatus === MeetingStatus.Succeeded) {
      const shownRoomIds = Cookies.get('shownRoomIds') ? JSON.parse(Cookies.get('shownRoomIds')) : [];

      if (!shownRoomIds.includes(roomId)) {
        setIsWarningModalOpen(true);
        shownRoomIds.push(roomId);
        Cookies.set('shownRoomIds', JSON.stringify(shownRoomIds), { expires: 1 });
      }
    }
  }, [isJoined, roomId, role, meetingStatus]);

  return (
    <RoomProvider>
      <ResourceModalProvider>
        <InsertPictureProvider>
          <FooterStatusProvider>
            <BreakoutMeetingProvider>
              <StudentInfoProvider>
                <GroupProvider>
                  <RewardsProvider>
                    <VideoListProvider>
                      <QuizServiceProvider>
                        <RecordVideoProvider>
                          <NetworkProvider>
                            <StyledMainPage data-testid='MainPage' id='MainPage'>
                              {isJoined && meetingStatus === MeetingStatus.Succeeded ? (
                                <Main role={role} classType={classType} />
                              ) : (
                                <Loading className='loading' />
                              )}
                              <WarningModal
                                isOpen={
                                  role === Roles.Teacher &&
                                  meetingStatus === MeetingStatus.Succeeded &&
                                  isWarningModalOpen
                                }
                                onClose={handleCloseWarningModal}
                                headerText={'準備好開始上課了嗎？'}
                                bodyText={'若學生尚未進入教室，同時未啟動錄影功能，30 分鐘後需重新進入教室。'}
                              />
                              {isFailure && <MeetingFailurePage />}
                            </StyledMainPage>
                          </NetworkProvider>
                        </RecordVideoProvider>
                      </QuizServiceProvider>
                    </VideoListProvider>
                  </RewardsProvider>
                </GroupProvider>
              </StudentInfoProvider>
            </BreakoutMeetingProvider>
          </FooterStatusProvider>
        </InsertPictureProvider>
      </ResourceModalProvider>
    </RoomProvider>
  );
};
