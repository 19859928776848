import styled from 'styled-components';

export const StyledPaintToolBar = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const PaintToolGroup = styled.div`
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #232323;
  border-radius: 8px;
  padding: 10px;
`;

export const PaintTool = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: auto;
  cursor: pointer;
  color: ${({ active }) => active ? '#EC7963' : '#fff'};
  background-color: transparent;
  border: none;

  & + & {
    /* margin-top: 24px; */
  }
  &:hover {
    background-color: #33353A;
  }
`;

export const WhiteboardPaintToolWrapper = styled.div`
  padding: 16px 8px;

  > .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & + .icon {
      margin-top: 16px;
    }
  }
`;