import React, { memo, forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import * as SVG from './svg';

const sizes = [8, 12, 16, 20, 24, 28, 32, 36];
const sizeMap = {
  xxxs: sizes[0],
  xxs: sizes[1],
  xs: sizes[2],
  sm: sizes[3],
  md: sizes[4],
  lg: sizes[5],
  xl: sizes[6],
  xxl: sizes[7],
};

const defaultSize = sizeMap.md;

const calcSize = (size) => {
  if (Array.isArray(size)) {
    return size.map((item) => sizeMap[item]);
  }
  return sizeMap[size] || defaultSize;
};

const StyledIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${layout}
  color: ${({ color }) => color || 'currentColor'};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  > svg {
    width: 100%;
    height: 100%;
    color: currentColor;
    * {
      fill: currentColor;
      transform: scale(${({ width }) => width / defaultSize});
    }
  }
`;

function Icon({ name, className, size = defaultSize, width, color, onClick }, ref) {
  const SVGComponent = useMemo(() => SVG[name], [name]);

  return (
    <StyledIcon
      ref={ref}
      className={className}
      width={width || calcSize(size)}
      height={width || calcSize(size)}
      color={color}
      onClick={onClick}
    >
      {SVGComponent ? <SVGComponent /> : null}
    </StyledIcon>
  );
}

export default memo(forwardRef(Icon));
