import { useState, useEffect } from 'react';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

export const useNetwork = () => {
  const audioVideo = useAudioVideo();
  const [networkNotice, setNetworkNotice] = useState(false);
  const [networkStatus, setNetworkStatus] = useState('good');

  useEffect(() => {
    if (!audioVideo) return;
    const observer = {
      audioVideoDidStartConnecting: () => {
        setNetworkStatus('good');
      },
      connectionDidBecomeGood: () => {
        setNetworkStatus('good');
      },
      connectionDidBecomePoor: () => {
        setNetworkStatus('poor');
      },
      connectionDidSuggestStopVideo: () => {
        setNetworkStatus('stop');
      },
    };
    if (audioVideo) {
      audioVideo.addObserver(observer);
    }
    return () => {
      audioVideo.removeObserver(observer);
    };
  }, [audioVideo]);

  const closeNetworkNotice = () => setNetworkNotice(false);

  const openNetworkNotice = () => setNetworkNotice(true);

  return {
    networkNotice,
    networkStatus,
    openNetworkNotice,
    closeNetworkNotice,
  };
};
