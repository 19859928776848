import React from 'react';
import Icon from '@onedesign/icon';
import { useBitrixService } from 'utils/hooks/useBitrix';
import { StyledCustomerService } from './CustomerService.style';

export const CustomerService = ({ className }) => {
  const { url: bitrixUrl } = useBitrixService();

  return (
    <StyledCustomerService className={className} data-testid="CustomerService">
      <a href={bitrixUrl} target='_blank' rel="noreferrer">
        <Icon name="HeadsetSolid" size='sm'/>
      </a>
    </StyledCustomerService>
  );
};