import styled from 'styled-components';

export const StyledSyncVideoList = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  > div {
    width: calc(100% / 6);
  }

  .customPopover {
    .ant-popover .ant-popover-content .ant-popover-inner {
      border-radius: 24px;
    }
  }

  .ant-popover .ant-popover-content .ant-popover-inner {
    overflow: hidden;
    border-radius: 24px;
  }

  .videoPopoverV2 {
    background-color: #fff;
  }

  .stagedTipWrap {
    display: flex;
    justify-content: center;
    flex: 0 0 24px;
    padding: 10px 0;
    margin: 0 0 0 8px;
    background-color: rgba(236, 121, 99, 0.4);
    border-radius: 0 0 0 15px;
  }

  .stagedTip {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ec7963;
  }
`;

export const VideoBoxWrap = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  margin: ${({ isLast }) => (isLast ? '0 8px 0 0' : '0')};
  background-color: ${({ isStage }) => (isStage ? 'rgba(236, 121, 99, 0.4)' : 'transparent')};
  border-radius: ${({ isLast }) => (isLast ? '0 0 15px 0' : '0')};

  &.teacherWrap {
    &:after {
      position: absolute;
      right: 0;
      top: 16px;
      display: inline-block;
      content: '';
      width: 2px;
      height: calc(100% - 32px);
      background-color: #2f3746;
      border-radius: 2px;
    }
  }
`;

export const PopoverIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #919eab;
  cursor: pointer;

  &.reward {
    &:hover {
      color: #f9c74f;
    }
  }
`;
