import styled from 'styled-components';

export const StyledVideoSingleList = styled.div.attrs(props => ({
  className: props.className
}))`
  width: 100%;
  height: 100%;
  background-color: #161C27;
  padding: 16px;
  border-bottom: 1px solid #454B5C;
  
  .customPopover {
    .ant-popover .ant-popover-content .ant-popover-inner {
      border-radius: 24px;
    }
  }
  .videoBoxWrap {
    width: 100%;
    &.sync-multiple{
      &.isBreakoutRoom{
        padding-top: 75%;
      }
      /* height: 100%; */
    }
  }
`;

export const PopoverIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  
  &.reward {
    &:hover {
      color: #F9C74F;
    }
  }
`;