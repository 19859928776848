import styled from 'styled-components';

export const StyledPrivateMessageModal = styled.div.attrs(props => ({
  className: props.className
}))`
.content {
    >textarea {
      width: 100%;
      height: 160px;
      padding: 14px 24px;
      border-radius: 12px;
      outline: none;
      background-color: #232A37;
      border: 2px solid #232A37;
      resize: none;
      &:focus {
        border: 2px solid #EC7963;
      }
    }
    .tip {
      margin-top: 8px;
      text-align: right;
      font-size: 14px;
      color: #8A94A6;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 12px;
  border: none;
  cursor: pointer;

  &.primary {
    color: #fff;
    background-color: #EC7963;
  }

  &.secondly {
    color: #8A94A6;
    background-color: #232A37;
  }
  
  &:disabled {
    color: #2F3746;
    cursor: not-allowed;
    background-color: #232A37;
  }
`;