import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useThrottleFn } from 'ahooks';
import { MeetingVideoResizableRect } from 'components';
import {
  useAudioVideo,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingDispatch,
  useMeetingState,
  RemoteVideo,
  LocalVideo,
} from '@oneboard/meeting';
import { Roles } from 'constants/index';
import { useGroupContext } from 'providers/GroupProvider';

import { StyledSyncObjectVideo, StyledVideoObjectBox } from './SyncObjectVideo.style';

const DATA_MESSAGE_LIFETIME_MS = 10000;
const topic = 'syncObject';


const TeacherSyncObjectVideoComponent = ({
  local,
  attendeeId,
  videoIsMirroring,
  originWidth,
  originHeight,
  onVideoClick
}) => {
  const { attendeeIdToTileId } = useRemoteVideoTileState();

  const audioVideo = useAudioVideo();
  const syncObject = (evt) => {
    audioVideo?.realtimeSendDataMessage(
      `${topic}`,
      evt,
      DATA_MESSAGE_LIFETIME_MS
    );
  };

  const { run: handler } = useThrottleFn((event) => {
    const { width, translate, } = event;
    const [x, y, rotate, scale] = translate;
    syncObject({
      type: 'drag',
      attendeeId,
      x: x / originWidth,
      y: y / originHeight,
      width: width / originWidth,
      height: width / originWidth,
      rotate,
      scale,
    });
  }, {
    wait: 500,
  },);

  const { run: resizeHandler } = useThrottleFn((event) => {
    const { width, height } = event;
    syncObject({
      type: 'resize',
      attendeeId,
      width: width / originWidth,
      height: width / originWidth,
    });
  }, {
    wait: 500,
  },);

  return (
    <MeetingVideoResizableRect
      onClose={() => onVideoClick(attendeeId)}
      onDrag={handler}
      onResize={resizeHandler}
      // onResizeEnd={handler}
    >
      {(ref) =>
        local ? (
          <LocalVideo
            ref={ref}
            attendeeId={attendeeId}
            className={!videoIsMirroring ? 'isFlip' : ''}
          />
        ) : (
          <RemoteVideo
            attendeeId={attendeeId}
            tileId={attendeeIdToTileId[attendeeId]}
            ref={ref}
          />
        )
      }
    </MeetingVideoResizableRect>
  );
};

const TeacherSyncObjectVideo = ({ width: originWidth, height: originHeight }) => {
  const { context: { attendeeId: selfAttendeeId, videoIsMirroring, objectedAttendeeIds } } = useMeetingState();
  const { unObjectedAttendee, muteAttendee, unMuteAttendee } = useMeetingDispatch();
  const {
    usersMuteState,
  } = useGroupContext();

  const videoClickHandler = useCallback((attendeeId) => {
    unObjectedAttendee({ attendeeId });
    const isMuted = usersMuteState[attendeeId];
    isMuted ? muteAttendee({ attendeeId }) : unMuteAttendee({ attendeeId });
  }, []);

  return (
    <>
      {objectedAttendeeIds
        .map(attendeeId =>
          <TeacherSyncObjectVideoComponent
            key={attendeeId}
            local={selfAttendeeId === attendeeId}
            attendeeId={attendeeId}
            onVideoClick={videoClickHandler}
            originWidth={originWidth}
            originHeight={originHeight}
            videoIsMirroring={videoIsMirroring}
          />
        )}
    </>
  );
};

TeacherSyncObjectVideo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

const StudentSyncObjectVideoComponent = ({
  local,
  attendeeId,
  videoIsMirroring,
  originWidth,
  originHeight,
}) => {
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const [{ x, y, width, height, rotate, scale }, setState] = useState({
    x: 150,
    y: 150,
    width: 150,
    height: 150,
    rotate: 1,
    scale: 1
  });

  const audioVideo = useAudioVideo();

  useEffect(() => {
    if (!audioVideo || !attendeeId) return;

    const handler = dataMessage => {
      const data = dataMessage.json();
      const { type, x, y, rotate, scale, width, attendeeId: targetId } = data;
      if (attendeeId !== targetId) return;

      switch (type) {
        case 'drag':
          setState(prev => ({
            ...prev,
            x: originWidth * x,
            y: originHeight * y,
            width: width * originWidth,
            height: width * originWidth,
            rotate,
            scale
          }));
          break;
        case 'resize':
        default:
          setState((prev) => ({
            ...prev,
            width: width * originWidth,
            height: width * originWidth,
          }));
      }
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      `${topic}`,
      handler
    );

    return () => {
      audioVideo.realtimeUnsubscribeFromReceiveDataMessage(topic);
    };
  }, [audioVideo, attendeeId]);

  return (
    <StyledVideoObjectBox
      x={x}
      y={y}
      width={width}
      height={height}
      rotate={rotate}
      scale={scale}
    >
      {
        local ? (
          <LocalVideo
            attendeeId={attendeeId}
            className={!videoIsMirroring ? 'isFlip' : ''}
          />
        ) : (
          <RemoteVideo
            key={attendeeId}
            attendeeId={attendeeId}
            tileId={attendeeIdToTileId[attendeeId]}
          />
        )
      }

    </StyledVideoObjectBox>
  );
};

const StudentSyncObjectVideo = ({ width: originWidth, height: originHeight }) => {
  const { context: { attendeeId: selfAttendeeId, videoIsMirroring, objectedAttendeeIds } } = useMeetingState();

  return (
    <>
      {objectedAttendeeIds
        .map(attendeeId => (
          <StudentSyncObjectVideoComponent
            key={attendeeId}
            local={selfAttendeeId === attendeeId}
            attendeeId={attendeeId}
            videoIsMirroring={videoIsMirroring}
            originWidth={originWidth}
            originHeight={originHeight}
          />
        ))}
    </>
  );
};


StudentSyncObjectVideo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

export const SyncObjectVideo = ({
  role,
  whiteboardSize
}) => {
  return (
    <StyledSyncObjectVideo data-testid="SyncObjectVideo">
      {
        role === Roles.Teacher ? (
          <TeacherSyncObjectVideo
            width={whiteboardSize.width}
            height={whiteboardSize.height}
          />
        ) : (
          <StudentSyncObjectVideo
            width={whiteboardSize.width}
            height={whiteboardSize.height}
          />
        )
      }
    </StyledSyncObjectVideo>
  );
};

SyncObjectVideo.propTypes = {
  role: PropTypes.string,
  whiteboardSize: PropTypes.object
};