import React, { useRef, useEffect } from 'react';
import { useBitrix } from 'utils/hooks/useBitrix';
import lottie from 'lottie-web';
import { StyledWaitingAnimation, Animation, AnimationWrap, Title, Text, TextConatiner } from './WaitingAnimation.style';
import oneBoardRocket from './OneBoard_Rocket_New.json';

export const WaitingAnimation = ({ isInClassTime, countdown }) => {
  const { showCRMChat, hideCRMChat } = useBitrix();
  const ref = useRef();

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  useEffect(() => {
    showCRMChat();
    const animation = lottie.loadAnimation({
      container: ref.current,
      animationData: oneBoardRocket,
    });

    return () => {
      animation.destroy();
      hideCRMChat();
    };
  }, []);

  return (
    <StyledWaitingAnimation data-testid='WaitingAnimation'>
      <AnimationWrap>
        <Animation ref={ref} />
        <TextConatiner>
          {!isInClassTime && countdown !== 0 ? (
            <>
              <Title>準備好了嗎？課程即將開始！</Title>
              {countdown && (
                <Text>
                  剩餘 {minutes} 分 {seconds} 秒
                </Text>
              )}
            </>
          ) : (
            <Title>稍等一下～老師正在趕來的路上！</Title>
          )}
        </TextConatiner>
      </AnimationWrap>
    </StyledWaitingAnimation>
  );
};
