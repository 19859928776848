import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { Tooltip } from 'antd';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useSize, useRequest, useLocalStorageState } from 'ahooks';
import { useGroupContext } from 'providers/GroupProvider';
import { getResult } from 'services/breakout';
import { Loading, Box } from '@oneboard/ui-components';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { usePagination } from 'utils/hooks/usePagination';
import { Roles } from 'constants/index';
import { StyledBreakoutRoomResult, StyledCardBox } from './BreakoutRoomResult.style';

const calculateImg = (page) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
      image.onload = function () {
        resolve({
          page,
          image: {
            width: this.width,
            height: this.height
          }
        });
      };
      image.src = page.lastScreenShot;
  });
};

const createScenes = (pages) => {
  return new Promise((resolve, reject) => {
    Promise.all(pages.map((page) => calculateImg(page)))
    .then(res => {
      const response = res.map(item => ({
        name: '' + item.page.roomId,
        ppt: {
          src: item.page.lastScreenShot,
          width: item.image.width,
          height: item.image.height
        }
      }));
      resolve(response);
    });
  });
};

const CardBox = ({ height, data, onClick }) => {
  const { lastScreenShot, groupName, roomId } = data;
  const members = data.members;
  return (
    <StyledCardBox height={height} onClick={() => onClick(roomId)}>
      <div className="content">
        <div className="screenShot">
          <img src={lastScreenShot} alt="" />
        </div>
        <div className="overlay"></div>
        <div className="groupName">{groupName}</div>
        <Tooltip placement="bottom" title={
          <>
            {members.map(member => <div key={member.userId}>{member.userName}</div>)}
          </>
        }>
          <div className="memberTip">
            <Icon name="InfoSolid" color='#fff' size="xxs" />
          </div>
        </Tooltip>
      </div>
    </StyledCardBox>
  );
};

CardBox.propTypes = {
  height: PropTypes.number,
  data: PropTypes.object,
  onClick: PropTypes.func
};

const ContentPerPage = 6;
const CardBoxList = ({
  data = [],
  height,
  onClick
 }) => {
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: ContentPerPage,
    count: data.length,
  });

  const isShowPrev = useMemo(() => {
    return data.length > ContentPerPage && page > 1;
  }, [data, page]);

  const isShowNext = useMemo(() => {
    return data.length > ContentPerPage && page < totalPages;
  }, [data, page, totalPages]);

  return (
    <>
      {
       isShowPrev && (
        <Box className="icon prev" onClick={prevPage}>
          <Icon name="ChevronLeftOutline" size='xs'/>
        </Box>
       )
      }
      <div className="container">
        {data.length > 0 && data.slice(firstContentIndex, lastContentIndex)
          .map(group => (
            <CardBox
              key={group.groupName}
              height={height}
              data={group}
              onClick={onClick}
            />
          ))}
      </div>
      {
       isShowNext && (
        <Box className="icon next" onClick={nextPage}>
          <Icon name="ChevronRightOutline" size='xs'/>
        </Box>
       )
      }
    </>
  );
};

const combineResultData = ({ resultData, students }) => {
  const nextData = resultData.groups.map(group => {
    const { members, ...rest } = group;
    return {
      members: members
      .map(member => {
        const studentMap = students.filter(student => student.userId === member.userId)[0] || {};

        return {
          ...member,
          chimeAttendeeId: studentMap.chimeAttendeeId,
        };
      }),
      ...rest
    };
  });
  return nextData;
};

export const BreakoutRoomResult = ({ className, service }) => {
  const {
    breakoutRoomResultData,
    breakoutRoomResultActions,
    setBreakoutRoomResultGroup,
    setBreakoutRoomResultGroupInfo,
    addSceneQueue
  } = useGroupContext();
  const { scaleToFit } = useZoomControl();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter(attendee => attendee.role === Roles.Student);
  const mainDomSize = useSize(document.querySelector('.main'));

  const getResultByCourseId = () => getResult({
    courseId: breakoutRoomResultData.courseId,
    timestamp: breakoutRoomResultData.timestamp
  });

  const { data: resultData, loading } = useRequest(getResultByCourseId);
  const [groups, setGroups] = useState([]);

  const cardClickHandler = async (roomId) => {
    const { courseId, groups } = resultData.data;
    service.removeScenes(`/${breakoutRoomResultData.courseId}`);
    const scenes = await createScenes(groups.filter(page => page.lastScreenShot));
    service.putScenes(`/${courseId}`, scenes);
    service.setScenePath(`/${courseId}/${roomId}`);
    scaleToFit();
    service.dispatchMagixEvent('changeBookId');
    breakoutRoomResultActions.setFalse();
    groupResultInfoHandler(roomId);
    addSceneQueue({ bookId: courseId, label: '分組討論', index: roomId });
  };

  const groupResultInfoHandler = (roomId) => {
    setBreakoutRoomResultGroupInfo(groups.filter(group => group.roomId === roomId)[0]);
  };

  useEffect(() => {
    if (!resultData) return;
    const nextGroups = combineResultData({ resultData: resultData.data, students }).filter(group => group.lastScreenShot);
    setGroups(nextGroups);
    setBreakoutRoomResultGroup(nextGroups);
  }, [resultData]);

  return (
    <StyledBreakoutRoomResult className={className} data-testid="BreakoutRoomResult">
      <div className="wrap">
        {loading && (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%" >
            <Loading />
          </Box>
          )
        }
        {
          groups.length > 0 && (
            <CardBoxList
              data={groups}
              height={mainDomSize.height}
              onClick={cardClickHandler}
            />
          )
        }
      </div>
    </StyledBreakoutRoomResult>
  );
};

BreakoutRoomResult.propTypes = {
  className: PropTypes.string,
  service: PropTypes.object
};