import { useGroupContext } from 'providers/GroupProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { useFooterStatus } from 'providers/FooterStatusProvider';

export const TakeTurnsButton = () => {
  const { toggleCarouselPanel } = useGroupContext();
  const { isFooterOpen } = useFooterStatus();

  return (
    <ToolButton
      icon={<Icon name='UserRotationSolid' size='md' color={'#FF8E8E'} />}
      onClick={toggleCarouselPanel}
    >
      {isFooterOpen && '輪流上台'}
    </ToolButton>
  );
};
