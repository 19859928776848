import React from 'react';
import PropTypes from 'prop-types';
import { StyledContentShareLayout } from './ContentShareLayout.style';

export const ContentShareLayout = ({ className, contentShare, left, right }) => {
  return (
    <StyledContentShareLayout className={className} data-testid="ContentShareLayout">
      <div className="contentShareWrap">
        {contentShare}
      </div>
      <div className="toolboxWrap">
        <div className="controlBlock">
          {left}
        </div>
        <div className="controlBlock">
          {right}
        </div>
      </div>
    </StyledContentShareLayout>
  );
};

ContentShareLayout.propTypes = {
  className: PropTypes.string,
  contentShare: PropTypes.node,
  left: PropTypes.node,
  right: PropTypes.node,
};