import styled from 'styled-components';

export const StyledStudentVideoContainer = styled.div.attrs(props => ({
  className: props.className
}))`
  width: 100%;
  height: 100%;
  background-color: #161C27;
  padding: 16px;
  border-bottom: 1px solid #454B5C;
  .customPopover {
    .ant-popover .ant-popover-content .ant-popover-inner {
      border-radius: 24px;
    }
  }
  .tileBox{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .previewVideoBox {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #161C27;
  }
  .videoBoxWrap {
    width: 100%;
  }
`;

export const PopoverIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  
  &.reward {
    &:hover {
      color: #F9C74F;
    }
  }
`;

export const StyledNameBox = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 64px;
  cursor: default;
`;

export const StyledStagedAnimationBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 75%;
  border-radius: 4px;
  background-color: #232A37;
  .boxWrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .text {
    margin-top: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
  }
`;

export const StyledStagedAnimation = styled.div`
  width: 2rem;
  height: 2rem;
`;

export const StyledPreviewBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  
  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;