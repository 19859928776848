import styled from 'styled-components';

export const StyledClassroomPerformanceModal = styled.div.attrs(props => ({
  className: props.className
}))`
/* Code Here */
`;

export const StyledModalHead = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  color: rgba(193, 199, 208, 1);
  > .title {
    flex: 1;
  }
`;

export const StyledModalBody = styled.div`
  max-height: 250px;
  margin-right: ${({ isScrollable }) => isScrollable ? '-18px' : 0};
  overflow-y: auto;
`;

export const StyledUserCell = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  font-weight: 500;

  .icon {
    &.performance {
      width: 50px;
      height: 50px;
      padding: 4px;
      background-color: rgba(59, 36, 109, 1);
      border-radius: 50%;
    }
    &.clock {
      width: 20px;
      height: 20px;
    }
    > img {
      width: 100%;
    }
  }
`;