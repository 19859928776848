import styled from 'styled-components';

export const StyledRandomModal = styled.div.attrs(props => ({
  className: props.className
}))`
  position: absolute;
  top: ${({ size }) => `${size?.height / 2 - 172}px`};
  left: ${({ size }) => `${size?.width / 2 - 225}px`};
  
  > .wrapper {
    width: 450px;
    border-radius: 12px;
    background-color: #232323;
    color: #D6DAE0;
    padding: 30px 36px;
    display: flex;
    flex-direction: column;

    > .body {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      overflow: hidden;  
    }

    .closeButton{
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

  }
  .viewWrap {
    position: relative;

    &:before,
    &:after {
      content: '';
      width: calc(100% - 12px);
      height: 40px;
      position: absolute;
      left: 6px;
      border-radius: 16px 16px 0 0;
      background-image: linear-gradient(180deg, #222222 0%, rgba(34, 34, 34, 0) 100%);
    }
    &:before {
      top: 6px;
    }
    &:after {
      bottom: 6px;
      transform: rotate(-180deg);
    }
    >.chevron {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;

      &.chevronRight {
        left: 20px;  
      }
      &.chevronLeft {
        right: 20px;  
      }
    }
  }

  .randomView {
    height: 144px;
    border: 6px solid #383838;
    border-radius: 16px;
    overflow: hidden;
    background-color: #32353A;
  }
  .nameList {
    text-align: center;
    height: 72px;
    padding: 0 24px;
    line-height: 72px;
    font-size: 40px;
  }
  .button {
    background-color: #EC7963;
    color: #fff;
    border-radius: 5px;

    &:hover {
      background-color: #EE8773;
    }
  }
`;

export const StyledHeader = styled.div`
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  font-size: 20px;
  font-weight: 500;
`;