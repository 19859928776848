import { useRef, useEffect } from 'react';
import AWS from 'aws-sdk';
import { getAWSToken } from 'services/awsToken';

const S3_BUCKET = 'oneclass-upload';
const REGION = 'ap-northeast-1';

export const useS3 = (roomId) => {
  const bucket = useRef();

  useEffect(() => {
    if (!roomId) return;
    getAWSToken(roomId).then((res) => {
      const { accessKeyId, secretAccessKey, sessionToken } = res;
      AWS.config.update({
        credentials: new AWS.Credentials({
          accessKeyId,
          secretAccessKey,
          sessionToken,
        }),
      });

      bucket.current = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });
    });
  }, [roomId]);

  const getFileExtension = (filename) => {
    return filename.split('.').pop();
  };

  const upload = (file, type = 'file', filename) => {
    const path = `${roomId}/upload/${filename}`;
    let params = {};

    switch (type) {
      case 'buffer':
        params = {
          Body: file.buf,
          Key: path,
          ContentType: type,
        };
        break;
      default:
        params = {
          Body: file,
          Key: path,
          ContentType: type,
        };
        break;
    }
    return new Promise((resolve, reject) => {
      bucket.current
        .putObject(params)
        .on('httpUploadProgress', (evt) => {
          console.log(Math.round((evt.loaded / evt.total) * 100));
        })
        .on('success', (data) => {
          resolve({
            state: 'success',
            url: `${process.env.REACT_APP_UPLOAD_CDN}/${roomId}/upload/${filename}`,
          });
        })
        .on('error', (err) => {
          reject({
            state: 'error',
            err,
          });
        })
        .send();
    });
  };

  return {
    upload,
  };
};
