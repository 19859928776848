import { useEffect } from "react"
import { BreakoutRoomActionTypes } from './machine';
import { useSocket, ReadyStateMap } from 'utils/hooks/useSocket';


export const SocketProvider = ({ 
  userId, 
  roomId, 
  send,
  children
 }) => {
  const { lastJsonMessage, readyState } = useSocket({ roomId, userId });

  const connectionStatus = {
    [ReadyStateMap.CONNECTING]: 'Connecting',
    [ReadyStateMap.OPEN]: 'Open',
    [ReadyStateMap.CLOSING]: 'Closing',
    [ReadyStateMap.CLOSED]: 'Closed',
    [ReadyStateMap.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  useEffect(()=>{
    console.log('connectionStatus:',connectionStatus)
  },[readyState])

  useEffect(()=>{
    if (!lastJsonMessage) return

    const handler = dataMessage => {
      const { event, ...rest } = dataMessage;
      send({
        type: BreakoutRoomActionTypes[event],
        payload: rest
      });
    }
    
    handler(lastJsonMessage)
    
  },[lastJsonMessage])

  return null;
}