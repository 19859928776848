import styled from 'styled-components';

const FeatureText = styled.p`
  color: #212b36;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
`;

export default FeatureText;
