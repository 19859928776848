import React from 'react';
import PropTypes from 'prop-types';
import { ResizableRect } from 'components';
import { StyledMeetingVideoResizableRect } from './MeetingVideoResizableRect.style';

const Editable = ({ onClose, name }) => {
  return {
    name: 'editable',
    props: {},
    events: {},
    render(moveable, React) {
      const rect = moveable.getRect();
      const { pos2 } = moveable.state;
      // use css for able
      const EditableViewer = moveable.useCSS('div', `
        {
            position: absolute;
            left: 4px;
            top: 4px;
            will-change: transform;
            transform-origin: 0px 0px;
        }
        .moveable-button {
            width: 24px;
            height: 24px;
            background: #33353A;
            border-radius: 4px;
            appearance: none;
            border: 0;
            color: white;
            font-weight: bold;
            cursor: pointer;
        }
        `);

      const NameViewer = moveable.useCSS('div', `
        {
            position: absolute;
            left: 4px;
            bottom: -${pos2[0] - 4}px;
            will-change: transform;
          }
        .moveable-name {
            height: 24px;
            border-radius: 4px;
            appearance: none;
            padding: 0 8px;
            border: 0;
            color: white;
            white-space: nowrap;
        }
        `);

      return (
        <>
          <EditableViewer key="editable-viewer" className={'moveable-editable'} style={{
            transform: `translate(${pos2[0]}px, (${pos2[1]}px) rotate((${rect.rotation}deg) translate(10px)`,
          }}>
            <button className="moveable-button" onClick={onClose}>-</button>
          </EditableViewer>
          {name && <NameViewer>
            <div className="moveable-name">{name}</div>
          </NameViewer>}
        </>
      );
    }
  };
};

export const MeetingVideoResizableRect = ({
  className,
  onClose,
  children,
  onDragStart,
  onDrag,
  onDragEnd,
  onResizeStart,
  onResize,
  onResizeEnd,
  chimeAttendeeObj
}) => {
  return (
    <StyledMeetingVideoResizableRect className={className} data-testid="MeetingVideoResizableRect">
      <ResizableRect
        ables={[Editable({ onClose, name: chimeAttendeeObj?.name })]}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onResizeStart={onResizeStart}
        onResize={onResize}
        onResizeEnd={onResizeEnd}
      >
        {children}
      </ResizableRect>
    </StyledMeetingVideoResizableRect>
  );
};

MeetingVideoResizableRect.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onResizeStart: PropTypes.func,
  onResize: PropTypes.func,
  onResizeEnd: PropTypes.func,
};