import styled from 'styled-components';

export const StyledStudentInfoBox = styled.div.attrs(props => ({
className: props.className
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(35, 35, 35, .4);
  z-index: 10000;

  >.container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%;
    height: 600px;
    margin: 20px;
    background-color: #161C27;
    border-radius: 20px;
    >.head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 35px;
      border-bottom: 1px solid #2F3746;
      .btn {
        width: 16px;
        height: 16px;
        border: none;
        padding: 0;
        background-color: inherit;
        cursor: pointer;
        &.previous {
        margin-right: 18px;
        }
      }
      >.title {
        display: flex;
        align-items: center;
        justify-content: center;
        >.text {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #fff;
        }
      }
    }
    >.content {
      flex: 1;
      padding: 32px 22px;
      overflow: hidden;
      .listContent {
        height: 100%;
      }
    }
  }
  .swiper-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: #fff;
    &.button-prev {
      margin-right: 10px;
    }
    &.button-next {
      margin-left: 10px;
    }
  }
`;

export const StyledUserProfile = styled.div`
  .profile {
    display: flex;
    align-items: center;
    padding: 28px 36px;

    .avatar {
      width: 50px;
      height: 50px;
      background-color: #ccc;
      border-radius: 50%;
    }
    
    .name {
      font-size: 18px;
      color: #fff;
      line-height: 27px;
    }
    
    .className {
      font-size: 14px;
      color: #B0B7C3;
      line-height: 24px;
    }
  }

  .itemWrap {
    &+ .itemWrap{
      margin-top: 40px;
    }
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .title {
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      }
      
      .more {
        display: inline-block;
        font-size: 14px;
        color: #B0B7C3;
        line-height: 24px;
        border: none;
        background-color: inherit;
        cursor: pointer;
      }
    }
    
    .content {
      margin-top: 16px;
    }
  }
`;

export const StyledHomework = styled.div`
  display: flex;
  align-items: center;
  
  .workCard {
    padding: 12px 12px 12px 18px;
    background-color: #232A37;
    border-radius: 10px;
    cursor: pointer;

    .title {
      overflow : hidden;
      font-size: 16px;
      text-overflow : ellipsis;
      white-space : nowrap;
      color :#fff;
      margin-bottom: 10px;
    }

    .paymentStatus {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .icon {
      opacity: 0;
      width: 16px;
      height: 16px;
    }
    &:hover {
      background-color: #2F3746;
      .icon {
        opacity: 1;
      }
    }
  }
`;

export const StyledHomeworkList = styled.div`
  height: 100%;
  .listbox {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    >.main {
      overflow-y: scroll;
    }
    >.action {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
  }
  .workItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 20px;
    background-color: #232A37;
    border-radius: 10px;
    cursor: pointer;
    +.workItem {
      margin-top: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 0;
      .text {
        overflow : hidden;
        font-size: 16px;
        text-overflow : ellipsis;
        white-space : nowrap;
        color :#fff;
      }
    }

    .paymentStatus {
      flex: 1 0 auto;
      text-align: right;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      width: 16px;
      height: 16px;
      margin: 0 10px;
    }
    &:hover {
      background-color: #2F3746;
      .icon {
        opacity: 1;
      }
    }
  }
`;

export const StyledAlbum = styled.div`
  height: 100%;
  .listbox {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    >.main {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex: 1;
      overflow-y: scroll;
    }
    >.action {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
  }
  .albumCard {
    flex: 0 1 auto;
    width: 140px;
    height: max-content;
    margin: 0 5px;
    cursor: pointer;

    .cover {
      position: relative;
      width: 100%;
      height: 100px;
      margin-bottom: 8px;
      border-radius: 4px;
      overflow: hidden;
      >img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
      .importCover {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);
        border-radius: 4px;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
        color: #fff;
      }
    }
    .title {
      font-size: 14px;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.75px;
    }
    .count {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.75px;
      color: #8A94A6;
    }
    &:hover {
      .cover {
        .importCover {
          display: flex;
        }
      }
    }
  }
`;

export const StyledFirstAlbum = styled(StyledAlbum)`
  display: flex;
  align-items: center;
  .albumCard {
    margin: 0 auto; 
  }
`;

export const StyledStatusLabel = styled.div`
  .label {
    display: inline-block;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 11px;
  }
  .paid {
    background-color: #38CB89;
    color: #232A37;
  }
  .late {
    background-color: #FF5630;
    color: #2F3746;
  }
  .unpaid {
    background-color: #2F3746;
    color: #B0B7C3;
  }
`;