import axios from 'axios';

const LIVEONECLASS_URL = process.env.REACT_APP_LIVEONECLASS_API_DOMAIN;


/**
 * 取得登入的token
 */
const setNaniToken = () => {
  const naniToken = document.cookie.replace(/(?:(?:^|.*;\s*)nani_oneclass_login_token\s*=\s*([^;]*).*$)|^.*$/, '$1');
  const jwt = JSON.parse(naniToken).jwt;
  return jwt;
};

/**
 * 取得課程相關資料
 */

export const getSessionInfo = async ({ sessionId }) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${LIVEONECLASS_URL}/sessions/${sessionId}`,
      responseType: 'json',
      headers: {
            Authorization: setNaniToken()
      }
    });
    return response.data;
  } catch (error) {
    console.log('error:', error);
  }
};

/**
 * 班級資訊
 * Get Group
 */
export const getGroupInfo = async groupId => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${LIVEONECLASS_URL}/groups/${groupId}`,
      responseType: 'json',
      headers: {
            Authorization: setNaniToken()
      },
    });
    return response.data;
  } catch (error) {
    console.log('error:', error);
  }
};

/**
 * 相簿列表
 * Get Group Albums
 */
export const getGroupAlbums = classId => async params => {
  const { nowPage = 0, rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }

  try {
    const response = await axios({
      method: 'GET',
      url: `${LIVEONECLASS_URL}/groups/${classId}/albums`,
      responseType: 'json',
      headers: {
            Authorization: setNaniToken()
      },
      params: queryParams
    });
    return response.data;
  } catch (error) {
    console.log('error:', error);
  }
};

/**
 * 取得作業列表
 * @param {string} sortKey (publishedAt, dueAt)  排序條件
 * @param {string} sortOrder (asc, desc)  排序升降冪
 */
export const getHomeworks = classId => async params => {
  const { nowPage = 0, rowsPage, isSubmitted = 0, sortKey, sortOrder } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  if (isSubmitted !== '') {
    queryParams.isSubmitted = isSubmitted;
  }
  if (sortKey) {
    queryParams.sortKey = sortKey;
    queryParams.sortOrder = sortKey === 'dueAt' ? 'asc' : 'desc';//預設排序
  }
  if (sortOrder) {
    queryParams.sortOrder = sortOrder;
  }

  try {
    const response = await axios({
      method: 'GET',
      url: `${LIVEONECLASS_URL}/groups/${classId}/missions`,
      responseType: 'json',
      headers: {
            Authorization: setNaniToken()
      },
      params: queryParams
    });
    return response.data;
  } catch (error) {
    console.log('error:', error);
  }
};

/**
 * 取得相簿相片
 * Get Default Group Album Entries albumId=image or video
 * Get Custom Group Album Entries
 */
export const getGroupAlbumEntries = ({ classId, albumId }) => async params => {
  const { nowPage = 0, rowsPage } = params;
  const queryParams = {
    limit: rowsPage ? rowsPage : 10,
  };
  if (rowsPage && nowPage * rowsPage !== 0) {
    queryParams.skip = nowPage * rowsPage;
  }
  try {
    const response = await axios({
      method: 'GET',
      url: `${LIVEONECLASS_URL}/groups/${classId}/albums/${albumId}`,
      responseType: 'json',
      headers: {
            Authorization: setNaniToken()
      },
      params: queryParams
    });
    return response.data;
  } catch (error) {
    console.log('error:', error);
  }
};