import { Group } from './Group';
import { Single } from './Single';
import { Multiple } from './Multiple';
import { SyncSingle } from './SyncSingle';
import { SyncSingle as SyncSingleV2 } from './SyncSingleV2';
import { SyncMultiple } from './SyncMultiple';
import { SyncMultiple as SyncMultipleV2 } from './SyncMultipleV2';

const TeacherPage = {
  Group,
  Single,
  Multiple,
  SyncSingle,
  SyncSingleV2,
  SyncMultiple,
  SyncMultipleV2,
};

export default TeacherPage;
