import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFooterStatus } from 'providers/FooterStatusProvider';

const StyledToolButton = styled.button`
  display: flex;
  width: ${({ isFooterOpen }) => (isFooterOpen ? '76px' : '44px')};
  height: ${({ isFooterOpen }) => (isFooterOpen ? '50px' : '32px')};
  padding: 4px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 11px;
  border: 2px solid #d9dae0;
  background: #fff;
  color: ${({ isTeacherHere }) => (isTeacherHere ? '#919EAB' : '#637381')};
  justify-content: space-around;
  cursor: ${({ isTeacherHere }) => (isTeacherHere ? 'not-allowed' : 'pointer')};
  font-size: 12px;
  font-weight: 500;
  user-select: none;

  > .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }
`;
const ToolButton = ({ icon, onClick, children, isTeacherHere }) => {
  const { isFooterOpen } = useFooterStatus();

  return (
    <StyledToolButton
      onClick={onClick}
      isTeacherHere={isTeacherHere}
      isFooterOpen={isFooterOpen}
      disabled={isTeacherHere && 'disabled'}
    >
      <div className='icon'>{icon}</div>
      {children}
    </StyledToolButton>
  );
};

ToolButton.propTypes = {
  icon: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node,
  isTeacherHere: PropTypes.bool,
  isFooterOpen: PropTypes.bool,
};

export default ToolButton;
