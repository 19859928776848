import styled from 'styled-components';

export const StyledSettingPopoverButton = styled.div.attrs(props => ({
  className: props.className
}))`  
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
    .iconButton {
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;

export const ShareContentTipTitle = styled.div`
  font-size: 18px;
  color: #fff;
`

export const ShareContentTipContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  color: #fff;
  font-size: 14px;
  padding: 4px 48px 28px;

  > .button {
    appearance: none;
    display: inline-block;
    padding: 4px 12px;
    width: 80px;
    margin-top: 20px;
    background: #EC7963;
    border: 0px;
    border-radius: 5px;
    cursor: pointer;
  }
`

export const StyledIcon = styled.div`
  svg > *{
    stroke: ${({ stroke }) => stroke ? stroke: 'initial'};
  }
`