import React, { useState, useMemo, useEffect } from 'react';
import Icon from '@onedesign/icon';
import styled from 'styled-components';
import RaiseCell from './RaiseCell';
import { Popover } from '@oneboard/ui-components';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { StyledRaiseHandPanel } from './RaiseHandControl.style';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import { Roles } from 'constants/index';

const RaiseHandButtonWrapper = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 56px;
  height: 56px;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.16);
  &:hover {
    background-color: #f2f2f2;
  }
`;

const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.isHighCount ? '10px' : '100%')};
  background: #ff7257;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  width: ${(props) => (props.isHighCount ? '28px' : '20px')};
  padding: ${(props) => (props.isHighCount ? '0px 6.5px 1px 6.5px' : '0')};
  position: absolute;
  top: 0;
  right: 0;
`;

const RaiseHandContainer = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  position: absolute;
  right: 16px;
  bottom: 165px;
  z-index: 2;
  box-shadow: 0px -1px 16px 0px rgba(145, 158, 171, 0.16);
`;

export default function RaiseHandButton() {
  const { roster } = useRosterState();
  const { isFooterOpen } = useFooterStatus();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const state = useMeetingState();
  const { context } = state;
  const { raisedHandAttendeeIds, role } = context;
  const { lowerAllAttendeesHand, lowerAllAttendeesHandFromAdvisor } = useMeetingDispatch();
  const [isRaised, setIsRaised] = useState(false);

  const lowerAllAttendeesHandHandler = () => {
    if (role === Roles.Advisor) {
      lowerAllAttendeesHandFromAdvisor();
    } else {
      lowerAllAttendeesHand();
    }
  };

  useEffect(() => {
    if (raisedHandAttendeeIds.length === 0) {
      setIsRaised(false);
    } else {
      setIsRaised(true);
    }
  }, [raisedHandAttendeeIds.length]);

  return (
    <>
      <Popover
        placement='topRight'
        trigger='click'
        arrow={true}
        content={
          <StyledRaiseHandPanel>
            {raisedHandAttendeeIds.length > 0 && (
              <div className='content'>
                {raisedHandAttendeeIds.map((attendeeId, index) => {
                  const name = roster[attendeeId]?.name;
                  return <RaiseCell name={name} attendeeId={attendeeId} key={attendeeId} index={index} />;
                })}
              </div>
            )}
            <div className='action'>
              <div className='btn' onClick={lowerAllAttendeesHandHandler}>
                舉手全部放下
              </div>
            </div>
          </StyledRaiseHandPanel>
        }
      >
        <RaiseHandButtonWrapper isFooterOpen={isFooterOpen}>
          {raisedHandAttendeeIds.length > 0 && (
            <NotificationWrapper isHighCount={raisedHandAttendeeIds.length > 9}>
              {raisedHandAttendeeIds.length > 9 ? '9+' : raisedHandAttendeeIds.length}
            </NotificationWrapper>
          )}
          <ButtonContainer>
            <Icon name={'RaisehandSolid'} size='xl' color='#07B2D8' />
          </ButtonContainer>
        </RaiseHandButtonWrapper>
      </Popover>
    </>
  );
}
