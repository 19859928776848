import { useState, useEffect } from 'react';
import { getServerTime } from 'services/board';
import moment from 'moment';

const useCountdownTimer = (courseStartTime) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    async function compareTimes() {
      const serverTimeResponse = await getServerTime();
      if (serverTimeResponse) {
        const serverTime = moment(serverTimeResponse.data.timestamp);
        const courseStartMoment = moment(courseStartTime);
        if (serverTime.isBefore(courseStartMoment)) {
          const diffInSeconds = courseStartMoment.diff(serverTime, 'seconds');
          setCountdown(diffInSeconds);
        } else {
          setCountdown(0);
        }
      }
    }

    compareTimes();
  }, [courseStartTime]);

  useEffect(() => {
    let intervalId;
    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [countdown]);

  return countdown;
};

export default useCountdownTimer;
