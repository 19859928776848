import styled from 'styled-components';

export const StyledCustomerService = styled.div.attrs(props => ({
  className: props.className
}))`
  width: 100%;
  height: 100%;
  
  >a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }
`;