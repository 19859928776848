import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useRosterState,
  useLocalVideo,
  ContentShare,
  useContentShareState,
  useAudioVideo,
  useRemoteVideoTileState,
  useToggleLocalMute,
  useVideoInputs,
  useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingState,
  MeetingStates,
  MeetingRoles,
  useMeetingDispatch,
  SettingPopoverButton,
  useLocalToolboxAuth,
} from '@oneboard/meeting';
import { WorldWallContainer, NetworkNotification, InsertPictureModal, ClassroomPerformanceCard, WaitingAnimation } from 'components';
import { QuizModal, TeachingMaterialContainer, SyncWhiteboard, SyncToolBox, SyncStagedVideoList, AuditVideoList, RightContainer } from 'containers';
import { MainLayout } from 'layouts';
import MainPageHeader from './Header';
import Icon from '@onedesign/icon';
import { Box, Modal, FullButton } from '@oneboard/ui-components';
import { useQuery } from 'utils/hooks/useQuery';
import { Roles, ClassType } from 'constants/index';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { useQuizService } from 'utils/hooks/useQuiz';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useGroupContext } from 'providers/GroupProvider';
import { StudentMainRoomNotification } from 'containers/BreakoutRoomNotification';
import { useNetworkContext } from 'providers/NetworkProvider';
import { useCheckDevice } from 'utils/hooks/useCheckDevice';
import { useRoom } from 'providers/RoomProvider';
import { useChatMessage } from 'utils/hooks/useChatMessage';
import { StyledSyncSingle, IconBox, SyncContent, NotificationLayout, ContentShareContainer } from './SyncSingle.style';

export const SyncSingle = () => {
  const { meetingId } = useParams();
  const { sharingAttendeeId } = useContentShareState();
  const [iframeGroup, setIframeGroup] = useState([]);
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const teacherIsJoined = attendees.filter(attendee => attendee.role === Roles.Teacher).length > 0;
  const advisorIsJoined = attendees.filter(attendee => attendee.role === Roles.Advisor).length > 0;
  const hasOtherRole = attendees.find(attendee => attendee.role === Roles.Teacher || attendee.role === Roles.Advisor);
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { raiseHand, lowerHand, noFocus, addToolboxAuthAttendeeIds } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { attendeeId: selfAttendeeId, stagedAttendeeIds, role, worldWall, userName, courseType, toolboxAuth } = meetingState.context;
  const query = useQuery();
  const { service: whiteboardService } = useWhiteboard();
  const { scaleToFit } = useZoomControl();
  const { isToolboxEnabled } = useLocalToolboxAuth();
  const { redirectToBreakoutMeeting, getBreakoutMeetingInfo, getBreakoutLatest } = useBreakoutMeeting();
  const {
    BreakoutRoomState,
    breakoutRoomStep,
    changeBreakoutRoomStep
  } = useGroupContext();
  const { networkNotice, closeNetworkNotice, openNetworkNotice } = useNetworkContext();
  const isInviting = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.BreakoutMeetingInvite}.${MeetingStates.Inviting}`
  });
  const isLeaving = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.BreakoutMeetingLeave}.${MeetingStates.Leaving}`
  });

  const isRaisingHand = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.RaiseHand}.${MeetingStates.RaisingHand}`
  });
  const audioVideo = useAudioVideo();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const tileIds = useMemo(() => Object.values(attendeeIdToTileId), [attendeeIdToTileId]);
  const { muted, toggleMute } = useToggleLocalMute();
  const { devices: videoDevices } = useVideoInputs();
  const { allNotCheck } = useCheckDevice();
  const { insertPictureModalSwitch } = useRoom();
  const { sendMessage } = useChatMessage();
  const meetingStatus = useMeetingStatus();

  // 課堂表現
  const isClassroomPerformanceOpen = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.ClassroomPerformance}.${MeetingStates.Open}`
  });

  const enterMessage = async () => {
    if (query.enterNotice === 'true') {
      let msg = '進教室囉';

      await sendMessage(msg);
    }
  };

  const toggleHand = useCallback(() => {
    const handler = isRaisingHand ? lowerHand : raiseHand;
    handler({ attendeeId: selfAttendeeId });
  }, [isRaisingHand, selfAttendeeId]);

  const closeTeachingMaterialHandler = material => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup(prev => prev.filter(item => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  const [inviteModalState, setInviteModalState] = useState(false);
  const [inviteInfo, setInviteInfo] = useState(null);

  const getBreakoutMeetingData = async () => {
    const data = await getBreakoutMeetingInfo();
    setInviteInfo(data);
  };

  const toggleInviteModalHandler = () => setInviteModalState(prev => !prev);

  const goToBreakoutRoomHandler = () => redirectToBreakoutMeeting();

  const isTilePaused = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.TileState}.${MeetingStates.Paused}`
  });

  useEffect(() => {
    isTilePaused ? openNetworkNotice() : closeNetworkNotice();
  }, [isTilePaused]);

  // 老師離開教室，trigger 學生關鏡頭
  useEffect(() => {
    if (videoDevices.length === 0) return;
    if (courseType === ClassType.SyncMultiple && isVideoEnabled && !teacherIsJoined && !advisorIsJoined) {
      toggleVideo();
    }
  }, [teacherIsJoined, advisorIsJoined]);

  // 同步 toolboxAuth 狀態
  useEffect(() => {
    if (toolboxAuth && (teacherIsJoined || advisorIsJoined)) {
      addToolboxAuthAttendeeIds({ attendeeId: selfAttendeeId });
    }
  }, [teacherIsJoined, advisorIsJoined, toolboxAuth]);

  // 上課類型為 SyncMultiple 時，由老師端在 SyncVideoList 組件 trigger 視訊
  useEffect(() => {
    if (courseType === ClassType.SyncMultiple) return;
    if (videoDevices.length === 0) return;
    if (!isVideoEnabled && meetingStatus === 1) {
      toggleVideo();
    }
  }, [meetingStatus]);

  useEffect(() => {
    if (!whiteboardService) return;
    const handler = () => {
      scaleToFit();
    };

    whiteboardService.addMagixEventListener('changeBookId', handler);
    whiteboardService.addMagixEventListener('closeViewMode', handler);

    return () => {
      whiteboardService.removeMagixEventListener('changeBookId', handler);
      whiteboardService.removeMagixEventListener('closeViewMode', handler);
    };

  }, [whiteboardService]);

  useEffect(() => {
    if (!isInviting) return;

    toggleInviteModalHandler();
    getBreakoutMeetingData();
    changeBreakoutRoomStep(BreakoutRoomState.grouping);
  }, [isInviting]);

  const getBreakoutInfo = async () => {
    const breakoutLatestInfo = await getBreakoutLatest(meetingId);
    if (breakoutLatestInfo?.status === 'Start') {
      await getBreakoutMeetingData();
      changeBreakoutRoomStep(BreakoutRoomState.grouping);
    }
  };

  const [roomClosingModal, setRoomClosingModalModal] = useState(false);
  const toggleRoomClosingModal = () => setRoomClosingModalModal(prev => !prev);

  useEffect(() => {
    getBreakoutInfo();
  }, []);

  useEffect(() => {
    if (isLeaving) {
      toggleRoomClosingModal();
    }
  }, [isLeaving]);
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);
  const isShowContentShare = useMemo(() =>
    contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]);

  const noFocusHandler = () => {
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        noFocus({
          userName,
          key: selfAttendeeId
        });
      }
    });
  };

  useEffect(() => {
    noFocusHandler();
  }, []);

  const teacher = attendees.find(attendee => attendee.role === Roles.Teacher);
  const advisor = attendees.find(attendee => attendee.role === Roles.Advisor);
  const teacherIsExist = !!teacher;
  const advisorIsExist = !!advisor;

  useEffect(() => {
    if (!audioVideo) return;
    if (!teacherIsExist && !advisorIsExist) return;
    if (query.classType !== ClassType.SyncMultiple) return;

    const stagedTileIds = stagedAttendeeIds
      .filter(attendeeId => attendeeIdToTileId[attendeeId])
      .map(attendeeId => attendeeIdToTileId[attendeeId]);

    if (teacherIsExist && !advisorIsExist) {
      const teacherTileId = attendeeIdToTileId[teacher.chimeAttendeeId];
      tileIds
        .filter(tileId => tileId !== teacherTileId)
        .filter(tileId => stagedTileIds.find(stagedTileId => stagedTileId === tileId) ? false : true)
        .forEach((tileId) => {
          audioVideo.pauseVideoTile(tileId);
        });
    } else if (!teacherIsExist && advisorIsExist) {
      const advisorTileId = attendeeIdToTileId[advisor.chimeAttendeeId];
      tileIds
        .filter(tileId => tileId !== advisorTileId)
        .filter(tileId => stagedTileIds.find(stagedTileId => stagedTileId === tileId) ? false : true)
        .forEach((tileId) => {
          audioVideo.pauseVideoTile(tileId);
        });
    } else if (teacherIsExist && advisorIsExist) {
      const teacherTileId = attendeeIdToTileId[teacher.chimeAttendeeId];
      const advisorTileId = attendeeIdToTileId[advisor.chimeAttendeeId];

      audioVideo.unpauseVideoTile(teacherTileId);
      tileIds
        .filter(tileId => tileId !== teacherTileId && tileId !== advisorTileId)
        .filter(tileId => stagedTileIds.find(stagedTileId => stagedTileId === tileId) ? false : true)
        .forEach((tileId) => {
          audioVideo.pauseVideoTile(tileId);
        });
    }
  }, [tileIds, attendees, stagedAttendeeIds]);

  const { studentQuizStateMatches, States } = useQuizService();
  const isOpenQuizModal = studentQuizStateMatches(States.Starting);

  useEffect(() => {
    if (query.classType === ClassType.SyncSingle) {
      if (allNotCheck) {
        if (!muted) {
          toggleMute();
        }
      } else {
        if (muted) {
          toggleMute();
        }
      }
    } else {
      if (!muted) {
        toggleMute();
      }
    }
  }, []);

  useEffect(() => {
    enterMessage();
  }, []);

  return (
    <Box display="flex" width="100%" height="100%">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        {breakoutRoomStep === BreakoutRoomState.grouping && <NotificationLayout>
          <StudentMainRoomNotification roomInfo={inviteInfo} isLeaving={isLeaving} />
        </NotificationLayout>}
        {networkNotice && <NotificationLayout>
          <NetworkNotification onClose={closeNetworkNotice} />
        </NotificationLayout>}
        <StyledSyncSingle
          className={`${breakoutRoomStep === BreakoutRoomState.grouping ? 'grouping' : ''} ${networkNotice ? 'networkNotice' : ''}`}>
          <MainLayout
            right={
              <RightContainer>
                <MainPageHeader />
              </RightContainer>
            }
            mode='single'
          >
            <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />
            {(worldWall.switch && role === Roles.Student) && <WorldWallContainer url={worldWall.url} />}
            <SyncContent>
              {
                stagedAttendeeIds.length > 0 && <Box className="stagedVideoListLayout" minHeight={150}>
                  <SyncStagedVideoList attendees={attendees} />
                </Box>
              }
              <SyncWhiteboard disableToolbox={!isToolboxEnabled} toolBox={
                <>
                  <SyncToolBox meetingId={meetingId} />
                </>
              }>
                <Box
                  display="flex"
                  position="absolute"
                  zIndex={5}
                  bottom={2}
                  right={2}
                >
                  {
                    (query.classType === ClassType.SyncMultiple) &&
                    (
                      <Box mr={2}>
                        <IconBox active={isRaisingHand} onClick={() => toggleHand()}>
                          <Icon name="RaisehandSolid" size='xs' />
                        </IconBox>
                      </Box>
                    )
                  }
                  <IconBox>
                    <SettingPopoverButton
                      className="settingPopoverButton"
                      placement="leftTop"
                      disabledPopover
                    />
                  </IconBox>
                </Box>
                {isClassroomPerformanceOpen && <Box
                  display="flex"
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={5}
                >
                  <ClassroomPerformanceCard />
                </Box>}
              </SyncWhiteboard>

              {(advisorIsExist && teacherIsExist) && <div className="auditListLayout">
                <AuditVideoList />
              </div>}

              {inviteModalState && <Modal width="600px" header="加入分組討論室" onClose={toggleInviteModalHandler}>
                你已受邀加入分組討論，但隨時可以返回主教室
                <Box display="flex" width="100%" pt={8}>
                  <Box width="100%" mr={2}>
                    <FullButton.Secondly onClick={toggleInviteModalHandler}>稍後加入</FullButton.Secondly>
                  </Box>
                  <Box width="100%" ml={2}>
                    <FullButton onClick={goToBreakoutRoomHandler}>加入</FullButton>
                  </Box>
                </Box>
              </Modal>}
              {roomClosingModal && <Modal width="600px" onClose={toggleRoomClosingModal} header='分組討論即將結束'>
                <Box>
                  老師已結束分組討論後，分組討論室將於30秒後關閉。
                </Box>
                <Box display="flex" justifyContent="center" width="100%" pt={8}>
                  <Box width="50%">
                    <FullButton.Secondly onClick={toggleRoomClosingModal}>關閉視窗</FullButton.Secondly>
                  </Box>
                </Box>
              </Modal>}

              {
                isOpenQuizModal && (
                  <QuizModal role={Roles.Student} />
                )
              }

            </SyncContent>

            {
              isShowContentShare && (
                <ContentShareContainer>
                  <ContentShare />
                </ContentShareContainer>
              )
            }

          </MainLayout>
        </StyledSyncSingle>
      </Box>

      {insertPictureModalSwitch && <InsertPictureModal />}

      {!hasOtherRole && <WaitingAnimation />}
    </Box>
  );
};