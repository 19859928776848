import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledRemoteVideo, StyledNameBox } from './RemoteVideo.style';
import {
  RemoteVideo as ChimeRemoteVideo,
  useRosterState,
  useAttendeeStatus,
} from 'amazon-chime-sdk-component-library-react';

export const RemoteVideoV2 = forwardRef(
  ({ className, tileId, attendeeId, onClick, isShowNameplate, isRatio = false, position }, ref) => {
    const { roster } = useRosterState();
    const attendee = roster[attendeeId];
    const { videoEnabled } = useAttendeeStatus(attendeeId);

    return (
      <StyledRemoteVideo
        className={className}
        data-testid='RemoteVideo'
        ref={ref}
        position={position}
        onClick={onClick}
      >
        {videoEnabled && !isShowNameplate ? (
          <ChimeRemoteVideo tileId={tileId} />
        ) : (
          <div className='tileBox'>
            <StyledNameBox bgColor={attendee?.color}>{attendee?.name?.slice(0, 1)}</StyledNameBox>
          </div>
        )}
      </StyledRemoteVideo>
    );
  }
);

RemoteVideoV2.propTypes = {
  className: PropTypes.string,
  attendeeId: PropTypes.string,
  tileId: PropTypes.number,
  onClick: PropTypes.func,
  isShowNameplate: PropTypes.bool,
};
