import { useCallback, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMeetingDispatch, useMeetingState, MeetingStates, MeetingActionTypes, MeetingRoles } from '@oneboard/meeting';

const QuizService = createContext({});

export const useQuiz = () => {
  const { dispatch } = useMeetingDispatch();
  const state = useMeetingState();
  const attendeeId = state.context.attendeeId;
  const quizContext = state.context.quiz;

  const setQuiz = useCallback(() => dispatch({ type: MeetingActionTypes.QuizSet }), []);
  const startQuiz = useCallback(({ count }) => {
    dispatch({ type: MeetingActionTypes.QuizStart, payload: { optionCount: count } });
  }, []);
  const endQuiz = useCallback(() => dispatch({ type: MeetingActionTypes.QuizEnd }), []);
  const reviewQuiz = useCallback(() => dispatch({ type: MeetingActionTypes.QuizReview }), []);
  const closeQuiz = useCallback(() => dispatch({ type: MeetingActionTypes.QuizClose }), []);

  const answerQuiz = useCallback(({ attendeeId, answer }) => dispatch({
    type: MeetingActionTypes.QuizAnswer,
    payload: {
      answer,
      attendeeId
    }
  }), []);

  const teacherQuizStateMatches = (matches) => state.matches({
    [MeetingStates.Joined]: {
      [MeetingRoles.Teacher]: {
        [MeetingStates.Quiz]: matches
      }
    }
  });

  const studentQuizStateMatches = (matches) => state.matches({
    [MeetingStates.Joined]: {
      [MeetingRoles.Student]: {
        [MeetingStates.Quiz]: matches
      }
    }
  });

  return {
    attendeeId,
    quizContext,
    setQuiz,
    startQuiz,
    answerQuiz,
    endQuiz,
    reviewQuiz,
    closeQuiz,
    teacherQuizStateMatches,
    studentQuizStateMatches,
    States: MeetingStates
  };
};

export const useQuizService = () => useContext(QuizService);

export const QuizServiceProvider = ({ children }) => {

  return (
    <QuizService.Provider value={useQuiz()}>
      {children}
    </QuizService.Provider>
  );
};


QuizServiceProvider.propTypes = {
  children: PropTypes.node
};