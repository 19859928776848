const BASE_URL = process.env.REACT_APP_BREAKOUT_API_URL;

export const create = ({ courseId, groups }) => {
  return fetch(`${BASE_URL}/GroupActivity/Put`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      courseId,
      groups
    })
  }).then(res => res.json());
};

export const start = ({ courseId }) => {
  return fetch(`${BASE_URL}/GroupActivity/Start`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      courseId,
    })
  }).then(res => res.json());
};

export const stop = ({ courseId }) => {
  return fetch(`${BASE_URL}/GroupActivity/Stop`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      courseId,
    })
  }).then(res => res.json());
};

export const getLatestGroupInfo = ({ courseId }) => {
  return fetch(`${BASE_URL}/GroupActivity/GetLatest?courseId=${courseId}`).then(res => res.json());
};

export const getJoinInfo = ({ courseId, userId }) => {
  return fetch(`${BASE_URL}/GroupActivity/GetJoinInfo?courseId=${courseId}&userId=${userId}`).then(res => res.json());
};

export const getLatest = ({ courseId }) => {
  return fetch(`${BASE_URL}/GroupActivity/GetLatest?courseId=${courseId}`).then(res => res.json());
};

export const getCallTeacher = ({ groupName, courseId, breakoutRoomId }) => {
  return fetch(`${BASE_URL}/GroupActivity/CallTeacher`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      groupName,
      courseId,
      breakoutRoomId
    })
  }).then(res => res.json());
};

export const broadcastToMain = ({ event, courseId, userId, userName, breakoutId }) => {
  return fetch(`${BASE_URL}/GroupActivity/BroadcastToMain`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      event,
      courseId,
      userId,
      userName,
      breakoutId
    })
  }).then(res => res.json());
};

export const broadcastToAll = ({ event, courseId, message }) => {
  return fetch(`${BASE_URL}/GroupActivity/BroadcastToAll`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      event,
      courseId,
      message
    })
  }).then(res => res.json());
};

export const getRecords = ({ courseId }) => {
  return fetch(`${BASE_URL}/GroupActivity/GetRecords?courseId=${courseId}`).then(res => res.json());
};

export const getResult = ({ courseId, timestamp }) => {
  return fetch(`${BASE_URL}/GroupActivity/GetResult?courseId=${courseId}&timestamp=${timestamp}`).then(res => res.json());
};