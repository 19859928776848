import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { useQuery } from 'utils/hooks/useQuery';
import { useWhiteboard } from '@oneboard/whiteboard';
import { IframeBridge } from '@oneboard/iframe-bridge';
import { Box } from '@oneboard/ui-components';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { useGroupContext } from 'providers/GroupProvider';
import { LoadingOutlined } from '@ant-design/icons';
import { useResource } from 'utils/hooks/useResource';
import { PRODUCT_TYPE } from 'constants/index';
import { getBookIds } from 'services/books';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { StyledBookList } from './BookList.style';
import { t } from 'utils/i18n';
import Books from './components/Books';
import ResourceBooks from './components/ResourceBooks';

const { TabPane } = Tabs;

const createScenes = (bookId, pages) => {
  return pages.map((page, index) => ({
    name: '' + (index + 1),
    ppt: {
      src: `${process.env.REACT_APP_BOOKS_IMAGE_URL}/${bookId}/${page.name}`,
      width: page.width,
      height: page.height,
    },
  }));
};

const getBookJsonById = (bookId) => {
  return fetch(`${process.env.REACT_APP_BOOKS_CDN_DOMAIN}/${bookId}/book.json`).then((res) => res.json());
};

const getBookImageJsonById = (bookId) => {
  return fetch(`${process.env.REACT_APP_BOOKS_IMAGE_URL}/${bookId}/book.json`).then((res) => res.json());
};

export const BookList = ({ courseInfo, width = '332px', pd = '16px' }) => {
  const { service: whiteboardService } = useWhiteboard();
  const { scaleToFit } = useZoomControl();
  const { addSceneQueue } = useGroupContext();
  const { currentBookId, setCurrentBook } = useResourceModal();
  const query = useQuery();
  const bookIds = query?.bookIds?.filter((id) => id !== '') || null;
  const { resourceIds, errorMessage, loading } = useResource();
  const [exampleBookIds, setExampleBookIds] = useState([]);
  const isOnelink = query.productType === PRODUCT_TYPE.onelink;
  const isMMSTeacher = query.productType === PRODUCT_TYPE.mmsTeacher;
  const organizationId = query.organizationId || null;

  const fetchBook = async (bookId) => await getBookJsonById(bookId);
  const fetchBooksHandler = async (bookIds) => Promise.all(bookIds.map(fetchBook));

  const bookIdChangeHandler = async ({ bookInfo }) => {
    const { pageInfos, bookId, display_name: label, resourceName } = bookInfo;
    if (pageInfos && pageInfos.length > 0) {
      const width = pageInfos[0].width;
      const height = pageInfos[0].height;
      const defaultPageIndex = 0;
      const DIR = `/book_iframe_${bookId}`;
      whiteboardService.putScenes(
        DIR,
        pageInfos.map((pageInfo) => ({ name: `${pageInfo.pageIndex + 1}` }))
      );
      whiteboardService.setScenePath(`/book_iframe_${bookId}/1`);
      setCurrentBook(bookId);
      const url = `${process.env.REACT_APP_ONEBOARD_READER_DOMAIN}/${bookId}/${defaultPageIndex}`;

      let bridge = await whiteboardService.getInvisiblePlugin(IframeBridge.kind);
      if (!bridge) {
        bridge = await IframeBridge.insert({
          room: whiteboardService,
          url, // iframe 的地址
          width,
          height,
          displaySceneDir: DIR,
          useSelector: true,
        });
      } else {
        bridge.setAttributes({
          url,
          width,
          height,
          displaySceneDir: DIR,
        });
      }
      bridge.scaleIframeToFit();
      whiteboardService.dispatchMagixEvent('changeBookId');
      addSceneQueue({ bookId: `book_iframe_${bookId}`, label, resourceName, width, height, name: '1' });
    }
  };

  const exampleBookIdChangeHandler = async ({ bookInfo }) => {
    const { bookId, display_name: label } = bookInfo;
    const { pageInfos } = await getBookImageJsonById(bookId);
    whiteboardService.putScenes(`/${bookId}`, createScenes(bookId, pageInfos));
    whiteboardService.setScenePath(`/${bookId}/1`);
    setCurrentBook(bookId);
    scaleToFit();
    whiteboardService.dispatchMagixEvent('changeBookId');
    addSceneQueue({ bookId, label, name: '1' });
  };

  const getExampleBookIds = async () => {
    try {
      const res = await getBookIds();
      if (res && res.length > 0) {
        const nextBookIds = res.map((item) => item.bookId);
        setExampleBookIds(nextBookIds);
      }
    } catch (error) {
      console.log('error:', error.message);
    }
  };

  useEffect(() => {
    getExampleBookIds();
  }, []);

  return (
    <StyledBookList data-testid='BookList' width={width} padding={pd}>
      <Tabs>
        <TabPane tab={t('containers.bookList.classMaterial', '上課教材')} key='0'>
          {!bookIds || bookIds.length === 0 ? (
            <Box display='flex' justifyContent='center'>
              {t('containers.bookList.noTextbookAvailable', '尚無可使用的教材')}
            </Box>
          ) : isOnelink || !courseInfo ? (
            <Books
              books={bookIds}
              currentBookId={currentBookId}
              onBookIdChange={bookIdChangeHandler}
              fetchBooks={fetchBooksHandler}
            />
          ) : (
            <ResourceBooks
              books={bookIds}
              onBookIdChange={bookIdChangeHandler}
              currentBookId={currentBookId}
              fetchBook={fetchBook}
              courseInfo={courseInfo}
            />
          )}
        </TabPane>
        {(isOnelink || (isMMSTeacher && organizationId)) && (
          <TabPane tab={t('containers.bookList.eduResources', '教學資源')} key='1'>
            {loading ? (
              <Box display='flex' justifyContent='center'>
                <LoadingOutlined style={{ color: '#A1A4B1' }} />
              </Box>
            ) : (
              <>
                {errorMessage && (
                  <Box display='flex' justifyContent='center'>
                    {errorMessage}
                  </Box>
                )}
                {!errorMessage && (!resourceIds || resourceIds.length === 0) && (
                  <Box display='flex' justifyContent='center'>
                    {t('containers.bookList.noTextbookAvailable', '尚無可使用的教材')}
                  </Box>
                )}
                {resourceIds && resourceIds.length !== 0 && (
                  <Books
                    books={resourceIds}
                    currentBookId={currentBookId}
                    onBookIdChange={bookIdChangeHandler}
                    fetchBooks={fetchBooksHandler}
                  />
                )}
              </>
            )}
          </TabPane>
        )}
        <TabPane tab={t('containers.bookList.textbookExample', '教材範例')} key='2'>
          <Books
            books={exampleBookIds}
            currentBookId={currentBookId}
            onBookIdChange={exampleBookIdChangeHandler}
            fetchBooks={fetchBooksHandler}
          />
        </TabPane>
      </Tabs>
    </StyledBookList>
  );
};

BookList.propTypes = {
  courseInfo: PropTypes.object,
  width: PropTypes.string,
  pd: PropTypes.string,
};
