import styled from 'styled-components';

export const StyledClassroomPerformanceCard = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .card {
    position: relative;
    z-index: 2;
    display: flex;
    width: 450px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 23px 44px rgba(3, 8, 17, 0.14);
    font-size: 16px;
    .cover {
      background-image: url('/images/performance-bg.png');
      background-size: cover;
      padding: 24px 16px;
    }
    .icon {
      width: 131px;
      height: 122px;
    }
    .name {
      margin-top: 4px;
      text-align: center;
      color: #fff;
      font-weight: 500;
    }
    > .main {
      padding: 24px;
      background-color: #fff;
      > .title {
        color: rgba(192, 104, 201, 1);
        font-weight: 500;
        line-height: 24px;
      }
      .subtitle {
        margin-top: 16px;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
      }
    }
    .row {
      display: flex;
      align-items: center;
      + .row {
        margin-top: 8px;
      }
      > .title {
        font-size: 12px;
        flex: 0 0 70px;
      }
      > .content {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: rgba(192, 104, 201, 1);
        .text {
          margin-left: 4px;
        }
      }
    }
  }
`;

export const StyledFireworks = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
