// ResourceBooks.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Box } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import { StyledResourceBox, StyledResource, StyledResourceItem } from '../BookList.style';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { pipe, prop, groupBy, map, omit, indexBy } from 'ramda';

const ResourceBooks = ({ books, courseInfo, currentBookId, fetchBook, onBookIdChange }) => {
  const { toggleResourceModalSwitch, setSeriesData } = useResourceModal();
  const [resourceMap, setResourceMap] = useState();
  const [seriesMap, setSeriesMap] = useState();
  const init = async () => {
    const { resources, series } = courseInfo;
    if (!resources || resources.length === 0) return;
    const _seriesMap = indexBy(prop('code'), series);
    const _resourceMap = pipe(groupBy(prop('seriesCode')), map(map(omit(['seriesCode']))))(resources);

    setResourceMap(_resourceMap);
    setSeriesMap(_seriesMap);
  };

  const clickHandler = (bookId, resourceName) => {
    fetchBook(bookId)
      .then((book) => {
        onBookIdChange({
          bookInfo: {
            ...book,
            resourceName,
          },
        });
      })
      .catch((error) => console.log(error.message));
  };

  const moreClickHandler = (seriesData) => {
    setSeriesData(seriesData);
    toggleResourceModalSwitch();
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <StyledResource>
      {resourceMap &&
        seriesMap &&
        Object.keys(resourceMap).map((seriesCode) => {
          return (
            <StyledResourceBox key={seriesCode}>
              <div className='head'>
                <div className='dot'></div>
                <div className='seriesTitle'>{seriesMap[seriesCode]?.name}</div>
                <Button
                  size='small'
                  type='text'
                  onClick={() => moreClickHandler(seriesMap[seriesCode])}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box mr='8px'>查看全部</Box>
                  <Icon name='ChevronRightOutline' size='xs' />
                </Button>
              </div>
              <div className='content'>
                <div className='dottedLine'></div>
                <div className='list'>
                  {resourceMap[seriesCode]?.map((resource) => {
                    return (
                      <StyledResourceItem
                        key={resource.bookId}
                        onClick={() => clickHandler(resource.bookId, resource.name)}
                        active={currentBookId === resource.bookId}
                      >
                        {resource.name}
                      </StyledResourceItem>
                    );
                  })}
                </div>
              </div>
            </StyledResourceBox>
          );
        })}
    </StyledResource>
  );
};

ResourceBooks.propTypes = {
  books: PropTypes.array.isRequired,
  currentBookId: PropTypes.string,
  onBookIdChange: PropTypes.func,
  fetchBook: PropTypes.func,
  courseInfo: PropTypes.object,
};

export default ResourceBooks;
