import styled from 'styled-components';

const VideoContainer = styled.div`
  display: flex;
  padding: 8px 8px 16px 8px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: #fff;
  margin-bottom: 10px;
`;

export default VideoContainer;
