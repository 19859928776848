import styled from 'styled-components';

export const StyledRecordButton = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: ${({ mode, classType }) => {
    let bgColor = '';
    switch (classType) {
      case 'noSync':
        bgColor = mode === 'dark' ? 'transparent' : '#fff';
        break;
      default:
        bgColor = mode === 'dark' ? '#232323' : '#fff';
        break;
    }
    return bgColor;
  }};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

export const StyledPopContent = styled.div`
  position: relative;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  user-select: none;
`;

export const StyledRecordBox = styled.div`
  .startRecordPopover .ant-popover-arrow-content::before {
    background-color: #ff7257;
  }

  .stopRecordPopover .ant-popover-arrow-content::before {
    background-color: #fff;
  }
`;
