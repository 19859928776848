import React, { useEffect } from 'react';
import {
  useToggleLocalMute,
  useLocalVideo
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingDispatch,
  useMeetingState
} from '@oneboard/meeting';
import { StyledStudentMainPage } from './Single.style';
import ReceiverPage from '../../receiver';


export const Single = () => {
  const { toggleVideo, isVideoEnabled } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const { noFocus } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { userName, attendeeId: selfAttendeeId } = meetingState.context;

  useEffect(() => {
    if (muted) {
      toggleMute();
    }
  }, []);

  useEffect(() => {
    if (!isVideoEnabled) {
      toggleVideo();
    }

  }, []);

  const noFocusHandler = () => {
    document.addEventListener('visibilitychange', function() {
      if (document.hidden) {
        noFocus({
          userName,
          key: selfAttendeeId
        });
      }
    });
  };

  useEffect(() => {
    noFocusHandler();
  }, []);

  return (
    <StyledStudentMainPage>
      <ReceiverPage.Single />
    </StyledStudentMainPage>
  );
};