import React, { useState, useEffect, useMemo } from 'react';
import { Box, Modal, Loading } from '@oneboard/ui-components';
import {
  ContentShare,
  useRosterState,
  useContentShareState,
  VideoGrid,
  useRemoteVideoTileState,
  useAudioVideo
} from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { CommentsIcon, MediaMetrics, CustomerService } from 'components';
import { WhiteboardFrame } from 'containers';
import { StyledReceiverMainPage, UiChatRoomLayout, ModalTitle, ToolBtn } from './Single.style';
import { Roles } from 'constants/index';
import {
  SettingPopoverButton,
  MeetingStates,
  MeetingRoles,
  useMeetingState,
  ChatRoom,
  LocalVideo,
  RemoteVideo,
} from '@oneboard/meeting';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { ContentShareLayout } from 'layouts';


export const Single = () => {
  const query = useQuery();
  const [chatRoomState, setChatRoomState] = useState(false);
  const { sharingAttendeeId } = useContentShareState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const audioVideo = useAudioVideo();
  const hasOtherRole = attendees.find(attendee => attendee.role === Roles.Teacher || attendee.role === Roles.Advisor);
  const tileIds = useMemo(() => Object.values(attendeeIdToTileId), [attendeeIdToTileId]);

  const meetingState = useMeetingState();
  const { roomId, userName, attendeeId: selfAttendeeId } = meetingState.context;
  const isWhiteboarding = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.Whiteboard}.${MeetingStates.Whiteboarding}`
  });

  const toggleChatRoomState = () => setChatRoomState(prev => !prev);

  const size = attendees.filter(attendee => attendee.role !== Roles.Observer).length || 0;

  useEffect(() => {
    if (sharingAttendeeId) {
      tileIds.forEach((tileId) => {
        audioVideo.pauseVideoTile(tileId);
      });
    } else {
      tileIds.forEach((tileId) => {
        audioVideo.unpauseVideoTile(tileId);
      });
    }
    return () => {
      tileIds.forEach((tileId) => {
        audioVideo.unpauseVideoTile(tileId);
      });
    };
  }, [tileIds, sharingAttendeeId]);

  return (
    <StyledReceiverMainPage>
      <Box display="flex" width="100%" height="100%">
        {
          !hasOtherRole ? (
            <Modal width="860px" closeIcon={false}>
              <Box my={3} display="flex" justifyContent="center" alignItems="center">
                <Loading />
              </Box>
              <ModalTitle>等待老師上課中</ModalTitle>
            </Modal>
          ) : (!sharingAttendeeId ?
            <VideoGrid size={size}>
              <LocalVideo />
              {
                attendees
                  .filter(attendee => attendee.chimeAttendeeId !== selfAttendeeId)
                  .filter(attendee => attendee.role !== Roles.Observer)
                  .filter((_, index) => index < MAX_STUDENT_VIDEO_COUNT - 1)
                  .map(attendee => {
                    const attendeeId = attendee.chimeAttendeeId;
                    return <RemoteVideo
                      key={attendeeId}
                      attendeeId={attendeeId}
                      tileId={attendeeIdToTileId[attendeeId]}
                    />;
                  })
              }
            </VideoGrid> : <ContentShareLayout
              contentShare={<ContentShare />}
              left={
                <>
                  <ToolBtn>
                    <SettingPopoverButton className="settingPopoverButton" disabledPopover />
                  </ToolBtn>
                  <ToolBtn>
                    <CustomerService />
                  </ToolBtn>
                  <ToolBtn>
                    <MediaMetrics />
                  </ToolBtn>
                </>
              }
              right={
                <>
                  {/* <ToolBtn onClick={toggleChatRoomState}>
                    <CommentsIcon isChatRoomOpen={chatRoomState} />
                  </ToolBtn> */}
                </>
              }
            >
            </ContentShareLayout>)
        }
        <UiChatRoomLayout active={chatRoomState}>
          <ChatRoom onClose={toggleChatRoomState} />
        </UiChatRoomLayout>
        {isWhiteboarding && <WhiteboardFrame roomId={roomId} role={query?.role} userName={userName}/>}
      </Box>
    </StyledReceiverMainPage>
  );
};