import { useEffect } from 'react';


const useBeforeunload = () => {

  useEffect(() => {
    // if (process.env.NODE_ENV !== 'production') return;
    const handleBeforeunload = event => {
      let returnValue = '';
      if (event.defaultPrevented) {
        event.returnValue = '';
      }
      if (typeof returnValue === 'string') {
        event.returnValue = returnValue;
        return returnValue;
      }
    };
    window.addEventListener('beforeunload', handleBeforeunload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, []);
};

export const Beforeunload = ({ children }) => {
  useBeforeunload();
  return children;
};