import { createMachine } from 'xstate';

export const RECORD_VIDEO_STATE = {
  Idle: 'Idle',
  On: 'On',
  Off: 'Off'
};

export const RECORD_VIDEO_EVENT = {
  turnOn: 'turnOn',
  turnOff: 'turnOff'
};

const recordVideoMachine = createMachine(
  {
    id: 'recordVideo',
    initial: RECORD_VIDEO_STATE.Idle,
    states: {
      // 閒置
      [RECORD_VIDEO_STATE.Idle]: {
        on: {
          [RECORD_VIDEO_EVENT.turnOn]: {
            target: RECORD_VIDEO_STATE.On,
          },
          [RECORD_VIDEO_EVENT.turnOff]: {
            target: RECORD_VIDEO_STATE.Off,
          },
        },
      },
      // 錄影中
      [RECORD_VIDEO_STATE.On]: {
        on: {
          [RECORD_VIDEO_EVENT.turnOff]: {
            target: RECORD_VIDEO_STATE.Off,
          },
        },
      },
      // 結束錄影
      [RECORD_VIDEO_STATE.Off]: {
        on: {
          [RECORD_VIDEO_EVENT.turnOn]: {
            target: RECORD_VIDEO_STATE.On,
          },
        },
      },
    },
  }
);

export default recordVideoMachine;