import styled from 'styled-components';

export const StyledChatRoom = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: ${({hasClose}) => hasClose ? '40px 0 0' : '24px 8px'};
  background-color: #161C27;
  
  >.close{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: #fff;
  }

  > .messagePanel {
    width: 100%;
    margin-top: ${({hasClose}) => hasClose ? '16px' : 0};
    height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    overflow-y: auto;
    &::-webkit-scrollbar{
			width: 1px;
			background-color: #232323;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #232323;
		}

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 8px;
      border-radius: 15px;
      color: #fff;
      background-color: #EC7963;

      span {
        padding: 0 2px;
      }

      svg {
        position: initial;
        width: inherit;
        height: inherit;
        margin-top: 0;
        margin-left: 0;
      }
    }
  }
`;


export const MessageControls = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;
  padding: 5px 0;
  .messageIcon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #EC7963;
  }
`

export const MessageInput = styled.input`
  appearance: none;
  width: 100%;
  padding: 12px 40px 12px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #33353A;
  border-radius: 8px;
  border: none;
  &:active, &:focus{
    outline: 0;
  }
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A1A4B1;
    opacity: 1; /* Firefox */
  }
  
  &::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A1A4B1;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #A1A4B1;
  }
`