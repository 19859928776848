import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalVideo, useRosterState, useToggleLocalMute } from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import {
  useMeetingState,
  useMeetingDispatch,
  MeetingStates,
  MeetingRoles,
} from '@oneboard/meeting';
import {
  TeachingMaterialContainer,
  SyncWhiteboard,
  SyncToolBox,
  WorldWallModal,
  SubRoomVideoList,
  RightContainer
} from 'containers';
import UsersModalMap from 'containers/UsersModal';
import { StudentSubRoomNotification } from 'containers/BreakoutRoomNotification';
import { MainLayout } from 'layouts';
import MainPageHeader from './Header';
import { Roles } from 'constants/index';
import { useGroupContext } from 'providers/GroupProvider';
import { Box, Modal, FullButton } from '@oneboard/ui-components';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import Icon from '@onedesign/icon';
import { useBoolean } from 'ahooks';
import {
  StyledSyncMultiple,
  IconBox,
  BreakoutRoomNotificationLayout,
  StyleUsersModalLayout,
  StyledSyncVideoList
} from './SyncMultiple.style';

export const SyncMultiple = () => {
  const { meetingId, breakoutId } = useParams();
  const query = useQuery();
  const {
    onstageFullModalState,
    toggleStageFullModal,
    worldWallModalState,
    toggleWorldWallModal,
  } = useGroupContext();
  const meetingState = useMeetingState();
  const { closeBroadcastModal } = useMeetingDispatch();
  const { isBreakoutRoom, getBreakoutLatest, callTeacher, broadcastEnterBreakoutRoom, redirectToMainMeeting } = useBreakoutMeeting();
  const { broadcastMessage, role } = meetingState.context;
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const observer = useMemo(() => attendees.find(attendee => attendee.role === Roles.Observer), [attendees]);
  const students = attendees.filter(attendee => attendee.role === Roles.Student);
  const { muted, toggleMute } = useToggleLocalMute();
  const [iframeGroup, setIframeGroup] = useState([]);
  const [userModalState, userModalActions] = useBoolean(false);

  const isLeaving = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.BreakoutMeetingLeave}.${MeetingStates.Leaving}`,
  }) || meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Observer}.${MeetingStates.BreakoutMeetingLeave}.${MeetingStates.Leaving}`
  });

  const isBroadcastMessage = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.BroadcastMessage}.${MeetingStates.Open}`
  });
  const [roomInfo, setRoomInfo] = useState(null);

  const loadTeachingMaterialsHandler = materials => {

    switch (materials.type) {
      case 'iframe':
        setIframeGroup(prev => [...prev, ...[materials]]);
        break;
      default:
        break;
    }
  };

  const closeTeachingMaterialHandler = material => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup(prev => prev.filter(item => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  const { isVideoEnabled, toggleVideo } = useLocalVideo();

  const isJoined = meetingState.matches(MeetingStates.Joined);

  useEffect(() => {
    if (!isVideoEnabled && query.role !== Roles.Advisor) {
      toggleVideo();
    }
  }, [isJoined]);

  const [groupInvitationModal, setGroupInvitationModal] = useState(false);
  const toggleGroupInvitationModal = () => setGroupInvitationModal(prev => !prev);

  const [roomClosingModal, setRoomClosingModalModal] = useState(false);
  const toggleRoomClosingModal = () => setRoomClosingModalModal(prev => !prev);

  const getLatestData = async () => {
    const data = await getBreakoutLatest(meetingId);
    const groupInfo = data.groups.filter(group => group.roomId === breakoutId);
    setRoomInfo(groupInfo[0]);
  };

  const callTeacherHandler = () => {
    toggleGroupInvitationModal();
    callTeacher({ courseId: meetingId, groupName: roomInfo.groupName, breakoutRoomId: breakoutId });
  };

  const toggleUsersModalHandler = () => userModalActions.toggle();

  useEffect(() => {
    if (isLeaving) {
      toggleRoomClosingModal();
    }
  }, [isLeaving]);

  const observerInitHandler = () => {
    if (role === Roles.Observer && !muted) {
      toggleMute();
    }
  };

  useEffect(() => {
    getLatestData();
    broadcastEnterBreakoutRoom();
  }, []);

  const isBreakoutMeetingEnd = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.BreakoutMeetingLeave}.${MeetingStates.End}`,
  }) || meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Observer}.${MeetingStates.BreakoutMeetingLeave}.${MeetingStates.End}`
  });

  useEffect(() => {
    if (isBreakoutMeetingEnd && isBreakoutRoom) {
      redirectToMainMeeting();
    }
  }, [isBreakoutMeetingEnd]);

  useEffect(() => {
    if (!observer) return;
    observerInitHandler();
  }, [observer]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%">
      {isBreakoutRoom && <BreakoutRoomNotificationLayout>
        <StudentSubRoomNotification title={roomInfo?.groupName} isLeaving={isLeaving} onGroupInvite={toggleGroupInvitationModal} />
      </BreakoutRoomNotificationLayout>}
      <StyledSyncMultiple>
        <MainLayout
          right={
            <RightContainer>
              <MainPageHeader />
            </RightContainer>
          }
          mode='sync'
          className={`${query.role} ${isBreakoutRoom ? 'isBreakoutRoom' : ''}`}
        >

          {students.length > 0 && <StyledSyncVideoList>
            <SubRoomVideoList />
          </StyledSyncVideoList>}

          <SyncWhiteboard toolBox={<SyncToolBox meetingId={meetingId} />}>
            <Box
              display="flex"
              position="absolute"
              zIndex={5}
              bottom={2}
              right={2}
            >
              <Box mr={2}>
                <IconBox onClick={toggleUsersModalHandler}>
                  <Icon name="UsersSolid" />
                </IconBox>
              </Box>
            </Box>
          </ SyncWhiteboard>
          <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />

          { worldWallModalState && <WorldWallModal onClose={toggleWorldWallModal} />}

          <StyleUsersModalLayout active={userModalState}>
            <UsersModalMap.LiteUsersModal onClose={toggleUsersModalHandler} />
          </StyleUsersModalLayout>

          {onstageFullModalState && <Modal onClose={toggleStageFullModal} header='警告通知' >人數已滿無法再邀請人上台</ Modal>}
          {groupInvitationModal && <Modal width="600px" onClose={toggleGroupInvitationModal} header='邀請教師加入討論'>
            <Box>
            是否確認送出請求，邀請教師加入此分組討論室？
            </Box>
            <Box display="flex" width="100%" pt={8}>
              <Box width="100%" mr={2}>
                <FullButton.Secondly onClick={toggleGroupInvitationModal}>取消</FullButton.Secondly>
              </Box>
              <Box width="100%" ml={2}>
                <FullButton onClick={callTeacherHandler}>送出請求</FullButton>
              </Box>
            </Box>
          </Modal>}
          {roomClosingModal && <Modal width="600px" onClose={toggleRoomClosingModal} header='分組討論即將結束'>
            <Box>
              老師已結束分組討論後，分組討論室將於30秒後關閉。
            </Box>
            <Box display="flex" justifyContent="center" width="100%" pt={8}>
              <Box width="50%">
                <FullButton.Secondly onClick={toggleRoomClosingModal}>關閉視窗</FullButton.Secondly>
              </Box>
            </Box>
          </Modal>}

          {isBroadcastMessage && <Modal width="600px" onClose={closeBroadcastModal} header='廣播訊息通知'>
            <Box>
              {broadcastMessage}
            </Box>
            <Box display="flex" justifyContent="center" width="100%" pt={8}>
              <Box width="50%">
                <FullButton onClick={closeBroadcastModal}>確定</FullButton>
              </Box>
            </Box>
          </Modal>}
        </MainLayout>
      </StyledSyncMultiple>
    </Box>
  );
};