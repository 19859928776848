import styled from 'styled-components';

const UserName = styled.p`
  color: #212b36;
  font-family: Noto Sans TC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157.143%;
  width: 100%;
  margin: 0;
`;

export default UserName;
