import styled from 'styled-components';

export const StyledVideoPopover = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ placement }) => placement === 'left' ? '8px 12px' : '12px 8px'};
  background-color: #161C27;
  flex-direction: ${({ placement }) => placement === 'left' ? 'column' : 'row'};
  > div {
    margin: ${({ placement }) => placement === 'left' ? '8px 0' : '0 8px'};
  }
`;

export const PopoverIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  
  &.reward {
    &:hover {
      color: #F9C74F;
    }
  }
`;