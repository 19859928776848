import React, { useEffect } from 'react';
import {
  useContentShareState,
  useContentShareControls
} from 'amazon-chime-sdk-component-library-react';
import SharerPage from '../../sharer';
import ReceiverPage from '../../receiver';
import { Roles } from 'constants/index';
import { useQuery } from 'utils/hooks/useQuery';

export const Single = () => {
  const query = useQuery();
  const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();
  const { toggleContentShare } = useContentShareControls();

  useEffect(() => {
    if (!isLocalUserSharing && query.role === Roles.Teacher) toggleContentShare();
  }, []);

  const createPage = () => {
    let component = null;

    if (isLocalUserSharing) {
      component = <SharerPage.Single />;
    } else {
      if (sharingAttendeeId) {
        component = <ReceiverPage.Single />;
      } else {
        component = <SharerPage.Single />;
      }
    }
    return component;
  };

  return (
    createPage()
  );
};