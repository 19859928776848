import styled from 'styled-components';

export const StyledTestList = styled.div`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  background-color: #fff;
  color: #212324;
  padding: 0 8px;
  max-height: 347px;
  overflow-y: auto;
  .ant-tabs-ink-bar {
    background-color: #ec7963 !important;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ec7963 !important;
  }
  .ant-tabs-content-holder {
    overflow-y: scroll;
    height: 286px;
  }
`;

export const StyledListContainer = styled.div`
  max-height: 320px;
`;

export const StyledList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  color: ${({ active }) => (active ? '#EC7963' : 'inherit')};

  &:hover {
    color: #ec7963;
  }
`;
export const StyledResource = styled.div`
  height: 263px;
`;

export const StyledResourceBox = styled.div`
  & + & {
    /* margin-top: 16px; */
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #ec7963;
      margin: 0 8px;
    }
    .seriesTitle {
      margin: 0 8px;
      flex: 1;
      font-size: 16px;
      color: #2f3746;
      font-weight: 500;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 8px;
    .dottedLine {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 28px;
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: repeating-linear-gradient(to top, #919eab3d, #919eab3d 3px, #ffffff 3px, #ffffff 6px);
      }
    }
    .list {
      overflow-y: auto;
      flex: 1;
    }
  }
`;

export const StyledResourceItem = styled.div`
  font-size: 16px;
  padding: 8px;
  font-weight: 500;
  user-select: none;
  color: ${({ active }) => (active ? '#212b36' : '#637381')};
  background-color: ${({ active }) => active && '#ffeddd'};
  border-radius: ${({ active }) => active && '8px'};
  &:hover {
    color: #212b36;
    background-color: #ffeddd;
    border-radius: 8px;
    cursor: pointer;
  }
`;
