import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import { useBitrix } from 'utils/hooks/useBitrix';
import { StyledWaitingAnimation, Animation, AnimationWrap } from './WaitingAnimation.style';

// 老師不在教室時的等待動畫組件
export const WaitingAnimation = () => {
  const { hideCRMChat } = useBitrix();
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    const animate = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/skyfall-ufo.json'
    });

    return () => {
      animate.onComplete = null;
      hideCRMChat();
    };
  }, [ref]);
  return (
    <StyledWaitingAnimation data-testid="WaitingAnimation">
      <AnimationWrap>
        <Animation ref={ref}>
          <div className="title">
            再等等喔～課程就快開始了！
          </div>
        </Animation>
      </AnimationWrap>
    </StyledWaitingAnimation>
  );
};