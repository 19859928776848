import React from 'react';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import styled from 'styled-components';
import { Roles } from 'constants/index';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';

const AllMicroButtonWrapper = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 56px;
  height: 56px;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.16);
  &:hover {
    background-color: #f2f2f2;
  }
`;

export default function DisableAllChatButton() {
  const { roster } = useRosterState();
  const meetingState = useMeetingState();
  const { context } = meetingState;
  const { isDisabledAllChat } = context;
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student).map((student) => student.chimeAttendeeId);
  const { isFooterOpen } = useFooterStatus();
  const { disableAllChat, enableAllChat } = useMeetingDispatch();

  const handleOnClick = () => isDisabledAllChat ? enableAllChat() : disableAllChat({ attendeeIds: students });

  return (
    <AllMicroButtonWrapper isFooterOpen={isFooterOpen}>
      <ButtonContainer onClick={handleOnClick}>
        {isDisabledAllChat ? (
          <Icon name='UserMessageMuteSolid' size='xl' color='#EC7963' />
        ) : (
          <Icon name='UserMessageSolid' size='xl' color='#8A94A6' />
        )}
      </ButtonContainer>
    </AllMicroButtonWrapper>
  );
}
