import { useState, useEffect, useCallback, useMemo } from 'react';
import { useRosterState, useRemoteVideoTileState, useLocalVideo } from 'amazon-chime-sdk-component-library-react';
import { ViewMode } from 'white-react-sdk';
import { useQuery } from 'utils/hooks/useQuery';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { Roles } from 'constants/index';

export const useViewMode = () => {
  const query = useQuery();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const { service: whiteboardService } = useWhiteboard();
  const { scaleToFit } = useZoomControl();
  const [currentViewMode, setCurrentViewMode] = useState(ViewMode.Freedom);
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { isVideoEnabled } = useLocalVideo();
  const advisor = useMemo(() => attendees.find(attendee => attendee.role === Roles.Advisor), [roster]);
  const advisorTileId = useMemo(() => attendeeIdToTileId[advisor?.chimeAttendeeId], [attendeeIdToTileId, advisor]);

  const viewModeConditionHandler = useCallback(() => {
    if (!whiteboardService) return;

    if (query.role === Roles.Teacher && !advisorTileId) {
      whiteboardService.setViewMode(ViewMode.Broadcaster);
    } else {
      whiteboardService.setViewMode(ViewMode.Follower);
    }
  }, [whiteboardService, advisorTileId]);

  const toggleViewMode = () => {
    if (!whiteboardService) return;
    const currentViewMode = whiteboardService.state.broadcastState.mode;
    if (currentViewMode !== ViewMode.Broadcaster) {
      whiteboardService.setViewMode(ViewMode.Broadcaster);
      setCurrentViewMode(ViewMode.Broadcaster);
    } else {
      whiteboardService.setViewMode(ViewMode.Freedom);
      setCurrentViewMode(ViewMode.Freedom);
      scaleToFit();
      whiteboardService.dispatchMagixEvent('closeViewMode');
    }
  };

  useEffect(() => {
    viewModeConditionHandler();
  }, [viewModeConditionHandler]);

  useEffect(() => {
    if (query.role === Roles.Advisor && isVideoEnabled) {
      whiteboardService.setViewMode(ViewMode.Broadcaster);
    }
  }, [isVideoEnabled]);

  return {
    ViewMode,
    currentViewMode,
    toggleViewMode
  };
};
