import { Group } from './Group';
import { Single } from './Single';
import { SyncSingle } from './SyncSingle';
import { SyncSingle as SyncSingleV2 } from './SyncSingleV2';
import { SyncMultiple } from './SyncMultiple';

const StudentPage = {
  Group,
  Single,
  SyncSingle,
  SyncSingleV2,
  SyncMultiple,
};

export default StudentPage;
