import styled from 'styled-components';
import { Box } from '@oneboard/ui-components';

export const StyledQuizModal = styled.div`
  position: absolute;
  top: ${({ position }) => `${position?.top}px`};
  left: ${({ position }) => `${position?.left}px`};

  .ghost{
    background-color: transparent;
  }

  > .wrapper {
    border-radius: 12px;
    background-color: #232323;
    color: #D6DAE0;
    padding: 30px 36px;
    display: flex;
    flex-direction: column;

    > .body {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      overflow: hidden;  
    }

    .closeButton{
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

  }
`;

export const StyledOptionSelectView = styled.div`
  width: 480px;
  > .options {
    font-size: 16px;
    max-width: 160px;
    margin-top: 16px;
    > .label {
      margin-bottom: 8px;
    }
  }

  .preview{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 160px;
    border: 2px solid #333333;
    font-size: 16px;
    border-radius: 16px;
    margin-top: 24px;
    color: #8B90A0;
  }

  >.action{
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
`;


export const StyledOptionView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ clickable }) => clickable ? 'auto' : 'none' };
  > .option{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
    margin-right: 16px;
    background: #33353A;
    border-radius: 6px;
    font-size: 30px;
    font-weight: 700;

    &.active {
      background-color: #EC7963;
    }
  }
`;

export const StyledQuizProgressingView = styled.div`
  margin-top: 10px;
  > .content {
    display: flex;
    justify-content: center;
    align-items: stretch;
    > .userListWrapper{
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        margin: auto;
        border-radius: 50%;
      }

      &:after {
        width: 8px;
        height: 8px;
        background-color: #EC7963;
        z-index: 1;
      }

      &:before {
        width: 32px;
        height: 24px;
        background-color: #33353A;
      }

    }

    > .statusBoxWrapper {
      > .label {
        margin-bottom: 20px;
      }

      .correctAnswerBox {

      }
    }
  }

  .primary{
    width: 100%;
    background-color: #EC7963;
  }
`;

export const StyledUserList = styled.div`
  position: relative;
  width: 100%;
  min-width: 320px;
  height: 500px;
  height: 100%;
  padding: 32px;
  background: #33353A;
  border-radius: 20px;

  > .listHeader {
    display: flex;
    justify-content: space-between;
    color: #fff;
    border-bottom: 1px solid #FFF;
    padding: 8px 10px;
  }

  > .listBody {
    height: 100%;
    max-height: 420px;
    overflow-y: auto;

    &::-webkit-scrollbar{
      width: 1px;
      background-color: #33353A;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #33353A;
    }

    > .listItem { 
      display: flex;
      justify-content: space-between;
      color: #fff;
      padding: 8px 10px;
      border-bottom: 1px solid #464646;
    }
  }

`;


export const StatusBox = styled(Box)`
  padding: 16px;
  background: #33353A;
  border-radius: 20px;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: ${({ disabled }) => disabled ? '#8B90A0' : '#fff'};

  &.correctStatus {
    > .count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 24px;
        margin-right: 4px;
        color: ${({ disabled }) => disabled ? '#8B90A0' : '#4C99A5'};
      }
    }
  }
  
  > .count {
    font-weight: 700;
    color: #8B90A0;
    span {
      font-size: 24px;
      margin-right: 4px;
      color: #fff;
    }
  }

  & + & {
    margin-left: 16px;
  }
`;

export const StyledHeader = styled.div`
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  font-size: 20px;
  font-weight: 500;
`;

export const StyledCloseModal = styled.div`
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > .wrapper {
    border-radius: 12px;
    background-color: #232323;
    color: #D6DAE0;
    padding: 30px 36px;
    display: flex;
    flex-direction: column;

    > .body {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      overflow: hidden;  
    }

    .closeButton{
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

  }
`;