import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@oneboard/ui-components';
import { StyledSyncMainLayout } from './SyncMainLayout.style';

export const SyncMainLayout = ({ children, top, right, mode = '' }) => {
  return (
    <StyledSyncMainLayout className={`${mode}`} data-testid="SyncMainLayout">
      <Box display="flex" className="top" justifyContent="center" gridArea="top">
        {top}
      </Box>
      <Box className="main" gridArea="main">{children}</Box>
      <Box className="right" gridArea="right">
        {right}
      </Box>
    </StyledSyncMainLayout>
  );
};

SyncMainLayout.propTypes = {
  top: PropTypes.node,
  right: PropTypes.node,
  mode: PropTypes.string,
  children: PropTypes.node,
};