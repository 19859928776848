import styled from 'styled-components';

const OutputLevel = styled.p`
  color: #637381;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin: 0;
  width: 104px;
  margin-right: 12px;
`;

export default OutputLevel;
