import { useState } from 'react';
import { useBoolean, useLocalStorageState, useSessionStorageState } from 'ahooks';

const BreakoutRoomState = {
  intro: 'intro',
  setting: 'setting',
  grouping: 'grouping',
  end: 'end'
};

export const useGroup = () => {
  const [onstageFullModalState, setOnstageFullModalState] = useState(false);
  const [usersModalState, setUsersModalState] = useState(false);
  const [carouselPanelState, setCarouselPanelState] = useState(false);
  const [usersMuteState, setUsersMuteState] = useState({});
  const [worldWallModalState, setWorldWallModalState] = useState(false);
  const [breakoutRoomSidebarState, setBreakoutRoomSidebar] = useState(false);
  const [breakoutRoomStep, setBreakoutRoomStep] = useState(BreakoutRoomState.setting);
  const [breakoutRoomEndNotice, setBreakoutRoomEndNotice] = useState(false);
  const [breakoutRoomIsLeaving, setBreakoutRoomIsLeaving] = useState(false);
  const [breakoutRoomIntroState, breakoutRoomIntroActions] = useBoolean(false);
  const [resultReviewState, resultReviewActions] = useBoolean(false);
  const [breakoutRoomResultState, breakoutRoomResultActions] = useBoolean(false);
  const [isRandomModalShow, setRandomModalShow] = useState(false);
  const [breakoutRoomIsEndState, setBreakoutRoomIsEndState] = useLocalStorageState(
    'breakoutRoomIsEnd',
    {
      defaultValue: false,
    },
  );
  const [breakoutRoomResultGroup, setBreakoutRoomResultGroup] = useState([]);
  const [breakoutRoomResultGroupInfo, setBreakoutRoomResultGroupInfo] = useState({});
  const [sceneTabsData, setSceneTabsData] = useSessionStorageState(
    'sceneData',
    {
      defaultValue: {
        currentScene: 'init',
        queue: [],
        sceneMap: {}
      }
    }
  );

  // 上台人數警告提示
  const toggleStageFullModal = () => setOnstageFullModalState(prev => !prev);

  // 學生列表
  const toggleUsersModal = () => setUsersModalState(prev => !prev);

  // 視訊列表輪播面板
  const toggleCarouselPanel = () => setCarouselPanelState(prev => !prev);

  // users靜音狀態
  const usersMuteHandler = (userMuteState) => {
    setUsersMuteState(prevUserMuteState => ({
      ...prevUserMuteState,
      ...userMuteState
    }));
  };

  const removeUsersMute = (id) => {
    setUsersMuteState(prevUserMuteState => {
      const { [id]: _, ...rest } = prevUserMuteState;
      return { ...rest };
    });
  };

  // WorldWallModal
  const toggleWorldWallModal = () => setWorldWallModalState(prev => !prev);

  // 分組選單
  const toggleBreakoutRoomSidebar = () => setBreakoutRoomSidebar(prev => !prev);

  const changeBreakoutRoomStep = (step) => setBreakoutRoomStep(step);

  // 結束通知
  const openBreakoutRoomEndNotice = () => setBreakoutRoomEndNotice(true);

  const closeBreakoutRoomEndNotice = () => setBreakoutRoomEndNotice(false);

  const toggleBreakoutRoomIsLeaving = () => setBreakoutRoomIsLeaving(prev => !prev);
  // 隨機小工具
  const toggleRandomModal = () => setRandomModalShow(prev => !prev);

  // 分組結果
  const [breakoutRoomResultData, setBreakoutRoomResultData] = useState({});
  const openBreakoutRoomResult = (breakoutRoomData) => {
    const { courseId, startTime, timestamp } = breakoutRoomData;
    setBreakoutRoomResultData({ courseId, startTime, timestamp });
    breakoutRoomResultActions.setTrue();
  };

  // 廣播 modal
  const [broadcastModalState, setBroadcastModalActions] = useBoolean(false);

  const setCurrentScene = ({ bookId }) => {
    const { queue, sceneMap } = sceneTabsData;
    const nextData = {
      currentScene: bookId,
      queue,
      sceneMap
    };

    setSceneTabsData(nextData);
  };

  const removeSceneQueue = ({ bookId, nextBookId }) => {
    const nextMap = { ...sceneTabsData.sceneMap };
    delete nextMap[bookId];

    const nextData = {
      currentScene: nextBookId,
      queue: sceneTabsData.queue.filter(id => id !== bookId),
      sceneMap: nextMap
    };

    setSceneTabsData(nextData);
  };

  const addSceneQueue = ({ bookId, label, resourceName, index = 0, width, height, name }) => {
    const isExist = sceneTabsData.queue.find(id => id === bookId);

    const sceneData = {
      currentScene: bookId,
      queue: [...sceneTabsData.queue, bookId],
      sceneMap: {
        ...sceneTabsData.sceneMap,
        [bookId]: {
          index,
          width,
          height,
          name,
          label: resourceName ? resourceName : label,
        },
      },
    };

    if (isExist) return;
    setSceneTabsData(sceneData);
  };

  const updateSceneMap = ({ bookId, index, name }) => {
    setSceneTabsData(prev => {
      const { currentScene, queue, sceneMap } = prev;
      const nextData = {
        currentScene,
        queue,
        sceneMap: {
          ...sceneMap,
          [bookId]: {
            ...sceneMap[bookId],
            index,
            name
          }
        }
      };
      return nextData;
    });
  };

  return {
    onstageFullModalState,
    toggleStageFullModal,
    usersModalState,
    toggleUsersModal,
    carouselPanelState,
    toggleCarouselPanel,
    usersMuteState,
    usersMuteHandler,
    removeUsersMute,
    worldWallModalState,
    toggleWorldWallModal,
    BreakoutRoomState,
    breakoutRoomSidebarState,
    toggleBreakoutRoomSidebar,
    breakoutRoomStep,
    changeBreakoutRoomStep,
    breakoutRoomEndNotice,
    openBreakoutRoomEndNotice,
    closeBreakoutRoomEndNotice,
    breakoutRoomIsLeaving,
    toggleBreakoutRoomIsLeaving,
    breakoutRoomIntroState,
    breakoutRoomIntroActions,
    breakoutRoomResultState,
    breakoutRoomResultActions,
    isRandomModalShow,
    toggleRandomModal,
    breakoutRoomResultData,
    setBreakoutRoomResultData,
    openBreakoutRoomResult,
    resultReviewState,
    resultReviewActions,
    breakoutRoomIsEndState,
    setBreakoutRoomIsEndState,
    breakoutRoomResultGroup,
    setBreakoutRoomResultGroup,
    breakoutRoomResultGroupInfo,
    setBreakoutRoomResultGroupInfo,
    broadcastModalState,
    setBroadcastModalActions,
    sceneTabsData,
    removeSceneQueue,
    addSceneQueue,
    setCurrentScene,
    updateSceneMap
  };
};