import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import { StyledReward } from './Reward.style';
import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 16px;
  width: 100%;
  height: 100%;
`;

const RewardType = {
  Trophy: 'Trophy',
};
const rewardSource = {
  [RewardType.Trophy]: { path: '/animations/trophy.json' },
};

export const Reward = ({ className, rewardType = RewardType.Trophy, onComplete }) => {
  const ref = useRef();
  const audioRef = useRef();
  const data = rewardSource[rewardType];

  useEffect(() => {
    if (!ref.current || !data) return;
    const animate = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: data.path,
    });
    let playAudioPromise = undefined;

    playAudioPromise = audioRef.current.play();

    if (playAudioPromise !== undefined) {
      playAudioPromise
        .then(() => {
          console.log('Auto paly audio started, playAudioPromise=%o', playAudioPromise);
        })
        .catch((error) => {
          console.error('play audio promise error=%o', error);
          //NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
        });
    }

    animate.onComplete = onComplete;
    return () => {
      animate.onComplete = null;
    };
  }, [ref, data, onComplete]);

  return (
    <>
      {/* <Overlay /> */}
      <StyledReward ref={ref} className={className} data-testid='Reward' />
      <audio src='/trophy.mp3' ref={audioRef}></audio>
    </>
  );
};
