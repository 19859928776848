import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAudioVideo, useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { Popover } from '@oneboard/ui-components';
import { Roles, ClassType } from 'constants/index';
import { StyledMediaMetrics, StyledMediaMetricsInfo } from './MediaMetrics.style';
import { t } from '../../utils/i18n';
import styled from 'styled-components';

const WifiContainer = styled.div`
  display: flex;
  padding: 10px 12px 14px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  width: fit-content;
  position: relative;
`;

const ConnectionText = styled.div`
  color: ${({ connectionState }) => {
    switch (connectionState) {
      case 'isPoor':
      case 'isStop':
        return '#FF5630';
      default:
        return '#74d633';
    }
  }};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  width: 98px;
`;

const WifiText = styled.div`
  color: #212b36;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

const filterConnectionState = (state) => {
  let connectionText = '';
  switch (state) {
    case 'isPoor':
      connectionText = t('containers.mediaMetrics.connectionText.poor', '不佳');
      break;
    case 'isStop':
      connectionText = t('containers.mediaMetrics.connectionText.stopped', '失敗');
      break;
    default:
      connectionText = t('containers.mediaMetrics.connectionText.good', '良好');
      break;
  }
  return connectionText;
};

const MediaMetricsInfo = ({ className, connectionState, streamDelayMs, packetLossPercent }) => {
  return (
    <WifiContainer>
      <ConnectionText connectionState={connectionState}>
        連線品質：{filterConnectionState(connectionState)}
      </ConnectionText>
      <WifiText>延遲：{Number(streamDelayMs).toFixed(2)}ms</WifiText>
      <WifiText>丟包率：{packetLossPercent}</WifiText>
    </WifiContainer>
  );
};

MediaMetricsInfo.propTypes = {
  className: PropTypes.string,
  connectionState: PropTypes.string,
  streamDelayMs: PropTypes.any,
  packetLossPercent: PropTypes.any,
};

export const MediaMetricsV2 = ({ className, placement = 'bottomRight' }) => {
  const state = useMeetingState();
  const { context } = state;
  const { role, courseType } = context;
  const audioVideo = useAudioVideo();
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const teacher = attendees.find((attendee) => attendee.role === Roles.Teacher);
  const student = attendees.find((attendee) => attendee.role === Roles.Student);

  const [videoStreamMetrics, setVideoStreamMetrics] = useState(null);
  const [streamDelayMs, setStreamDelayMs] = useState(0);
  const [packetLossPercent, setPacketLossPercent] = useState(0);
  const [connectionState, setConnectionState] = useState('isGood');

  useEffect(() => {
    if (!videoStreamMetrics) return;
    if (!student) return;
    if (!teacher) return;
    if (!videoStreamMetrics[student.chimeAttendeeId]) return;
    if (!videoStreamMetrics[teacher.chimeAttendeeId]) return;

    let streamSsrc = null;
    let streamMetricsInfo = null;

    if (role === Roles.Student) {
      streamSsrc = Object.keys(videoStreamMetrics[teacher.chimeAttendeeId]);
      streamMetricsInfo = videoStreamMetrics[teacher.chimeAttendeeId][streamSsrc[0]];
      setPacketLossPercent(streamMetricsInfo.videoDownstreamPacketLossPercent?.toFixed(2));
    } else {
      if (courseType === ClassType.SyncSingle || courseType === ClassType.Single) {
        streamSsrc = Object.keys(videoStreamMetrics[student.chimeAttendeeId]);
        streamMetricsInfo = videoStreamMetrics[student.chimeAttendeeId][streamSsrc[0]];
        setPacketLossPercent(streamMetricsInfo?.videoDownstreamPacketLossPercent?.toFixed(2));
      }
    }
  }, [videoStreamMetrics, student, teacher]);

  useEffect(() => {
    if (!audioVideo) return;
    const observer = {
      audioVideoDidStartConnecting: () => {
        setConnectionState('isGood');
      },
      connectionDidBecomeGood: () => {
        setConnectionState('isGood');
      },
      connectionDidBecomePoor: () => {
        setConnectionState('isPoor');
      },
      connectionDidSuggestStopVideo: () => {
        setConnectionState('isStop');
      },
      metricsDidReceive: (clientMetricsReport) => {
        const metricsReport = clientMetricsReport.getObservableMetrics();
        const videoMetricsReport = clientMetricsReport.getObservableVideoMetrics();
        setStreamDelayMs(metricsReport.audioSpeakerDelayMs);
        setVideoStreamMetrics(videoMetricsReport);
      },
    };

    if (audioVideo) {
      audioVideo.addObserver(observer);
    }

    return () => {
      audioVideo.removeObserver(observer);
    };
  }, [audioVideo]);

  return (
    <Popover
      arrow='true'
      arrowPointAtCenter={true}
      content={
        <MediaMetricsInfo
          connectionState={connectionState}
          streamDelayMs={streamDelayMs}
          packetLossPercent={packetLossPercent}
        />
      }
      placement={placement}
      // visible={true}
    >
      <StyledMediaMetrics className={className} data-testid='MediaMetrics'>
        <div className={`iconBtn ${connectionState}`}>
          <Icon name='WifiSolid' size='sm' />
        </div>
      </StyledMediaMetrics>
    </Popover>
  );
};

MediaMetricsV2.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
};
