import styled from 'styled-components';

export const StyledInput = styled.input`
  border: none;
  background-color: #2F3746;
  border-radius: 12px;
  padding: 12px 16px;
  height: 52px;
  color: #EEF1F5;
  
  > input {
    background-color: #2F3746;
    color:#fff;
    font-size: 16px;
    &::placeholder {
      color: #EEF1F5;
    }
  }
  
  svg {
    color: #fff;
  }
`;