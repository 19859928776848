import { useEffect, useState, useCallback } from 'react';
import { useWhiteboard } from '@oneboard/whiteboard';
import { ViewMode } from 'white-react-sdk';

export const useInteractiveObjects = (currentViewMode) => {
  const { service: whiteboardService } = useWhiteboard();
  const [audioIdentifier, setAudioIdentifier] = useState(null);
  const [videoIdentifier, setVideoIdentifier] = useState(null);

  const goPageHandler = useCallback((magixObj) => {
    const { pageIndex } = magixObj.payload;
    const { contextPath } = whiteboardService.state.sceneState;

    whiteboardService.setScenePath(`${contextPath}/${pageIndex}`);
  }, [whiteboardService]);

  const audioHandler = useCallback((magixObj) => {
    const { src: filename } = magixObj.payload;
    const { contextPath } = whiteboardService.state.sceneState;
    const bookId = contextPath.replace('/book_iframe_', '');
    const audioSrc = `${process.env.REACT_APP_ONEBOOK_RESOURCE_DOMAIN}/media/${bookId}/audio/${filename}.mp3`;
    const audioIsExist = whiteboardService.getPluginRectangle(audioIdentifier) !== undefined;

    if (audioIdentifier && audioIsExist ) {
      const attrs = whiteboardService.getPluginAttributes(audioIdentifier);
      whiteboardService.updatePlugin(audioIdentifier, {
        attributes: {
          ...attrs,
          src: audioSrc,
        },
      });

    } else {
      if (currentViewMode === ViewMode.Follower) return;
      const newAudioIdentifier = whiteboardService.insertPlugin('audio2', {
        originX: -240, originY: -43, width: 480, height: 86,
        attributes: {
          src: audioSrc,
          isNavigationDisable: false,
          paused: false
        },
      });
      setAudioIdentifier(newAudioIdentifier);
    }
  }, [audioIdentifier, whiteboardService, currentViewMode]);

  const videoHandler = useCallback((magixObj) => {
    const { videoInfo } = magixObj.payload;
    const { src: filename } = videoInfo;
    const { contextPath } = whiteboardService.state.sceneState;
    const bookId = contextPath.replace('/book_iframe_', '');
    const videoSrc = `${process.env.REACT_APP_ONEBOOK_RESOURCE_DOMAIN}/media/${bookId}/video/${filename}.mp4`;
    const videoIsExist = whiteboardService.getPluginRectangle(videoIdentifier) !== undefined;

    if (videoIdentifier && videoIsExist ) {
      const attrs = whiteboardService.getPluginAttributes(videoIdentifier);
      whiteboardService.updatePlugin(videoIdentifier, {
        attributes: {
          ...attrs,
          src: videoSrc,
        },
      });

    } else {
      const newVideoIdentifier = whiteboardService.insertPlugin('video2', {
        originX: -240, originY: -135, width: 480, height: 270,
        attributes: {
          src: videoSrc,
          isNavigationDisable: false,
          paused: false
        },
      });
      setVideoIdentifier(newVideoIdentifier);
    }
  }, [videoIdentifier, whiteboardService]);

  useEffect(() => {
    if (!whiteboardService) return;

    whiteboardService.addMagixEventListener('GoPage', goPageHandler);

    return () => {
      whiteboardService.removeMagixEventListener('GoPage', goPageHandler);
    };
  }, [whiteboardService, goPageHandler]);

  useEffect(() => {
    if (!whiteboardService) return;

    whiteboardService.addMagixEventListener('Audio', audioHandler);

    return () => {
      whiteboardService.removeMagixEventListener('Audio', audioHandler);
    };
  }, [whiteboardService, audioHandler]);

  useEffect(() => {
    if (!whiteboardService) return;

    whiteboardService.addMagixEventListener('Video', videoHandler);

    return () => {
      whiteboardService.removeMagixEventListener('Video', videoHandler);
    };
  }, [whiteboardService, videoHandler]);
};