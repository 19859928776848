import React, { createContext, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useMeetingDispatch, useMeetingState, MeetingStates } from '@oneboard/meeting';
import { start, stop, create, getJoinInfo, getLatest, getCallTeacher, broadcastToMain, broadcastToAll } from 'services/breakout';
import { Roles } from 'constants/index';
import { useWhiteboard } from '@oneboard/whiteboard';

const StateContext = createContext({});

export const useBreakoutMeeting = () => {
  const { userName, role, userId } = useQuery();
  const { breakoutId, meetingId } = useParams();
  const meetingManager = useMeetingManager();
  const state = useMeetingState();
  const isJoined = state.matches(MeetingStates.Joined);
  const {
    joinMeeting,
  } = useMeetingDispatch();
  const { service: whiteboardService } = useWhiteboard();

  const history = useHistory();
  const location = useLocation();
  const isBreakoutRoom = breakoutId ? true : false;


  const joinBreakoutMeeting = async () => {
    await joinMeeting({
      roomId: breakoutId,
      userName: userName,
      role: role,
      userId: userId
    });
  };

  const broadcastEnterBreakoutRoom = async () => {
    await broadcastToMain({
      event: 'enterBreakoutRoom',
      courseId: meetingId,
      userId,
      userName,
      breakoutId
    });
  };

  const broadcastLeaveBreakoutRoom = async () => {
    await broadcastToMain({
      event: 'leaveBreakoutRoom',
      courseId: meetingId,
      userId,
      userName,
      breakoutId
    });
  };

  useEffect(() => {
    if (!breakoutId) return;
    joinBreakoutMeeting();
  }, []);

  useEffect(() => {
    if (!breakoutId) return;
    if (!isJoined) return;
    meetingManager.start();
  }, [isJoined]);

  const createBreakout = useCallback(async ({ groups = [] }) => {

    await create({
      courseId: meetingId,
      groups: groups.map(group => (
        {
          groupName: group.groupName,
          members: group.members.map(member => ({
            userName: member.name,
            userId: member.userId
          }))
        }
      ))
    });

  }, [meetingId, breakoutId, location, history]);

  const startBreakout = useCallback(async () => {

    await start({ courseId: meetingId });

  }, [meetingId, breakoutId, location, history]);


  const redirectToBreakoutMeeting = async () => {
    try {
      const response = await getJoinInfo({ courseId: meetingId, userId });
      if (response.status === 'success') {
        const { roomId: breakoutId } = response.data;
        const searchParams = new URLSearchParams(location.search);
        whiteboardService.disconnect();
        meetingManager.leave().then(() => {
          window.location.href = `/${meetingId}/breakout/${breakoutId}?${searchParams.toString()}`;
        });
      }
    } catch (error) {
      console.log('error:', error);
      message.error('加入分組失敗，請重新嘗試');
    }
  };

  const getBreakoutMeetingInfo = async () => {
    const response = await getJoinInfo({ courseId: meetingId, userId });
    return response.data;
  };

  const redirectToMainMeeting = async () => {
    const searchParams = new URLSearchParams(location.search);
    if (role === Roles.Student) {
      searchParams.set('isBreakoutBack', true);
    }

    if (role === Roles.Teacher) {
      searchParams.set('isBreakoutBack', true);
    }
    whiteboardService.disconnect();
    await meetingManager.leave();
    await broadcastLeaveBreakoutRoom();

    if (role !== Roles.Observer) {
      window.location.href = `/${meetingId}?${searchParams.toString()}`;
    }
  };

  const goToBreakoutMeeting = async ({ breakoutId }) => {
    const searchParams = new URLSearchParams(location.search);
    await meetingManager.leave();
    whiteboardService.disconnect();
    window.location.href = `/${meetingId}/breakout/${breakoutId}?${searchParams.toString()}`;
  };

  const getBreakoutLatest = async (meetingId) => {
    const { data } = await getLatest({ courseId: meetingId });
    return data;
  };

  const stopBreakout = async (courseId) => await stop({ courseId });

  const callTeacher = async ({ courseId, groupName, breakoutRoomId }) => await getCallTeacher({ courseId, groupName, breakoutRoomId });

  const broadcastMessageToAll = async ({ event = 'broadcastMessageToAll', courseId, message }) => await broadcastToAll({ event, courseId, message });

  return {
    createBreakout,
    startBreakout,
    stopBreakout,
    joinBreakoutMeeting,
    isBreakoutRoom,
    getBreakoutLatest,
    callTeacher,
    redirectToMainMeeting,
    goToBreakoutMeeting,
    redirectToBreakoutMeeting,
    getBreakoutMeetingInfo,
    broadcastEnterBreakoutRoom,
    broadcastMessageToAll,
  };
};

export const BreakoutMeetingProvider = ({ children }) => {
  const { isInviting, ...context } = useBreakoutMeeting();
  return (
    <StateContext.Provider value={context}>
      {!isInviting && children}
    </StateContext.Provider>
  );
};

BreakoutMeetingProvider.propTypes = {
  children: PropTypes.node
};


export const useBreakoutMeetingService = () => useContext(StateContext);