import React from 'react';
import PropTypes from 'prop-types';
import { StyledVideoPopover } from './VideoPopover.style';

export const VideoPopover = ({ className, placement, children }) => {

  return (
    <StyledVideoPopover className={className} placement={placement} data-testid="VideoPopover">
      {children}
    </StyledVideoPopover>
  );
};