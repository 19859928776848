import React, { useState } from 'react';
import Icon from '@onedesign/icon';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { BookList } from 'containers';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';

export const MaterialButton = () => {
  const { courseInfo } = useCourseInfo();
  const { isFooterOpen } = useFooterStatus();
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = (e) => {
    setOpen(false);
  };

  return (
    <>
      <ToolButton
        icon={<Icon name='BooksSolid' size='md' color='#4246A8' />}
        onClick={showModal}
      >
        {isFooterOpen && '教材'}
      </ToolButton>
      <ToolModal active={open} onClose={handleCancel} title={'教材'} height='450px' justifyContent='flex-start'>
        <BookList courseInfo={courseInfo} width='auto' pd='0' />
      </ToolModal>
    </>
  );
};
