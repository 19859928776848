import React, { useRef } from 'react';
import { 
  useMeetingManager,
  useAudioInputs,
  useLocalAudioInputActivityPreview
} from 'amazon-chime-sdk-component-library-react';
import { Box, FullButton, Select } from '@oneboard/ui-components';
import { useDeviceDetectDispatch, useDeviceDetectState } from '../../providers/DeviceDetectProvider';
import Icon from '@onedesign/icon';
import { StyledMicrophoneStep, StyledTestBar } from './MicrophoneStep.style';

const ActivityBar = () => {
  const activityBarRef = useRef();
  useLocalAudioInputActivityPreview(activityBarRef);

  return (
    <StyledTestBar>
      <div className="baseLayer"></div>
      <div className="bar" ref={activityBarRef}></div>
      <div className="coverBox"></div>
    </StyledTestBar>
  )
}

export const MicrophoneStep = ({ className }) => {
  const { goAudio, updateEquipment } = useDeviceDetectDispatch();
  const deviceDetectState = useDeviceDetectState();
  const state = deviceDetectState.context;

  const meetingManager = useMeetingManager();
  const { devices, selectedDevice } = useAudioInputs();
  
  const deviceChangeHandler = (deviceId) => meetingManager.startVideoInputDevice(deviceId);
  
  const abnormalHandler = () => {
    updateEquipment({
      microphone: false
    });
    goAudio();
  }

  const normalHandler = () => {
    updateEquipment({
      microphone: true
    });
    goAudio();
  }
  
  return (
    <StyledMicrophoneStep className={className} data-testid="MicrophoneStep">
      <div className="microphoneBlock">
        <div className="microphoneStepWrap">
          {devices.length > 0 && <Select className="select" defaultValue={selectedDevice} onChange={deviceChangeHandler}>
            {devices.map((item) => <Select.Option 
              value={item.deviceId} 
              key={item.deviceId}>
                {item.label}
              </Select.Option>)}
          </Select>}
          <div className="microphoneIcon">
            <Icon name="MicrophoneAltSolid" size='xl' color="#2F3746" />
          </div>
          <ActivityBar />
          <div className="content">
            <div className="title">請說話</div>
            <div className="desc">“我正在測試麥克風”</div>
          </div>
        </div>
      </div>
      <Box mt={4}>
        <div className="question">
          <div className="title">
            請觀察能否看到顏色跳動？
          </div>
          <div className="action">
            <Box mr={1} width="100%">
              <FullButton.Secondly onClick={abnormalHandler}>不行</FullButton.Secondly>
            </Box>
            <Box ml={1} width="100%">
              <FullButton onClick={normalHandler}>可以</FullButton>
            </Box>
          </div>  
        </div>
      </Box>
    </StyledMicrophoneStep>
  );
};