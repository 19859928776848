import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { Popover, Tooltip, Button } from 'antd';
import { Box } from '@oneboard/ui-components';
import { useLocalStorageState } from 'ahooks';
import { StyledClassroomPerformanceButton, StyledAwardButton, StyledPopContent, StyledRecordBox } from './ClassroomPerformanceButton.style';
import { t } from '../../utils/i18n';
import { Trans } from 'react-i18next';

export const ClassroomPerformanceButton = ({ className, onOpen }) => {
  const buttonRef = useRef();
  const [tipStorage, setTipStorage] = useLocalStorageState('classroom_performance_tip');

  return (
    <StyledRecordBox>
      <Popover
        getPopupContainer={() => buttonRef.current}
        overlayInnerStyle={{
          backgroundColor: '#4C99A5',
          borderRadius: '8px',
          width: '280px',
        }}
        content={(
          <StyledPopContent>
            <div className="content">
              <Box mb='12px'>{t('containers.classroomPerformanceButton.coursePerformance', '課程表現')}</Box>
              <Trans
                i18nKey='containers.classroomPerformanceButton.clickMedal'
              >
                點擊「獎牌 <Icon name="MedalSolid" size='xs' /> 」，可以讓學生看到今天的課堂表現，學生也會同步顯示！
              </Trans>
            </div>
            <div className="actions">
              <Button onClick={() => setTipStorage(true)}>
                {t('containers.classroomPerformanceButton.understand', '我知道了')}
              </Button>
            </div>
          </StyledPopContent>
        )}
        placement={'topRight'}
        visible={!tipStorage}
        arrowPointAtCenter={true}
      >
        <StyledClassroomPerformanceButton className={className} data-testid="ClassroomPerformanceButton" ref={buttonRef}>
          <Tooltip placement="top" title={t('containers.classroomPerformanceButton.classroomPerformance', '課堂表現')}>
            <StyledAwardButton onClick={onOpen}>
              <Icon name="MedalSolid" size='xl' color="rgba(47, 55, 70, 1)" />
            </StyledAwardButton>
          </Tooltip>
        </StyledClassroomPerformanceButton>
      </Popover>
    </StyledRecordBox>
  );
};

ClassroomPerformanceButton.propTypes = {
  className: PropTypes.string,
  onOpen: PropTypes.func,
};