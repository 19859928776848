import styled from 'styled-components';

export const StyledWorldWallContainer = styled.div.attrs(props => ({
  className: props.className
}))`
/* Code Here */
`;

export const ControlWrap = styled.div`
  position: absolute;
  top: 150px;
  left: 150px;

  &.iframeBox{
    padding: 15px;
    width: 650px;
    height: 430px;
    background-color: #232323;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;