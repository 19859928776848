import React, { useState } from 'react';
import Icon from '@onedesign/icon';
import styled from 'styled-components';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { ResourceList } from 'containers';
import { ChapterSectionList } from 'containers';
import { LevelList } from 'containers';
import { QuestionTypeList } from 'containers';
import { message } from 'antd';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useGroupContext } from 'providers/GroupProvider';
import { Box } from '@oneboard/ui-components';
import { CancelButton, SendButton } from 'components/NewOneBoard/common/MessageButton';
import { getBooksData } from 'api/getBooks';
import { getQuestionsData } from 'api/getQuestions';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import educations from './constants/educations';
import volumns from './constants/volumns';

const ButtonContainer = styled(Box)`
  display: flex;
  height: 68px;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const gte = 112;
const lte = 112;
const curriculum = [99, 108];

export const QuestionButton = () => {
  const { service: whiteboardService } = useWhiteboard();
  const { scaleToFit } = useZoomControl();
  const { addSceneQueue } = useGroupContext();
  const { courseInfo } = useCourseInfo();
  const { isFooterOpen } = useFooterStatus();
  const [openChapterModal, setOpenChapterModal] = useState(false);
  const [openLevelModal, setOpenLevelModal] = useState(false);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [resource, setResource] = useState(null); // 教材
  const [section, setSection] = useState(null); // 節、重點
  const [knowledge, setKnowledge] = useState(null); // 知識點
  const [selectedLevel, setSelectedLevel] = useState(null); // 難易度
  const [selectedType, setSelectedType] = useState(null); // 題型
  const [selectedSection, setSelectedSection] = useState(null);
  const [usedQuestions, setUsedQuestions] = useState([]);

  //避免chapter為空字串
  const resourceChapter = resource?.chapter && resource?.chapter?.match(/\d+/)[0];

  const showModal = () => {
    setOpenChapterModal(true);
  };

  const handleCancelModal = (e) => {
    setOpenChapterModal(false);
    setOpenLevelModal(false);
    setOpenQuestionModal(false);
    setSelectedLevel(null);
    setResource(null);
    setKnowledge(null);
    setSection(null);
    setSelectedSection(null);
    setSelectedType(null);
  };

  const mapEducationToCategory = (education) => {
    return educations[education];
  };

  const mapEducationToVolumn = (education) => {
    return volumns[education] || education;
  };

  const isUnsupportedMaterial = (mappedEducation, subject) => {
    const unsupportedE = ['SO', 'NA', 'CW']; // 小學不支援的科目
    const unsupportedH = ['EW', 'MA', 'NA', 'BI', 'PH', 'CE', 'EA', 'SO', 'EN']; // 高中不支援的科目
    const unsupportedSubject = ['CN', 'CO', 'PE', 'AR', 'TE', 'CU', 'FE', 'PE']; // 不支援的科目

    if (
      (mappedEducation === 'E' && unsupportedE.includes(subject)) ||
      (mappedEducation === 'H' && unsupportedH.includes(subject)) ||
      unsupportedSubject.includes(subject)
    ) {
      return true;
    }

    return false;
  };

  const generateChapterPayload = (chapter) => {
    const codeParts = chapter.seriesCode.split('-');
    if (codeParts.length >= 5) {
      const subject = codeParts[0];
      const education = codeParts[1];
      const publisher = codeParts[3];

      const mappedEducation = mapEducationToCategory(education);
      const mappedVolumn = mapEducationToVolumn(education);

      if (!mappedEducation) {
        message.error('暫不支援此教材');
        return;
      }

      const eduSubject = mappedEducation + subject;

      return {
        filters: {
          eduSubject: [eduSubject],
          education: [mappedEducation],
          subject: [subject],
          year: {
            gte,
            lte,
          },
          curriculum,
          publisher: [publisher],
          volume: mappedVolumn,
        },
        sort: [
          {
            field: 'year',
            direction: 'asc',
          },
        ],
        pagination: {
          page: 1,
          pageSize: 200,
        },
      };
    }
    return null;
  };

  const handleResourceNextStep = () => {
    const codeParts = resource.seriesCode.split('-');
    const subject = codeParts[0];
    const education = codeParts[1];
    const mappedEducation = mapEducationToCategory(education);

    // 檢查是否支援此教材，chapter為空字串也不支援
    if (isUnsupportedMaterial(mappedEducation, subject) || !resourceChapter) {
      message.error('暫不支援此教材');
      return;
    }

    const payload = generateChapterPayload(resource);
    if (payload) {
      getBooksData(payload).then((data) => {
        if (data.data.length === 0) {
          message.error('暫不支援此教材');
          return;
        } else {
          const section = data.data[0].chapter[resourceChapter - 1];
          setSection(section);
        }
      });
    }
  };

  const handleSectionNextStep = () => {
    if (knowledge) {
      setOpenQuestionModal(true);
      setOpenChapterModal(false);
    } else {
      setSection(selectedSection);
    }
    setSelectedSection(null);
  };

  const handleQuestionTypeNextStep = () => {
    if (selectedType) {
      setOpenQuestionModal(false);
      setOpenLevelModal(true);
    }
  };

  const handleLevelLastStep = () => {
    setOpenLevelModal(false);
    setOpenQuestionModal(true);
    setSelectedLevel(null);
  };

  const generateQuestionsPayload = (knowledge, selectedLevel, selectedType) => {
    if (knowledge && selectedLevel) {
      const knowledgeCodes = knowledge.map((knowledge) => knowledge.code);

      return {
        filters: {
          knowledge: knowledgeCodes,
          difficulty5: [selectedLevel.code],
          tag: [],
          quesGroup: [selectedType.code],
        },
        exclude: {
          quiz: [],
          paper: [],
        },
        sort: [
          {
            field: 'difficulty5',
            direction: 'asc',
          },
        ],
        pagination: {
          page: 1,
          pageSize: 120,
        },
      };
    }

    return null;
  };

  const handleLevelNextStep = () => {
    if (knowledge && selectedLevel && selectedType) {
      const payload = generateQuestionsPayload(knowledge, selectedLevel, selectedType);
      if (payload) {
        getQuestionsData(payload)
          .then((data) => {
            insertQuestions(data.data);
          })
          .catch((error) => {
            console.error('API Error:', error);
          });
      }
    }
  };

  const loadScene = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ src: url, width: img.width, height: img.height });
      img.onerror = reject;
      img.src = url;
    });
  };

  const insertQuestions = async (data) => {
    if (data.length === 0) {
      message.error('沒有測驗題目');
      return;
    }

    const unusedQuestions = data.filter((question) => !usedQuestions.includes(question.questionId));
    if (unusedQuestions.length === 0) {
      message.error('此難度題目皆已使用過');
      return;
    }

    // 隨機選擇沒有使用過的題目
    const randomIndex = Math.floor(Math.random() * unusedQuestions.length);
    const selectedQuestion = unusedQuestions[randomIndex];

    // 更新已使用题目的狀態
    setUsedQuestions((prevUsed) => [...prevUsed, selectedQuestion.questionId]);

    //overall: 題目, answer: 答案, analyze: 解析
    const { overall, answer, analyze } = selectedQuestion.image;
    const { questionId } = selectedQuestion;
    const questionName = section.name;
    const label = section.name;
    const scenes = [];

    const prepareSceneData = async (url) => {
      if (!url) return null;
      const sceneData = await loadScene(url);
      return { ppt: sceneData };
    };

    if (overall) scenes.push({ name: '1', ...(await prepareSceneData(overall)) });
    if (answer) scenes.push({ name: '2', ...(await prepareSceneData(answer)) });
    if (analyze) scenes.push({ name: '3', ...(await prepareSceneData(analyze)) });

    const DIR = `/question_${questionId}`;
    whiteboardService.putScenes(DIR, scenes);
    whiteboardService.setScenePath(`${DIR}/1`);
    scaleToFit();

    scenes.forEach((scene) => {
      addSceneQueue({
        bookId: `question_${questionId}`,
        label,
        questionName,
        width: scene.ppt.width,
        height: scene.ppt.height,
        name: '1',
      });
    });
    message.success('上傳成功');
    setOpenLevelModal(false);
    setSelectedLevel(null);
  };

  return (
    <>
      <ToolButton icon={<Icon name='FileAltSolid' size='md' color='#27C2CC' />} onClick={showModal}>
        {isFooterOpen && '測驗'}
      </ToolButton>

      {/* 測驗章節彈窗 */}
      {!openLevelModal && !openQuestionModal && (
        <ToolModal
          active={openChapterModal}
          onClose={handleCancelModal}
          title='測驗章節'
          height='400px'
          justifyContent='flex-start'
          footerHeight='69px'
          footerContent={
            <ButtonContainer>
              <CancelButton className='ghost' onClick={handleCancelModal}>
                取消
              </CancelButton>
              {!section && (
                <SendButton disabled={!resource} onClick={handleResourceNextStep}>
                  下一步
                </SendButton>
              )}
              {section && (
                <SendButton disabled={!selectedSection} onClick={handleSectionNextStep}>
                  下一步
                </SendButton>
              )}
            </ButtonContainer>
          }
        >
          {!section && (
            <ResourceList courseInfo={courseInfo} resource={resource} setResource={setResource} width='auto' pd='0' />
          )}
          {section && (
            <ChapterSectionList
              knowledge={knowledge}
              setKnowledge={setKnowledge}
              resourceChapter={resourceChapter}
              section={section}
              setSection={setSection}
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              width='auto'
              pd='0'
            />
          )}
        </ToolModal>
      )}

      {/* 測驗題型彈窗 */}
      {openQuestionModal && (
        <ToolModal
          active={openQuestionModal}
          onClose={handleCancelModal}
          title='測驗題型'
          height='400px'
          justifyContent='flex-start'
          footerHeight='69px'
          footerContent={
            <ButtonContainer>
              <CancelButton className='ghost' onClick={handleCancelModal}>
                取消
              </CancelButton>
              <SendButton disabled={!selectedType} onClick={handleQuestionTypeNextStep}>
                下一步
              </SendButton>
            </ButtonContainer>
          }
        >
          <QuestionTypeList selectedType={selectedType} setSelectedType={setSelectedType} />
        </ToolModal>
      )}

      {/* 測驗難易度彈窗 */}
      {openLevelModal && (
        <ToolModal
          active={openLevelModal}
          onClose={handleCancelModal}
          title='測驗難易度'
          height='400px'
          justifyContent='flex-start'
          footerHeight='69px'
          footerContent={
            <ButtonContainer>
              <CancelButton className='ghost' onClick={handleLevelLastStep}>
                上一步
              </CancelButton>
              <SendButton disabled={!selectedLevel} onClick={handleLevelNextStep}>
                出題
              </SendButton>
            </ButtonContainer>
          }
        >
          <LevelList selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} />
        </ToolModal>
      )}
    </>
  );
};
