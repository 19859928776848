import { MeetingRoles } from '../providers/MeetingProvider';

const BASE_URL = process.env.REACT_APP_ONEBOARD_API_URL + '/oneboard/course';
const MICROSERVICE_URL = process.env.REACT_APP_MICROSERVICE_API_DOMAIN;

export const sendUserEvent = async ({
  courseId,
  userId,
  userName,
  eventName,
  role
}) => {
  return fetch(BASE_URL + '/plusUserEvent', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      courseId,
      userId,
      userName,
      eventName,
      role
    })
  })
    .then(res => res.json())
    .catch(err => console.error(err));
}

export const plusReward = async ({
  courseId,
  presenterId,
  studentName,
  role,
}) => {
  const params = {
    ownerName: studentName,
    learningPointType: 'receivePoints',
    points: 40,
    mission: {
      category: 'courseTrophyPoints'
    },
    courseId,
  }

  if (role === MeetingRoles.Teacher) {
    params.teacher = presenterId;
  }

  if (role === MeetingRoles.Advisor) {
    params.consultant = presenterId;
  }

  return fetch(`${MICROSERVICE_URL}/learning-point-record/trophy-to-learning-point`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(params)
  })
    .catch(err => console.error(err));
}