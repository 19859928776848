import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useRemoteVideoTileState,
  useAudioVideo,
  useRosterState
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingState,
  RemoteVideo,
  LocalVideo,
} from '@oneboard/meeting';
import { Box } from '@oneboard/ui-components';
import { VideoBox } from 'containers';
import { Roles } from 'constants/index';
import { StyledSyncStagedVideoList } from './SyncStagedVideoList.style';

const StagedBox = ({ attendeeId, selfAttendeeId, userId }) => {
  const meetingState = useMeetingState();
  const { objectedAttendeeIds } = meetingState.context;
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const audioVideo = useAudioVideo();

  useEffect(() => {
    audioVideo.unpauseVideoTile(attendeeIdToTileId[attendeeId]);

    return () => {
      audioVideo.pauseVideoTile(attendeeIdToTileId[attendeeId]);
    };
  }, []);

  return (
    <Box width="100%" height="100%" className="remoteVideoBox" key={attendeeId}>
      <VideoBox
        attendeeId={attendeeId}
        userId={userId}
        isStudent>
        {attendeeId === selfAttendeeId ? <LocalVideo
          isShowNameplate={objectedAttendeeIds.includes(attendeeId)}
          attendeeId={attendeeId}
          tileId={attendeeIdToTileId[attendeeId]}
        /> : <RemoteVideo
          isShowNameplate={objectedAttendeeIds.includes(attendeeId)}
          attendeeId={attendeeId}
          tileId={attendeeIdToTileId[attendeeId]}
        />}
      </VideoBox>
    </Box>
  );
};

StagedBox.propTypes = {
  attendeeId: PropTypes.string,
  userId: PropTypes.string,
};

export const SyncStagedVideoList = ({ className }) => {
  const { roster } = useRosterState();
  const meetingState = useMeetingState();
  const { attendeeId: selfAttendeeId, stagedAttendeeIds, role, userId: selfUserId } = meetingState.context;
  return (
    <StyledSyncStagedVideoList className={className} isObserver={role === Roles.Observer} data-testid="SyncStagedVideoList">
      <div className="videoListWrap">
        {stagedAttendeeIds.includes(selfAttendeeId) && <StagedBox attendeeId={selfAttendeeId} userId={selfUserId} selfAttendeeId={selfAttendeeId} />}
        {stagedAttendeeIds
          .filter(attendeeId => attendeeId !== selfAttendeeId)
          .map(attendeeId => {
            const userId = roster[attendeeId]?.userId;
            return <StagedBox attendeeId={attendeeId} selfAttendeeId={selfAttendeeId} userId={userId} key={attendeeId} />;
          }
          )}
      </div>
    </StyledSyncStagedVideoList>
  );
};

SyncStagedVideoList.propTypes = {
  className: PropTypes.string,
};