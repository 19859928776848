import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  useLocalVideo,
  useRosterState,
  useAudioVideo,
  useContentShareState,
  ContentShare,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'utils/hooks/useQuery';
import { useMeetingState, MeetingStates, MeetingRoles, useMeetingDispatch } from '@oneboard/meeting';
import { WorldWallModal, StudentInfoBox, PrivateMessageList } from 'containers';
import MainLayout from 'layouts/MainLayoutV2';
import { InsertPictureModal, SyncMainBlock, ResourceModal, NetworkNotification } from 'components';
import { Box } from '@oneboard/ui-components';
import { Roles, NOTIFICATION_TYPE, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { useNotification } from 'utils/hooks/useNotification';
import { useViewMode } from 'utils/hooks/useViewMode';
import { usePrevious } from 'ahooks';
import { complementarySet } from 'utils/array';
import { useGroupContext } from 'providers/GroupProvider';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useIframeBridge } from 'utils/hooks/useIframeBridge';
import { useInteractiveObjects } from 'utils/hooks/useInteractiveObjects';
import { useNetworkContext } from 'providers/NetworkProvider';
import { useStudentInfoContext } from 'providers/StudentInfoProvider';
import { useRoom } from 'providers/RoomProvider';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { NotificationLayout } from './SyncSingle.style';
import styled from 'styled-components';
import Whiteboard from 'components/NewOneBoard/Whiteboard';
import ToolBox from 'components/NewOneBoard/ToolBox';
import Teacher from 'components/NewOneBoard/VideoInfo/Teacher';
import Footer from 'components/NewOneBoard/Footer';
import { ContentShareContainer } from './SyncSingle.style';
import AdvisorContainer from 'components/NewOneBoard/VideoInfo/common/AdvisorContainer';
import AdvisorVideo from 'components/NewOneBoard/VideoInfo/common/WhiteboardVideo';
import RewardWrapper from 'components/NewOneBoard/Reward/RewardWrapper';

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const BottomContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SyncSingle = () => {
  useIframeBridge();
  const { meetingId } = useParams();
  const { worldWallModalState, toggleWorldWallModal } = useGroupContext();
  const query = useQuery();
  const meetingState = useMeetingState();
  const { role, attendeeId: selfAttendeeId, toolboxAuthAttendeeIds, rewards } = meetingState.context;
  const { service: whiteboardService } = useWhiteboard();
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const student = useMemo(() => attendees.find((attendee) => attendee.role === Roles.Student), [attendees]);
  const studentsPrevious = usePrevious(students) || [];
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { networkNotice, closeNetworkNotice, openNetworkNotice, networkStatus } = useNetworkContext();
  const { removeToolboxAuthAttendeeIds } = useMeetingDispatch();
  const { userId: studentInfoId, studentInfoState, toggleStudentInfo } = useStudentInfoContext();
  const { insertPictureModalSwitch } = useRoom();
  const { resourceModalSwitch } = useResourceModal();
  const mainRef = useRef(null);
  const advisors = Object.values(roster).filter((attendee) => attendee.role === 'advisor');
  const { attendeeIdToTileId } = useRemoteVideoTileState();

  const audioVideo = useAudioVideo();
  useViewMode();
  useInteractiveObjects();

  useEffect(() => {
    if (!isVideoEnabled && query.role !== Roles.Advisor) {
      toggleVideo();
    }
  }, []);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.ENTER,
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.LEAVE,
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  const isTilePaused = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.TileState}.${MeetingStates.Paused}`,
  });

  useEffect(() => {
    if (isTilePaused) {
      openNetworkNotice();
    }
  }, [isTilePaused]);

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);

  const isShowContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  const isSelfContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId === selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  // 移除 ToolboxAuth
  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    const rosterUpdateCallback = (chimeAttendeeId, present) => {
      if (!present) {
        const isEnableToolbox = toolboxAuthAttendeeIds.includes(chimeAttendeeId);
        if (isEnableToolbox) {
          removeToolboxAuthAttendeeIds({ attendeeId: chimeAttendeeId });
        }
        return;
      }
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo, toolboxAuthAttendeeIds]);

  return (
    <MainLayout
      main={
        <MainContainer>
          <SyncMainBlock ref={mainRef}>
            <PrivateMessageList filterRole={Roles.Teacher} containerRef={mainRef} />
            {networkNotice && (
              <NotificationLayout>
                <NetworkNotification networkStatus={networkStatus} onClose={closeNetworkNotice} />
              </NotificationLayout>
            )}
            <Whiteboard toolBox={<ToolBox meetingId={meetingId} />} userRole={Roles.Teacher} />
            {isShowContentShare && (
              <ContentShareContainer>
                <ContentShare />
              </ContentShareContainer>
            )}
            {isSelfContentShare && (
              <Box width='100%' height='100%' position='absolute' zIndex='1000'>
                <ContentShare />
              </Box>
            )}
            {worldWallModalState && role === Roles.Teacher && <WorldWallModal onClose={toggleWorldWallModal} />}
            {insertPictureModalSwitch && <InsertPictureModal />}
            {resourceModalSwitch && <ResourceModal />}
            {whiteboardService && studentInfoState && (
              <StudentInfoBox id={studentInfoId} onClose={toggleStudentInfo} room={whiteboardService} />
            )}
            <RewardWrapper student={student} />
          </SyncMainBlock>
          <AdvisorContainer>
            {advisors &&
              advisors.map((advisor) => (
                <AdvisorVideo
                  key={advisor.chimeAttendeeId}
                  userType={advisor.role}
                  tileId={attendeeIdToTileId[advisor.chimeAttendeeId]}
                  attendeeId={advisor.chimeAttendeeId}
                  advisorName={advisor.name}
                />
              ))}
          </AdvisorContainer>
        </MainContainer>
      }
      side={
        <RightContainer>
          <Teacher isSettingModalOpen={isSettingModalOpen} />
        </RightContainer>
      }
      footer={
        <BottomContainer>
          <Footer isSettingModalOpen={isSettingModalOpen} setIsSettingModalOpen={setIsSettingModalOpen} />
        </BottomContainer>
      }
    ></MainLayout>
  );
};
