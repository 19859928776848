import React, { useState, useRef, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Resizable from 'react-moveable';
import MoveableHelper from 'moveable-helper';
import { useSize } from 'ahooks';
import { StyledResizableRect } from './ResizableRect.style';

export const ResizableRect = forwardRef(({
  className,
  ables = [],
  keepRatio = true,
  resizable = true,
  renderDirections = ['n', 'nw', 'ne', 's', 'se', 'sw', 'e', 'w'],
  isBorder = true,
  draggable = true,
  children,
  onDragStart = () => {},
  onDrag = () => {},
  onDragEnd = () => {},
  onResizeStart = () => {},
  onResize = () => {},
  onResizeEnd = () => {},
}, ref) => {
  const [helper] = useState(() => {
    return new MoveableHelper();
  });
  const childrenRef = useRef(null);

  const [isDrag, setIsDrag] = useState(false);
  const [boundsDom, setBoundsDom] = useState(null);
  const boundsSize = useSize(boundsDom);

  const dragHandler = (state) => setIsDrag(state);

  useEffect(() => {
    setBoundsDom(document.querySelector('.main'));
  }, []);

  return (
    <StyledResizableRect className={`objectMode ${className ? className : ''}`} ref={ref} isBorder={isBorder} isDrag={isDrag} data-testid="ResizableRect">
      {children(childrenRef)}
      <Resizable
        target={childrenRef}
        ables={ables}
        className='resizableRect'
        props={{
          editable: ables.length !== 0 ? true : false,
        }}
        origin={false}
        keepRatio={keepRatio}
        draggable={draggable}
        resizable={resizable}
        renderDirections={renderDirections}
        snappable={true}
        bounds={{ left: 0, top: 0, bottom: boundsSize?.height, right: boundsSize?.width }}
        onDragStart={(e) => {
          helper.onDragStart(e);
          dragHandler(true);
          onDragStart(e);
        }}
        onDrag={e => {
          helper.onDrag(e);
          onDrag(e);
        }}
        onDragEnd={(e) => {
          dragHandler(false);
          onDragEnd(e);
        }}
        onResizeStart={e => {
          helper.onResizeStart(e);
          dragHandler(true);
          onResizeStart(e);
        }}
        onResize={(e) => {
          helper.onResize(e);
          onResize(e);
        }}
        onResizeEnd={e => {
          dragHandler(false);
          onResizeEnd(e);
        }}
      />
    </StyledResizableRect>
  );
});

ResizableRect.propTypes = {
  className: PropTypes.string,
  ables: PropTypes.array,
  keepRatio: PropTypes.bool,
  resizable: PropTypes.bool,
  renderDirections: PropTypes.array,
  children: PropTypes.func,
  draggable: PropTypes.bool,
  isBorder: PropTypes.bool,
  bounds: PropTypes.object,
  onDragStart: PropTypes.func,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onResizeStart: PropTypes.func,
  onResize: PropTypes.func,
  onResizeEnd: PropTypes.func,
};