import { useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useMeetingManager,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { stopRecordVideo } from 'services/recordVideo';
import { useInterval } from 'ahooks';

// 限觀課者使用
// attendees 數量為 1 的時候
// checkedCount + 1
// checkedCount = 3 的時候自動關掉錄影

const useAutoStopRecordVideo = () => {
  const meetingManager = useMeetingManager();
  const { roster } = useRosterState();
  const { meetingId: mainMeetingId, breakoutId } = useParams();
  const checkedCount = useRef(0);
  const meetingId = breakoutId ? breakoutId : mainMeetingId;
  const attendees = useMemo(() => Object.values(roster), [roster]);

  useInterval(async () => {
    if (attendees.length === 1) {
      checkedCount.current += 1;
    } else {
      checkedCount.current = 0;
    }

    if (checkedCount.current >= 3) {
      await stopRecordVideo({ meetingId });
      meetingManager.leave();
    }
  },
    checkedCount.current >= 3 ? undefined : 60 * 1000,
    { immediate: false },
  );
};

export default useAutoStopRecordVideo;