
import { useRef } from 'react';
import { useLocalAudioInputActivity, useToggleLocalMute } from 'amazon-chime-sdk-component-library-react';

export const useMicrophoneActivity = () => {
  const { muted } = useToggleLocalMute();

  let speakerSecond = useRef(0);
  let prevSpeakerSecond = useRef(0);
  let isFirst = useRef(true);
  let firstTime = useRef(0);
  let lastTime = useRef(0);

  const callback = (decimal) => {
    if (!muted || decimal !== 0) {
      if (isFirst.current) {
        firstTime.current = new Date().getTime();
        isFirst.current = false;
      }

      lastTime.current = new Date().getTime();
      isFirst.current = false;
      speakerSecond.current = prevSpeakerSecond.current + (lastTime.current - firstTime.current);
    } else {
      isFirst.current = true;
      prevSpeakerSecond.current = speakerSecond.current;
    }
  };

  useLocalAudioInputActivity(callback);

  const clearSpeakerSecond = () => {
    speakerSecond.current = 0;
    prevSpeakerSecond.current = 0;
    isFirst.current = true;
  };

  return { speakerSecond, clearSpeakerSecond };

};