import { Single } from './Single';
import { SyncSingle } from './SyncSingle';
import { SyncSingle as SyncSingleV2 } from './SyncSingleV2';
import { SyncMultiple } from './SyncMultiple';
import { SyncMultiple as SyncMultipleV2 } from './SyncMultipleV2';

const AdvisorPage = {
  Single,
  SyncSingle,
  SyncSingleV2,
  SyncMultiple,
  SyncMultipleV2,
};

export default AdvisorPage;
