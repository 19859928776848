import styled from 'styled-components';
import VideoContentContainer from './VideoContentContainer';

const VolumeContainer = styled(VideoContentContainer)`
  padding-bottom: 0;

  @media (max-width: 1023px) {
    gap: 8.04px;
  }

  @media (min-width: 1024px) and (max-width: 1073px) {
    gap: 8.04px;
  }

  @media (min-width: 1074px) and (max-width: 1124px) {
    gap: 10px;
  }

  @media (min-width: 1125px) and (max-width: 1439px) {
    gap: 8.04px;
  }

  @media (min-width: 1440px) {
    gap: 12.22px;
  }
`;

export default VolumeContainer;
