import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Draggable from 'react-draggable';
import { useQuery } from 'utils/hooks/useQuery';
import Icon from '@onedesign/icon';
import { Box } from '@oneboard/ui-components';
import { useMeetingDispatch, useMeetingState, MeetingStates, MeetingRoles } from '@oneboard/meeting';
import { WhiteboardAttendeeList } from 'containers';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { ClassType, Roles } from 'constants/index';
import { StyledWhiteboardFrame, WhiteboardFrameHeader, Locker, StyledPopover } from './WhiteboardFrame.style';

const createUrl = ({ roomId, attendeeId, userName, role }) => {
  const query = queryString.stringify({
    whiteboardid: attendeeId ? `${roomId}_${attendeeId}` : roomId,
    username: userName,
    role
  });
  return `${process.env.REACT_APP_WHITEBOARD_DOMAIN}/?${query}`;
};

const SingleLocker = ({ isLock, onClick }) => {

  return (
    <Locker isLock={isLock} onClick={onClick}>
      {
        isLock ? (
          <Icon name="LockSolid" size="sm"/>
        ) : (
          <Icon name="LockOpenSolid" size="sm"/>
        )
      }
      <Box ml={2}>
        {isLock ? '協作未開啟' : '協作已開啟'}
      </Box>
    </Locker>
  );
};

const GroupLocker = ({ isLock, onChange, whiteboardingAttendeeIds }) => {

  return (
    <div>
      <StyledPopover
        arrow={false}
        trigger="click"
        getPopupContainer={triggerNode => triggerNode}
        content={
          <WhiteboardAttendeeList
            currentAttendeeId={whiteboardingAttendeeIds.length === 1 ? whiteboardingAttendeeIds[0] : ''}
            onChange={(attendeeIds) => onChange(attendeeIds)}
          />
        }
        placement="bottom">
        <Locker isLock={isLock}>
          {
            isLock ? (
              <Icon name="LockSolid" size="sm"/>
            ) : (
              <Icon name="LockOpenSolid" size="sm"/>
            )
          }
          <Box ml={2}>
            {isLock ? '協作未開啟' : `協作人員：${whiteboardingAttendeeIds.length}`}
          </Box>
        </Locker>
      </StyledPopover>
    </div>
  );
};


export const WhiteboardFrame = ({
  roomId,
  attendeeId,
  userName,
  role,
  isShowClose = false,
  toggleWhiteboardShow = () => {}
 }) => {
  const query = useQuery();
  const [url, setUrl] = useState();
  useEffect(() => {
    setUrl(createUrl({ roomId, attendeeId, userName, role }));
  }, [roomId, attendeeId]);

  const [isDragging, setDragging] = useState(false);

  const draggingHandler = useCallback((event) => {
    setDragging(true);
  }, []);

  const stopHandler = useCallback(() => {
    setDragging(false);
  }, []);

  const { roster } = useRosterState();
  const meetingState = useMeetingState();
  const { startWhiteboard, endWhiteboard, startAllWhiteboard } = useMeetingDispatch();

  const { whiteboardingAttendeeIds } = meetingState.context;
  const isWhiteboarding = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.Whiteboard}.${MeetingStates.Whiteboarding}`
  });

  const openWhiteboardHandler = useCallback(attendeeIds => {
    startWhiteboard({ attendeeIds });
  }, []);

  const openAllAttendeeWhiteboard = () => startAllWhiteboard();

  useEffect(() => {
    if (role !== Roles.Teacher) return;
    if (isWhiteboarding) {
      whiteboardingAttendeeIds.length > 0 ? openWhiteboardHandler(whiteboardingAttendeeIds) : startAllWhiteboard();
    } else {
      endWhiteboard();
    }
  }, [roster]);

  const [isLock, setIsLock] = useState(true);
  const handleLock = useCallback(() => {
    setIsLock(prev => {
      const next = !prev;
      if (next) {
        endWhiteboard();
      } else {
        openAllAttendeeWhiteboard();
      }
      return next;
    });
  }, [endWhiteboard, openAllAttendeeWhiteboard]);

  const handleClose = useCallback(() => {
    toggleWhiteboardShow();
    endWhiteboard();
  }, []);

  return (
    <Draggable cancel=".iframe" onDrag={draggingHandler} onStop={stopHandler} positionOffset={{ x: '-50%', y: '-50%' }}>
      <StyledWhiteboardFrame data-testid="WhiteboardFrame" isDragging={isDragging}>

        <WhiteboardFrameHeader>
          {
            isShowClose && (
              <>
                {
                  query?.classType === ClassType.Single ? (
                    <SingleLocker isLock={isLock} onClick={handleLock} />
                  ) : (
                    <GroupLocker
                      isLock={whiteboardingAttendeeIds.length < 1}
                      onChange={openWhiteboardHandler}
                      whiteboardingAttendeeIds={whiteboardingAttendeeIds}
                    />
                  )
                }
              </>
            )
          }
          {
            isShowClose && (
              <button className="close" onClick={handleClose}>
                <Icon name="XmarkOutline" size="sm"/>
              </button>
            )
          }
        </WhiteboardFrameHeader>
        <iframe
          className="iframe"
          title="whiteboard"
          src={url}
          frameborder="0"
        />
      </StyledWhiteboardFrame>
    </Draggable>
  );
};


WhiteboardFrame.propTypes = {
  roomId: PropTypes.string,
  attendeeId: PropTypes.string,
  userName: PropTypes.string,
  isShowClose: PropTypes.bool,
  role: PropTypes.string,
  toggleWhiteboardShow: PropTypes.func
};