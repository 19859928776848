import styled from 'styled-components';
import { ResizableRect } from 'components';

export const StyledResizableRect = styled(ResizableRect)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 0;
`;

export const StyledOnebookFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(400px * 1.5);
  height: calc(300px * 1.5);
  border: 1px solid #232323;
  background-color: #fff;
  z-index: 10;
  > iframe {
    width: 100%;
    height: ${({ isDragging }) => isDragging ? 0 : '100%'};
  }
`;

export const StyledOnebookFrameHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #A1A4B1; 
  padding: 5px 10px;
  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A1A4B1;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;