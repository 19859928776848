import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledListContainer, StyledList } from '../BookList.style';

const Books = ({ books = [], currentBookId, onBookIdChange, fetchBooks }) => {
  const [bookList, setBookList] = useState();
  useEffect(() => {
    fetchBooks(books)
      .then((res) => {
        setBookList(
          res.map((book) => ({
            ...book,
            label: book.display_name,
          }))
        );
      })
      .catch((error) => console.log(error.message));
  }, []);

  return (
    <StyledListContainer>
      {bookList &&
        bookList.map((book) => {
          const { bookId, label } = book;
          return (
            <StyledList
              key={bookId}
              active={currentBookId === bookId}
              onClick={() => {
                onBookIdChange({ bookInfo: book });
              }}
            >
              {label}
            </StyledList>
          );
        })}
    </StyledListContainer>
  );
};

Books.propTypes = {
  books: PropTypes.array.isRequired,
  currentBookId: PropTypes.string,
  onBookIdChange: PropTypes.func,
  fetchBooks: PropTypes.func,
};

export default Books;
