import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const UiGridLayout = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 100%) 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'top'
    'footer';
  width: 100vw;
  height: 100%;
  background-image: linear-gradient(158deg, #ffeade 0%, #ffd7bc 100%);
`;

const UiTopLayout = styled.div`
  grid-area: top;
  display: grid;
  grid-template-rows: 100% 100%;
  grid-template-areas: 'main side';
  grid-template-columns: minmax(0, 80%) 1fr;
  column-gap: 10px;
  width: 100%;
  height: 100%;
  padding: 8px 3px 8px 8px;

  @media screen and (max-width: 1082px) {
    grid-template-columns: 1fr 200px;
  }

  @media screen and (min-width: 1462px) {
    grid-template-columns: minmax(0, 1fr) 276px;
  }
`;

const UiMainLayout = styled.div`
  grid-area: main;
  position: relative;
`;

const UiSideLayout = styled.div`
  grid-area: side;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b2a8a3;
    border-radius: 12px;
  }
`;

const MainLayoutV2 = ({ main, side, footer }) => {
  return (
    <>
      <UiGridLayout>
        <UiTopLayout>
          <UiMainLayout>{main}</UiMainLayout>
          <UiSideLayout>{side}</UiSideLayout>
        </UiTopLayout>
        <div grid-area='footer'>{footer}</div>
      </UiGridLayout>
    </>
  );
};

MainLayoutV2.propTypes = {
  main: PropTypes.node,
  side: PropTypes.node,
  footer: PropTypes.node,
};

export default MainLayoutV2;
