import React, { useEffect, useState, useRef, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { Roles } from 'constants/index';
import { Popover, Tooltip, Button, notification } from 'antd';
import Icon from '@onedesign/icon';
import { RECORD_VIDEO_STATE } from 'machines/RecordVideoMachine';
import { Box, Modal } from '@oneboard/ui-components';
import { startRecordVideo, stopRecordVideo, recordVideoInfo } from 'services/recordVideo';
import { useRequest, useUpdateEffect } from 'ahooks';
import { v4 as uuid } from 'uuid';
import { StyledRecordButton, StyledPopContent, StyledRecordBox } from './RecordButton.style';
import { useMeetingDispatch, useMeetingState, MeetingStates } from '@oneboard/meeting';
import { t } from 'utils/i18n';

const RECORD_MAP = {
  dark: {
    On: {
      color: '#F44336',
    },
    Off: {
      color: '#D4D7DD',
    },
  },
  light: {
    On: {
      color: '#F44336',
    },
    Off: {
      color: 'rgba(47, 55, 70, 1)',
    },
  }
};

const openNotification = ({ error }) => {
  notification.error({
    className: 'customNotification',
    message: error,
    key: uuid()
  });
};

const ConfirmModal = ({ closeConfirmModal, confirmEndHandler, stopRecordLoading, portalDom }) => {

  return createPortal(
    (
      <Modal width="600px" onClose={closeConfirmModal} header={t('containers.recordButton.confirmModal.header', '錄影結束')}>
        <Box>
          {t('containers.recordButton.confirmModal.content', '確認結束錄影嗎？')}
        </Box>
        <Box display="flex" width="100%" pt={8}>
          <Box width="100%">
            <Button block size="large" onClick={confirmEndHandler} loading={stopRecordLoading}>
              {t('containers.recordButton.confirmModal.button', '結束')}
            </Button>
          </Box>
        </Box>
      </Modal>
    ),
    portalDom
  );
};

export const RecordButton = ({ placement = 'topRight', mode = 'dark', content = '', classType }) => {
  const { meetingId } = useParams();
  const meetingState = useMeetingState();
  const { roster } = useRosterState();
  const {
    teacherStartRecord,
    teacherStopRecord,
    advisorStartRecord,
    advisorStopRecord,
    observerStopRecord
  } = useMeetingDispatch();

  const attendees = useMemo(() => Object.values(roster), [roster]);
  const isRecordObserver = attendees.find(attendee => attendee.role === Roles.Observer && attendee.name === 'recordObserver');
  const { context } = meetingState;
  const { role } = context;
  let isRecordVideoOn = false;

  switch (role) {
    case Roles.Teacher:
      isRecordVideoOn = meetingState.matches({
        [MeetingStates.Joined]: `${Roles.Teacher}.${MeetingStates.RecordVideo}.${RECORD_VIDEO_STATE.On}`
      });
      break;
    case Roles.Advisor:
      isRecordVideoOn = meetingState.matches({
        [MeetingStates.Joined]: `${Roles.Advisor}.${MeetingStates.RecordVideo}.${RECORD_VIDEO_STATE.On}`
      });
      break;
    default:
      break;
  }

  const [recordingModalState, setRecordingModal] = useState(false);
  const mainPageRef = useRef();
  const buttonRef = useRef();

  const recordVideoState = meetingState.value.Joined[role].RecordVideo;

  const { loading: startRecordLoading, run: runStartRecordVideo } = useRequest(startRecordVideo, {
    manual: true,
    onSuccess: async (result, params) => {
      if (result.ok) {
        switch (role) {
          case Roles.Teacher:
            teacherStartRecord();
            break;
          case Roles.Advisor:
            advisorStartRecord();
            break;
          default:
            break;
        }
      } else {
        const errorText = await result.text();
        openNotification({ error: errorText });
      }
    },
    onError: (error, params) => {
      openNotification({ error: error.message });
    }
  });

  const { loading: stopRecordLoading, run: runStopRecordVideo } = useRequest(stopRecordVideo, {
    manual: true,
    onSuccess: async (result, params) => {
      if (result.ok) {
        setRecordingModal(false);
        switch (role) {
          case Roles.Teacher:
            teacherStopRecord();
            break;
          case Roles.Advisor:
            advisorStopRecord();
            break;
          default:
            break;
        }
      } else {
        const errorText = await result.text();
        openNotification({ error: errorText });
      }
    },
    onError: (error, params) => {
      openNotification({ error: error.message });
    }
  });

  // 錄影狀態
  const { loading: recordVideoInfoLoading, run: runRecordVideoInfo } = useRequest(recordVideoInfo, {
    manual: true,
    onSuccess: async (result, params) => {
      if (result.ok) {
        const recordInfo = await result.json().then(res => res.data[0]);

        if (recordInfo) {
          const isRecording = recordInfo.isRecording;
          if (isRecording) {
            switch (role) {
              case Roles.Teacher:
                teacherStartRecord();
                break;
              case Roles.Advisor:
                advisorStartRecord();
                break;
              default:
                break;
            }
          }
        }
      } else {
        const errorText = await result.text();
        openNotification({ error: errorText });
      }
    },
  });

  const openConfirmModal = () => setRecordingModal(true);

  const closeConfirmModal = () => {
    if (stopRecordLoading) return;
    setRecordingModal(false);
  };

  const confirmEndHandler = async () => {
    await stopRecord();
  };

  const startRecord = async () => {
    if (recordVideoInfoLoading || startRecordLoading) return;
    await runStartRecordVideo({ meetingId });
  };

  const stopRecord = () => runStopRecordVideo({ meetingId });

  const clickHandler = () => {
    const { On } = RECORD_VIDEO_STATE;
    switch (recordVideoState) {
      case On:
        openConfirmModal();
        break;

      default:
        startRecord();
        break;
    }
  };

  useEffect(() => {
    const getRecordVideoInfo = () => {
      if (role === Roles.Teacher || role === Roles.Advisor) {
        runRecordVideoInfo({ meetingId });
      }
    };

    getRecordVideoInfo();

    const rootDom = document.getElementById('MainPage');
    mainPageRef.current = rootDom;
  }, []);

  useUpdateEffect(() => {
    if (!isRecordObserver) {
      observerStopRecord();
    }
  }, [isRecordObserver]);

  return (
    <StyledRecordBox>
      {isRecordVideoOn ? (
        <Tooltip
          trigger={isRecordVideoOn ? 'hover' : 'click'}
          title={isRecordVideoOn ?
            t('containers.recordButton.endClassRecording', '結束上課錄影') :
            t('containers.recordButton.startClassRecording', '開始上課錄影')}
          placement={placement}
          arrowPointAtCenter={true}
        >
          <StyledRecordButton data-testid="RecordButton" onClick={clickHandler} mode={mode} classType={classType} >
            <Icon name="RecordSolid" size="lg" color={RECORD_MAP[mode][recordVideoState].color} />
          </StyledRecordButton>
        </Tooltip>
      ) : (
        <Popover
          trigger={isRecordVideoOn ? 'hover' : 'click'}
          overlayClassName='recordButtonPopover'
          getPopupContainer={() => buttonRef.current}
          overlayInnerStyle={{
            backgroundColor: '#1890FF',
            borderRadius: '8px',
            width: '280px',
          }}
          content={(
            <StyledPopContent>
              <div className="content" dangerouslySetInnerHTML={{ __html: content }}>
              </div>
              <div className="actions">
                <Button onClick={startRecord} loading={recordVideoInfoLoading || startRecordLoading}>
                  {t('containers.recordButton.startRecording', '開始錄影')}
                </Button>
              </div>
            </StyledPopContent>
          )}
          placement={placement}
          visible={true}
          arrowPointAtCenter={true}
        >
          <StyledRecordButton data-testid="RecordButton" onClick={clickHandler} mode={mode} classType={classType} ref={buttonRef}>
            <Icon name="RecordSolid" size="lg" color={RECORD_MAP[mode][isRecordVideoOn ? 'On' : 'Off'].color} />
          </StyledRecordButton>
        </Popover>
      )}
      {recordingModalState && (
        <ConfirmModal
          portalDom={mainPageRef.current}
          closeConfirmModal={closeConfirmModal}
          confirmEndHandler={confirmEndHandler}
          stopRecordLoading={stopRecordLoading}
        />
      )}
    </ StyledRecordBox>
  );
};

RecordButton.propTypes = {
  mode: PropTypes.string,
  placement: PropTypes.string,
  closeHandler: PropTypes.func,
  content: PropTypes.string,
  classType: PropTypes.string,
};