import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import { Roles } from 'constants/index';
import Icon from '@onedesign/icon';
import { Popover } from '@oneboard/ui-components';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { useGroupContext } from 'providers/GroupProvider';
import { StyledRaiseHandControl, StyledRaiseHandPanel, StyledRaiseHandBtn, StyledRaiseCell } from './RaiseHandControl.style';

const RaiseCell = ({ name, attendeeId, index }) => {
  const state = useMeetingState();
  const { context } = state;
  const { stagedAttendeeIds, role } = context;
  const { stageAttendee, lowerHand, unMuteAttendee, stageAttendeeFromAdvisor, unMuteAttendeeFromAdvisor } = useMeetingDispatch();
  const { toggleStageFullModal } = useGroupContext();

  const unStageHandler = (e) => {
    e.stopPropagation();
    lowerHand({ attendeeId });
  };
  const onStageHandler = () => {
    if (stagedAttendeeIds.length === MAX_STUDENT_VIDEO_COUNT) {
      toggleStageFullModal();
    } else {
      if (role === Roles.Advisor) {
        stageAttendeeFromAdvisor({ attendeeId });
        unMuteAttendeeFromAdvisor({ attendeeId });
      } else {
        stageAttendee({ attendeeId });
        unMuteAttendee({ attendeeId });
      }
    }
  };
  return (
    <StyledRaiseCell>
      <div className="raiseHandList" onClick={onStageHandler}>
        <div className="icon">
          <Icon name="RaisehandSolid" size='md' />
        </div>
        <div className="userName">{`${index + 1}. ${name}`}</div>
        <div className="close" onClick={unStageHandler}>
          <Icon name="XmarkOutline" size='xxs' />
        </div>
      </div>
    </StyledRaiseCell>
  );
};

RaiseCell.propTypes = {
  name: PropTypes.string,
  attendeeId: PropTypes.string,
  index: PropTypes.number
};

export const RaiseHandControl = ({ className, sync = false }) => {
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = attendees.filter(attendee => attendee.role === Roles.Student);
  const state = useMeetingState();
  const { context } = state;
  const { raisedHandAttendeeIds, role } = context;
  const { lowerAllAttendeesHand, lowerAllAttendeesHandFromAdvisor } = useMeetingDispatch();

  const [isRaised, setIsRaised] = useState(false);

  const lowerAllAttendeesHandHandler = () => {
    if (role === Roles.Advisor) {
      lowerAllAttendeesHandFromAdvisor();
    } else {
      lowerAllAttendeesHand();
    }
  };

  useEffect(() => {
    if (raisedHandAttendeeIds.length === 0) {
      setIsRaised(false);
    } else {
      setIsRaised(true);
    }
  }, [raisedHandAttendeeIds.length]);

  return (
    <StyledRaiseHandControl className={`${sync ? 'sync' : ''} ${className}`} data-testid="RaiseHandControl">
      <Popover
        placement="topRight"
        trigger="click"
        content={
          <StyledRaiseHandPanel>
            {raisedHandAttendeeIds.length > 0 && <div className="content">
              {raisedHandAttendeeIds.map((attendeeId, index) => {
                const name = roster[attendeeId]?.name;
                return <RaiseCell name={name} attendeeId={attendeeId} key={attendeeId} index={index} />;
              })}
            </div>}
            <div className="action">
              <div className="btn" onClick={lowerAllAttendeesHandHandler}>舉手全部放下</div>
            </div>
          </StyledRaiseHandPanel>
        }>
        <StyledRaiseHandBtn isRaised={isRaised}>
          <Icon name="RaisehandSolid" size={`${sync ? 'xs' : 'md'}`} />
          {isRaised && <div className="studentAmount">
            <span className="raiseAmount">{raisedHandAttendeeIds.length}</span>
            <span>/</span>
            <span>{students.length}</span>
          </div>}
        </StyledRaiseHandBtn>
      </Popover>
    </StyledRaiseHandControl>
  );
};

RaiseHandControl.propTypes = {
  className: PropTypes.string,
  sync: PropTypes.bool
};