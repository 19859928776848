export const $black = '#010409';
export const $white = '#F0F6FC';

// -------- Grays --------
export const $gray0 = '#F0F6FC';
export const $gray1 = '#C9D1D9';
export const $gray2 = '#B1BAC4';
export const $gray3 = '#8B949E';
export const $gray4 = '#6E7681';
export const $gray5 = '#484F58';
export const $gray6 = '#30363D';
export const $gray7 = '#21262D';
export const $gray8 = '#161B22';
export const $gray9 = '#0D1117';

// -------- Blue --------
export const $blue0 = '#CAE8FF';
export const $blue1 = '#A5D6FF';
export const $blue2 = '#79C0FF';
export const $blue3 = '#58A6FF';
export const $blue4 = '#388BFD';
export const $blue5 = '#1F6FEB';
export const $blue6 = '#1158C7';
export const $blue7 = '#0D419D';
export const $blue8 = '#0C2D6B';
export const $blue9 = '#051D4D';

// -------- Green --------
export const $green0 = '#AFF5B4';
export const $green1 = '#7EE787';
export const $green2 = '#56D364';
export const $green3 = '#3FB950';
export const $green4 = '#2EA043';
export const $green5 = '#238636';
export const $green6 = '#196C2E';
export const $green7 = '#0F5323';
export const $green8 = '#033A16';
export const $green9 = '#04260F';

// -------- Yellow --------
export const $yellow0 = '#F8E3A1';
export const $yellow1 = '#F2CC60';
export const $yellow2 = '#E3B341';
export const $yellow3 = '#D29922';
export const $yellow4 = '#BB8009';
export const $yellow5 = '#9E6A03';
export const $yellow6 = '#845306';
export const $yellow7 = '#693E00';
export const $yellow8 = '#4B2900';
export const $yellow9 = '#341A00';

// -------- Orange --------
export const $orange0 = '#FFDFB6';
export const $orange1 = '#FFC680';
export const $orange2 = '#FFA657';
export const $orange3 = '#F0883E';
export const $orange4 = '#DB6D28';
export const $orange5 = '#BD561D';
export const $orange6 = '#9B4215';
export const $orange7 = '#762D0A';
export const $orange8 = '#5A1E02';
export const $orange9 = '#3D1300';

// -------- Red --------
export const $red0 = '#FFDCD7';
export const $red1 = '#FFC1BA';
export const $red2 = '#FFA198';
export const $red3 = '#FF7B72';
export const $red4 = '#F85149';
export const $red5 = '#DA3633';
export const $red6 = '#B62324';
export const $red7 = '#8E1519';
export const $red8 = '#67060C';
export const $red9 = '#490202';

// -------- Color defaults --------
export const $red = $red5;
export const $blue = $blue5;
export const $green = $green5;
export const $yellow = $yellow5;
export const $orange = $orange5;
export const $gray = $gray5;

// -------- Color scale --------
export const $scale = {
  $black,
  $white,
  gray: {
    $gray0,
    $gray1,
    $gray2,
    $gray3,
    $gray4,
    $gray5,
    $gray6,
    $gray7,
    $gray8,
    $gray9,
  },

  blue: {
    $blue0,
    $blue1,
    $blue2,
    $blue3,
    $blue4,
    $blue5,
    $blue6,
    $blue7,
    $blue8,
    $blue9,
  },

  green: {
    $green0,
    $green1,
    $green2,
    $green3,
    $green4,
    $green5,
    $green6,
    $green7,
    $green8,
    $green9,
  },

  yellow: {
    $yellow0,
    $yellow1,
    $yellow2,
    $yellow3,
    $yellow4,
    $yellow5,
    $yellow6,
    $yellow7,
    $yellow8,
    $yellow9,
  },

  orange: {
    $orange0,
    $orange1,
    $orange2,
    $orange3,
    $orange4,
    $orange5,
    $orange6,
    $orange7,
    $orange8,
    $orange9,
  },

  red: {
    $red0,
    $red1,
    $red2,
    $red3,
    $red4,
    $red5,
    $red6,
    $red7,
    $red8,
    $red9,
  },
};