import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLocalVideo,
  useRosterState,
  useAudioVideo,
  useContentShareState,
  ContentShare,
} from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import {
  useMeetingState,
  SettingPopoverButton,
  MeetingStates,
  MeetingRoles,
  useMeetingDispatch,
} from '@oneboard/meeting';
import {
  TeachingMaterialContainer,
  AuditVideoList,
  SyncWhiteboard,
  SyncToolBox,
  WorldWallModal,
  SceneTab,
  RecordButton,
  RightContainer,
  StudentInfoBox,
  PrivateMessageModal,
  PrivateMessageList,
  ClassroomPerformanceModal,
} from 'containers';
import { MainLayout } from 'layouts';
import MainPageHeader from './Header';
import {
  StagedVideo,
  ScenesImageUploadButton,
  NetworkNotification,
  InsertPictureModal,
  ClassroomPerformanceButton,
  SyncMainBlock,
  ResourceModal,
} from 'components';
import { Box, Modal } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import { Roles, NOTIFICATION_TYPE, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { useNotification } from 'utils/hooks/useNotification';
import { useViewMode } from 'utils/hooks/useViewMode';
import { usePrevious } from 'ahooks';
import { complementarySet } from 'utils/array';
import { useGroupContext } from 'providers/GroupProvider';
import PageController from '@netless/page-controller';
import PreviewController from '@netless/preview-controller';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useIframeBridge } from 'utils/hooks/useIframeBridge';
import { useInteractiveObjects } from 'utils/hooks/useInteractiveObjects';
import { useNetworkContext } from 'providers/NetworkProvider';
import { useStudentInfoContext } from 'providers/StudentInfoProvider';
import { Tooltip } from 'antd';
import { useRoom } from 'providers/RoomProvider';
import { useResourceModal } from 'providers/ResourceModalProvider';
import {
  StyledSyncSingle,
  IconBox,
  NotificationLayout,
  StyledPrivateMessageButton,
  StyledAwardButton,
} from './SyncSingle.style';
import { t } from 'utils/i18n';

export const SyncSingle = () => {
  useIframeBridge();
  const { worldWallModalState, toggleWorldWallModal } = useGroupContext();
  const { meetingId } = useParams();
  const query = useQuery();
  const meetingState = useMeetingState();
  const { stagedAttendeeIds, role, attendeeId: selfAttendeeId, toolboxAuthAttendeeIds } = meetingState.context;
  const { service: whiteboardService, addPage } = useWhiteboard();
  const [isShowPreviewMenu, setIsShowPreviewMenu] = useState(false);
  const [iframeGroup, setIframeGroup] = useState([]);
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const studentsPrevious = usePrevious(students) || [];
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { networkNotice, closeNetworkNotice, openNetworkNotice } = useNetworkContext();
  const { removeToolboxAuthAttendeeIds } = useMeetingDispatch();
  const { userId: studentInfoId, studentInfoState, toggleStudentInfo } = useStudentInfoContext();
  const { insertPictureModalSwitch } = useRoom();
  const { resourceModalSwitch } = useResourceModal();
  const mainRef = useRef(null);

  const [classroomPerformanceModalState, setClassroomPerformanceModalState] = useState(false);

  const audioVideo = useAudioVideo();
  useViewMode();
  useInteractiveObjects();

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup((prev) => prev.filter((item) => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  const pagePreviewHandler = (state) => {
    setIsShowPreviewMenu(state);
  };

  const [privateMessageModalState, setPrivateMessageModalState] = useState(false);
  const advisorIsExist = attendees.find((attendee) => attendee.role === Roles.Advisor);

  const onPrivateMessageClose = () => setPrivateMessageModalState(false);

  useEffect(() => {
    if (!isVideoEnabled && query.role !== Roles.Advisor) {
      toggleVideo();
    }
  }, []);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.ENTER,
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.LEAVE,
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  const openClassroomPerformanceModal = () => setClassroomPerformanceModalState(true);

  const closeClassroomPerformanceModal = () => setClassroomPerformanceModalState(false);

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  const handleSceneUpload = () => {
    pagePreviewHandler(true);
    setTimeout(() => {
      const element = document.querySelector('nav.bm-item-list > .bm-item');
      element.scrollTop = element.scrollHeight;
    }, 100);
  };

  const isTilePaused = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.TileState}.${MeetingStates.Paused}`,
  });

  useEffect(() => {
    if (isTilePaused) {
      openNetworkNotice();
    }
  }, [isTilePaused]);

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);

  const isSelfContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId === selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  // 移除 ToolboxAuth
  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    const rosterUpdateCallback = (chimeAttendeeId, present) => {
      if (!present) {
        const isEnableToolbox = toolboxAuthAttendeeIds.includes(chimeAttendeeId);
        if (isEnableToolbox) {
          removeToolboxAuthAttendeeIds({ attendeeId: chimeAttendeeId });
        }
        return;
      }
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo, toolboxAuthAttendeeIds]);

  useEffect(() => {
    if (!advisorIsExist) {
      onPrivateMessageClose();
    }
  }, [advisorIsExist]);

  return (
    <StyledSyncSingle networkNotice={networkNotice}>
      {networkNotice && (
        <NotificationLayout>
          <NetworkNotification onClose={closeNetworkNotice} />
        </NotificationLayout>
      )}
      <MainLayout
        right={
          <RightContainer>
            <MainPageHeader />
          </RightContainer>
        }
        mode='sync'
      >
        <SyncMainBlock ref={mainRef}>
          <PrivateMessageList filterRole={Roles.Teacher} containerRef={mainRef} />
          {whiteboardService && <SceneTab room={whiteboardService} />}
          <SyncWhiteboard toolBox={<SyncToolBox meetingId={meetingId} />}>
            <Box width='56px' position='absolute' zIndex={50} bottom='96px' right={2} mb={2}>
              <Box mb={2}>
                <ClassroomPerformanceButton onOpen={openClassroomPerformanceModal} />
              </Box>
              {advisorIsExist && (
                <Tooltip placement='top' title={t('mainPage.teacher.syncSingle.privateMessageConsultant', '私訊顧問')}>
                  <StyledPrivateMessageButton onClick={() => setPrivateMessageModalState(true)}>
                    <Icon name='WalkieTalkie' size='xl' color='#EC7963' />
                  </StyledPrivateMessageButton>
                </Tooltip>
              )}
            </Box>
            <Box position='absolute' zIndex={50} bottom='40px' right={2}>
              <RecordButton
                mode='light'
                placement='leftBottom'
                content={t(
                  'mainPage.teacher.syncSingle.remindToTurnVideoOn',
                  '開始上課了嗎?\n別忘了按下錄影按鈕，讓學生可以在課後複習本次課程哦！'
                )}
              />
            </Box>
            <Box display='flex' position='absolute' zIndex={5} bottom={2} right={2}>
              <Box ml={2}>
                <IconBox>
                  <SettingPopoverButton className='settingPopoverButton' placement='leftTop' disabledPopover />
                </IconBox>
              </Box>
              <Box ml={2}>
                <ScenesImageUploadButton service={whiteboardService} onUploaded={handleSceneUpload}>
                  <IconBox>
                    <Icon name='ImageSolid' size='xs' />
                  </IconBox>
                </ScenesImageUploadButton>
              </Box>
              <Box ml={2}>
                <IconBox onClick={() => pagePreviewHandler(true)}>
                  <Icon name='ThSolid' size='xs' />
                </IconBox>
              </Box>
              <Box ml={2}>
                <IconBox onClick={addPage}>
                  <Icon name='FileAddSolid' size='xs' />
                </IconBox>
              </Box>
              <Box ml={2}>
                <PageController room={whiteboardService} />
              </Box>
            </Box>
            <PreviewController
              room={whiteboardService}
              isVisible={isShowPreviewMenu}
              handlePreviewState={pagePreviewHandler}
            />
            {classroomPerformanceModalState && (
              <ClassroomPerformanceModal students={students} onClose={closeClassroomPerformanceModal} />
            )}
          </SyncWhiteboard>

          {isSelfContentShare && (
            <Box width='100%' height='100%' position='absolute' zIndex='1000'>
              <ContentShare />
              <Box position='absolute' right='8px' bottom='8px'>
                <IconBox>
                  <SettingPopoverButton className='settingPopoverButton' placement='leftTop' disabledPopover />
                </IconBox>
              </Box>
            </Box>
          )}

          {stagedAttendeeIds.length > 0 && <StagedVideo stagedAttendeeIds={stagedAttendeeIds} objectMode={true} />}

          <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />
          <div className='auditListLayout'>
            <AuditVideoList />
          </div>
          {worldWallModalState && role === Roles.Teacher && <WorldWallModal onClose={toggleWorldWallModal} />}

          {insertPictureModalSwitch && <InsertPictureModal />}

          {resourceModalSwitch && <ResourceModal />}

          {advisorIsExist && privateMessageModalState && (
            <Modal
              width='600px'
              header={t('mainPage.teacher.syncSingle.promptMessage', '提示訊息')}
              onClose={onPrivateMessageClose}
            >
              <PrivateMessageModal
                onClose={onPrivateMessageClose}
                targetRole={Roles.Advisor}
                placeholder={t('mainPage.teacher.syncSingle.enterReminderMessage', '在這裡輸入提示訊息，傳送給顧問。')}
              />
            </Modal>
          )}

          {whiteboardService && studentInfoState && (
            <StudentInfoBox id={studentInfoId} onClose={toggleStudentInfo} room={whiteboardService} />
          )}
        </SyncMainBlock>
      </MainLayout>
    </StyledSyncSingle>
  );
};
