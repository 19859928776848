import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  MainPage,
  DeviceSetupPageV2,
  LoginPage,
  CreateMeetingPage,
  ObserverPage,
  NotFoundPage,
  DeviceDetectPage,
} from 'views';
import { JoinMeetingRedirect } from 'containers';
import ReactGA from 'react-ga4';
import { FallbackComponent } from 'components';
import * as Sentry from '@sentry/react';

const App = () => {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => <FallbackComponent error={error} componentStack={componentStack} />}
    >
      <Switch>
        <Route exact path='/'>
          <Redirect to='/notFound' />
        </Route>
        <Route path='/login' component={LoginPage} />
        <Route path='/create' component={CreateMeetingPage} />
        <Route path='/deviceDetect' component={DeviceDetectPage} />
        <Route exact path='/:meetingId/setup' component={DeviceSetupPageV2} />
        <Route exact path='/:meetingId/observer' component={ObserverPage} />
        <Route exact path='/:meetingId/breakout/:breakoutId/observer' component={ObserverPage} />
        <Route exact path='/:meetingId/breakout/:breakoutId' component={MainPage} />
        <Route path='/notFound' component={NotFoundPage} />
        <Route
          exact
          path='/:meetingId'
          component={() => (
            <JoinMeetingRedirect>
              <MainPage />
            </JoinMeetingRedirect>
          )}
        />
      </Switch>
    </Sentry.ErrorBoundary>
  );
};
export default App;
