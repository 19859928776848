import React from 'react';
import Icon from '@onedesign/icon';
import PropTypes from 'prop-types';
import { StyledRaiseCell } from './RaiseHandControl.style';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import { Roles } from 'constants/index';
import { useGroupContext } from 'providers/GroupProvider';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';

const RaiseCell = ({ name, attendeeId, index }) => {
  const state = useMeetingState();
  const { context } = state;
  const { stagedAttendeeIds, role } = context;
  const {
    stageAttendee,
    lowerHandFromTeacher,
    lowerHandFromAdvisor,
    unMuteAttendee,
    stageAttendeeFromAdvisor,
    unMuteAttendeeFromAdvisor,
  } = useMeetingDispatch();
  const { toggleStageFullModal } = useGroupContext();

  const lowerHandHandler = (e) => {
    e.stopPropagation();
    if (role === Roles.Advisor) {
      lowerHandFromAdvisor({ attendeeId });
    } else {
      lowerHandFromTeacher({ attendeeId });
    }
  };
  const onStageHandler = () => {
    if (stagedAttendeeIds.length === MAX_STUDENT_VIDEO_COUNT) {
      toggleStageFullModal();
    } else {
      if (role === Roles.Advisor) {
        stageAttendeeFromAdvisor({ attendeeId });
        unMuteAttendeeFromAdvisor({ attendeeId });
      } else {
        stageAttendee({ attendeeId });
        unMuteAttendee({ attendeeId });
      }
    }
  };
  return (
    <StyledRaiseCell>
      <div className='raiseHandList' onClick={onStageHandler}>
        <div className='icon'>
          <Icon name='RaisehandSolid' size='md' />
        </div>
        <div className='userName'>{`${index + 1}. ${name}`}</div>
        <div className='close' onClick={lowerHandHandler}>
          <Icon name='XmarkOutline' size='xxs' />
        </div>
      </div>
    </StyledRaiseCell>
  );
};

export default RaiseCell;

RaiseCell.propTypes = {
  name: PropTypes.string,
  attendeeId: PropTypes.string,
  index: PropTypes.number,
};
