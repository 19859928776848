import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  Label as ChimeLabel,
  MicSelection as ChimeMicSelection,
  useLocalAudioInputActivityPreview,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import { useAudioInputs, useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import Icon from '@onedesign/icon';
import { t } from 'utils/i18n';

const Track = styled.div`
  width: 100%;
  height: 0.625rem;
  background-color: rgba(145, 158, 171, 0.32);
  border-radius: 16px;
  overflow: auto;
`;

const Progress = styled.div`
  height: 0.625rem;
  background-color: #75bec2;
  border-radius: 0.25rem;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 33ms ease-in-out;
  will-change: transform;
`;

const Label = styled(ChimeLabel)`
  display: block;
  color: #212b36;
`;

const DeviceSelection = styled.select`
  display: flex;
  height: 40px;
  padding: 8px 8px 7px 14px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 75px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  color: #919eab;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  gap: 4px;
`;

const audioInputConfig = {
  additionalDevices: false,
};

export const MicrophoneActivityPreviewBar = () => {
  const activityBarRef = useRef();
  useLocalAudioInputActivityPreview(activityBarRef);

  return (
    <Track>
      <Progress ref={activityBarRef} />
    </Track>
  );
};

export const MicrophoneActivityPreview = ({ label = true }) => {
  return (
    <div style={{ flex: '1' }}>
      {label && <Label>{t('meeting.components.microphoneDevices.microphoneDetection', '麥克風偵測')}</Label>}
      <MicrophoneActivityPreviewBar />
    </div>
  );
};

export const MicSelection = ({ label = true }) => {
  const meetingManager = useMeetingManager();
  const { devices: audioInputs, selectedDevice: selectedAudioInput } = useAudioInputs(audioInputConfig);

  return (
    <div>
      <LabelWrapper>
        <Icon name='MicrophoneAltSolid' size='sm' color={'#212B36'} />
        {label && <Label>{t('meeting.components.microphoneDevices.microphoneSource', '麥克風來源')}</Label>}
      </LabelWrapper>
      <DeviceSelection
        onChange={(event) => meetingManager.startAudioInputDevice(event.target.value)}
        value={selectedAudioInput}
      >
        {audioInputs.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </option>
        ))}
      </DeviceSelection>
    </div>
  );
};

export const MicrophoneDevices = () => {
  return (
    <div>
      <MicSelection />
      <MicrophoneActivityPreview />
    </div>
  );
};
