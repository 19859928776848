import React from 'react';
import PropTypes from 'prop-types';
import { useAttendeeStatus } from 'amazon-chime-sdk-component-library-react';
import { RemoteVideoV2, useMeetingDispatch } from '@oneboard/meeting';
import VideoContainer from '../common/VideoContainer';
import VideoContentContainer from '../common/VideoContentContainer';
import VolumeContainer from '../common/VolumeContainer';
import VideoWrapper from '../common/VideoWrapper';
import IconWrapper from '../../common/IconWrapper';
import StyledImage from '../../common/StyledImage';
import UserName from '../common/UserName';
import NotOpen from '../common/NotOpen';
import { MicroBlack } from '../../common/StyledIcon';
import VolumeIndicator from '../common/VolumeIndicator';
import Icon from '@onedesign/icon';
import StudentIcon from 'assets/newOneBoard/images/student_icon.png';

export default function RemoteVideo({
  userType,
  tileId,
  attendeeId,
  studentName,
  toggleToolboxAuth,
  queryToolboxAuthById,
}) {
  const { muted: attendMuted, videoEnabled } = useAttendeeStatus(attendeeId);
  const { openVideoAttendee, closeVideoAttendee, muteAttendee, unMuteAttendee } = useMeetingDispatch();

  const toggleVideo = () => {
    if (videoEnabled) {
      closeVideoAttendee({ attendeeId });
    } else {
      openVideoAttendee({ attendeeId });
    }
  };

  const toggleMute = () => {
    if (attendMuted) {
      unMuteAttendee({ attendeeId });
    } else {
      muteAttendee({ attendeeId });
    }
  };

  return (
    <VideoContainer key={tileId}>
      <VideoWrapper>
        {videoEnabled ? <RemoteVideoV2 tileId={tileId} attendeeId={attendeeId} /> : <NotOpen />}
      </VideoWrapper>
      <VideoContentContainer>
        <StyledImage src={StudentIcon} alt='name icon' width={28} height={28} />
        <UserName>{studentName || 'Unknown'}</UserName>
        <IconWrapper isEnabled={videoEnabled} onClick={toggleVideo} userType={userType}>
          <Icon name={videoEnabled ? 'VideoSolid' : 'VideoSlashSolid'} size='xxs' color='#FFFFFF' />
        </IconWrapper>
        <IconWrapper isEnabled={!attendMuted} onClick={toggleMute} userType={userType}>
          <Icon name={attendMuted ? 'MicrophoneAltSlashSolid' : 'MicrophoneAltSolid'} size='xs' color='#FFFFFF' />
        </IconWrapper>
        <IconWrapper
          onClick={() => toggleToolboxAuth({ attendeeId })}
          isEnabled={queryToolboxAuthById({ attendeeId })}
          userType={userType}
        >
          {queryToolboxAuthById({ attendeeId }) ? (
            <Icon name='ChalkboardOutline' size='xs' color='#FFFFFF' />
          ) : (
            <Icon name='ChalkboardSlashSolid' size='xs' color='#FFFFFF' />
          )}
        </IconWrapper>
      </VideoContentContainer>
      <VolumeContainer>
        <MicroBlack />
        <VolumeIndicator attendeeId={attendeeId} totalVolumeLevel={10} />
      </VolumeContainer>
    </VideoContainer>
  );
}

RemoteVideo.propTypes = {
  userType: PropTypes.string.isRequired,
  tileId: PropTypes.number.isRequired,
  attendeeId: PropTypes.string.isRequired,
  studentName: PropTypes.string.isRequired,
  toggleToolboxAuth: PropTypes.func.isRequired,
  queryToolboxAuthById: PropTypes.func.isRequired,
};
