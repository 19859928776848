import styled from 'styled-components';

export const StyledSingleControlBar = styled.div.attrs(props => ({
  className: props.className
}))`
  position: absolute;
  left: 48px;
  top: calc(40vh + 21px);
  border-radius: 8px;
  padding: 24px 8px;
  background-color: #232323;
  z-index: 100;

  .settingPopoverButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #D5D7DE;
  }
`;

export const ToolBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 0;
  padding: 0;
  color: ${({ active }) => active ? '#EC7963' : '#D5D7DE'};
  cursor: pointer;
  background-color: transparent;
  border: none;
  
  & + & {
    margin-top: 20px;
  }
  
  &:hover {
    background-color: #33353A;
  }
`;
