import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useFirebaseStorage } from 'utils/hooks/useStorage';

import { StyledFileUploader } from './FileUploader.style';

export const FileUploader = ({ className, children, uploadPath, onUploaded }) => {
  const ref = useRef();
  const { meetingId } = useParams();
  const { upload } = useFirebaseStorage();

  const handleUpload = async (event) => {
    const [file] = event.target.files;
    if (!file || !file.type) return;
    const uploadPath = `${meetingId}/${file.name}_${new Date().getTime()}`;
    const { status, url } = await upload(uploadPath, file);
    if (!status) return;
    onUploaded({ fileName: file.name, url });
    event.target.value = null;
  };

  return (
    <StyledFileUploader className={className} data-testid="FileUploader" onClick={() => ref.current.click()}>
      <input hidden type="file" ref={ref} onChange={handleUpload}/>
      {children}
    </StyledFileUploader>
  );
};

FileUploader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  uploadPath: PropTypes.string,
  onUploaded: PropTypes.func
};