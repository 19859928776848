import styled from 'styled-components';

export const StyledFallbackComponent = styled.div.attrs(props => ({
  className: props.className
}))`
  height: 100%;
  padding: 16px;
  background-color: #2E343F;
  color: #fff;
  overflow: auto;
  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 960px;
    margin: auto;
  }
  .textContent {
    flex: 1 1 50%;
    .oops {
      font-size: 96px; 
      color: #8B90A0;
      font-weight: 600;
    }
    .title {
      font-size: 36px; 
      color: #F2F4F8;
      font-weight: 600;
    }
    .content {
      color: #A1A4B1;
      font-size: 20px;
      .phone {
        color: #E4E7EC;
      }
    }
  }

  .imgContent {
    flex: 1 1 50%;
    img {
      width: 100%;
    }
  }
  
  .errorMsg {
    font-size: 32px; 
  }
  .componentStack {
    color: #f00;
    line-height: 2em;
  }
`;