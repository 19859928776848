import React from 'react';
import PropTypes from 'prop-types';
import { useRemoteVideoTileState, useAttendeeStatus } from 'amazon-chime-sdk-component-library-react';
import VideoWrapper from '../common/VideoWrapper';
import VideoContainer from '../common/VideoContainer';
import VideoContentContainer from '../common/VideoContentContainer';
import VolumeContainer from '../common/VolumeContainer';
import IconWrapper from '../../common/IconWrapper';
import StyledImage from '../../common/StyledImage';
import UserName from '../common/UserName';
import { RemoteVideoV2 } from '@oneboard/meeting';
import NotOpen from '../common/NotOpen';
import { MicroBlack } from '../../common/StyledIcon';
import VolumeIndicator from '../common/VolumeIndicator';
import Icon from '@onedesign/icon';
import TeacherIcon from 'assets/newOneBoard/images/teacher_icon.png';
import { useWindowWidth } from '../Student/hooks/useWindowWidth';

export default function TeacherVideo({ teacherId, teacherName }) {
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { muted: teacherMuted, videoEnabled: teacherVideoEnabled } = useAttendeeStatus(teacherId);
  const windowWidth = useWindowWidth();
  const totalVolumeLevel = windowWidth < 1224 ? 8 : 10;

  return (
    <>
      {teacherId && (
        <VideoContainer>
          <VideoWrapper>
            {teacherVideoEnabled ? (
              <RemoteVideoV2 attendeeId={teacherId} tileId={attendeeIdToTileId[teacherId]} />
            ) : (
              <NotOpen />
            )}
          </VideoWrapper>
          <VideoContentContainer>
            <StyledImage src={TeacherIcon} alt='name icon' width={28} height={28} />
            <UserName>{teacherName}</UserName>
            <IconWrapper isEnabled={teacherVideoEnabled}>
              <Icon name={teacherVideoEnabled ? 'VideoSolid' : 'VideoSlashSolid'} size='xxs' color='#FFFFFF' />
            </IconWrapper>
            <IconWrapper isEnabled={!teacherMuted}>
              <Icon name={teacherMuted ? 'MicrophoneAltSlashSolid' : 'MicrophoneAltSolid'} size='xs' color='#FFFFFF' />
            </IconWrapper>
          </VideoContentContainer>
          <VolumeContainer>
            <MicroBlack />
            <VolumeIndicator attendeeId={teacherId} totalVolumeLevel={totalVolumeLevel} />
          </VolumeContainer>
        </VideoContainer>
      )}
    </>
  );
}

TeacherVideo.propTypes = {
  teacherId: PropTypes.string.isRequired,
  teacherName: PropTypes.string.isRequired,
};
