import styled from 'styled-components';

export const StyledVideoModeControlBar = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  padding: 3px;
  background-color: #232323;
  border-radius: 9px;

  .btn {
    width: 157px;
    padding: 13px 8px;
    font-size: 16px;
    color: #fff;
    border-radius: 9px;
    text-align: center;
    line-height: 1;
    cursor: pointer;

    &.active{
      background-color: #33353A;
    }
  }
`;