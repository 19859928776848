import React, { useRef } from 'react';
import {
  StyledWaitingAnimation,
  Animation,
  AnimationWrap,
  TextContainer,
  StyledHeader,
  StyledText,
} from './MeetingFailurePage.style';
import Time from './Time.gif';
import { SendButton } from 'components/NewOneBoard/common/MessageButton';

export const MeetingFailurePage = () => {
  const ref = useRef();
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <StyledWaitingAnimation>
      <AnimationWrap ref={ref}>
        <Animation ref={ref}>
          <img width={'200px'} src={Time} alt='Time' />
        </Animation>
        <TextContainer>
          <StyledHeader>教室已關閉</StyledHeader>
          <StyledText>您已超過30分鐘沒有動作，請點擊「重新整理」重新進入教室。</StyledText>
        </TextContainer>
        <SendButton onClick={handleRefresh}>重新整理</SendButton>
      </AnimationWrap>
    </StyledWaitingAnimation>
  );
};
