import styled from 'styled-components';
import { Input } from 'antd';

export const StyledInput = styled(Input)`
  border: none;
  background: #33353A;
  border-radius: 8px;
  padding: 12px 16px;
  
  > input {
    background: #33353A;
    color:#fff;
    font-size: 14px;
  }
  
  svg {
    color: #fff;
  }
`;

export const StyledBox = styled.div`

  .close {
    background-color: transparent;
    margin-right: 30px;
    transition: 0.35s;

    &:hover {
      background-color: #33353A;
    }
  }
`;