import { t as trans } from 'i18next';

const t = (
  key,
  defaultValue,
  params,
) => {
  return (trans(key, { ...params, defaultValue })).toString();
};

const getUserLanguagePreference = () => {
  const lang = localStorage.getItem('lang');
  return lang || 'zh'; // return 'zh' as the default language if no language preference is stored
};

export { t, getUserLanguagePreference };
