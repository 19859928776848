import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useVideoListCarousel } from './useVideoListCarousel';

export const StateContext = createContext();

export const useVideoListContext = () => useContext(StateContext);

export const VideoListProvider = ({ children }) => {
  const videoListContext = useVideoListCarousel();

  return (
    <StateContext.Provider value={videoListContext}>
      {children}
    </StateContext.Provider>
  );
};

VideoListProvider.propTypes = {
  children: PropTypes.node,
};