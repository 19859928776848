import styled from 'styled-components';

export const StyledSyncMainBlock = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  .ant-notification {
    position: absolute;
  }
  .notificationList {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 6px 20px;
    background-color: #ff7257;
    border-radius: 8px;
    .ant-notification-notice-content {
      width: 100%;
    }
    .ant-notification-notice-description {
      color: #fff;
    }
    .ant-notification-notice-close {
      position: initial;
    }
  }
`;
