import React, { useMemo, useState, createContext, useContext } from 'react';
import { useMeetingModal } from './useMeetingModal';

export const StateContext = createContext();

export const useMeetingModalContext = () => useContext(StateContext);

export const MeetingModalProvider = ({ children }) => {
  const meetingModalContext = useMeetingModal();

  return (
    <StateContext.Provider value={meetingModalContext}>
      {children}
    </StateContext.Provider>
  );
};



export const RedirectRoomModalStateContext = createContext();

export const useRedirectRoomModalContext = () => useContext(RedirectRoomModalStateContext);

export const RedirectRoomModalProvider = ({ children }) => {
  const [url, setUrl] = useState('');

  const redirect = () => {
    if (!url) return;
    window.location.href = url;
  };

  const context = useMeetingModal();

  const value = useMemo(() => ({
    ...context,
    setUrl,
    redirect,
    redirectUrl: url
  }), [context, url]);

  return (
    <RedirectRoomModalStateContext.Provider value={value}>
      {children}
    </RedirectRoomModalStateContext.Provider>
  );
};
