import React from 'react';
import { useMeetingState, MeetingStates, MeetingRoles, useMeetingDispatch, Reward } from '@oneboard/meeting';
import { Roles } from 'constants/index';

const RewardWrapper = ({ student }) => {
  const meetingState = useMeetingState();
  const { pauseReward, pauseRewardFromAdvisor } = useMeetingDispatch();
  const { role } = meetingState.context;

  const isRewardAnimateActive =
    meetingState.matches({
      [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
    }) ||
    meetingState.matches({
      [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
    });

  const isAnimate = meetingState.context.rewards.animate.includes(student?.userId);

  const rewardCompletedHandler = () => {
    if (role === Roles.Advisor) {
      pauseRewardFromAdvisor({ userId: student?.userId });
    } else {
      pauseReward({ userId: student?.userId });
    }
  };

  return (
    <>{isRewardAnimateActive && isAnimate && <Reward rewardType='Trophy' onComplete={rewardCompletedHandler} />}</>
  );
};

export default RewardWrapper;
