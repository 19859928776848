import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

const STRENGTH = {
  NONE: 'SpeakingNoneSolid',
  WEAK: 'SpeakingWeakSolid',
  STRONG: 'SpeakingStrongSolid'
};
const getIconName = (volume = 0) => {
  let strength = STRENGTH.NONE;
  if (volume > 0) strength = STRENGTH.WEAK;
  if (volume > 0.5) strength = STRENGTH.STRONG;
  return strength;
};

export const VolumeStatusMeter = ({ attendeeId, size }) => {
  const audioVideo = useAudioVideo();
  const [volumeStrength, setVolumeStrength] = useState(0);

  useEffect(() => {
    if (!attendeeId || !audioVideo) return;
    const volumeHandler = (
      _attendeeId,
      volume,
      _muted,
      _signalStrength
    ) => {
      setVolumeStrength(volume);
    };
    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, volumeHandler);
    return () => {
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, volumeHandler);
    };
  }, [attendeeId, audioVideo]);

  return <Icon name={getIconName(volumeStrength)} size={size} />;
};

VolumeStatusMeter.propTypes = {
  attendeeId: PropTypes.string,
  size: PropTypes.string,
};