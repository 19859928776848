import React from 'react';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useToolboxAuth } from 'utils/hooks/useToolboxAuth';
import { useMeetingState } from '@oneboard/meeting';
import { Roles } from 'constants/index';
import TeacherVideo from './TeacherVideo';
import StudentVideo from './StudentVideo';
import ChatRoomButton from 'components/NewOneBoard/ChatRoom/ChatRoomButton';
import VideoPanelContainer from 'components/NewOneBoard/VideoInfo/common/VideoPanelContainer';
import ButtonContainer from 'components/NewOneBoard/VideoInfo/common/ButtonContainer';

export function ObserverVideoPanel() {
  const { roster } = useRosterState();
  const state = useMeetingState();
  const { context } = state;
  const { role } = context;
  const { queryToolboxAuthById } = useToolboxAuth();
  const student = Object.values(roster).find((attendee) => attendee.role === Roles.Student);
  const teacher = Object.values(roster).find((attendee) => attendee.role === Roles.Teacher);
  const observer = Object.values(roster).filter((attendee) => attendee.role === Roles.Observer);
  const teacherId = teacher?.chimeAttendeeId;
  const studentId = student?.chimeAttendeeId;

  if (!observer) {
    return null;
  }

  return (
    <VideoPanelContainer>
      <div>
        {teacherId && (
          <TeacherVideo
            teacherId={teacherId}
            teacherName={teacher?.name}
            studentId={studentId}
            studentName={student?.name}
            studentRole={student?.role}
            queryToolboxAuthById={queryToolboxAuthById}
          />
        )}
        {studentId && (
          <StudentVideo
            studentId={studentId}
            studentName={student?.name}
            studentRole={student?.role}
            queryToolboxAuthById={queryToolboxAuthById}
          />
        )}
      </div>
      <ButtonContainer>
        <ChatRoomButton role={role} />
      </ButtonContainer>
    </VideoPanelContainer>
  );
}
