import dayjs from 'dayjs';
import { getServerTime as getServerTimeApi } from 'services/board';

export const getServerTime = async () => {
  let serverTime = null;
  const response = await getServerTimeApi();

    try {
      if (response.status === 'success') {
        const serverTimestamp = response.data.timestamp;
        serverTime = dayjs(serverTimestamp);
      }
    } catch (error) {
      console.log(error);
      serverTime = dayjs();
    }

    return serverTime;
};

export const formatSeconds = (seconds) => new Date(seconds).toISOString().substr(11, 8);