import { fetch } from './index';

const COURSE_URL = process.env.REACT_APP_COURSE_API_DOMAIN;

/**
 * 取得課外學習課程資料
 * @param {string[]} courseId - course id
 * @returns
 */
export const getInternalCourse = (courseId) => {
  return fetch(`${COURSE_URL}/api/internal/courses/courseId/${courseId}`);
};