import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAudioVideo, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { postMessage as postMessageApi } from 'services/chat';

const DATA_MESSAGE_IMAGE_STATUS_TOPIC = 'imageStatus';
const DATA_MESSAGE_IMAGE_TOPIC = 'imageChat';
const DATA_MESSAGE_LIFETIME_MS = 30000;

export const useImage = () => {
  const { meetingId } = useParams();
  const meetingManager = useMeetingManager();
  const { attendeeId: selfAttendeeId } = meetingManager?.meetingSessionConfiguration?.credentials || {};
  const audioVideo = useAudioVideo();
  const query = useQuery();

  const createImage = useCallback(
    (file, fileName) => {
      if (!selfAttendeeId || !fileName) return;
      const userName = query.userName || '';

      return {
        name: file.name,
        senderAttendeeId: selfAttendeeId,
        userName,
        fileName,
        role: query.role,
        timestamp: new Date().getTime(),
      };
    },
    [selfAttendeeId, query.userName, query.role]
  );

  const sendImage = useCallback(
    async (file, fileName) => {
      if (!audioVideo) return;
      const imageMessage = createImage(file, fileName);
      if (!meetingId) return;
      await postMessageApi({
        userId: query.userId,
        courseId: meetingId,
        message: fileName,
      });
      audioVideo?.realtimeSendDataMessage(DATA_MESSAGE_IMAGE_TOPIC, imageMessage, DATA_MESSAGE_LIFETIME_MS);
    },
    [audioVideo, createImage, meetingId, query.userId]
  );

  const receiveImage = useCallback(
    (callback) => {
      if (!audioVideo) return;
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_IMAGE_TOPIC, callback);
    },
    [audioVideo]
  );

  const updateImageStatus = useCallback(
    async (fileName, senderAttendeeId, status) => {
      if (!audioVideo || !fileName || !status) return;

      const statusMessage = {
        senderAttendeeId,
        fileName,
        status,
        timestamp: new Date().getTime(),
      };

      audioVideo?.realtimeSendDataMessage(DATA_MESSAGE_IMAGE_STATUS_TOPIC, statusMessage, DATA_MESSAGE_LIFETIME_MS);
    },
    [audioVideo]
  );

  const receiveImageStatus = useCallback(
    (callback) => {
      if (!audioVideo) return;
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_IMAGE_STATUS_TOPIC, (dataMessage) => {
        const statusMessage = dataMessage.json();

        if (statusMessage.senderAttendeeId === selfAttendeeId) {
          callback(statusMessage);
        }
      });
    },
    [audioVideo, selfAttendeeId]
  );

  return { createImage, sendImage, receiveImage, updateImageStatus, receiveImageStatus };
};
