import styled from 'styled-components';

export const StyledSyncObserver = styled.div`
  height: 100%;
  width: 100%;

  .auditListLayout {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
`;

export const SyncContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;

  .stagedVideoListLayout {
    width: 100%;
    min-height: 150px;
    padding: 16px 8px;
    background-color: #161c27;
  }
`;

export const ContentShareContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;
