import styled from 'styled-components';

export const StyledResizableRect = styled.div.attrs(props => ({
  className: props.className
}))`
  display: block;
  width: 100%;
  height: ${({ isDrag }) => isDrag ? '100%' : '0' };
  position: absolute;
  top: 0;
  transform: translate(0,0);
  z-index: ${({ isDrag }) => isDrag ? 1000 : 10 };

  iframe {
    pointer-events: ${({ isDrag }) => isDrag ? 'none' : 'auto' };
  }

  .resizableRect {
    .moveable-control {
      border: 2px solid #fff;
      background: #33353A;
    }
    .moveable-line {
      background: ${({ isBorder }) => isBorder ? '#33353A' : 'transparent'};
    }
    .moveable-rotation .moveable-rotation-control {
      border-color: #33353A;
    }
  }
`;