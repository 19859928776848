import {
  createContext,
  useContext,
  useState,
  useMemo
} from 'react';

const RoomContext = createContext(null);

const RoomProvider = ({ children }) => {
  const [insertPictureModalSwitch, setInsertPictureModalSwitch] = useState(false);

  const toggleInsertPictureModalSwitch = () => setInsertPictureModalSwitch(prev => !prev);

  const value = useMemo(() => ({
    insertPictureModalSwitch,
    toggleInsertPictureModalSwitch
  }),
    [
      insertPictureModalSwitch,
      toggleInsertPictureModalSwitch
    ]
  );

  return (
    <RoomContext.Provider value={value}>
      {children}
    </RoomContext.Provider>
  );
};

const useRoom = () => {
  const context = useContext(RoomContext);

  return context;
};

export { RoomProvider, useRoom };