import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useWhiteboard } from '@oneboard/whiteboard';

const Context = createContext(null);

const LocalToolboxAuthProvider = ({ children }) => {
  const { service: whiteboardService } = useWhiteboard();
  const [isToolboxEnabled, setIsToolboxEnabled] = useState(false);

  const toggleLocalToolboxAuth = useCallback(() => {
    if (!whiteboardService) return
    try {
      if (isToolboxEnabled) {
        whiteboardService.setWritable(false).then(() => {
          setIsToolboxEnabled(false);
          console.log('disable ToolboxAuth');
        });
      } else {
        whiteboardService.setWritable(true).then(() => {
          setIsToolboxEnabled(true);
          console.log('enable ToolboxAuth');
        });
      }
    } catch (error) {
      console.log('Failed to toggle ToolboxAuth:', error.message)
    }
  }, [whiteboardService, isToolboxEnabled]);

  const value = useMemo(
    () => ({
      isToolboxEnabled,
      toggleLocalToolboxAuth,
    }),
    [
      isToolboxEnabled,
      toggleLocalToolboxAuth,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useLocalToolboxAuth = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useLocalToolboxAuth must be used within LocalToolboxAuthProvider');
  }

  return context;
};

export { LocalToolboxAuthProvider, useLocalToolboxAuth };
