import React, { forwardRef } from 'react';
import { LocalVideo as ChimeLocalVideo, useRosterState, useLocalVideo } from 'amazon-chime-sdk-component-library-react';
import { StyledLocalVideo, StyledNameBox } from './LocalVideo.style';
import styled from 'styled-components';

/**
 * @param {string} position // static || absolute 物件模式下使用 static
 */

export const LocalVideoV2 = forwardRef(
  ({ className, attendeeId, onClick, isShowNameplate = false, isRatio = false, position, videoIsMirroring }, ref) => {
    const { isVideoEnabled } = useLocalVideo();
    const { roster } = useRosterState();
    return (
      <StyledLocalVideo
        className={`${className} ${videoIsMirroring ? '' : 'isFlip'}`}
        data-testid='LocalVideo'
        onClick={onClick}
        position={position}
        ref={ref}
      >
        {!isVideoEnabled || isShowNameplate ? (
          <div className='tileBox'>
            <StyledNameBox bgColor={roster[attendeeId]?.color}>{roster[attendeeId]?.name?.slice(0, 1)}</StyledNameBox>
          </div>
        ) : (
          <ChimeLocalVideo />
        )}
      </StyledLocalVideo>
    );
  }
);
