import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useRosterState,
  useAttendeeStatus,
  useAudioVideo
} from 'amazon-chime-sdk-component-library-react';
import { VolumeStatusMeter } from 'components';
import { Box } from '@oneboard/ui-components';
import {
  useMeetingState,
  Reward,
  useMeetingDispatch,
  MeetingStates,
  MeetingRoles
} from '@oneboard/meeting';
import { Roles } from 'constants/index';
import Icon from '@onedesign/icon';
import { useCallback } from 'react';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useThrottle } from 'ahooks';
import { StyledVideoBox, UiInfoPanel, StatePanelContainer, InsideVideoBox, StyledVolumeActivity } from './VideoBox.style';
import styled from 'styled-components';

const TrophyContainer = styled(Box)`
    background: rgba(69, 79, 91, 0.60);
    border-radius: 10px;
    width: 32px;
    height: 20px;
    display:flex;
    align-items:center;
    justify-content: space-evenly;
    padding: 0 4px;
  `  

const VolumeActivity = ({ attendeeId }) => {
  const audioVideo = useAudioVideo();
  const [volumeStrength, setVolumeStrength] = useState(0);
  const throttleVolumeStrength = useThrottle(volumeStrength, { wait: 500 });

  useEffect(() => {
    if (!attendeeId || !audioVideo) return;
    const volumeHandler = (
      _attendeeId,
      volume,
      _muted,
      _signalStrength
    ) => {
      setVolumeStrength(volume);
    };
    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, volumeHandler);
    return () => {
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, volumeHandler);
    };
  }, [attendeeId, audioVideo]);
  return (
    <StyledVolumeActivity volumeStrength={throttleVolumeStrength} />
  );
};

VolumeActivity.propTypes = {
  attendeeId: PropTypes.string,
};

const InfoPanel = ({ roster, attendeeId, isStudent, userId }) => {
  const state = useMeetingState();
  const { context } = state;
  const { isBreakoutRoom } = useBreakoutMeeting();
  const { rewards } = context;

  return (
    <UiInfoPanel>
      <div className="name">
        {roster[attendeeId]?.name}
      </div>
      {!isBreakoutRoom && isStudent && <TrophyContainer className="reward">
        <Icon name="TrophySolid" color="#FACD61" size="xxs" />
        <Box className="rewardNumber">{userId ? rewards[userId] : 0}</Box>
      </TrophyContainer>}
    </UiInfoPanel>
  );
};

InfoPanel.propTypes = {
  roster: PropTypes.object,
  attendeeId: PropTypes.string,
  isStudent: PropTypes.bool,
  userId: PropTypes.string,
};

const StatePanel = ({ attendeeId }) => {
  const { context } = useMeetingState();
  const { raisedHandAttendeeIds } = context;
  const { muted } = useAttendeeStatus(attendeeId);

  return (
    <StatePanelContainer>
      {raisedHandAttendeeIds.includes(attendeeId) && <div className="stateIcon raiseHand">
        <Icon name="RaisehandSolid" size='xxs' />
      </div>}
      <div className="stateIcon">
        {muted ? <Icon name="MicrophoneAltSlashSolid" size="xxs" /> :
          <VolumeStatusMeter attendeeId={attendeeId} size="xxs" />}
      </div>
    </StatePanelContainer>
  );
};

StatePanel.propTypes = {
  attendeeId: PropTypes.string,
};

export const VideoBox = ({ className, attendeeId, isStudent = false, children, onClick = () => { }, isRatio = false, userId }) => {
  const { roster } = useRosterState();
  const meetingState = useMeetingState();
  const { pauseReward, pauseRewardFromAdvisor } = useMeetingDispatch();
  const { context } = meetingState;
  const { rewards, role } = context;

  const isRewardAnimateActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`
  }) || meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`
  });

  const rewardCompletedHandler = useCallback(() => {
    if (role === Roles.Advisor) {
      pauseRewardFromAdvisor({ userId });
    } else {
      pauseReward({ userId });
    }
  }, []);

  const isAnimate = rewards.animate.includes(userId);

  useEffect(() => {
    return () => {
      if (role === Roles.Advisor) {
        pauseRewardFromAdvisor({ userId });
      } else {
        pauseReward({ userId });
      }
    };
  }, []);

  return (
    <StyledVideoBox className={className} data-testid="VideoBox" onClick={onClick}>
      <VolumeActivity attendeeId={attendeeId} />
      <InsideVideoBox isRatio={isRatio}>
        {
          (isRewardAnimateActive && isAnimate) && (
            <Reward
              rewardType="Trophy"
              onComplete={rewardCompletedHandler} />
          )
        }
        {children}
        <StatePanel attendeeId={attendeeId} />
        <InfoPanel isStudent={isStudent} roster={roster} attendeeId={attendeeId} userId={userId} />
      </InsideVideoBox>
    </StyledVideoBox>
  );
};

VideoBox.propTypes = {
  className: PropTypes.string,
  attendeeId: PropTypes.string,
  isStudent: PropTypes.bool,
  onClick: PropTypes.func,
  isRatio: PropTypes.bool,
  userId: PropTypes.string,
  children: PropTypes.node,
};