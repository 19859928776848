import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import Icon from '@onedesign/icon';
import { useRealTimeSubscriber } from 'utils/hooks/useRealTimeSubscriber';

export const CommentsIcon = ({ isChatRoomOpen, color = '#fff' }) => {
  const [isBadge, setIsBadge] = useState(false);
  useRealTimeSubscriber('chat', (data) => {
    if (!isChatRoomOpen) {
      setIsBadge(true);
    } else {
      setIsBadge(false);
    }
  });

  useEffect(() => {
    setIsBadge(false);
  }, [isChatRoomOpen]);

  return (
    <Badge dot={isBadge} size="small">
      <Icon name="CommentsSolid" size='sm' color={color}/>
    </Badge>
  );
};

CommentsIcon.propTypes = {
  isChatRoomOpen: PropTypes.bool,
  color: PropTypes.string
};