import { useBitrixService } from 'utils/hooks/useBitrix';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';

export const ServiceButton = () => {
  const { url: bitrixUrl } = useBitrixService();
  const { isFooterOpen } = useFooterStatus();

  const handleCustomerService = () => {
    window.open(bitrixUrl, '_blank');
  };
  return (
    <ToolButton
      icon={<Icon name='HeadsetSolid' size='md' color='#75BEC2' />}
      onClick={handleCustomerService}
    >
      {isFooterOpen && '聯絡客服'}
    </ToolButton>
  );
};
