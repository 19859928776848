const PAPER_URL = process.env.REACT_APP_ONEPAPER_API_DOMAIN;

export const getBooksData = (payload) => {
  const apiUrl = `${PAPER_URL}/api/Resource/GetBooks`;

  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      Accept: 'text/plain',
      'Content-Type': 'application/json-patch+json',
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error('API Error:', error);
    });
};
