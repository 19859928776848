import styled from 'styled-components';



export const StyledList = styled.div`
    padding: 8px;
    font-size: 14px;
    color: #D6DAE0;
    cursor: pointer;
    
    &.active, &:hover {
      background-color: #3E4045;
      color: #FAFAFC;
    }
`;

export const StyledTeachingMaterialModal = styled.div.attrs(props => ({
  className: props.className
}))`
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    width: 332px;
    height: 50vh;
    border-radius: 12px;
    padding: 0 0 16px;
    background-color: #232323;
    z-index: 11;

    >.head {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 16px;
      padding: 12px 0;
      border-bottom: 1px solid #454B5C;

      .title {
        font-size: 16px;
        color: #91949E;
        text-align: center;
      }
      .closeBtn {
        position: absolute;
        right: 0;
        font-size: 0;
        cursor: pointer;
      }
    }

    >.content {
      padding: 8px 24px;
      overflow-y: auto;
    }

  .ant-tabs-tab {
    color: #8b90a0;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
  }

  .ant-tabs-ink-bar {
    background: #fff;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border-color: #454b5c;
  }
`;
