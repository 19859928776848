import styled from 'styled-components';

export const StyledSyncSingle = styled.div`
  height: 100%;
  
  .auditListLayout {
    position: absolute;
    top: 0;
    right: 0;
    z-index:100;
  }
  .chatRoomLayout {
    height: 100%;
  }
  &.grouping {
    max-height: calc(100% - 60px);
    height: 100%;
    }
  &.networkNotice {
    max-height: calc(100% - 60px);
    height: 100%;
  }
`;

export const ModalTitle = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
`;

export const StyledNameBox = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 64px;
  cursor: default;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
  color: ${({ active }) => active ? '#EC7963' : '#212324'};
  background-color: #fff;
  
  &:hover {
    background: rgba(33, 35, 36, 0.1);
  }
`;

export const SyncContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  
  .stagedVideoListLayout {
    width: 100%;
    padding: 16px 8px;
    background-color: #161C27;
  }
`;

export const NotificationLayout = styled.div`
  padding: 12px 8px;
  background-color: #161C27;
`;

export const ContentShareContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;