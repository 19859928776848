import { useWhiteboard } from '@oneboard/whiteboard';

export const useZoomControl = () => {
  const { service: whiteboardService } = useWhiteboard();

  const scaleToFit = () => {
    if (!whiteboardService) return;

    const scenes = whiteboardService.state.sceneState.scenes;
    const index = whiteboardService.state.sceneState.index;

    if (scenes && scenes[index].ppt) {
      whiteboardService.scalePptToFit();
    } else {
      zoomChange(1);
    }
  };

  const zoomChange = (scale) => {
    whiteboardService.moveCamera({
      centerX: 0,
      centerY: 0,
      scale: scale,
    });
  };

  return {
    scaleToFit
  };
};

