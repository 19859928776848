const BASE_URL = process.env.REACT_APP_ONEBOARD_COURSE_DOMAIN;

export const createCourse = async ({
  courseName,
  teacherName,
  studentName,
  advisorName,
  classType,
  bookIds
}) => {
  try {
    const response = await fetch(`${BASE_URL}/oneboard/course`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        courseName,
        teacherName,
        studentName,
        advisorName,
        classType,
        bookIds
      })
    }).then(res => res.json());
    return response.data;
  } catch (error) {
    return {
      courseId: ''
    };
  }
};
