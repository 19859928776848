import styled, { keyframes } from 'styled-components';
import { Box } from '@oneboard/ui-components';

export const StyleUsersModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 100%;
  padding: 16px;
  background-color: #232323;
  color: #d6dae0;
`;

export const StyleHeader = styled.div`
  position: relative;
  font-size: 20px;
  color: #fff;
  text-align: center;
  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

export const StyledUserCell = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-bottom: 1px solid #33353a;

  &:hover {
    background-color: #33353a;
  }

  .iconGroup {
    > div {
      cursor: pointer;
    }
  }
`;

export const StyledModalHead = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #8b90a0;
  color: #8b90a0;
  > .title {
    flex: 1;
  }
`;

export const StyledModalBody = styled.div`
  /* max-height: 300px; */
  /* flex: 1 1 auto; */
  margin-right: ${({ isScrollable }) => (isScrollable ? '-18px' : 0)};
  overflow-y: auto;
`;

export const StyledTeacher = styled.div`
  margin-bottom: 12px;
`;

export const StyledName = styled(Box)`
  color: ${({ active }) => (active ? '#EC7963' : '#D6DAE0')};
`;

export const StyledRewards = styled(Box)`
  flex: 1;
  display: flex;
  align-items: center;
`;

const flash = keyframes`
  from {
    color: #D5D7DE;
  }

  to {
    color: #8B90A0;
  }
`;

export const StyledStage = styled(Box)`
  flex: 0.5;
  display: flex;
  align-items: center;
  color: ${({ isOnStaged }) => (isOnStaged ? '#EC7963' : '#D5D7DE')};
  cursor: pointer;

  &:hover {
    color: #8b90a0;
  }

  animation: ${({ isRaiseHand }) => (isRaiseHand ? flash : '')};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
`;

export const StyleLiteUsersModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 100%;
  padding: 16px;
  background-color: #232323;
  color: #d6dae0;
`;
