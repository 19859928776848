import React from 'react';
import PropType from 'prop-types';
import Icon from '@onedesign/icon';
import { StyledOnebookFrame, StyledOnebookFrameHeader, StyledResizableRect } from './OnebookFrame.style';

export const OnebookFrame = ({ bookId, onClose }) => {

  const onebookUrl = `${process.env.REACT_APP_READER_URL}/${bookId}?beforeunload=false`;

  return (
    <StyledResizableRect keepRatio={false}>
      {
        (ref) => (
          <StyledOnebookFrame ref={ref} data-testid="WhiteboardFrame">
            <StyledOnebookFrameHeader>
              <div className="title"></div>
              <div className="close" onClick={onClose}>
                <Icon name="XmarkOutline" size="sm" />
              </div>
            </StyledOnebookFrameHeader>
            <iframe
              className="iframe"
              title="onebook"
              src={onebookUrl}
              frameborder="0"
            />
          </StyledOnebookFrame>
        )
      }
    </StyledResizableRect>
  );
};


OnebookFrame.propTypes = {
  bookId: PropType.string,
  onClose: PropType.func
};