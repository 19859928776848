import styled from 'styled-components';
import { ReactComponent as MicroIconBlack } from 'assets/newOneBoard/svg/microphone_black.svg';

const StyledIcon = styled.svg`
  width: ${(props) => props.size || '14px'};
  height: ${(props) => props.size || '14px'};
`;

export const MicroBlack = styled(MicroIconBlack)`
  width: 24px;
  height: 24px;
`;

export default StyledIcon;
