import React, { useEffect } from 'react';
import { useMeetingDispatch } from '@oneboard/meeting';
import {
  useDeviceDetectState,
  DeviceDetectStates,
  Intro,
  StepsBar,
  NetworkStep,
  VideoStep,
  MicrophoneStep,
  AudioStep,
  ResultStep,
} from '@oneboard/deviceDetect';
import { Box } from '@oneboard/ui-components';
import { v4 as uuid } from 'uuid';
import { postBitrixNotify, postBitrixOrderNotify } from 'services/bitrix';
import { useBitrix } from 'utils/hooks/useBitrix';
import { useQuery } from 'utils/hooks/useQuery';
import { StyledDeviceDetectPage } from './DeviceDetectPage.style';

export const DeviceDetectPage = ({ className }) => {
  const deviceDetectState = useDeviceDetectState();
  const isIntroducing = deviceDetectState.value === DeviceDetectStates.Intro;
  const isNetwork = deviceDetectState.matches({
    [DeviceDetectStates.Steps]: `${DeviceDetectStates.Network}`
  });
  const isVideo = deviceDetectState.matches({
    [DeviceDetectStates.Steps]: `${DeviceDetectStates.Video}`
  });
  const isMicrophone = deviceDetectState.matches({
    [DeviceDetectStates.Steps]: `${DeviceDetectStates.Microphone}`
  });
  const isAudio = deviceDetectState.matches({
    [DeviceDetectStates.Steps]: `${DeviceDetectStates.Audio}`
  });
  const isResult = deviceDetectState.matches({
    [DeviceDetectStates.Steps]: `${DeviceDetectStates.Result}`
  });
  const query = useQuery();
  const orderId = query?.orderId || null;

const { joinMeeting } = useMeetingDispatch();
  const id = uuid();

  useEffect(() => {
    joinMeeting({
      roomId: 'deviceDetect',
      userName: 'user',
      role: 'student',
      userId: id
    });
  }, []);

  const { insertCRMChat } = useBitrix();
  useEffect(() => {
    insertCRMChat();
  }, []);

  return (
    <StyledDeviceDetectPage className={className} data-testid="DeviceDetectPage">
      <div className="container">
        <div className="header">
          <div className="logo">
            <img src="/logo.svg" alt="" />
          </div>
          <div className="title">學習平台檢測</div>
        </div>

        {!isIntroducing && <Box mt={4}>
          <StepsBar />
        </Box>}

        {isIntroducing && <Intro />}

        <div className="stepContent">
          {isNetwork && <NetworkStep />}

          {isVideo && <VideoStep />}

          {isMicrophone && <MicrophoneStep />}

          {isAudio && <AudioStep />}

          {isResult && <ResultStep postBitrix={postBitrixNotify} postBitrixOrder={postBitrixOrderNotify} orderId={orderId} />}
        </div>
      </div>
    </StyledDeviceDetectPage>
  );
};