import React, { createContext, useContext } from 'react';
import { useGroup } from './useGroup';

export const StateContext = createContext();

export const useGroupContext = () => useContext(StateContext);

export const GroupProvider = ({ children }) => {
  const groupContext = useGroup();

  return (
    <StateContext.Provider value={groupContext}>
      {children}
    </StateContext.Provider>
  );
};