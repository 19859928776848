import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledVideoModeControlBar } from './VideoModeControlBar.style';

export const VideoModeControlBar = ({ className, onChangeMode, clearStage }) => {
  const [mode, setMode] = useState('video');
  const changeModeHandler = (mode) => {
    setMode(mode);
    onChangeMode(mode);
    clearStage();
  };

  return (
    <StyledVideoModeControlBar className={className} data-testid="VideoModeControlBar">
      <div className={`btn ${mode === 'video' ? 'active' : null}`}
        onClick={() => changeModeHandler('video')}>
          視訊模式
      </div>
      <div className={`btn ${mode === 'object' ? 'active' : null}`}
        onClick={() => changeModeHandler('object')}>
          物件模式
      </div>
    </StyledVideoModeControlBar>
  );
};

VideoModeControlBar.propTypes = {
  className: PropTypes.string,
  onChangeMode: PropTypes.func,
  clearStage: PropTypes.func
};