import styled from 'styled-components';

export const StyledSpeedTest = styled.div.attrs(props => ({
className: props.className
}))`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 650px;
  .loadingBox {
    position: absolute;
  }
  > iframe {
    width: 100%;
    height: 100%;
  }
`;