import { createMachine, assign } from 'xstate';

export const States = {
  Idle: 'Idle',

  Intro: 'Intro',
  Introducing: 'Introducing',

  Steps: 'Steps',
  Network: 'Network',
  Video: 'Video',
  Microphone: 'Microphone',
  Audio: 'Audio',
  Result: 'Result'
};

export const ActionTypes = {
  Initial: 'Initial',
  Reset: 'Reset',
  StartDetect: 'StartDetect',
  GoVideo: 'GoVideo',
  GoMicrophone: 'GoMicrophone',
  GoAudio: 'GoAudio',
  GoResult: 'GoResult',

  UpdateDevice: 'UpdateDevice',
  UpdateNetwork: 'UpdateNetwork',
  UpdateAudio: 'UpdateAudio',
  UpdateEquipment: 'UpdateEquipment',
};

const defaultState = {
  equipment: {
    video: false,
    microphone: false,
    audio: false 
  },
  device:{
    type: '',
    mobileSystem: '',
    tableBoard: '',
    otherBoard: ''
  },
  network: {
    ip: '',
    speedDown: '',
    speedUp: '',
    speedPing: '',
    speedJitter: '',
    type: '',
    source: '',
    other: ''
  },
  audio: {
    device: '',
    otherBoard: ''
  },
};

export const machine = createMachine({
  id: 'deviceDetectMachine',
  initial: `${States.Intro}`,
  context: defaultState,
  on: {
    [ActionTypes.Reset]: {
      target: `${States.Intro}`,
      actions: assign(defaultState)
    },
    [ActionTypes.StartDetect]: {
      target: `${States.Steps}`
    },
    [ActionTypes.GoVideo]: {
      target: `${States.Steps}.${States.Video}`
    },
    [ActionTypes.GoMicrophone]: {
      target: `${States.Steps}.${States.Microphone}`
    },
    [ActionTypes.GoAudio]: {
      target: `${States.Steps}.${States.Audio}`
    },
    [ActionTypes.GoResult]: {
      target: `${States.Steps}.${States.Result}`
    },
    [ActionTypes.UpdateDevice]: {
      actions: assign({
        device: (ctx, evt) => {
          return {
            ...ctx.device,
            ...evt.payload.device
          }
        }
      })
    },
    [ActionTypes.UpdateNetwork]: {
      actions: assign({
        network: (ctx, evt) => {
          return {
            ...ctx.network,
            ...evt.payload.network
          }
        }
      })
    },
    [ActionTypes.UpdateAudio]: {
      actions: assign({
        audio: (ctx, evt) => {
          return {
            ...ctx.audio,
            ...evt.payload.audio
          }
        }
      })
    },
    [ActionTypes.UpdateEquipment]: {
      actions: assign({
        equipment: (ctx, evt) => {
          return {
            ...ctx.equipment,
            ...evt.payload.equipment
          }
        }
      })
    },
  },
  states: {
    [States.Intro]: {},
    [States.Steps]: {
      initial: States.Network,
      states: {
        [States.Network]: {},
        [States.Video]: {},
        [States.Microphone]: {},
        [States.Audio]: {},
        [States.Result]: {},
      }
    },
  },
});
