import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ChatBubbleContainer, ChatBubble, useAudioVideo, useMeetingManager, useRosterState } from 'amazon-chime-sdk-component-library-react';
import Icon from '@onedesign/icon';
import { useRedirectRoomModalContext } from 'providers/MeetingModalProvider'
import { useChatMessage } from 'utils/hooks/useChatMessage';
import { EmojiButton } from 'components'
import { Box } from '@oneboard/ui-components';
import { useQuery } from 'utils/hooks/useQuery';
import { useLobby } from 'utils/hooks/useLobby';
import { Roles } from 'constants/index';
import { StyledChatRoom, MessageInput, MessageControls } from './ChatRoom.style';

const chatBubbleContainerStyle = `
  width: 100%;
  margin: 0;
  padding: 5px;
  flex-direction: column;
  background-color: #161C27;
`;


const chatBubbleStyle = (variant) => `
  max-width: 95%;
  margin-bottom: 1rem;
  margin-left: ${variant === 'incoming' ? '0' : 'auto'};
  margin-right: ${variant === 'incoming' ? 'auto' : '0'};
  background-color: ${variant === 'incoming' ? '#33353A' : '#595C64'};
  color: #D6DAE0;
  > .ch-header {
    color: #fff; 
  }
`;

const ActionMessageType = {
  Oneboard: 'oneboard',
  Link: 'link'
}

const ActionMessageTypeContent = {
  [ActionMessageType.Oneboard]: '上課教室連結'
}

const oneboardUrlReg = /(https?:\/\/oneboard(?:\-dev|\-uat|)\.oneclass\.com\.tw.[^\s]*)/gm;
const linkUrlReg = /(https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/gm;

const ActionButton = ({ message, type, variant }) => {

  const { setUrl, openModal } = useRedirectRoomModalContext();

  useEffect(() => {
    if(variant !== 'incoming' || !message) return;
    if(type === ActionMessageType.Oneboard) {
      setUrl(message)
      openModal();
    }

  }, [variant, message])


  if(type === ActionMessageType.Oneboard) {
    const userName = new URLSearchParams(message).get('userName');
    return (
      <a href={message}>
        <Icon name='ClassSolid' size='xs' />
        <span>{userName}的{ActionMessageTypeContent[type]}</span>
        <Icon name='ChevronRightOutline' size='xs' />
      </a>
    )
  }

}

const ActionMessage = ({ content, type, variant }) => {
  const strArr = content.split(oneboardUrlReg);
  return (
    <>
      {
        strArr.map((str, index) => str.search(oneboardUrlReg) > -1 ? (
          <ActionButton 
            key={index} 
            type={type} 
            variant={variant} 
            message={str} 
          />
        ) : (
          str
        ))
      }
    </>
  )
}

const LinkButton = ({ message, type }) => {
  if(type === ActionMessageType.Link) {
    return (
      <a href={message} target='_blank' >{message}</a>
    )
  }
}

const LinkMessage = ({ content, type }) => {
  const strArr = content.split(linkUrlReg);
  return (
    <>
      {
        strArr.map((str, index) => str.search(linkUrlReg) > -1 ? (
          <LinkButton 
            key={index} 
            type={type} 
            message={str} 
          />
        ) : (
          str
        ))
      }
    </>
  )
}

const createMessageContent = ({ content, variant }) => {
  const matchOneboardIndex = content.search(oneboardUrlReg);
  const matchHttpIndex = content.search(linkUrlReg);
  if(matchOneboardIndex > -1) {
    return <ActionMessage type={ActionMessageType.Oneboard} variant={variant} content={content} />
  }

  if(matchHttpIndex > -1) {
    return <LinkMessage type={ActionMessageType.Link} content={content} />
  }

  return content;

}


export const ChatRoom = ({onClose}) => {
  const query = useQuery();
  const { meetingId } = useParams();
  const { roster } = useRosterState();
  const audioVideo = useAudioVideo();
  const meetingManager = useMeetingManager();
  const { sendMessage, receiveMessage, createMessage } = useChatMessage();
    
  const {
    attendeeId: selfAttendeeId,
  } = meetingManager?.meetingSessionConfiguration?.credentials || {};

  const inputRef = useRef();
  const panelRef = useRef();
  const [messages,setMessages] = useState([]);
  const [audioMessages,setAudioMessages] = useState([]);

  const audioRef = useRef();
  const { lobbyId } = useLobby();
  const isLobbyRoom = meetingId === lobbyId;

  const sendMessageHandler = async () => {
    const { value } = inputRef.current;
    if(!value) return;

    const message = value.trim();
    if (message.length === 0) return

    await sendMessage(message)
    const newMessage = createMessage(message);

    setMessages(prev => [...prev,newMessage])

    inputRef.current.value = ''
  }

  const enterHandler = async(evt) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 13) {
      await sendMessageHandler();
    }
  }

  const insertEmojiHandler = (emojiObject) => {
    inputRef.current.value = inputRef.current.value + emojiObject.emoji
  }

  useEffect(() => {
    if (!panelRef.current) return;
    const y = panelRef.current.scrollHeight;
    panelRef.current.scrollTo(0, y);
    const nextMessages = messages.filter(msg => msg.role !== Roles.Teacher)
    
    setAudioMessages(nextMessages);
  }, [messages.length])

  useEffect(() => {
    if (audioMessages.length === 0) return;
    
    if (query.role === Roles.Teacher && query.enterNotice === 'true') {
      audioRef.current.volume = 0.4; // 設置音量為 40%
      audioRef.current.play();
    }
  }, [audioMessages.length])



  useEffect(() => {
    if(!audioVideo) return;
    const receiveDataMessageHandler = dataMessage => {
      const data = dataMessage.json();
      const { senderAttendeeId, content, timestamp, role } = data;

      const receivedData = {
        senderAttendeeId,
        content,
        timestamp,
        role
      };

      setMessages(prev => [...prev,receivedData])
    }

    receiveMessage(receiveDataMessageHandler);

  },[audioVideo, receiveMessage])

  return selfAttendeeId ? (
    <StyledChatRoom  data-testid="ChatRoom" hasClose={onClose ? true : undefined}>
      {onClose && <div className="close" onClick={onClose}>
          <Icon name='XmarkOutline' size='xs' />
        </div>}
      <div className="messagePanel" ref={panelRef}>
        <audio src="/door_chime.mp3" ref={audioRef}></audio>
        <ChatBubbleContainer
          time={new Date().toString()}
          css={chatBubbleContainerStyle}
        >
          {
            messages.map(message => {
              const variant = message.senderAttendeeId === selfAttendeeId ? 'outgoing' : 'incoming';
              const userName = roster[message.senderAttendeeId]?.name || '';
              return  (
                <ChatBubble 
                  key={`${message.timestamp}_${message.senderAttendeeId}`}
                  variant={'outgoing'} 
                  senderName={userName}
                  css={chatBubbleStyle(variant)}>
                    {createMessageContent({
                      content: message.content, 
                      variant
                    })}
                </ChatBubble>
              )
            })
          }
        </ChatBubbleContainer>
      </div>
      <MessageControls>
        <Box display='flex' width='100%' position='relative'>
          <MessageInput
            ref={inputRef}
            type="text"
            onKeyDown={enterHandler}
          />
          <Icon 
            className="messageIcon" 
            name="PaperPlaneSolid" 
            onClick={sendMessageHandler}
          />
        </Box>
        <Box ml={2}>
          <EmojiButton  insertEmojiHandler={insertEmojiHandler} />
        </Box>
      </MessageControls>
    </StyledChatRoom>
  ) : null;
};