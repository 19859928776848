import styled from 'styled-components';

export const StyledTilePausedBox = styled.div.attrs(props => ({
className: props.className
}))`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPausedBox = styled.div`
  width: 64px;
  height: 64px;
  background-color: #2F3746;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #C1C7D0;
`;