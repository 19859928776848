import styled from 'styled-components';

export const StyledBreakoutRoomNotification = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 24px;
  border-radius: 8px;
  background-color: ${({ isLeaving }) => isLeaving ? '#FFAB00' : '#393221'};
  color: #fff;
  .title {
    font-size: 14px;
    line-height: 24px;
  }
  .action {
    font-size: 14px;
    line-height: 24px;
    >.btn {
      cursor: pointer;
      color: ${({ isLeaving }) => isLeaving ? '#fff' : '#FFAB00'};
    }
  }
`;

export const StyledSubRoomNotification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 24px;
  border-radius: 8px;
  background-color: ${({ isLeaving }) => isLeaving ? '#377DFF' : '#1B2B47'};
  color: #fff;
  .title {
    font-size: 14px;
    line-height: 24px;
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    >.btn {
      cursor: pointer;
      color: #B0B7C3;
      +.btn {
        margin-left: 16px;
      }
      &.primary {
        color: ${({ isLeaving }) => isLeaving ? '#fff' : '#377DFF'};
      }
    }
  }
`;

export const StyledSubRoomInvite = styled.div`
  width: 100%;
`;

export const StyledSingleInvite = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 24px;
  border-radius: 8px;
  background-color: #377DFF;
  color: #fff;
  > .notice {
    font-size: 14px;
    line-height: 24px;
  }
  > .action {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
    >.btn {
      cursor: pointer;
      color: #fff;
      +.btn{
        margin-left: 8px;
      }
    }
  }
`;

export const StyledMultipleInvite = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  >.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 24px;
    border-radius: ${({ isOpen }) => isOpen ? '8px 8px 0 0' : '8px'};
    background-color: #377DFF;
    color: #fff;
    >.title {
      font-size: 14px;
      line-height: 24px;
    }
    >.action {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      >.btn {
        cursor: pointer;
        color: #fff;
        +.btn{
          margin-left: 8px;
        }
      }
    }
  }
  >.content {
    position: absolute;
    top: 36px;
    left: 0;
    width: 100%;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    .inviteList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 24px;
      background-color: #1B2B47;
      color: #fff;
      .listTitle {
        font-size: 14px;
        line-height: 24px;
      }
      .listAction {
        font-size: 14px;
        line-height: 24px;
        >.btn {
          cursor: pointer;
          color: #377DFF;
        }
      }
    }
  }
`;

export const StyledBreakoutRoomResultNotification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 24px;
  border-radius: 8px;
  background-color: #1B3636;
  color: #fff;

  .title {
    font-size: 14px;
    line-height: 24px;
  }

  .memberTip {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #38CB89;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    >.btn {
      cursor: pointer;
      color: #B0B7C3;
      +.btn {
        margin-left: 16px;
      }
      &.primary {
        color: #38CB89
      };
    }
  }
`;

export const StyledMemberList = styled.div`
  width: 210px;
  padding: 8px 0;
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
  }
  .content {
    padding: 0 24px 0 12px;
    font-size: 14px;
  }
  .action {
    >.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 24px;
      padding: 0;
      background-color: #EC7963;
      color: #fff;
      font-size: 12px;
      border-radius: 12px;
      border: none;
      &.isOnStaged{
        background-color: #2F3746;
      }
    }
  }
`;