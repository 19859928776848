import styled from 'styled-components';

export const StyledBreakoutRoomContainer = styled.div.attrs(props => ({
  className: props.className
}))`
  height: 100%;
`;

export const BreakoutRoomIntro = styled.div`
  width: 100%;
  height: 100%;
  background-color: #161C27;
  border-radius: 20px;
  box-shadow: 0px 50px 77px rgba(3, 8, 17, 0.22);

  .head {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    border-bottom: 1px solid #2F3746;
    .title {
      font-size: 20px;
      color: #fff;
    }
    .close {
      position: absolute;
      left: 32px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #B0B7C3;
      cursor: pointer;
    }
  }
  .content {
    padding: 36px;
    .groupingOption {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #232A37;
      border-radius: 15px;
      padding: 20px;
      cursor: pointer;
      &:hover {
          opacity: 0.8;
        }
      +.groupingOption {
        margin-top: 24px;
      }

      >.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;

        &.blue {
          background-color: #377DFF;
        }
        &.green {
          background-color: #38CB89;
        }
      }
      .optionContent {
        margin-left: 12px;
        >.title {
          font-size: 14px;
          color: #fff;
        }
        >.desc {
          font-size: 12px;
          color: #8A94A6;
        }
      }
    }
  }
`;

export const BreakoutRoomSetting = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #161C27;
  border-radius: 20px;
  box-shadow: 0px 50px 77px rgba(3, 8, 17, 0.22);

  >.head {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    border-bottom: 1px solid #2F3746;
    
    >.title {
      font-size: 20px;
      color: #fff;
    }
    .close {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #B0B7C3;
      cursor: pointer;
    }
  }
  >.content {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    height: 100%;
    
    .settingPanel {
      display: flex;
      justify-content: center;
      padding: 6px 14px 26px;
      border-bottom: 1px solid #2F3746;
    }

    .roomBox {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      height: 0px;
      margin-top: 24px;
      overflow-y: auto;
    }
  }
  >.action {
    display: flex;
    padding: 20px;
  }
`;

export const SettingButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ disabled }) => disabled ? '0.2' : '1'};

    &+& {
      margin-left: 32px;
    }

    >.title {
      font-size: 16px;
      color: #8A94A6;
      text-align: center;
      font-weight: 600;
    }
    >.optionContent {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-top: 14px;
      .countVal {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 40px;
        background-color: #2F3746;
        color: #fff;
        border-radius: 8px;
      }
      .settingBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background-color: #232A37;
        border: none;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
          cursor: ${({ disabled }) => disabled ? 'initial' : 'pointer'};
          opacity: ${({ disabled }) => disabled ? '1' : '0.8'};
        }
        &.close{
          background-color: #FF5630;
        }
      }
    }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  padding: 16px;
  background-color: ${({ isEnd }) => isEnd ? '#F44336' : '#EC7963'};
  color: #fff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  &:hover {
    background-color: ${({ isEnd }) => isEnd ? '#F44336' : '#EE8773'};
  }
  &:disabled {
    background-color: #232A37;
    color: #2F3746;
    cursor: not-allowed;
  }
`;

export const NumberInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #232A37;
  border-radius: 8px;
  overflow: hidden;

  >.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #fff;
    cursor: pointer;
    background-color: #232A37;
    user-select: none;
    &:hover {
      opacity: .8;
    }
  }

  > input {
    border: none;
    width: 40px;
    height: 100%;
    padding: 6px;
    background-color: #2F3746;
    color: #fff;
    font-size: 14px;
    text-align: center;
    &:focus {
      border: 2px solid #EC7963;
      background-color: #EEF1F5;
      color: #161C27;
    }
  }
`;

export const CellButton = styled.button`
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  background-color: ${({ bg }) => bg};
  cursor: pointer;
  border: none;
  border-radius: 8px;
  padding: 2px 16px;
  &+&{
    margin-left: 12px;
  }
`;

export const StyledRoomCell = styled.div`
  &+& {
        margin-top: 16px;
    }
  .roomInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .groupName {
      color: #8A94A6;
      font-size: 16px;
      font-weight: 600;
      line-height: 29px;
    }
    .inviteLabel {
      width: 68px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      color: #fff;
      background-color: #EC7963;
      border-radius: 8px;
      cursor: default;
      font-size: 11px;
      line-height: 16px;
    }
    .peopleCount {
      font-size: 12px;
      font-weight: 600;
      line-height: 29px;
      color: #fff;
    }
  }
  .peopleBox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    padding: 0 10px;
    border-radius: 8px;
    border: 1px solid #2F3746;
  }
  .addPeople {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 48px 12px 8px;
    cursor: pointer;

    .addPeopleIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      color: #fff;
      border-radius: 50%;
      background-color: #232A37;
    }
    .addPeopleTitle {
      margin-left: 12px;
      font-size: 14px;
      font-weight: 600;
      line-height: 29px;
      color: #EEF1F5;
    }
  }

  .addPeopleSelect {
    width: 180px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 8px;
    .ant-select {
      color: #B0B7C3;
    }
    .ant-select-selector {
      background-color: #232A37;
    }
    .option {
      color: #B0B7C3;
      &.ant-select-item-option-active,
      &.ant-select-item-option-selected {
        color: #fff;
        background-color: #2F3746;
      }
      &:hover {
        color: #fff;
        background-color: #2F3746;
      }
    }
  }
`;

export const PeopleList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 8px;
  .peopleIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    color: #fff;
    border-radius: 50%;
    background-color: ${({ bgColor }) => bgColor};
  }
  .peopleName {
    margin-left: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 29px;
    color: #EEF1F5;
  }
  .peopleDelete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    margin-left: 8px;
    color: #B0B7C3;
    cursor: pointer;
  }
`;
