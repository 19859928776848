import React, { useEffect } from 'react';
import { useBitrix } from 'utils/hooks/useBitrix';
import { StyledNotFoundPage, StyledHead, StyledContent } from './NotFoundPage.style';

export const NotFoundPage = ({ className }) => {
  const { insertCRMChat } = useBitrix();

  useEffect(() => {
    insertCRMChat();
  }, []);

  return (
    <StyledNotFoundPage className={className} data-testid="NotFoundPage">
      <StyledHead>
        <div className="logo">
          <img src="/oneClass.svg" alt="" />
        </div>
      </StyledHead>
      <StyledContent>
        <div className="notFoundPic">
          <img src="/notFound.svg" alt="" />
        </div>
        <div className="title">Page Not Found</div>
        <div className="desc">
          哎呀！您要的網頁不在這裡，或您的網頁連結可能有誤<br />
          您可以到 <a href="https://tutor.oneclass.com.tw" className="highlight" target="_blank" rel="noreferrer">OneClass 真人Live家教</a> 看看，或者直接與客服人員聯絡
        </div>
      </StyledContent>
    </StyledNotFoundPage>
  );
};