import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { Trans } from 'react-i18next';
import { t } from 'utils/i18n';
import PerformanceModal from '../PerformanceModal';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import StyledIcon from 'components/NewOneBoard/common/StyledIcon';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { ReactComponent as MonsterSolid } from 'assets/newOneBoard/svg/monster_solid.svg';

const StyledPopContent = styled.div`
  position: relative;
  color: #637381;
  font-size: 14px;
  line-height: 24px;
  .title {
    color: #ec7963;
  }
`;
export const PerformanceButton = ({ students }) => {
  const { isFooterOpen } = useFooterStatus();
  const [classroomPerformanceModalState, setClassroomPerformanceModalState] = useState(false);

  const openClassroomPerformanceModal = () => {
    setClassroomPerformanceModalState(true);
  };
  const closeClassroomPerformanceModal = () => setClassroomPerformanceModalState(false);

  return (
    <>
      <Popover
        overlayInnerStyle={{
          borderRadius: '8px',
          width: '280px',
        }}
        content={
          <StyledPopContent>
            <div className='title'>{t('containers.classroomPerformanceButton.coursePerformance', '課程表現')}</div>
            <div className='content'>
              <Trans i18nKey='containers.classroomPerformanceButton.clickMedal'>
                按下「課堂表現」可以同時讓學生看到今天的表現！
              </Trans>
            </div>
          </StyledPopContent>
        }
        placement={'top'}
        arrowPointAtCenter={true}
        trigger='hover'
      >
        <div>
          <ToolButton
            icon={<StyledIcon as={MonsterSolid} size='24px' />}
            onClick={openClassroomPerformanceModal}
          >
            {isFooterOpen && '課堂表現'}
          </ToolButton>
        </div>
      </Popover>

      {classroomPerformanceModalState && (
        <PerformanceModal
          modalOpen={classroomPerformanceModalState}
          onClose={closeClassroomPerformanceModal}
          students={students}
        />
      )}
    </>
  );
};

PerformanceButton.propTypes = {
  students: PropTypes.array,
};
