import styled from 'styled-components';

export const StyledVideoBox = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 8px;
`;

export const InsideVideoBox = styled.div`
  position: relative;
  height: ${({ isRatio }) => (isRatio ? 'auto' : '100%')};
  padding-top: ${({ isRatio }) => (isRatio ? '75%' : 'auto')};
  border-radius: 4px;
  overflow: hidden;
  .studentVideo {
    height: 100%;
  }
`;

export const UiInfoPanel = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 2px 4px 0 0;
  border-radius: 0 0 4px 4px;
  overflow: hidden;

  > .name {
    color: #FFF;
    background-color: #013667;
    height: 100%;
    padding: 5px;
    border-radius: 0rem 0.25rem 0rem 0rem;
    font-size: 14px;
    line-height: 16px;
  }

  > .reward {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    color: #f9c74f;

    .rewardNumber {
      color: #fff;
      font-size: 11px;
      font-weight: 700;
    }
  }
`;

export const StatePanelContainer = styled.div`
  position: absolute;
  z-index: 2;
  right: 4px;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  .stateIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: rgba(69, 79, 91, 0.60);
    color: #fff;
    &.raiseHand {
      background-color: #ec7963;
    }
    + .stateIcon {
      margin-left: 4px;
    }
  }
`;

export const StyledVolumeActivity = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  border-radius: 8px;
  border: 3px solid transparent;
  border-color: ${({ volumeStrength }) => (volumeStrength > 0 ? '#EC7963' : 'transparent')};
  z-index: 1;
`;
