import React, { useEffect } from 'react';
import {
  useContentShareState,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import SharerPage from '../../sharer';
import ReceiverPage from '../../receiver';
import { StyledSinglePage } from './Single.style';

export const Single = () => {
  const { muted, toggleMute } = useToggleLocalMute();
  const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();

  useEffect(() => {
    if (!muted) {
      toggleMute();
    }
  }, []);


  const createPage = () => {
    let component = null;

    if (isLocalUserSharing) {
      component = <SharerPage.Single />;
    } else {
      if (sharingAttendeeId) {
        component = <ReceiverPage.Single />;
      } else {
        component = <SharerPage.Single />;
      }
    }
    return component;
  };
  return (
    <StyledSinglePage>
      {createPage()}
    </StyledSinglePage>
  );
};