import Icon from '@onedesign/icon';
import { useGroupContext } from 'providers/GroupProvider';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { useMeetingState } from '@oneboard/meeting';
import { ClassType } from 'constants/index';
import UsersModalV2 from 'containers/UsersModalV2';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import styled from 'styled-components';

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  padding: 18px 16px 0;
`;

const Title = styled.p`
  margin: 0;
  color: #919eab;
  font-size: 12px;
`;

const NameTitle = styled(Title)`
  width: 190px;
`;

const TrophyTitle = styled(Title)`
  width: 100px;
`;

export const ParticipantButton = ({ currentRole }) => {
  const { toggleUsersModal, usersModalState } = useGroupContext();
  const { isFooterOpen } = useFooterStatus();
  const state = useMeetingState();
  const { context } = state;
  const { courseType } = context;
  const toggleUsersModalHandler = () => {
    toggleUsersModal();
  };

  return (
    <>
      <ToolButton icon={<Icon name='UsersSolid' size='md' color='#68B783' />} onClick={toggleUsersModalHandler}>
        {isFooterOpen && '參與者'}
      </ToolButton>
      {usersModalState && (
        <ToolModal active={usersModalState} onClose={toggleUsersModal} title='參與者' height='419px'>
          {courseType === ClassType.SyncMultiple && (
            <TitleContainer>
              <NameTitle>姓名</NameTitle>
              <TrophyTitle>獎盃數</TrophyTitle>
              <Title>狀態</Title>
            </TitleContainer>
          )}

          <UsersModalV2.UsersModal onClose={toggleUsersModalHandler} currentRole={currentRole} />
        </ToolModal>
      )}
    </>
  );
};
