import styled from 'styled-components';

export const StyledSyncRightContainer = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #232323;
  padding: 16px 0 0;
  border-bottom: 1px solid #454B5C;

  .customPopover {
    .ant-popover .ant-popover-content .ant-popover-inner {
      border-radius: 24px;
    }
  }

  .chatRoomLayout {
    height: 100%;
  }
`;

export const PopoverIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;