import React, { useState, useRef, useEffect } from 'react';
import {
  useAudioOutputs,
  useMeetingManager
} from 'amazon-chime-sdk-component-library-react';
import { Box, FullButton, Select } from '@oneboard/ui-components';
import { Input } from '../../components/Input';
import Icon from '@onedesign/icon';
import { useDeviceDetectDispatch, useDeviceDetectState } from '../../providers/DeviceDetectProvider';
import lottie from 'lottie-web';
import { useMediaDevices } from '../../utils/hooks/useMediaDevices';
import { StyledAudioStep, StyledAudioAnimationBox } from './AudioStep.style';

const deviceTypes = [
  {
    label: '請選擇裝置類型',
    icon: ''
  },
  {
    label: '耳機(單純耳機功能)',
    icon: 'HeadphonesAltSolid'
  },
  {
    label: '專用耳機麥克風',
    icon: 'Earphone'
  },
  {
    label: '頭戴式耳機麥克風',
    icon: 'HeadsetSolid'
  },
  {
    label: '手機平板內建音響（喇叭）',
    icon: 'TabletAltSolid'
  },
  {
    label: '電腦外接音響(喇叭)',
    icon: 'Speaker'
  },
  {
    label: '電腦內建音響(喇叭)',
    icon: 'Screen'
  },
  {
    label: '藍芽耳機',
    icon: 'EarphoneBluetooth'
  },
  {
    label: '藍芽音箱(喇叭)',
    icon: 'SpeakerBluetooth'
  },
  {
    label: '不清楚',
    icon: 'QuestionCircleOutline'
  },
  {
    label: '其他',
    icon: ''
  }
];

const AudioAnimation = () => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/audioTest.json'
    });
}, [ref]);

  return (
    <StyledAudioAnimationBox ref={ref} />
  );
};

export const AudioStep = ({ className }) => {
  const meetingManager = useMeetingManager();
  const { handleError, attachSinkId } = useMediaDevices();
  const { devices, selectedDevice } = useAudioOutputs();
  const { goResult, updateAudio, updateEquipment } = useDeviceDetectDispatch();
  const deviceDetectState = useDeviceDetectState();
  const state = deviceDetectState.context;

  const [playState, setPlayState] = useState(false);
  const [deviceVal, setDeviceVal] = useState(null);
  const otherVal = useRef(null);
  const audioRef = useRef();

  const abnormalHandler = () => {
    updateAudio({
      device: deviceVal,
      otherBoard: otherVal.current
    });
    updateEquipment({
      audio: false
    });
    goResult();
  }

  const normalHandler = () => {
    updateAudio({
      device: deviceVal,
      otherBoard: otherVal.current
    });
    updateEquipment({
      audio: true
    });
    goResult();
  }

  const otherInputHandler = e => otherVal.current = e.target.value;

  const playSound = () => {
    setPlayState(true);
    audioRef.current.play();
  }

  const startAudio = () => {
    const constraints = { audio: true };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .catch(handleError)

    // navigator.mediaDevices.ondevicechange = () => updateDeviceList();
  }

  const deviceChangeHandler = (deviceId) => {
    meetingManager.startAudioOutputDevice(deviceId)
    changeAudioDestination(deviceId);
  };

  const deviceTypeChangeHandler = (val) => setDeviceVal(val);

  // 處理音訊改變的方法
  const changeAudioDestination = (id) => attachSinkId(audioRef.current, id);

  useEffect(() => {
    startAudio();
  }, []);

  useEffect(() => {
    if (deviceVal === null) return

    if (deviceVal !== '其他') otherVal.current = ''
  }, [deviceVal]);

  return (
    <StyledAudioStep className={className} data-testid="AudioStep">
      <div className="audioBlock">
        <div className="itemWrap playBlock">
          <audio ref={audioRef} src="/testSound.mp3" loop></audio>
          {
            playState ? <AudioAnimation /> : (
              <button className="playBth" onClick={playSound}>
                <Icon name="PlaySolid" size='lg' color="#2F3746" />
              </button>
            )
          }
          <div className="desc">請聆聽音檔</div>
        </div>
        <div className="itemWrap">
          <Box>
            {devices.length > 0 && <Select className="select" defaultValue={selectedDevice} onChange={deviceChangeHandler}>
              {devices.map((item) => <Select.Option 
                value={item.deviceId} 
                key={item.deviceId}>
                  {item.label}
                </Select.Option>)}
            </Select>}
          </Box>
          <Box mt={3}>
            <Select className="select" defaultValue="請選擇裝置類型" onChange={deviceTypeChangeHandler}>
              {deviceTypes.map(item => <Select.Option 
                value={item.label}
                label={item.label}
                disabled={item.label === '請選擇裝置類型'} 
                key={item.label}>
                  <Box display='flex' alignItems="center" >
                    <Icon name={item?.icon} size='lg' color="#8A94A6" />
                    <Box ml={3}>
                      {item.label}  
                    </Box>
                  </Box>
                </Select.Option>)}
            </Select>
          </Box>
          {deviceVal === '其他' && <Box mt={3}>
            <Input onChange={otherInputHandler} />
          </Box>}
        </div>
      </div>
      <Box mt={4}>
        <div className="question">
          <div className="title">
            請選擇播放裝置，<br />
            並判斷能否清楚聽到播放的音檔。
          </div>
          <div className="action">
            <Box mr={1} width="100%">
              <FullButton.Secondly onClick={abnormalHandler}>不行</FullButton.Secondly>
            </Box>
            <Box ml={1} width="100%">
              <FullButton onClick={normalHandler}>可以</FullButton>
            </Box>
          </div>  
        </div>
      </Box>
    </StyledAudioStep>
  );
};