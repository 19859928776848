import styled from 'styled-components';

const VideoContentContainer = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export default VideoContentContainer;
