import styled from 'styled-components';

export const StyledSyncMultiple = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  .auditListLayout {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
  }
`;

export const Sidebar = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 396px;
  height: 100%;
  transform: translateX(${({ isSidebar }) => (isSidebar ? '0px' : '396px')});
  z-index: 7;
  transition: 0.3s;
  padding: 8px;
`;

export const StyleUsersModalLayout = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(${({ active }) => (active ? '0' : '360px')});
  width: 360px;
  height: 100%;
  z-index: 2001;
  transition: 0.3s;
`;

export const StyledOtherButton = styled.div`
  position: relative;
  > .controlButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: #232323;

    &:hover {
      color: #33353a;
    }
  }
`;

export const OtherOption = styled.div`
  background-color: #1e1f20;
  width: 200px;
  border-radius: 8px;

  .otherList {
    display: flex;
    padding: 12px 16px;
    color: #d5d7de;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
  .listIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
  .listContent {
    font-size: 14px;
    margin-left: 8px;
    line-height: 40px;
    flex: 1;

    .customerService {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
`;

export const MuteOption = styled.div`
  background-color: #1e1f20;
  border-radius: 8px;
  padding: 8px 0;

  .optionList {
    display: flex;
    margin: 0 16px;
    padding: 12px 16px;
    color: #d5d7de;
    cursor: pointer;
    border-radius: 15px;

    &:hover {
      background-color: #33353a;
    }
  }
  .listIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .listContent {
    font-size: 14px;
    margin-left: 8px;
    line-height: 24px;
    flex: 1;

    .customerService {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #212324;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
  background: #fff;

  &:hover {
    background: rgba(33, 35, 36, 0.1);
  }
`;

export const UiChatRoomLayout = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(${({ active }) => (active ? '0' : '280px')});
  width: 280px;
  height: 100%;
  z-index: 10;
  padding: 0 8px 24px;
  background-color: #161c27;
  transition: 0.3s;
`;

export const BreakoutRoomNotificationLayout = styled.div`
  position: relative;
  z-index: 10;
  padding: 12px 8px;
  background-color: #161c27;
`;

export const ContentShareContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const StyledBroadcastModal = styled.div`
  .content {
    > textarea {
      width: 100%;
      height: 160px;
      padding: 14px 24px;
      border-radius: 12px;
      outline: none;
      background-color: #232a37;
      border: none;
      resize: none;
      color: #8a94a6;
      &:focus {
        border: 2px solid #ec7963;
      }
    }
    .tip {
      text-align: right;
      margin-top: 8px;
      font-size: 14px;
      color: #8a94a6;
    }
  }
`;

export const StyledSyncVideoList = styled.div`
  display: flex;
  min-height: 136px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.16);
`;

export const StyledPrivateMessageButton = styled.button`
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;
