import styled from 'styled-components';

export const StyledWaitingAnimation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #161c27;
`;

export const AnimationWrap = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  background-image: url('/images/waitingBackground.png');
  background-size: cover;
  background-position-x: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Animation = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 65%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 40px 0;
`;

export const StyledHeader = styled.div`
  color: #212b36;
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
`;

export const StyledText = styled.div`
  color: #454f5b;
  text-align: center;
  font-weight: 400;
  line-height: 150%;
  font-size: 24px;
`;
