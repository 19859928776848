import { t as trans } from 'i18next';

const t = (
  key,
  defaultValue,
  params,
) => {
  return trans(key, { ...params, defaultValue });
};

export { t };
