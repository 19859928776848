import React, { useState } from 'react';
import { Modal, Box, Button } from '@oneboard/ui-components';
import PropTypes from 'prop-types';
import { StyledInput, StyledBox } from './LinkModal.style';
import queryString from 'query-string';

const filterYouTubeId = (url) => {
  const isYouTubeURL = url.includes('www.youtube.com');
  const isShareYouTubeURL = url.includes('youtu.be');
  let youTubeId = '';

  if (isYouTubeURL) {
    const { search } = new URL(url);
    const params = queryString.parse(search);
    youTubeId = params.v;
  }

  if (isShareYouTubeURL) {
    const pattern = /^.*\/([a-zA-Z0-9_-]{11})$/;
    youTubeId = pattern.exec(url)[1];
  }
  return youTubeId;
};

export const LinkModal = ({ onClose, submit }) => {
  const [link, setLink] = useState('');
  const [noticeState, setNoticeState] = useState(false);
  const onChange = e => setLink(e.target.value);

  const noticeHandler = () => setNoticeState(prev => !prev);

  const submitHandle = () => {
    const urlPattern = /^http/;
    const httpStart = urlPattern.test(link);

    if (!httpStart) {
      noticeHandler();
      return;
    }

    onClose();

    const youTubeId = filterYouTubeId(link);

    if (youTubeId !== '') {
      const youTubeURL = `https://www.youtube.com/embed/${youTubeId}`;
      submit && submit(youTubeURL);
      return;
    }
    else {
      submit && submit(link);
      return;
    }
  };

  return (
    <>
      <Modal width="600px" header="網站資源" onClose={onClose}>
        <StyledInput placeholder="請在此輸入網址..." value={link} onChange={onChange} allowClear={true}/>
        <StyledBox>
          <Box display="flex" justifyContent="flex-end" width="100%" pt={8}>
            <Button className="close" onClick={onClose}>關閉</Button>
            <Button disabled={link === ''} onClick={submitHandle}>加入畫面</Button>
          </Box>
        </StyledBox>
      </Modal>
      {noticeState && <Modal header="注意" onClose={noticeHandler}>
        <Box>
          請輸入正確網址
        </Box>
        <Box display="flex" justifyContent="flex-end" width="100%" pt={8}>
          <Button onClick={noticeHandler}>關閉</Button>
        </Box>
      </Modal>}
    </>
  );
};

LinkModal.propTypes = {
  onClose: PropTypes.string,
  submit: PropTypes.func,
};