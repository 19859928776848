import styled from 'styled-components';

export const StyledViewToolBar = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background-color: #232323;
  border-radius: 24px;
`;

export const GroupToolBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px;
`;

export const ToolBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 0;
  color: #D5D7DE;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: #33353A;
  }

  >.settingPopoverButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #D5D7DE;
  }
`;

export const TextView = styled.div`
  padding: 4px 12px;
  color: #fff;
  background-color: #33353A;
  border-radius: 16px;
  line-height: 24px;
`;

export const DividingLine = styled.div`
  width: 1px;
  height: 18px;
  margin: 4px 12px;
  background-color: #454B5C;
`;