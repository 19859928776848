import queryString from 'query-string';

export const urlFormat = ({
  pathname,
  query
}) => {
  const requestUrl = new URL(`${window.location.origin}`);
  requestUrl.pathname = pathname;
  requestUrl.search = queryString.stringify(query, { arrayFormat: 'index' });
  return requestUrl.toString();
};