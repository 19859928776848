import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import Icon from '@onedesign/icon';
import { useCanvasDispatch, useCanvasState } from '@oneboard/whiteboard';
import {
  useMeetingDispatch,
  useMeetingState,
  MeetingStates,
  MeetingRoles,
  SettingPopoverButton
} from '@oneboard/meeting';
import { Popover } from '@oneboard/ui-components';
import { RecordButton, TeachingMaterialModal } from 'containers';
import { MediaMetrics, CustomerService, ResizableRect } from 'components';
import { StyledSingleControlBar, ToolBtn } from './SingleControlBar.style';

export const SingleControlBar = ({
  className,
  bookId,
  onLinkToolClick,
  isWhiteboardOpen,
  toggleWhiteboardShow,
  loadTeachingMaterials
}) => {
  const state = useCanvasState();
  const { toggleTeachingMaterial } = useCanvasDispatch();

  const { context: { teachingMaterialState } } = state;

  const meetingState = useMeetingState();

  const { startWhiteboard, endWhiteboard } = useMeetingDispatch();

  const isWhiteboarding = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.Whiteboard}.${MeetingStates.Whiteboarding}`
  });

  const toggleTeachingMaterialHandler = useCallback(() => toggleTeachingMaterial(teachingMaterialState), [teachingMaterialState]);

  const { roster } = useRosterState();
  const attendeeIds = Object.keys(roster);

  const openWhiteboardHandler = useCallback(attendeeIds => {
    startWhiteboard({ attendeeIds });
  }, []);

  const openAllAttendeeWhiteboard = () => {
    openWhiteboardHandler(attendeeIds);
  };

  useEffect(() => {
    if (isWhiteboarding) {
      openAllAttendeeWhiteboard();
    } else {
      endWhiteboard();
    }
  }, [roster]);

  const changeTeachingMaterialHandler = (materials) => {
    loadTeachingMaterials(materials);
    toggleTeachingMaterialHandler();
  };

  return (
    <ResizableRect resizable={false} isBorder={false}>
      {(ref) => (
        <StyledSingleControlBar className={className} data-testid="SingleControlBar" ref={ref}>
          <ToolBtn>
            <RecordButton
              mode='dark'
              classType='noSync'
              placement="leftBottom"
              content='開始上課了嗎?<br />別忘了按下錄影按鈕，讓學生可以在課後複習本次課程哦！'
            />
          </ToolBtn>
          <Popover
            trigger="click"
            visible={teachingMaterialState}
            placement="rightTop"
            content={
              <TeachingMaterialModal
                onClose={toggleTeachingMaterialHandler}
                onChange={changeTeachingMaterialHandler}
                currentBookId={bookId}
              />
            }
          >
            <ToolBtn title='加入教材' onClick={toggleTeachingMaterialHandler}>
              <Icon name="FolderOpenSolid" />
            </ToolBtn>
          </Popover>

          <ToolBtn onClick={onLinkToolClick} title='網站資源'>
            <Icon name="GlobeSolid" />
          </ToolBtn>

          <ToolBtn active={isWhiteboardOpen} onClick={toggleWhiteboardShow} title='白板'>
            <Icon name="ChalkboardSolid" />
          </ToolBtn>
          <ToolBtn title='客服'>
            <CustomerService />
          </ToolBtn>

          <ToolBtn>
            <SettingPopoverButton
              className="settingPopoverButton"
              placement="right"
            />
          </ToolBtn>
          <ToolBtn>
            <MediaMetrics placement="right" />
          </ToolBtn>
        </StyledSingleControlBar>
      )}
    </ResizableRect>
  );
};

SingleControlBar.propTypes = {
  className: PropTypes.string,
  bookId: PropTypes.string,
  onLinkToolClick: PropTypes.func,
  isWhiteboardOpen: PropTypes.bool,
  toggleWhiteboardShow: PropTypes.func,
  loadTeachingMaterials: PropTypes.func
};