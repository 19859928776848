import React, { createContext, useContext } from 'react';
import { useStudentInfo } from './useStudentInfo';

export const StateContext = createContext();

export const useStudentInfoContext = () => useContext(StateContext);

export const StudentInfoProvider = ({ children }) => {
  const studentInfoContext = useStudentInfo();

  return (
    <StateContext.Provider value={studentInfoContext}>
      {children}
    </StateContext.Provider>
  );
};