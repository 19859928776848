import { useMeetingState, useMeetingDispatch } from '@oneboard/meeting';
import { Roles } from 'constants/index';

// 老師用 管理 ToolboxAuth 的 hook
export const useToolboxAuth = () => {
  const { disableToolbox, enableToolbox, advisorEnableToolbox, advisorDisableToolbox } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { toolboxAuthAttendeeIds, role } = meetingState.context;

  const queryToolboxAuthById = ({ attendeeId }) => toolboxAuthAttendeeIds.includes(attendeeId);

  const toggleToolboxAuth = ({ attendeeId }) => {
    const isEnableToolbox = queryToolboxAuthById({ attendeeId });

    switch (role) {
      case Roles.Teacher:
        isEnableToolbox ? disableToolbox({ attendeeId }) : enableToolbox({ attendeeId });
        break;
      case Roles.Advisor:
        isEnableToolbox ? advisorDisableToolbox({ attendeeId }) : advisorEnableToolbox({ attendeeId });
        break;
      default:
        break;
    }
  };

  return {
    queryToolboxAuthById,
    toggleToolboxAuth,
  };
};
