import styled from 'styled-components';
import { Popover } from 'antd';

export const StyledWhiteboardFrame = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1010px;
  height: 95%;
  max-height: 600px;
  background-color: #fff;
  border: 1px solid #F2F4F8;
  box-shadow: 0px 23px 44px rgba(3, 8, 17, 0.14);
  border-radius:16px;
  overflow: hidden;
  z-index: 10;
  > iframe {
    width: 100%;
    height: ${({ isDragging }) => isDragging ? 0 : '100%'};
  }
`;

export const WhiteboardFrameHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 25px;
  height: 48px;

  .close {
    appearance: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A1A4B1;
    background-color: transparent;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
`;


export const Locker = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    height: 32px;
    background: ${({ isLock }) => isLock ? 'rgba(236, 121, 99, 0.1)' : 'rgba(55, 125, 255, 0.1)'};
    border-color: ${({ isLock }) => isLock ? 'rgba(236, 121, 99, 0.6)' : 'rgba(55, 125, 255, 0.6)'};
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    font-size: 14px;
    color: ${({ isLock }) => isLock ? '#EC7963' : '#377DFF'};
    cursor: pointer;

    &:hover {
      color: #fff;
      background: ${({ isLock }) => isLock ? '#EC7963' : '#377DFF'};
    }
`;


export const StyledPopover = styled(Popover)`
    .ant-popover  {
        width: 240px;
        border: 1px solid #F2F4F8;
        box-shadow: 0px 23px 44px rgba(3, 8, 17, 0.14);
        border-radius: 12px;

       .ant-popover-content {

         .ant-popover-arrow {
           display: ${({ arrow }) => arrow ? 'block' : 'none'};
           border-color: #232323 !important;
         }

         .ant-popover-inner {
           border-radius: 12px;
           overflow: hidden;

           .ant-popover-title {
             padding: 12px;
             text-align: center;
             border-bottom: none;
            }

            .ant-popover-inner-content {
              padding: 24px !important;
              color: #fff;
              padding: 0;
            }

          }

      }
    }
`;
