import { createMachine } from 'xstate';

export const Insert_Picture_State = {
  Idle: 'Idle',
  Ready: 'Ready',
  Countdown: 'Countdown',
  Preview: 'Preview',
  End: 'End'
};

export const Insert_Picture_Event = {
  Idle: 'Idle',
  Ready: 'Ready',
  Countdown: 'Countdown',
  Take_photo: 'Take_photo',
  Turn_back: 'Turn_back',
  Upload: 'Upload',
  Close: 'Close'
};

const insertPictureMachine = createMachine(
  {
    id: 'insertPicture',
    initial: Insert_Picture_State.Idle,
    states: {
      // 閒置
      [Insert_Picture_State.Idle]: {
        on: {
          [Insert_Picture_Event.Ready]: {
            target: Insert_Picture_State.Ready,
          },
          [Insert_Picture_Event.Close]: {
            target: Insert_Picture_State.End,
          },
        },
      },
      // 準備
      [Insert_Picture_State.Ready]: {
        on: {
          [Insert_Picture_Event.Countdown]: {
            target: Insert_Picture_State.Countdown,
          },
          [Insert_Picture_Event.Close]: {
            target: Insert_Picture_State.End,
          },
        },
      },
      // 倒數
      [Insert_Picture_State.Countdown]: {
        on: {
          [Insert_Picture_Event.Take_photo]: {
            target: Insert_Picture_State.Preview,
          },
          [Insert_Picture_Event.Close]: {
            target: Insert_Picture_State.End,
          },
        },
      },
      // 預覽
      [Insert_Picture_State.Preview]: {
        on: {
          [Insert_Picture_Event.Turn_back]: {
            target: Insert_Picture_State.Ready,
          },
          [Insert_Picture_Event.Close]: {
            target: Insert_Picture_State.End,
          },
        },
      },
      [Insert_Picture_State.End]: {
        on: {
          [Insert_Picture_Event.Idle]: {
            target: Insert_Picture_State.Idle,
          },
        },
      },
    },
  }
);

export default insertPictureMachine;