const BASE_URL = process.env.REACT_APP_ONEBOARD_API_URL;

export const getServerTime = async () => {
  try {
    const response = await fetch(`${BASE_URL}/oneboard/timestamp`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
    }).then(res => res.json());
    return response;
  } catch (error) {
    console.log('error:', error);
  }
};
