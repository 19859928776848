import styled from 'styled-components';

export const StyledWorldWallModal = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .content {
    > textarea {
      width: 100%;
      height: 160px;
      padding: 14px;
      border-radius: 12px;
      outline: none;
      /* background-color: #232A37; */
      background-color: #fff;
      /* border: none; */
      resize: none;
    }
    .tip {
      margin-top: 8px;
      font-size: 14px;
      color: #8a94a6;
      letter-spacing: 1px;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 12px;
  border: none;
  cursor: pointer;

  &.primary {
    color: #fff;
    background-color: #ec7963;
  }

  &.secondly {
    color: #8a94a6;
    background-color: #232a37;
  }

  &:disabled {
    color: #2f3746;
    cursor: not-allowed;
    background-color: #232a37;
  }
`;
