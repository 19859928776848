import React from 'react';
import {
  useVideoInputs,
  useMeetingManager,
  PreviewVideo
} from 'amazon-chime-sdk-component-library-react';
import { Box, FullButton, Select } from '@oneboard/ui-components';
import { useDeviceDetectDispatch, useDeviceDetectState } from '../../providers/DeviceDetectProvider';
import { StyledVideoStep } from './VideoStep.style';

export const VideoStep = ({ className }) => {
  const { goMicrophone, updateEquipment } = useDeviceDetectDispatch();
  const deviceDetectState = useDeviceDetectState();
  const state = deviceDetectState.context;
  
  const { devices, selectedDevice } = useVideoInputs();
  const meetingManager = useMeetingManager();
  
  const deviceChangeHandler = (deviceId) => meetingManager.startVideoInputDevice(deviceId);

  const abnormalHandler = () => {
    updateEquipment({
      video: false
    })
    goMicrophone();
  };

  const normalHandler = () => {
    updateEquipment({
      video: true
    })
    goMicrophone();
  };

  return (
    <StyledVideoStep className={className} data-testid="VideoStep">
      <div className="videoBlock">
        <div className="videoStepWrap">
          {devices.length > 0 && <Select className="select" defaultValue={selectedDevice} onChange={deviceChangeHandler}>
            {devices.map((item) => <Select.Option 
              value={item.deviceId} 
              key={item.deviceId}>
                {item.label}
              </Select.Option>)}
          </Select>}
          <div className="videoBox">
            <PreviewVideo />
          </div>
        </div>
      </div>
      <Box mt={4}>
        <div className="question">
          <div className="title">
            請選擇使用設備，並觀察能否見到影像？
          </div>
          <div className="action">
            <Box mr={1} width="100%">
              <FullButton.Secondly onClick={abnormalHandler}>不行</FullButton.Secondly>
            </Box>
            <Box ml={1} width="100%">
              <FullButton onClick={normalHandler}>可以</FullButton>
            </Box>
          </div>  
        </div>
      </Box>
    </StyledVideoStep>
  );
};