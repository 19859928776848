import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocalVideo, useToggleLocalMute, useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { useLocalToolboxAuth } from '@oneboard/meeting';
import { ClassType } from 'constants/index';
import VideoWrapper from '../common/VideoWrapper';
import VideoContainer from '../common/VideoContainer';
import VolumeContainer from '../common/VolumeContainer';
import VideoContentContainer from '../common/VideoContentContainer';
import IconWrapper from '../../common/IconWrapper';
import StyledImage from '../../common/StyledImage';
import UserName from '../common/UserName';
import { LocalVideoV2, useMeetingState } from '@oneboard/meeting';
import NotOpen from '../common/NotOpen';
import { MicroBlack } from '../../common/StyledIcon';
import VolumeIndicator from '../common/VolumeIndicator';
import PreviewVideo from './PreviewVideo';
import Icon from '@onedesign/icon';
import StudentIcon from 'assets/newOneBoard/images/student_icon.png';
import MediaMetricsV2 from 'components/MediaMetricsV2';
import { useInsertPicture } from 'providers/InsertPictureProvider';
import { Insert_Picture_State, Insert_Picture_Event } from 'machines/InsertPictureMachine';
import { useWindowWidth } from './hooks/useWindowWidth';
import StagedAnimationBox from './StagedAnimationBox';

export default function StudentVideo({ studentName, isSettingModalOpen }) {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const { isToolboxEnabled } = useLocalToolboxAuth();
  const audioVideo = useAudioVideo();
  const { state: insertPictureState, send: insertPictureSend } = useInsertPicture();
  const isEnd = insertPictureState.matches(Insert_Picture_State.End);
  const windowWidth = useWindowWidth();
  const meetingState = useMeetingState();
  const { courseType } = meetingState.context;
  const { attendeeId: selfAttendeeId, stagedAttendeeIds } = meetingState.context;
  const { context } = useMeetingState();
  const { videoIsMirroring } = context;
  const totalVolumeLevel = windowWidth < 1224 ? 8 : 10;

  useEffect(() => {
    if (!isVideoEnabled) {
      toggleVideo();
    }
    if (muted && courseType === ClassType.SyncSingle) {
      toggleMute();
    }
  }, []);

  useEffect(() => {
    if (isEnd && !isVideoEnabled) {
      insertPictureSend({
        type: Insert_Picture_Event.Idle,
      });
    }
  }, [isEnd, isVideoEnabled]);

  return (
    <VideoContainer>
      {isSettingModalOpen ? (
        <VideoWrapper>
          <MediaMetricsV2 />
          <NotOpen />
        </VideoWrapper>
      ) : (
        <VideoWrapper>
          <MediaMetricsV2 />
          {isVideoEnabled ? (
            stagedAttendeeIds.includes(selfAttendeeId) ? (
              <StagedAnimationBox />
            ) : (
              <LocalVideoV2 videoIsMirroring={videoIsMirroring}/>
            )
          ) : courseType === ClassType.SyncMultiple ? (
            <PreviewVideo />
          ) : (
            <NotOpen />
          )}
        </VideoWrapper>
      )}
      <VideoContentContainer>
        <StyledImage src={StudentIcon} alt='name icon' width={28} height={28} />
        <UserName>{studentName}</UserName>
        {courseType === ClassType.SyncMultiple ? (
          <IconWrapper isEnabled={true}>
            <Icon name={'VideoSolid'} size='xxs' color='#FFFFFF' />
          </IconWrapper>
        ) : (
          <IconWrapper isEnabled={isVideoEnabled}>
            <Icon name={isVideoEnabled ? 'VideoSolid' : 'VideoSlashSolid'} size='xxs' color='#FFFFFF' />
          </IconWrapper>
        )}
        <IconWrapper isEnabled={!muted}>
          <Icon name={muted ? 'MicrophoneAltSlashSolid' : 'MicrophoneAltSolid'} size='xs' color='#FFFFFF' />
        </IconWrapper>
        <IconWrapper isEnabled={isToolboxEnabled}>
          {isToolboxEnabled ? (
            <Icon name='ChalkboardOutline' size='xs' color='#FFFFFF' />
          ) : (
            <Icon name='ChalkboardSlashSolid' size='xs' color='#FFFFFF' />
          )}
        </IconWrapper>
      </VideoContentContainer>
      <VolumeContainer>
        <MicroBlack />
        <VolumeIndicator
          attendeeId={audioVideo.audioVideoController.configuration.credentials.attendeeId}
          totalVolumeLevel={totalVolumeLevel}
        />
      </VolumeContainer>
    </VideoContainer>
  );
}

StudentVideo.propTypes = {
  studentName: PropTypes.string.isRequired,
};
