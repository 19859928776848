import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { Roles, ClassType } from 'constants/index';
import { Popover, notification } from 'antd';
import { Box } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import { RECORD_VIDEO_STATE } from 'machines/RecordVideoMachine';
import { startRecordVideo, stopRecordVideo, recordVideoInfo } from 'services/recordVideo';
import { useRequest, useUpdateEffect, useInterval, useLocalStorageState } from 'ahooks';
import { v4 as uuid } from 'uuid';
import { StyledPopContent, StyledRecordBox } from './RecordButton.style';
import { useMeetingDispatch, useMeetingState, MeetingStates } from '@oneboard/meeting';
import { t } from 'utils/i18n';
import { LoadingOutlined } from '@ant-design/icons';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import { CancelButton, SendButton } from 'components/NewOneBoard/common/MessageButton';
import { useStreaming } from 'providers/StreamingProvider';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { shortId } from 'utils';

const ButtonContainer = styled(Box)`
  display: flex;
  height: 68px;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const ModalText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: #637381;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 8px;
`;

const EndButton = styled(SendButton)`
  width: 60px;
`;

const openNotification = ({ error }) => {
  notification.error({
    className: 'customNotification',
    message: error,
    key: uuid(),
  });
};

const RecordingContainer = styled.div`
  display: flex;
  align-items: center;
  color: #ec7963;
  font-weight: 500;
  gap: 2px;
`;

const STREAMING_URL = process.env.REACT_APP_STREAMING_API_DOMAIN;
const FIVE_MINUTE = 1000 * 60 * 5;

export const RecordButton = ({ currentRole }) => {
  const { isFooterOpen } = useFooterStatus();
  const { courseInfo } = useCourseInfo();

  const ReminderMessage =
    currentRole === Roles.Teacher
      ? t(
        'mainPage.teacher.syncSingle.remindToTurnVideoOn',
        '開始上課了嗎?\n別忘了按下錄影按鈕，讓學生可以在課後複習本次課程喔！'
      )
      : '顧問談單請務必錄影，未提供錄影檔業績將不列入計算！';
  const StreamingMessage = '開始上課了嗎?\n別忘了按下直播按鈕，系統將同步開始錄影！';
  const { meetingId } = useParams();
  const meetingState = useMeetingState();
  const { roster } = useRosterState();
  const { teacherStartRecord, teacherStopRecord, advisorStartRecord, advisorStopRecord, observerStopRecord } =
    useMeetingDispatch();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const isRecordObserver = attendees.find(
    (attendee) => attendee.role === Roles.Observer && attendee.name === 'recordObserver'
  );
  const { context } = meetingState;
  const { role, courseType } = context;
  const [recordingModalState, setRecordingModal] = useState(false);
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState(false);
  const [checkStreamingStatus, setCheckStreamingStatus] = useState(false);
  const [isOpenDisconnectRecordModal, setIsOpenDisconnectRecordModal] = useState(false);
  const { setIsBroadcasting } = useStreaming();
  const [intervalTime, setIntervalTime] = useState(FIVE_MINUTE);
  const mainPageRef = useRef();
  const buttonRef = useRef();
  const recordVideoState = meetingState.value.Joined[role].RecordVideo;
  let isRecordVideoOn = false;

  // 紀錄錄影按鈕狀態
  const [initiativeTriggerRecordMap, setInitiativeTriggerRecordMap] = useLocalStorageState('initiativeTriggerRecord', {});

  switch (role) {
    case Roles.Teacher:
      isRecordVideoOn = meetingState.matches({
        [MeetingStates.Joined]: `${Roles.Teacher}.${MeetingStates.RecordVideo}.${RECORD_VIDEO_STATE.On}`,
      });
      break;
    case Roles.Advisor:
      isRecordVideoOn = meetingState.matches({
        [MeetingStates.Joined]: `${Roles.Advisor}.${MeetingStates.RecordVideo}.${RECORD_VIDEO_STATE.On}`,
      });
      break;
    default:
      break;
  }

  const { loading: startRecordLoading, run: runStartRecordVideo } = useRequest(startRecordVideo, {
    manual: true,
    onSuccess: async (result, params) => {
      if (result.ok) {
        switch (role) {
          case Roles.Teacher:
            teacherStartRecord();
            break;
          case Roles.Advisor:
            advisorStartRecord();
            break;
          default:
            break;
        }
        setInitiativeTriggerRecordMap({ [meetingId]: true });
      } else {
        const errorText = await result.text();
        openNotification({ error: errorText });
      }
    },
    onError: (error, params) => {
      openNotification({ error: error.message });
    },
  });

  const { loading: stopRecordLoading, run: runStopRecordVideo } = useRequest(stopRecordVideo, {
    manual: true,
    onSuccess: async (result, params) => {
      if (result.ok) {
        setRecordingModal(false);
        switch (role) {
          case Roles.Teacher:
            teacherStopRecord();
            break;
          case Roles.Advisor:
            advisorStopRecord();
            break;
          default:
            break;
        }
        setInitiativeTriggerRecordMap({ [meetingId]: false });
        setIntervalTime(undefined);
      } else {
        const errorText = await result.text();
        openNotification({ error: errorText });
      }
    },
    onError: (error, params) => {
      openNotification({ error: error.message });
    },
  });

  // 錄影狀態
  const { loading: recordVideoInfoLoading, run: runRecordVideoInfo } = useRequest(recordVideoInfo, {
    manual: true,
    onSuccess: async (result, params) => {
      if (result.ok) {
        const recordInfo = await result.json().then((res) => res.data[0]);

        if (recordInfo) {
          const isRecording = recordInfo.isRecording;
          if (isRecording) {
            switch (role) {
              case Roles.Teacher:
                teacherStartRecord();
                break;
              case Roles.Advisor:
                advisorStartRecord();
                break;
              default:
                break;
            }
            setInitiativeTriggerRecordMap({ [meetingId]: true });
          } else {
            switch (role) {
              case Roles.Teacher:
                teacherStopRecord();
                break;
              case Roles.Advisor:
                advisorStopRecord();
                break;
              default:
                break;
            }

            if (initiativeTriggerRecordMap[meetingId]) {
              setIsOpenDisconnectRecordModal(true);
              setInitiativeTriggerRecordMap({ [meetingId]: false });
            }
          }
        }
      } else {
        const errorText = await result.text();
        openNotification({ error: errorText });
      }
    },
  });

  const openConfirmModal = () => setRecordingModal(true);

  const closeConfirmModal = () => {
    if (stopRecordLoading) return;
    setRecordingModal(false);
  };

  const confirmEndHandler = async () => {
    stopRecord();
  };

  const parsed = queryString.parse(window.location.search);
  const { classType } = parsed;
  const stringified = queryString.stringify({
    classType,
    role: Roles.Observer,
    userName: 'recordObserver',
    userId: shortId(),
  });

  const startStreaming = async () => {
    const response = await fetch(`${STREAMING_URL}/stream-course-video/${meetingId}/start`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        targetUrl: `${process.env.REACT_APP_HOSTING_DOMAIN}/${meetingId}/${Roles.Observer}?${stringified}`,
      }),
    });
    const data = await response.json();
    if (data.err === 0) {
      setCheckStreamingStatus(true);
    }
  };

  const stopStreaming = async () => {
    const response = await fetch(`${STREAMING_URL}/stream-course-video/${meetingId}/stop`, {
      method: 'POST',
    });
    const data = await response.json();

    if (data.err === 0) {
      setIsBroadcasting(false);
      setCheckStreamingStatus(false);
    }
  };

  const startRecord = async () => {
    if (recordVideoInfoLoading || startRecordLoading) return;
    runStartRecordVideo({ meetingId });

    if (courseType === ClassType.SyncStreaming && role === Roles.Teacher) {
      startStreaming();
    }
  };

  // 檢查串流狀態
  const getStreamingStatus = async () => {
    if (!courseInfo) return;
    const grade = courseInfo?.livestreaming.grade;
    const subject = courseInfo?.livestreaming.subject;
    const response = await fetch(`${STREAMING_URL}/stream-course-video/ivs/stream-status/${grade}/${subject}`, {
      method: 'GET',
    });
    const res = await response.json();
    const statusData = res.data;
    const ivsStreamState = statusData?.ivsStreamState || {};
    const isRunningOrPending = statusData.phase === 'pending' || statusData.phase === 'running';
    if (ivsStreamState.state === 'offline' && !isRunningOrPending) {
      setIsOpenDisconnectModal(true);
      setIsBroadcasting(false);
      // 串流斷線，停止錄影
      stopRecord();
    } else if (ivsStreamState.state === 'live') {
      setIsBroadcasting(true);
    }
  };

  const handleCloseDisconnectModal = () => {
    setIsOpenDisconnectModal(false);
  };

  const handleCloseDisconnectRecordModal = () => {
    setIsOpenDisconnectRecordModal(false);
  };

  useEffect(() => {
    if (!courseInfo || !checkStreamingStatus) return;
    const interval = setInterval(() => {
      getStreamingStatus();
    }, 30000);
    return () => clearInterval(interval);
  }, [checkStreamingStatus]);

  const stopRecord = () => {
    runStopRecordVideo({ meetingId });
    if (courseType === ClassType.SyncStreaming && role === Roles.Teacher) {
      setIsBroadcasting(false);
    }
    if (courseType === ClassType.SyncStreaming && role === Roles.Teacher) {
      stopStreaming();
    }
  };

  const clickHandler = () => {
    const { On } = RECORD_VIDEO_STATE;
    switch (recordVideoState) {
      case On:
        openConfirmModal();
        break;

      default:
        startRecord();
        break;
    }
  };

  useEffect(() => {
    const rootDom = document.getElementById('MainPage');
    mainPageRef.current = rootDom;
  }, []);


  useInterval(() => {
    if (role === Roles.Teacher || role === Roles.Advisor) {
      runRecordVideoInfo({ meetingId });
    }
  }, intervalTime, { immediate: true });

  useEffect(() => {
    const firstCheckStreamingStatus = async () => {
      if (!courseInfo) return;
      const grade = courseInfo?.livestreaming.grade;
      const subject = courseInfo?.livestreaming.subject;
      const response = await fetch(`${STREAMING_URL}/stream-course-video/ivs/stream-status/${grade}/${subject}`, {
        method: 'GET',
      });
      const res = await response.json();
      const statusData = res.data;
      const ivsStreamState = statusData?.ivsStreamState || {};
      if (ivsStreamState.state === 'live') {
        stopRecord();
      }
    };

    if (courseType === ClassType.SyncStreaming) {
      firstCheckStreamingStatus();
    }
  }, []);

  useUpdateEffect(() => {
    if (!isRecordObserver) {
      observerStopRecord();
    }
  }, [isRecordObserver]);

  return (
    <StyledRecordBox ref={buttonRef}>
      {isRecordVideoOn ? (
        <Popover
          overlayInnerStyle={{
            borderRadius: '8px',
            width: 'fit-content',
          }}
          overlayClassName='stopRecordPopover'
          content={
            <StyledPopContent>
              <RecordingContainer>
                <Icon name='RecordSolid' size='xs' color='#EC7963' />
                {courseType === ClassType.SyncStreaming ? '直播中' : '錄影中'}
              </RecordingContainer>
              {/* {recordingDuration && <RecordingText>錄影時間：{recordingDuration}</RecordingText>} */}
            </StyledPopContent>
          }
          placement={'top'}
          trigger='hover'
          arrowPointAtCenter={true}
        >
          <div>
            <ToolButton
              onClick={clickHandler}
              icon={
                <Icon
                  name={courseType === ClassType.SyncStreaming ? 'FluentLiveOffFilled' : 'StopRecordSolid'}
                  size='sm'
                  color='#CD3B33'
                />
              }
            >
              {courseType !== ClassType.SyncStreaming && isFooterOpen && '停止錄影'}
              {courseType === ClassType.SyncStreaming && isFooterOpen && '停止直播'}
            </ToolButton>
          </div>
        </Popover>
      ) : (
        <Popover
          visible={true}
          placement={
            courseType === ClassType.SyncMultiple || courseType === ClassType.SyncStreaming ? 'topLeft' : 'top'
          }
          getPopupContainer={() => buttonRef.current}
          overlayInnerStyle={{
            backgroundColor: '#FF7257',
            borderRadius: '8px',
            width: '190px',
          }}
          overlayClassName='startRecordPopover'
          content={
            <StyledPopContent>
              {courseType === ClassType.SyncStreaming ? StreamingMessage : ReminderMessage}
            </StyledPopContent>
          }
        >
          <ToolButton
            onClick={startRecord}
            icon={
              recordVideoInfoLoading || startRecordLoading ? (
                <LoadingOutlined />
              ) : (
                <Icon
                  name={courseType === ClassType.SyncStreaming ? 'FluentLiveFilled' : 'RecordSolid'}
                  size='sm'
                  color='#CD3B33'
                />
              )
            }
          >
            {courseType !== ClassType.SyncStreaming && isFooterOpen && '開始錄影'}
            {courseType === ClassType.SyncStreaming && isFooterOpen && '開始直播'}
          </ToolButton>
        </Popover>
      )}

      <ToolModal
        active={recordingModalState}
        onClose={closeConfirmModal}
        title={courseType === ClassType.SyncStreaming ? '直播結束' : '錄影結束'}
        footerHeight='84px'
        footerContent={
          <ButtonContainer>
            <CancelButton block size='large' onClick={closeConfirmModal} loading={stopRecordLoading}>
              取消
            </CancelButton>
            <EndButton block size='large' onClick={confirmEndHandler} loading={stopRecordLoading}>
              {stopRecordLoading ? <LoadingOutlined /> : t('containers.recordButton.confirmModal.button', '結束')}
            </EndButton>
          </ButtonContainer>
        }
      >
        <ModalText>
          {courseType === ClassType.SyncStreaming
            ? '確認結束直播嗎？'
            : t('containers.recordButton.confirmModal.content', '確認結束錄影嗎？')}
        </ModalText>
      </ToolModal>
      <ToolModal
        active={isOpenDisconnectModal}
        onClose={handleCloseDisconnectModal}
        title='直播中斷'
        footerHeight='84px'
        footerContent={
          <ButtonContainer>
            <EndButton block size='large' onClick={handleCloseDisconnectModal}>
              確定
            </EndButton>
          </ButtonContainer>
        }
      >
        <ModalText>若要繼續進行直播，需重新點擊開始直播按鈕</ModalText>
      </ToolModal>

      <ToolModal
        active={isOpenDisconnectRecordModal}
        onClose={handleCloseDisconnectRecordModal}
        title='錄影中斷'
        footerHeight='84px'
        footerContent={
          <ButtonContainer>
            <EndButton block size='large' onClick={handleCloseDisconnectRecordModal}>
              確定
            </EndButton>
          </ButtonContainer>
        }
      >
        <ModalText>若要繼續進行錄影，需重新點擊開始錄影按鈕</ModalText>
      </ToolModal>
    </StyledRecordBox>
  );
};
