import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { Box } from '@oneboard/ui-components';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { useGroupContext } from 'providers/GroupProvider';
import { IframeBridge } from '@oneboard/iframe-bridge';
import { StyledSceneTab, StyledInitTab, StyledGap, StyledTabs, StyledTabPane } from './SceneTab.style';
import { t } from 'utils/i18n';

const InitTab = ({ children, onClick, isActive }) => {
  return (
    <StyledInitTab onClick={onClick} isActive={isActive}>
      {children}
    </StyledInitTab>
  );
};

InitTab.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

const Tabs = ({ children }) => {
  return (
    <StyledTabs>
      {children}
    </StyledTabs>
  );
};

Tabs.propTypes = {
  children: PropTypes.node
};

const TabPane = ({ label, onTabClick, onRemove, isActive }) => {
  return (
    <StyledTabPane onClick={onTabClick} isActive={isActive}>
      <div className="label">{label}</div>
      <div className="icon" onClick={onRemove}>
        <Icon name='XmarkOutline' size='xxs' />
      </div>
    </StyledTabPane>
  );
};

TabPane.propTypes = {
  label: PropTypes.string,
  onTabClick: PropTypes.func,
  onRemove: PropTypes.func,
  isActive: PropTypes.bool,
};

export const SceneTab = ({ className, room }) => {
  const { sceneTabsData, setCurrentScene, removeSceneQueue, updateSceneMap } = useGroupContext();
  const { currentScene, queue, sceneMap } = sceneTabsData;
  const { scaleToFit } = useZoomControl();

  const generateRootScene = () => {
    const isInitSceneExist = room.scenePathType('/init') !== 'none';
    const entireScenes = room.entireScenes();

    if (isInitSceneExist) {
      return 'init';
    }

    if ('/' in entireScenes) {
      return entireScenes['/'][0].name;
    }

    room.putScenes('/', [{ name: 'init' }], 0);
    return 'init';
  };

  const rootTabClickHandler = () => {
    let rootBookId = generateRootScene();
    room.setScenePath(`/${rootBookId}`);
    setCurrentScene({ bookId: rootBookId });
    scaleToFit();
  };

  const onTabClickHandler = (bookId) => {
    const { index, width, height } = sceneMap[bookId];
    const page = index + 1;
    room.setScenePath(`/${bookId}/${page}`);
    setCurrentScene({ bookId });
    scaleToFit();

    // width & height only for iframe bridge component
    if (bookId.indexOf('iframe') > -1) {
      let bridge = room.getInvisiblePlugin(IframeBridge.kind);
      bridge.setAttributes({ width, height });
      bridge.scaleIframeToFit();
    }
  };

  const removeScenesHandler = (event, bookId, index) => {
    event.stopPropagation();
    let nextBookId = '';
    let nextScenePath = '';

    if (queue.length === 1) {
      nextBookId = generateRootScene();
      nextScenePath = `/${nextBookId}`;
    } else if (index !== queue.length - 1) {
      nextBookId = queue[index + 1];
      const nextPage = sceneMap[nextBookId].index + 1;
      nextScenePath = `/${nextBookId}/${nextPage}`;
    } else {
      nextBookId = queue[index - 1];
      const nextPage = sceneMap[nextBookId].index + 1;
      nextScenePath = `/${nextBookId}/${nextPage}`;
    }

    room.setScenePath(nextScenePath);
    removeSceneQueue({ bookId, nextBookId });
    scaleToFit();

    // width & height only for iframe bridge component
    if (nextBookId.indexOf('iframe') > -1) {
      const { width, height } = sceneMap[nextBookId];
      let bridge = room.getInvisiblePlugin(IframeBridge.kind);
      bridge.setAttributes({ width, height });
      bridge.scaleIframeToFit();
    }
  };

  // Listen for room status changes and update the scene map
  useEffect(() => {
    const handler = (state) => {
      if (!state?.sceneState) return;
      const bookId = state.sceneState.contextPath.split('/')[1];
      if (!bookId) return;
      const { index } = state.sceneState;
      updateSceneMap({ bookId, index });
    };
    room.callbacks.on('onRoomStateChanged', handler);
    return () => room.callbacks.off('onRoomStateChanged', handler);
  }, [room]);

  return (
    <StyledSceneTab className={className} data-testid="SceneTab">
      <InitTab onClick={rootTabClickHandler} isActive={currentScene === 'init'}>
        <Icon name="ChalkboardOutline" />
        <Box ml={2}>{t('containers.sceneTab.whiteboard', '白板')}</Box>
      </InitTab>
      <StyledGap />
      <Tabs>
        {queue.map((bookId, index) => {
          const { label } = sceneMap[bookId];
          return (
            <TabPane
              label={label}
              bookId={bookId}
              key={bookId}
              isActive={bookId === currentScene}
              onTabClick={() => onTabClickHandler(bookId)}
              onRemove={(event) => removeScenesHandler(event, bookId, index)} />
          );
        })}
      </Tabs>
    </StyledSceneTab>
  );
};

SceneTab.propTypes = {
  className: PropTypes.string,
  room: PropTypes.object,
};