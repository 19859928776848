import styled from 'styled-components';
import { mediaQueries } from '../../utils/theme';

export const StyledMicrophoneStep = styled.div.attrs(props => ({
className: props.className
}))`
  .microphoneBlock {
    max-height: 440px;
    height: 100%;
    background-color: #161C27;
    border-radius: 15px;
    padding: 52px 20px;
  }

  .microphoneStepWrap {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    .microphoneIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 96px;
      margin: 45px auto 20px;
      border: 2px solid #232A37;
      border-radius: 90px;
    }
    .content {
      margin-top: 30px;
      .title {
        font-weight: 500;
        font-size: 24px;
        color: #fff;
        text-align: center;
      }
      .desc {
        font-weight: 500;
        font-size: 16px;
        color: #8A94A6;
        text-align: center;
      }
    }
    ${mediaQueries} {
      .microphoneIcon {
        margin: 36px auto 20px;
      }
    }
  }
  
  .question {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .title {
      flex: 1 1 0px;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
    }
    .action {
      display: flex;
      flex: 1 1 0px;
    }
    ${mediaQueries.small} {
      flex-direction: column;
      .title {
        text-align: center;
      }
      .action {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
`;

export const StyledTestBar = styled.div`
  position: relative;
  max-width: 200px;
  width: 100%;
  height: 24px;
  margin: 0 auto;
  overflow: hidden;

  .baseLayer {
    width: 100%;
    height: 24px;
    background-image: linear-gradient(90deg, #232A37 50%, transparent 50%);
    background-size: 10px 24px;
  }
  .coverBox {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 24px;
    background-image: linear-gradient(90deg, transparent 50%, #161C27 50%);
    background-size: 10px 24px;
  }
  .bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 24px;
    background-color: #EC7963;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 33ms ease-in-out;
    will-change: transform;
  }
`;