import styled from 'styled-components';

export const StyledGroupPage = styled.div`
  height: 100%;
  .contentShareContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1031;
  }
`;

export const ControlsGroup = styled.div`
    position: absolute;
    right: 8px;
    bottom: 30px;
    display: inline-flex;
    align-items: center;
`;

export const ControlsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #232323;
  border-radius: 28px;
  margin-left: 16px;
  padding: 8px 30px;
  
  > .controlButton,
  > .settingPopoverButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #232323;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #fff;
  }

  > .controlButton{
    border-radius: 24px;
    font-size: 16px;
    &:hover {
      background-color: #33353A;
    }
    +.controlButton {
      margin-left: 8px;
    }
  }
`;

export const OnebookFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0px;
  z-index: 10;
`;

export const UiChatRoomLayout = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(${({ active }) => active ? '0' : '280px'});
  width: 280px;
  height: 100%;
  z-index: 10;
  padding: 0 8px 24px;
  background-color: #232323;
  transition: .3s;
`;

export const StyleUsersModalLayout = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(${({ active }) => active ? '0' : '360px'});
  width: 360px;
  height: 100%;
  z-index: 2001;
  transition: .3s;
`;


export const StyledOtherButton = styled.div`
  position: relative;
  > .controlButton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #232323;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: #fff;
    &:hover {
      background-color: #33353A;
    }
  }
`;

export const OtherOption = styled.div`
  background-color: #1E1F20;
  width: 200px;
  border-radius: 8px;
  
  .otherList {
    display: flex;
    padding: 12px 16px;
    color: #D5D7DE;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
  .listIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
  .listContent {
    font-size: 14px;
    margin-left: 8px;
    line-height: 40px;
    flex: 1;
    
    .customerService {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
`;

export const MuteOption = styled.div`
  background-color: #1E1F20;
  border-radius: 8px;
  padding: 8px 0;

  .optionList {
    display: flex;
    margin: 0 16px;
    padding: 12px 16px;
    color: #D5D7DE;
    cursor: pointer;
    border-radius: 15px;

    &:hover {
      background-color: #33353A;
    }
  }
  .listIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .listContent {
    font-size: 14px;
    margin-left: 8px;
    line-height: 24px;
    flex: 1;
    
    .customerService {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
`;