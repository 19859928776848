import React from 'react';
import { StyledResourceBox, StyledResource, StyledResourceItem } from '../QuestionTypeList.style';

const questionTypes = [
  { code: 'FL', name: '填充題' },
  { code: 'GR', name: '題組題' },
  { code: 'MT', name: '多選題' },
  { code: 'MX', name: '混合題' },
  { code: 'SS', name: '單一選擇題' },
  { code: 'WR', name: '非選題' },
];

const QuestionType = ({ selectedType, setSelectedType }) => {
  const clickHandler = (selectedType) => {
    setSelectedType(selectedType);
  };

  return (
    <StyledResource>
      {questionTypes.map((type) => (
        <StyledResourceBox key={type.code}>
          <div className='content'>
            <div className='list'>
              <StyledResourceItem
                key={type.code}
                onClick={() => clickHandler(type)}
                active={selectedType && selectedType.code === type.code}
              >
                {type.name}
              </StyledResourceItem>
            </div>
          </div>
        </StyledResourceBox>
      ))}
    </StyledResource>
  );
};

export default QuestionType;
