import styled from 'styled-components';

const AdvisorContainer = styled.div`
  position: absolute;
  right: 10px;
  top: ${({ userType, isStudentList }) =>
    isStudentList
      ? userType === 'student' || userType === 'observer'
        ? '151px'
        : '206px'
      : userType === 'student' || userType === 'observer'
      ? '5px'
      : '60px'};
  z-index: 2;
`;

export default AdvisorContainer;
