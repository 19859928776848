import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  SpeakerSelection as ChimeSpeakerSelection,
  useAudioOutputs,
  Label as ChimeLabel,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import Icon from '@onedesign/icon';
import TestSound from './testSound';
import { t } from 'utils/i18n';

const Label = styled(ChimeLabel)`
  display: block;
  color: #212b36;
`;

const TestLabel = styled(Label)`
  margin: 0;
  cursor: ${({ isPlaying }) => (isPlaying ? 'not-allowed' : 'pointer')};
  color: ${({ isPlaying }) => (isPlaying ? '#919eab' : '#75BEC2')};
`;

const TestButton = styled.button`
  display: flex;
  width: 104px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  margin: 10px 0;
  font-weight: 700;
  border: 1px solid ${({ isPlaying }) => (isPlaying ? '#919eab' : '#75BEC2')};
  opacity: ${({ isPlaying }) => (isPlaying ? '0.6' : '1')};
  cursor: ${({ isPlaying }) => (isPlaying ? 'not-allowed' : 'pointer')};
`;

const DeviceSelection = styled.select`
  display: flex;
  height: 40px;
  padding: 8px 8px 7px 14px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 75px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  color: #919eab;
  width: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  gap: 4px;
`;

const audioInputConfig = {
  additionalDevices: false,
};

export const SpeakerSelection = ({ label = true }) => {
  const meetingManager = useMeetingManager();
  const { devices: audioOutputs, selectedDevice: selectedAudioOutput } = useAudioOutputs();

  return (
    <div>
      <LabelWrapper>
        <Icon name='VolumeMediumSolid' size='sm' color={'#212B36'} />
        {label && <Label>音訊來源</Label>}
      </LabelWrapper>
      {/* <ChimeSpeakerSelection label={null} notFoundMsg={t('meeting.components.speakerDevices.noDeviceDetected', '沒有偵測到設備')}/> */}
      <DeviceSelection
        onChange={(event) => meetingManager.startAudioOutputDevice(event.target.value)}
        value={selectedAudioOutput}
      >
        {audioOutputs.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label}
          </option>
        ))}
      </DeviceSelection>
    </div>
  );
};

// export const TestButton = styled.div`
//   display: inline-block;
//   color: #D5D7DE;
//   cursor: pointer;
//   &:hover {
//     color: #8B90A0;
//   }
// `;

export const SpeakerTestButton = () => {
  const { selectedDevice } = useAudioOutputs();
  const [selectedOutput] = useState(selectedDevice);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef();

  const handleTestSpeaker = () => {
    setIsPlaying(true);
    new TestSound(selectedDevice);
    setTimeout(() => {
      setIsPlaying(false);
    }, 1500);
  };

  useEffect(() => {
    audioRef.current = new Audio(TestSound);
  }, []);

  return (
    <TestButton isPlaying={isPlaying} onClick={handleTestSpeaker}>
      <TestLabel isPlaying={isPlaying}>
        {isPlaying ? '播放中' : t('meeting.components.speakerDevices.testAudio', '測試音訊')}
      </TestLabel>
    </TestButton>
  );
};

export const SpeakerDevices = () => {
  return (
    <div>
      <SpeakerSelection
        label={null}
        notFoundMsg={t('meeting.components.speakerDevices.noDeviceDetected', '沒有偵測到設備')}
      />
      <SpeakerTestButton />
    </div>
  );
};
