import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, FullButton } from '@oneboard/ui-components';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { getMultiUserRecord } from 'services/userRecord';
import { formatSeconds } from 'utils/time';
import { StyledClassroomPerformanceModal, StyledModalHead, StyledModalBody, StyledUserCell } from './ClassroomPerformanceModal.style';
import { t } from '../../utils/i18n';

const UserCell = ({ user }) => {

  return (
    <StyledUserCell>
      <Box className="iconGroup" flex={1} display="flex">
        <Box display="flex" alignItems="center" flex="2">
          <Box className="icon performance" mr={2} display="flex" alignItems="center">
            <img src={`/images/performance-icon-${user.iconIndex}.svg`} alt="" />
          </Box>
          {user.name}
        </Box>
        <Box display="flex" alignItems="center" ml={1} flex="2">
          <Box className="icon clock" mr={2} display="flex" alignItems="center">
            <img src="/images/clock.svg" alt="" />
          </Box>
          {formatSeconds(user.speakerSecond)}
        </Box>
        <Box display="flex" alignItems="center" ml={1} flex="1">
          <Box className="icon" mr={2} display="flex" alignItems="center">
            <Icon name="TrophySolid" size='sm' color="#F9C74F" />
          </Box>
          {user.trophy} 個
        </Box>
      </Box>
    </StyledUserCell>
  );
};

UserCell.propTypes = {
  user: PropTypes.object,
};

export const ClassroomPerformanceModal = ({ onClose, students }) => {
  const { openClassroomPerformance, closeClassroomPerformance } = useMeetingDispatch();
  const state = useMeetingState();
  const { context } = state;
  const { roomId } = context;
  const [studentsPerformance, setStudentsPerformance] = useState([]);
  const [openConfirmClose, setOpenConfirmClose] = useState(false);

  const openConfirmModel = () => setOpenConfirmClose(true);

  const handleConfirmClose = () => {
    onClose();
    closeClassroomPerformance();
  };

  const handleConfirmCancel = () => setOpenConfirmClose(false);

  useEffect(() => {
    const init = async () => {
      const multiRecord = await getMultiUserRecord({
        courseId: roomId,
      });
      const studentsRecord = students.map((student, index) => {
        const record = multiRecord.find(item => item.userId === student.userId);
        return {
          iconIndex: index % 6,
          ...student,
          ...record.summary
        };
      });

      setStudentsPerformance(studentsRecord);
      openClassroomPerformance({ classroomPerformance: studentsRecord });
    };

    init();

  }, []);

  return (
    <>
      <Modal width="600px" header={t('containers.classroomPerformanceModal.classroomPerformance.classroomPerformance', '課堂表現')} onClose={openConfirmModel}>
        <StyledClassroomPerformanceModal data-testid="ClassroomPerformanceModal">
          <StyledModalHead>
            <Box flex="2">{t('containers.classroomPerformanceModal.studentName', '學生姓名')}</Box>
            <Box flex="2">{t('containers.classroomPerformanceModal.speakingTime', '發言時間')}</Box>
            <Box flex="1">{t('containers.classroomPerformanceModal.noTrophies', '獎盃數')}</Box>
          </StyledModalHead>
          <StyledModalBody>
            {studentsPerformance.length > 0 && studentsPerformance.map(item => <UserCell key={item.userId} user={item} />)}
          </StyledModalBody>
        </StyledClassroomPerformanceModal>
      </Modal>
      {openConfirmClose && <Modal width="600px" header={t('containers.classroomPerformanceModal.closeModalHeader', '確定要關閉嗎？')} onClose={handleConfirmCancel}>
        <Box>
          {t('containers.classroomPerformanceModal.closeModalNotice', '關閉視窗時，同時學生也會一起關閉課堂表現喔！')}
        </Box>
        <Box display="flex" width="100%" pt={8}>
          <Box width="100%">
            <FullButton.Secondly onClick={handleConfirmClose} color="#FF5630">{t('containers.classroomPerformanceModal.confirmation', '確認')}</FullButton.Secondly>
          </Box>
        </Box>
      </Modal>}
    </>
  );
};

ClassroomPerformanceModal.propTypes = {
  onClose: PropTypes.func,
  students: PropTypes.array,
};