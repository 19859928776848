import styled from 'styled-components';

export const StyledObserverPage = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ModalTitle = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
`;

export const StyledNameBox = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 64px;
  cursor: default;
`;