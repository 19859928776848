import React from 'react';
import Icon from '@onedesign/icon';
import { StyledTilePausedBox, StyledPausedBox } from './TilePausedBox.style';

export const TilePausedBox = () => {
return (
    <StyledTilePausedBox data-testid="TilePausedBox">
      <StyledPausedBox>
        <Icon name='WifiWeakSolid' size='lg' />
      </StyledPausedBox>
    </StyledTilePausedBox>
  );
};