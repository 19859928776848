import styled from 'styled-components';

export const StyledRightContainer = styled.div.attrs(props => ({
className: props.className
}))`
  display: flex;
  flex-direction: column;
  height: 100%;

  .videoWrap {
    width: 100%;
  }
  .chatWrap {
    width: 100%;
    flex: 1;
    overflow: hidden;
  }
`;