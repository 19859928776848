import styled from 'styled-components';
import { mediaQueries } from '@oneboard/deviceDetect';

export const StyledDeviceDetectPage = styled.div.attrs(props => ({
className: props.className
}))`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #2E343F;
  overflow-y: scroll;
  
  .container {
    max-width: 768px;
    width: 100%;
    padding: 20px 16px;
    margin: auto; 
  }

  ${mediaQueries.small} {
    .container {
      padding: 20px 16px 152px;
    }
  }

  .header {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    .logo {
      width: 220px;
      img {
        width: 100%;
      }
    }

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 35px;
      color: #fff;
      margin-left: 10px;
    }
    
    ${mediaQueries.small} {
      .logo {
        width: 160px;
        
      }
      .title {
        font-size: 18px;
      }
    }
  }

  .stepContent {
    margin-top: 24px;
  }
`;