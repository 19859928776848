import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from '@oneboard/ui-components';

const UiMainLayout = styled(Box)`
  display: grid;
  width: 100vw;
  height: 100%;
  overflow: hidden;

  &.group {
    grid-template-rows: 1fr minmax(0, 4fr);
    grid-template-areas: 'top top' 'left main';
    grid-template-columns: 60px minmax(560px, 1fr);
  }
    
  &.single {
    grid-template-rows: 100% 100%;
    grid-template-areas: 'main right';
    grid-template-columns: 80% 20%;
    &.grouping {
      max-height: calc(100% - 60px);
      height: 100%;
    }
    &.networkNotice {
      height: 100%;
    }
  }

  &.multiple {
    grid-template-rows: 1fr minmax(0, 4fr);
    grid-template-areas: 'top top' 'left main';
    grid-template-columns: 60px minmax(560px, 1fr);
  }

  &.sync {
    grid-template-rows: 100% 100%;
    grid-template-areas: 'main right';
    grid-template-columns: 80% 20%;
  }

  > .main {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  > .right {
    max-height: 100%;
  }

  > .top {
    height: 100%;
    background-color: #232323;
  }
`;

export const MainLayout = ({ className = '', children, top, right, left = null, mode = '' }) => {
  return (
    <UiMainLayout className={`${mode} ${className}`}>
      <Box display="flex" className="top" justifyContent="center" gridArea="top">
        {top}
      </Box>
      {left && <Box gridArea="left">{left}</Box>}
      <Box className="main" gridArea="main">{children}</Box>
      <Box className="right" gridArea="right">
        {right}
      </Box>
    </UiMainLayout>
  );
};

MainLayout.propTypes = {
  className: PropTypes.string,
  top: PropTypes.node,
  left: PropTypes.node,
  right: PropTypes.node,
  mode: PropTypes.string,
  children: PropTypes.node,
};