import React, { useEffect, useState } from 'react';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { ReactComponent as VolumeFilled } from 'assets/newOneBoard/svg/volume_filled.svg';
import { ReactComponent as VolumeEmpty } from 'assets/newOneBoard/svg/volume_empty.svg';

const VolumeIndicator = ({ attendeeId, totalVolumeLevel = 12 }) => {
  const audioVideo = useAudioVideo();
  const [volumeLevel, setVolumeLevel] = useState(0);

  useEffect(() => {
    const volumeIndicatorCallback = (newAttendeeId, volume) => {
      if (newAttendeeId === attendeeId) {
        const scaledVolumeLevel = Math.round(volume * totalVolumeLevel);
        setVolumeLevel(scaledVolumeLevel);
      }
    };

    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, volumeIndicatorCallback);

    return () => {
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, volumeIndicatorCallback);
    };
  }, [audioVideo, attendeeId, totalVolumeLevel]);

  return (
    <>
      {Array.from({ length: totalVolumeLevel }, (_, i) =>
        i < volumeLevel ? <VolumeFilled key={i} /> : <VolumeEmpty key={i} />
      )}
    </>
  );
};

export default VolumeIndicator;
