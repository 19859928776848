const educations = {
  '01U': 'E',
  '01D': 'E',
  '01A': 'E',
  '02U': 'E',
  '02D': 'E',
  '02A': 'E',
  '03U': 'E',
  '03D': 'E',
  '03A': 'E',
  '04U': 'E',
  '04D': 'E',
  '04A': 'E',
  '05U': 'E',
  '05D': 'E',
  '05A': 'E',
  '06U': 'E',
  '06D': 'E',
  '06A': 'E',
  '07U': 'J',
  '07D': 'J',
  '07A': 'J',
  '08U': 'J',
  '08D': 'J',
  '08A': 'J',
  '09U': 'J',
  '09D': 'J',
  '09A': 'J',
  '10U': 'H',
  '10D': 'H',
  '10A': 'H',
  '11U': 'H',
  '11D': 'H',
  '11A': 'H',
  '12U': 'H',
  '12D': 'H',
  '12A': 'H',
};

export default educations;
