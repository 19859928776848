const BASE_URL = process.env.REACT_APP_ONEBOARD_API_URL;


export const getUserRecord = async ({
  courseId,
  userId
}) => {
  try {
    const response = await fetch(`${BASE_URL}/oneboard/course/userRecord?courseId=${courseId}&userId=${userId}`, {
      mode: 'cors',
    })
    .then(res => res.json());
    return response.data;
  } catch (error) {
    return {
      summary: {
        trophy: 0,
        inattentive: 0,
        raiseHand: 0,
        speakerSecond: 0
      },
      details: [
        {
          duration: 0,
          devices: {
            cameraName: '',
            cameraEnabled: true,
            micName: '',
            micEnabled: true,
            audioOutputName: ''
          },
          networkHealth: {
            green: 0,
            yellow: 0,
            red: 0
          }
        }
      ]
    };
  }
};

export const getMultiUserRecord = async ({
  courseId,
}) => {
  try {
    const response = await fetch(`${BASE_URL}/oneboard/course/multiUserRecord`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({
        courseId,
      }),
    })
    .then(res => res.json());
    return response.data;
  } catch (error) {
    return null;
  }
};

export const updateUserRecord = async ({
  courseId,
  userId,
  userName,
  role,
  userAgent,
  devices,
  duration = 0,
  speakerSecond,
  networkHealth = {
    green: 0,
    yellow: 0,
    red: 0
  }
}) => {
  try {
    const response = await fetch(`${BASE_URL}/oneboard/course/userRecord`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        courseId,
        userId,
        userName,
        role,
        data: {
          speakerSecond,
          duration,
          userAgent,
          devices,
          networkHealth
        }
      })
    }).then(res => res.json());
    return response.data;
  } catch (error) {
    return {
      courseId: ''
    };
  }
};