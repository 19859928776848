import React, { createContext, useContext } from 'react';
import { useNetwork } from './useNetwork';

export const StateContext = createContext();

export const useNetworkContext = () => useContext(StateContext);

export const NetworkProvider = ({ children }) => {
  const NetworkContext = useNetwork();

  return (
    <StateContext.Provider value={NetworkContext}>
      {children}
    </StateContext.Provider>
  );
};