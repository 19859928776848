import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '@oneboard/ui-components';
import {
  ContentShare,
  useContentShareState,
  useMeetingStatus,
  MeetingStatus,
  useMeetingManager,
  useLocalVideo,
  useToggleLocalMute,
  useRosterState,
  VideoGrid,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { useMeetingDispatch, useMeetingState, MeetingStates, LocalVideo, RemoteVideo } from '@oneboard/meeting';
import { Modal, Box } from '@oneboard/ui-components';
import { Roles, ClassType } from 'constants/index';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { updateUserRecord } from 'services/userRecord';
import { SyncObserverV2 } from './syncObserver';
import { GroupProvider } from 'providers/GroupProvider';
import { FooterStatusProvider } from 'providers/FooterStatusProvider';
import { VideoListProvider } from 'providers/VideoListProvider';
import { RewardsProvider } from 'providers/RewardsProvider';
import { SyncMultiple } from '../MainPage/student/SyncMultiple';
import useAutoStopRecordVideo from './hooks/useAutoStopRecordVideo';
import { StyledObserverPage, ModalTitle } from './ObserverPage.style';

const Observer = () => {
  const query = useQuery();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { sharingAttendeeId } = useContentShareState();
  const { roster } = useRosterState();
  const meetingState = useMeetingState();

  const attendees = Object.values(roster);
  const hasOtherRole = attendees.find((attendee) => attendee.role === Roles.Teacher || attendee.role === Roles.Advisor);
  const { attendeeId: selfAttendeeId } = meetingState.context;
  const size = attendees.filter((attendee) => attendee.role !== Roles.Observer).length || 0;

  return !hasOtherRole ? (
    <Modal width='860px' closeIcon={false}>
      <Box my={3} display='flex' justifyContent='center' alignItems='center'>
        <Loading />
      </Box>
      <ModalTitle>等待老師上課中</ModalTitle>
    </Modal>
  ) : !sharingAttendeeId ? (
    <VideoGrid size={size}>
      {query.role !== Roles.Observer && <LocalVideo attendeeId={selfAttendeeId} />}
      {attendees
        .filter((attendee) => attendee.chimeAttendeeId !== selfAttendeeId)
        .filter((attendee) => attendee.role !== Roles.Observer)
        .filter((_, index) => index < MAX_STUDENT_VIDEO_COUNT - 1)
        .map((attendee) => {
          const attendeeId = attendee.chimeAttendeeId;
          return <RemoteVideo key={attendeeId} attendeeId={attendeeId} tileId={attendeeIdToTileId[attendeeId]} />;
        })}
    </VideoGrid>
  ) : (
    <ContentShare />
  );
};

const createObserverPageByClassType = ({ classType, meetingId, isBreakout }) => {
  switch (classType) {
    case ClassType.SyncSingle:
      return <SyncObserverV2 meetingId={meetingId} />;
    case ClassType.SyncMultiple:
      return isBreakout ? <SyncMultiple /> : <SyncObserverV2 meetingId={meetingId} />;
    case ClassType.SyncStreaming:
      return <SyncObserverV2 meetingId={meetingId} />;
    case ClassType.Single:
    case ClassType.Group:
    case ClassType.Multiple:
    default:
      return <Observer />;
  }
};

export const ObserverPage = () => {
  const query = useQuery();
  const { meetingId: mainMeetingId, breakoutId } = useParams();
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const { muted, toggleMute } = useToggleLocalMute();
  const { toggleVideo, isVideoEnabled } = useLocalVideo();
  const meetingState = useMeetingState();
  const { context } = meetingState;
  const { roomId, userId, userName } = context;
  const { joinMeeting } = useMeetingDispatch();
  useAutoStopRecordVideo();

  const meetingId = breakoutId ? breakoutId : mainMeetingId;
  const { classType } = query;
  const isJoined = meetingState.matches(MeetingStates.Joined);

  const join = async () => {
    await joinMeeting({
      roomId: meetingId,
      userName: query.userName,
      role: query.role,
      userId: query.userId,
    });
  };

  const initUserRecord = () => {
    updateUserRecord({
      courseId: roomId,
      userId,
      userName,
      role: context.role,
    });
  };

  useEffect(() => {
    if (!meetingId) return;
    join();
  }, [meetingId]);

  useEffect(() => {
    if (!isJoined) return;
    meetingManager.start();

    if (isVideoEnabled) {
      toggleVideo();
    }

    if (!muted) {
      toggleMute();
    }
  }, [isJoined]);

  useEffect(() => {
    if (!isJoined) return;

    initUserRecord();
  }, [isJoined]);

  return (
    <GroupProvider>
      <FooterStatusProvider>
        <RewardsProvider>
          <VideoListProvider>
            <StyledObserverPage data-testid='ObserverPage'>
              {isJoined && meetingStatus === MeetingStatus.Succeeded ? (
                createObserverPageByClassType({ classType, meetingId, isBreakout: !!breakoutId })
              ) : (
                <Loading />
              )}
            </StyledObserverPage>
          </VideoListProvider>
        </RewardsProvider>
      </FooterStatusProvider>
    </GroupProvider>
  );
};

ObserverPage.propTypes = {};
