import { useEffect } from 'react';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';


export const useRealTimeSubscriber = (topic, handler) => {
  const audioVideo = useAudioVideo();

  useEffect(() => {
    if (!topic || !handler || !audioVideo) return;
    audioVideo.realtimeSubscribeToReceiveDataMessage(
      topic,
      (data) => handler(data.json())
    );
  }, [topic, handler]);

};