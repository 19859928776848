// 錯誤處理
const handleError = (error) => {
  console.log(
    'navigator.MediaDevices.getUserMedia error: ',
    error.message,
    error.name,
  )
}

// 讀取設備
const getEnumerateDevices = () => navigator.mediaDevices.enumerateDevices();

// 手動修改
const attachSinkId = (element, sinkId) => {
  if (typeof element.sinkId !== 'undefined') {
    element
      .setSinkId(sinkId)
      .then(() => {
        console.log(`Success, device attached: ${sinkId}`)
      })
      .catch((error) => {
        let errorMessage = error
        if (error.name === 'SecurityError') {
          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`
        }
        console.error(errorMessage)
      })
  } else {
    console.warn('Browser does not support output device selection.')
  }
}

export const useMediaDevices = () => {
  
  return {
    getEnumerateDevices,
    handleError,
    attachSinkId
  }
}