import styled from 'styled-components';

export const StyledSyncObjectVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const StyledVideoObjectBox = styled.div`
  position: absolute;
  top: 150px;
  left: 150px;
  /* top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`}; */
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  transform: ${({ x, y, rotate, scale }) => `translate(${x}px, ${y}px) rotate(${rotate}deg) scale(${scale})`};
  transition: .3s;
`;