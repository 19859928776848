import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { ResizableRect } from 'components';

import { StyledTeachingMaterialContainer, ControlWrap } from './TeachingMaterialContainer.style';

const Editable = (onClose) => {
  return {
    name: 'editable',
    props: {},
    events: {},
    render(moveable, React) {
      const { pos2 } = moveable.state;
      // use css for able
      const EditableViewer = moveable.useCSS('div', `
        {
            position: absolute;
            right: 4px;
            top: 4px;
            will-change: transform;
            transform-origin: 0px 0px;
            transform: translate(${pos2[0] + 32}px, ${pos2[1]}px)
        }
        .moveable-button {
            width: 24px;
            height: 24px;
            background: #33353A;
            border-radius: 4px;
            appearance: none;
            border: 0;
            color: white;
            font-weight: bold;
            cursor: pointer;
        }
        `);
      return <EditableViewer key="editable-viewer" className='moveable-editable'>
        <button className="moveable-button" onClick={onClose}>
          <Icon name="XmarkOutline" size="xxs" />
        </button>
      </EditableViewer>;
    }
  };
};


const ControlBox = forwardRef(({ children, className }, ref) => {
  return (
    <ControlWrap className={className} ref={ref}>
      {children}
    </ControlWrap>
  );
});

ControlBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export const TeachingMaterialContainer = ({
  className,
  audios = [],
  videos = [],
  iframeGroup = [],
  onClose
}) => {

  const totalLength = audios.length + videos.length + iframeGroup.length;

  return totalLength > 0 && (
    <StyledTeachingMaterialContainer>
      {
        iframeGroup.map(i => (
          <ResizableRect key={i.sources} ables={[Editable(() => onClose(i))]}>
            {(ref) => (
              <ControlBox key={i.sources} ref={ref} className="iframeBox">
                <iframe title={i.label} src={i.sources} frameborder="0" />
              </ControlBox>
            )}
          </ResizableRect>
        ))
      }
      {
        videos.map(video => (
          <ResizableRect key={video.sources} ables={[Editable(() => onClose(video))]}>
            {(ref) => (
              <ControlBox ref={ref} className="videoBox">
                <video src={video.sources} controls />
              </ControlBox>
            )}
          </ResizableRect>
        ))
      }
      {
        audios.map(audio => (
          <ResizableRect
            key={audio.sources}
            keepRatio={false}
            renderDirections={[false, false, false, false, false, false, 'e', 'w']}
            ables={[Editable(() => onClose(audio))]}>
            {(ref) => (
              <ControlBox ref={ref} className="audioBox">
                <audio src={audio.sources} controls />
              </ControlBox>
            )}
          </ResizableRect>
        ))
      }
    </StyledTeachingMaterialContainer>
  );
};


TeachingMaterialContainer.propTypes = {
  className: PropTypes.string,
  audios: PropTypes.array,
  videos: PropTypes.array,
  iframeGroup: PropTypes.array,
  onClose: PropTypes.func
};
