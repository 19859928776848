import styled from 'styled-components';

export const StyledWaitingAnimation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #161c27;
`;

export const AnimationWrap = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  background-image: url('/images/waitingBackground.png');
  background-size: cover;
  background-position-x: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Animation = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
  gap: 8px;
`;

export const Title = styled.div`
  z-index: 2;
  font-size: 32px;
  font-weight: 700;
  color: #212b36;
`;

export const Text = styled.div`
  z-index: 2;
  font-size: 24px;
  font-weight: 400;
  color: #454f5b;
`;

export const TextConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;
