const BASE_URL = process.env.REACT_APP_ONEBOARD_API_URL;

export const getAWSToken = (roomId) => {
  return fetch(BASE_URL + '/oneboard/fetch_upload_certificate', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      roomId
    })
  })
  .then(res => res.json())
  .then(res => res.data)
  .catch(err => console.error(err));
};