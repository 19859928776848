import styled from 'styled-components';

export const StyledStudentMainPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ToolBtn = styled.div`
  /* position: relative; */
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0 8px;
  font-size: 0;
  color: #D5D7DE;
  cursor: pointer;
  background-color: ${({ active }) => active ? '#EC7963' : 'transparent'};
  border: none;

  &:hover {
    background-color: #33353A;
  }

  >.settingPopoverButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #D5D7DE;
  }
`;

export const UiChatRoomLayout = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(${({ active }) => active ? '0' : '280px'});
  width: 280px;
  height: 100%;
  z-index: 10;
  padding: 0 8px 24px;
  background-color: #161C27;
  transition: .3s;
`;

export const ModalTitle = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
`;

export const StyledNameBox = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 64px;
  cursor: default;
`;