import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { useMeetingState, MeetingStates, useMeetingDispatch } from '@oneboard/meeting';
import { useWhiteboard } from '@oneboard/whiteboard';

export const JoinMeetingRedirect = ({ children }) => {
  const location = useLocation();
  const state = useMeetingState();
  const { meetingId } = useParams();
  const { joinMeeting } = useMeetingDispatch();
  const query = useQuery();
  const history = useHistory();
  const meetingState = useMeetingState();
  const isJoined = meetingState.matches(MeetingStates.Joined);
  const meetingManager = useMeetingManager();
  const { joinWhiteboard } = useWhiteboard();

  const join = () => {
    joinMeeting({
      roomId: meetingId,
      userName: query.userName,
      role: query.role,
      userId: query.userId
    });
  };

  const meetingStartHandler = async () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('isBreakoutBack');
    await joinWhiteboard({
      meetingId,
      role: query.role,
      userName: query.userName,
      userId: query.userId
    });
    meetingManager.start().then(() => {
      history.push({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`
      });
    });
  };

  useEffect(() => {
    if (state.matches(MeetingStates.Idle) && !query.isBreakoutBack) {
      history.push(`${location.pathname}/setup${location.search}`);
    }
    if (query.isBreakoutBack) {
      join();
    }
  }, [location]);

  useEffect(() => {
    if (!isJoined) return;
    if (query.isBreakoutBack) {
      meetingStartHandler();
    }
  }, [isJoined]);

  return <>{children}</>;
};