import { useState } from 'react';

export const useRewards = () => {
  const [rewardAnimateId, setRewardAnimateId] = useState(null);

  const addRewardAnimateId = ({ attendeeId }) => setRewardAnimateId(attendeeId);

  const clearRewardAnimateId = () => setRewardAnimateId(null);

  return {
    addRewardAnimateId,
    clearRewardAnimateId,
    rewardAnimateId
  };
};