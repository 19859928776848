import styled from 'styled-components';

export const StyledGroupPage = styled.div`
  height: 100%;

  .auditListLayout {
    position: absolute;
    top: 0;
    right: 32px;
    z-index:100;
  }
`;

export const ControlsGroup = styled.div`
    position: absolute;
    right: 8px;
    bottom: 30px;
    display: inline-flex;
    align-items: center;

  > .muteAllButton,
  > .settingPopoverButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #232323;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-left: 8px;
    cursor: pointer;
    color: #fff;
  }

  > .muteAllButton{
    width: 100px;
    padding: 12px 18px;
    border-radius: 24px;
    font-size: 16px;
  }
`;

export const OnebookFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0px;
  z-index: 10;
`;