import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalVideo, useToggleLocalMute, useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import VideoContainer from '../common/VideoContainer';
import VideoWrapper from '../common/VideoWrapper';
import VideoContentContainer from '../common/VideoContentContainer';
import IconWrapper from '../../common/IconWrapper';
import StyledImage from '../../common/StyledImage';
import UserName from '../common/UserName';
import NotOpen from '../common/NotOpen';
import { MicroBlack } from '../../common/StyledIcon';
import VolumeIndicator from '../common/VolumeIndicator';
import VolumeContainer from '../common/VolumeContainer';
import Icon from '@onedesign/icon';
import TeacherIcon from 'assets/newOneBoard/images/teacher_icon.png';
import MediaMetricsV2 from 'components/MediaMetricsV2';
import { LocalVideoV2, useMeetingState } from '@oneboard/meeting';
import { useInsertPicture } from 'providers/InsertPictureProvider';
import { Insert_Picture_State, Insert_Picture_Event } from 'machines/InsertPictureMachine';

export default function TeacherVideo({ userType, teacherName, isSettingModalOpen }) {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const audioVideo = useAudioVideo();

  const { state: insertPictureState, send: insertPictureSend } = useInsertPicture();
  const isEnd = insertPictureState.matches(Insert_Picture_State.End);
  const { context } = useMeetingState();
  const { videoIsMirroring } = context;

  useEffect(() => {
    if (isEnd && !isVideoEnabled) {
      toggleVideo();
      insertPictureSend({
        type: Insert_Picture_Event.Idle,
      });
    }
  }, [isEnd, isVideoEnabled]);

  return (
    <VideoContainer>
      {isSettingModalOpen ? (
        <VideoWrapper>
          <MediaMetricsV2 />
          <NotOpen />
        </VideoWrapper>
      ) : (
        <VideoWrapper>
          <MediaMetricsV2 />
          {isVideoEnabled ? <LocalVideoV2 videoIsMirroring={videoIsMirroring} /> : <NotOpen />}
        </VideoWrapper>
      )}
      <VideoContentContainer>
        <StyledImage src={TeacherIcon} alt='name icon' width={28} height={28} />
        <UserName>{teacherName}</UserName>
        <IconWrapper onClick={toggleVideo} isEnabled={isVideoEnabled} userType={userType}>
          <Icon name={isVideoEnabled ? 'VideoSolid' : 'VideoSlashSolid'} size='xxs' color='#FFFFFF' />
        </IconWrapper>
        <IconWrapper onClick={toggleMute} isEnabled={!muted} userType={userType}>
          <Icon name={muted ? 'MicrophoneAltSlashSolid' : 'MicrophoneAltSolid'} size='xs' color='#FFFFFF' />
        </IconWrapper>
      </VideoContentContainer>
      <VolumeContainer>
        <MicroBlack />
        <VolumeIndicator
          attendeeId={audioVideo.audioVideoController.configuration.credentials.attendeeId}
          totalVolumeLevel={10}
        />
      </VolumeContainer>
    </VideoContainer>
  );
}

TeacherVideo.propTypes = {
  userType: PropTypes.string.isRequired,
  teacherName: PropTypes.string.isRequired,
  isSettingModalOpen: PropTypes.bool.isRequired,
};
