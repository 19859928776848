import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';

const HeaderTab = styled.div`
  display: flex;
  height: 40px;
  max-width: 280px;
  padding: 8px 8px 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 16px 16px 0px 0px;
  color: ${(props) => (props.isActive ? '#637381' : '#FFFFFF')};
  background-color: ${(props) => (props.isActive ? '#f9fafb' : 'rgba(255, 255, 255, 0.12)')};
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background-color: ${(props) => (!props.isActive ? 'rgba(255, 255, 255, 0.48)' : '#f9fafb')};
    color: #637381;
  }
`;

const TabText = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
`;

function Tab({ children, isActive, onClick, onRemove }) {
  return (
    <HeaderTab
      isActive={isActive}
      onClick={onClick}
    >
      <TabText>{children}</TabText>
      <CloseIconContainer onClick={onRemove}>
        <Icon name='XmarkOutline' size='xxxs' />
      </CloseIconContainer>
    </HeaderTab>
  );
}

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Tab;
