const volumns = {
  '01U': ['B01'],
  '01D': ['B02'],
  '01A': ['B01', 'B02'],
  '02U': ['B03'],
  '02D': ['B04'],
  '02A': ['B03', 'B04'],
  '03U': ['B05'],
  '03D': ['B06'],
  '03A': ['B05', 'B06'],
  '04U': ['B07'],
  '04D': ['B08'],
  '04A': ['B07', 'B08'],
  '05U': ['B09'],
  '05D': ['B10'],
  '05A': ['B09', 'B10'],
  '06U': ['B11'],
  '06D': ['B12'],
  '06A': ['B11', 'B12'],
  '07U': ['B01'],
  '07D': ['B02'],
  '07A': ['B01', 'B02'],
  '08U': ['B03'],
  '08D': ['B04'],
  '08A': ['B03', 'B04'],
  '09U': ['B05'],
  '09D': ['B06'],
  '09A': ['B05', 'B06'],
  '10U': ['B01'],
  '10D': ['B02'],
  '10A': ['B01', 'B02'],
  '11U': ['B03'],
  '11D': ['B04'],
  '11A': ['B03', 'B04'],
  '12U': ['B05'],
  '12D': ['B06'],
  '12A': ['B05', 'B06'],
  A12: ['B01', 'B02', 'B03', 'B04'],
  A34: ['B05', 'B06', 'B07', 'B08'],
  A56: ['B09', 'B10', 'B11', 'B12'],
  A14: ['B01', 'B02', 'B03', 'B04', 'B05', 'B06', 'B07', 'B08'],
  A36: ['B05', 'B06', 'B07', 'B08', 'B09', 'B10', 'B11', 'B12'],
  A16: ['B01', 'B02', 'B03', 'B04', 'B05', 'B06', 'B07', 'B08', 'B09', 'B10', 'B11', 'B12'],
  A79: ['B01', 'B02', 'B03', 'B04', 'B05', 'B06'],
  A89: ['B03', 'B04', 'B05', 'B06'],
  AXZ: ['B01', 'B02', 'B03', 'B04', 'B05', 'B06'],
  AYZ: ['B03', 'B04', 'B05', 'B06'],
};

export default volumns;
