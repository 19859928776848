import { stringify } from 'query-string';
import { naniToken } from 'utils/login';

const ONELINK_URL = process.env.REACT_APP_ONELINK_API_DOMAIN;
const ONELINK2_URL = process.env.REACT_APP_ONELINK2_API_DOMAIN;

/**
 * @param {object} param
 * @param {string} param.organizationId
 * @returns {object}
 */
export const getLinkResourcesList = async ({ organizationId }) => {
  const queryParams = {
    skip: 0,
    limit: 100,
    status: 'ready'
  };
  const querystring = await stringify(queryParams);

  return fetch(`${ONELINK_URL}/organizations/${organizationId}/resources?${querystring}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
      'Authorization': naniToken
    },
  }).then(res => res.json());
};

/**
 * @param {object} param
 * @param {string} param.organizationId
 * @param {string} param.courseId
 * @returns {object}
 */
export const getMmsTeacherResourcesList = async ({ organizationId, courseId }) => {
  return fetch(`${ONELINK2_URL}/mmssessions/${organizationId}/course/${courseId}/linkResource`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
      'Authorization': naniToken
    },
  }).then(res => res.json());
};
