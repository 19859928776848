import styled from 'styled-components';

export const StyledWhiteboardAttendeeList = styled.div`
  width: 300px;
  max-height: 50vh;
  overflow-y: auto;
  > * {
    user-select: none;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #EC7963;
      border-color: #EC7963 !important;
      color: #fff;

      &:after {
        border-color: #fff;
      }
    }
  }

  .ant-checkbox-indeterminate{
      .ant-checkbox-inner {
          background-color: #EC7963;
          border-color: #EC7963;
          color: #fff;

          &:after {
            width: 8px;
            height: 2px;
            background-color: #fff;
          }
        }
    }

  .ant-divider-horizontal{
      margin: 8px 0;
  }

  .group {

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;

      .ant-checkbox-group-item {
        padding: 8px 0;
      }

    }
  }
`;
