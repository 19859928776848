import styled from 'styled-components';

export const StyledNotFoundPage = styled.div.attrs(props => ({
  className: props.className
}))`
  background-color: #232323;
  min-height: 100%;
`;

export const StyledHead = styled.div`
  padding: 36px 40px;
  .logo{
    width: 160px;
    height: 48px;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .notFoundPic{
    height: 45vh;
    >img { 
      height: 100%;
    }
  }

  .title {
    margin-top: 64px;
    font-size: 40px;
    color: #fff;
    font-weight: bold;
  }

  .desc {
    margin-top: 40px;
    text-align: center;
    color: #A1A4B1;
    font-size: 20px;
    line-height: 30px;
  }

  .highlight {
    color: #EC7963;
    &:hover {
      color: #EE8773;
    }
  }
`;
