import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useRemoteVideoTileState,
  useContentShareControls,
  useContentShareState,
  useRosterState,
  useAttendeeStatus,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import { VolumeStatusMeter } from 'components';
import { useQuery } from 'utils/hooks/useQuery';
import { Box } from '@oneboard/ui-components';
import {
  LocalVideo,
  RemoteVideo,
  useMeetingState,
  useMeetingDispatch,
  VideoControlsPanel,
  Reward,
} from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { StyledVideoList, UiInfoPanel } from './VideoList.style';
import { useCallback } from 'react';
import { Roles } from 'constants/index';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useRewardsContext } from 'providers/RewardsProvider';
import { useGroupContext } from 'providers/GroupProvider';

export const StudentControlsPanel = ({ attendeeId, onMute }) => {
  const {
    usersMuteHandler,
  } = useGroupContext();
  const { muted } = useAttendeeStatus(attendeeId);

  const muteHandler = () => {
    onMute(muted);
    usersMuteHandler({
      [attendeeId]: !muted
    });
  };

  return (
    <VideoControlsPanel className="videoControlsPanel">
      <VolumeStatusMeter attendeeId={attendeeId} />
      <Box display="flex" alignItems="center" justifyContent="between" onClick={muteHandler}>
        {
          muted ?
            <Icon name="MicrophoneAltSlashSolid" /> :
            <Icon name="MicrophoneAltSolid" />
        }
      </Box>
    </VideoControlsPanel>
  );
};

StudentControlsPanel.propTypes = {
  attendeeId: PropTypes.string,
  onMute: PropTypes.func,
};

const StudentInfoPanel = ({ roster, attendeeId, onReward, userId }) => {
  const state = useMeetingState();
  const { context } = state;

  return (
    <UiInfoPanel>
      <div className="name">
        {roster[attendeeId]?.name}
      </div>
      <Box className="reward" display="flex" alignItems="'center" onClick={() => onReward({ attendeeId, userId })}>
        <Icon name="TrophySolid" />
        <Box ml="5px" className="rewardNumber">{context.rewards[userId] || 0}</Box>
      </Box>
    </UiInfoPanel>
  );
};

StudentInfoPanel.propTypes = {
  roster: PropTypes.object,
  attendeeId: PropTypes.string,
  onReward: PropTypes.func,
  userId: PropTypes.string,
};

export const TeacherControlsPanel = ({ attendeeId }) => {
  const {
    usersMuteHandler,
  } = useGroupContext();
  const { muted, toggleMute } = useToggleLocalMute();

  const muteHandler = () => {
    toggleMute();
    usersMuteHandler({
      [attendeeId]: !muted
    });
  };

  return (
    <VideoControlsPanel className="videoControlsPanel">
      <VolumeStatusMeter attendeeId={attendeeId} />
      <Box display="flex" alignItems="'center" onClick={toggleMute}>
        {
          muted ?
            <Icon name="MicrophoneAltSlashSolid" /> :
            <Icon name="MicrophoneAltSolid" />
        }
      </Box>
    </VideoControlsPanel>
  );
};

TeacherControlsPanel.propTypes = {
  attendeeId: PropTypes.string
};

const TeacherInfoPanel = ({ roster, selfAttendeeId }) => {

  return (
    <UiInfoPanel>
      <div className="name">
        {roster[selfAttendeeId]?.name}
      </div>
      <div className="trophy"></div>
    </UiInfoPanel>
  );
};

TeacherInfoPanel.propTypes = {
  roster: PropTypes.object,
  selfAttendeeId: PropTypes.string
};

const VideoBox = ({ attendeeId, children }) => {
  const { openVideoAttendee, closeVideoAttendee } = useMeetingDispatch();
  const {
    videoEnabled,
  } = useAttendeeStatus(attendeeId);

  useEffect(() => {
    if (!videoEnabled ) {
      openVideoAttendee({ attendeeId });
    }
    return function cleanup() {
      closeVideoAttendee({ attendeeId });
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
};

VideoBox.propTypes = {
  attendeeId: PropTypes.string,
  children: PropTypes.node,
};

export const VideoList = ({ className }) => {
  const { videoList: videoStudentList } = useVideoListContext();
  const {
    addRewardAnimateId,
    clearRewardAnimateId,
    rewardAnimateId
  } = useRewardsContext();
  const { roster } = useRosterState();

  const { isLocalUserSharing } = useContentShareState();
  const { toggleContentShare } = useContentShareControls();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { context } = useMeetingState();
  const { attendeeId: selfAttendeeId, videoIsMirroring, stagedAttendeeIds, raisedHandAttendeeIds } = context;
  const { stageAttendee, muteAttendee, unMuteAttendee, sendReward, updateReward } = useMeetingDispatch();

  const [rewardAnimationState, setRewardAnimationState] = useState(false);

  const query = useQuery();

  useEffect(() => {
    if (!isLocalUserSharing && query.role === Roles.Teacher) {
      toggleContentShare();
    }
  }, []);

  const videoClickHandler = (attendeeId) => {
    stageAttendee({ attendeeId });
    unMuteAttendee({ attendeeId });
  };

  const rewardHandler = ({ attendeeId, userId }) => {
    if (rewardAnimateId !== null) return;
    addRewardAnimateId({ attendeeId });
    sendReward({ attendeeId, userId });
    updateReward({ userId });
  };

  const rewardCompletedHandler = useCallback(() => {
    clearRewardAnimateId();
  }, []);

  const muteHandler = ({ attendeeId, isMuted }) => {
    const isOnstage = stagedAttendeeIds.includes(attendeeId);
    if (isOnstage) return;
    isMuted ? unMuteAttendee({ attendeeId }) : muteAttendee({ attendeeId });
  };

  useEffect(() => {
    const rewardInStage = videoStudentList.findIndex(video => video.chimeAttendeeId === rewardAnimateId) < MAX_STUDENT_VIDEO_COUNT;

    if (rewardInStage) {
      setRewardAnimationState(true);
    } else {
      setRewardAnimationState(false);
    }

  }, [rewardAnimateId]);

  return (
    <StyledVideoList className={className} data-testid="VideoList">
      <Box
        className="gridBox"
        height="100%"
        display="grid"
        gridGap=".5rem"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gridTemplateRows="100%"
        gridAutoFlow="column"
      >
        <Box className="localVideoBox">
          <div className="insideVideo">
            <LocalVideo
              className={!videoIsMirroring ? 'isFlip' : ''}
              onClick={() => videoClickHandler(selfAttendeeId)}
              nameplate={roster[selfAttendeeId]?.name}
              attendeeId={selfAttendeeId}
              isShowNameplate={stagedAttendeeIds.includes(selfAttendeeId) && roster[selfAttendeeId]}
            />
            <TeacherControlsPanel attendeeId={selfAttendeeId} />
          </div>
          <TeacherInfoPanel roster={roster} selfAttendeeId={selfAttendeeId} />
        </Box>
        {
          videoStudentList
            .filter((attendee) => attendee.chimeAttendeeId !== selfAttendeeId)
            .filter((tile, index) => index < MAX_STUDENT_VIDEO_COUNT)
            .map((attendee, index) => {
              const userId = attendee?.externalUserId;
              const attendeeId = attendee.chimeAttendeeId;
              const tileId = attendeeIdToTileId[attendeeId];
              return (
                <VideoBox attendeeId={attendeeId} key={attendeeId}>
                  <Box width="100%" height="100%" className="remoteVideoBox" key={attendeeId}>
                    <div className="insideVideo">
                      {
                        (rewardAnimationState && rewardAnimateId === attendeeId) && (
                          <Reward
                            rewardType="Trophy"
                            onComplete={rewardCompletedHandler} />
                        )
                      }
                      <RemoteVideo
                        attendeeId={attendeeId}
                        tileId={tileId}
                        onClick={() => videoClickHandler(attendeeId)}
                        isShowNameplate={stagedAttendeeIds.includes(attendeeId)}
                      />
                      <StudentControlsPanel
                        onMute={(isMuted) => muteHandler({ attendeeId, isMuted })}
                        attendeeId={attendeeId}
                      />
                      {
                        raisedHandAttendeeIds.includes(attendeeId) && (
                          <div className="raiseHand" onClick={() => videoClickHandler(attendeeId)}>
                            <Icon name="RaisehandSolid" size='xxs' />
                          </div>
                        )
                      }
                    </div>
                    <StudentInfoPanel roster={roster} attendeeId={attendeeId} userId={userId} onReward={rewardHandler} />
                  </Box>
                </VideoBox>
              );
            })
        }
      </Box>
    </StyledVideoList>
  );
};

VideoList.propTypes = {
  className: PropTypes.string,
};