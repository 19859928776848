import {
  createContext,
  useContext,
} from 'react';
import { useInterpret } from '@xstate/react';
import { useActor } from '@xstate/react';
import recordVideoMachine from 'machines/RecordVideoMachine';

const RecordVideoContext = createContext({});

const RecordVideoProvider = ({ children }) => {
  const recordVideoService = useInterpret(recordVideoMachine);

  return (
    <RecordVideoContext.Provider value={{ recordVideoService }}>
      {children}
    </RecordVideoContext.Provider>
  );
};

const useRecordVideo = () => {
  const globalServices = useContext(RecordVideoContext);
  const [state] = useActor(globalServices.recordVideoService);
  const { send } = globalServices.recordVideoService;

  return { state, send };
};

export { RecordVideoProvider, useRecordVideo };