import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAudioVideo, useVideoInputs, useMeetingManager, useLogger } from 'amazon-chime-sdk-component-library-react';

const PreviewVideoContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(-1, 1);
  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default function PreviewVideo() {
  const logger = useLogger();
  const audioVideo = useAudioVideo();
  const { selectedDevice } = useVideoInputs();
  const videoEl = useRef(null);
  const meetingManager = useMeetingManager();

  useEffect(() => {
    const videoElement = videoEl.current;
    return () => {
      if (videoElement) {
        audioVideo?.stopVideoPreviewForVideoInput(videoElement);
      }
    };
  }, [audioVideo]);

  useEffect(() => {
    async function startPreview() {
      if (!audioVideo || !selectedDevice || !videoEl.current) {
        return;
      }

      try {
        await meetingManager.startVideoInputDevice(selectedDevice);
        audioVideo.startVideoPreviewForVideoInput(videoEl.current);
      } catch (error) {
        logger.error('Failed to start video preview');
      }
    }
    startPreview();
  }, [audioVideo, selectedDevice]);

  return (
    <PreviewVideoContainer>
      <video ref={videoEl} autoPlay playsInline />
    </PreviewVideoContainer>
  );
}
