import styled from 'styled-components';

const TestButton = styled.button`
  display: flex;
  width: 104px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  margin: 10px 0;
  font-weight: 700;
  border: 1px solid ${({ isPlaying }) => (isPlaying ? '#919eab' : '#f5bbb0')};
  color: ${({ isPlaying }) => (isPlaying ? '#919eab' : '#ec7963')};
  opacity: ${({ isPlaying }) => (isPlaying ? '0.6' : '1')};
  cursor: ${({ isPlaying }) => (isPlaying ? 'not-allowed' : 'pointer')};
`;

export default TestButton;
