import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  border: 0;
  background: ${(props) => (props.isEnabled ? '#454f5b' : '#FF5630')};
  margin: 0;
  flex: 0 0 24px;
  cursor: ${(props) => (props.userType === 'teacher' || props.userType === 'advisor' ? 'pointer' : 'not-allowed')};
`;

export default IconWrapper;
