import { useCallback } from 'react';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch, MeetingActionTypes, useMeetingState } from '@oneboard/meeting';

export const useRealtimeActions = () => {
  const audioVideo = useAudioVideo();
  const {
    raiseHand,
    lowerHand,
    updateAppStaged
  } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { stagedAttendeeIds } = meetingState.context;

  const receiveRaiseHandAction = useCallback(() => {
    if (!audioVideo) return;

    const callback = dataMessage => {
      const data = dataMessage.json();
      const { senderAttendeeId } = data;

      raiseHand({ attendeeId: senderAttendeeId });
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      MeetingActionTypes.RaiseHand,
      callback
    );
  }, [audioVideo]);

  const receiveLowerHandAction = useCallback(() => {
    if (!audioVideo) return;

    const callback = dataMessage => {
      const data = dataMessage.json();
      const { senderAttendeeId } = data;

      lowerHand({ attendeeId: senderAttendeeId });
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      MeetingActionTypes.LowerHand,
      callback
    );
  }, [audioVideo]);

  const receiveAppFinishInit = useCallback(() => {
    if (!audioVideo) return;

    const callback = () => {
      updateAppStaged({ stagedAttendeeIds });
    };

    audioVideo.realtimeSubscribeToReceiveDataMessage(
      'CanNotificationStage',
      callback
    );
  }, [audioVideo, stagedAttendeeIds]);

  return {
    receiveRaiseHandAction,
    receiveLowerHandAction,
    receiveAppFinishInit
  };
};

