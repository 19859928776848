import styled from 'styled-components';

export const StyledVideoList = styled.div.attrs(props => ({
  className: props.className
}))`
  width: 100%;
  height: 100%;

  > .gridBox{
    > .localVideoBox, > .remoteVideoBox {
        position: relative;
        cursor: pointer;
        .tileBox{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        > .insideVideo {
          position: relative;
          height: calc(100% - 32px);
          display: flex;
          align-items: center;
          justify-content: center;

          > .videoControlsPanel {
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            background: transparent;
              > * {
                color: #fff;
              }
            }

          > .raiseHand {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 4px;
            right: 4px;
            color: #fff;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #EC7963;
          }
        }
    }

  }
`;

export const UiInfoPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 8px 8px 8px 16px;
  background-color: #33353A;
  
  > .name {
    color: #fff;
    font-size: 14px;
    line-height: 16px;
  }

  > .reward{
    color: #F9C74F;
    
    .rewardNumber{
      color: #fff;
    }
  }
`;

export const StyledNameBox = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 64px;
  cursor: default;
`;