import React from 'react';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import RemoteVideo from './RemoteVideo';
import StudentVideo from './StudentVideo';
import ChatRoomButton from 'components/NewOneBoard/ChatRoom/ChatRoomButton';
import VideoPanelContainer from 'components/NewOneBoard/VideoInfo/common/VideoPanelContainer';
import ButtonContainer from 'components/NewOneBoard/VideoInfo/common/ButtonContainer';

export function StudentVideoPanel({ isSettingModalOpen }) {
  const { roster } = useRosterState();
  const student = Object.values(roster).find((attendee) => attendee.role === 'student');
  const teacher = Object.values(roster).find((attendee) => attendee.role === 'teacher');
  const teacherId = teacher?.chimeAttendeeId;

  if (!student) {
    return null;
  }

  return (
    <VideoPanelContainer>
      <div>
        {teacherId && <RemoteVideo attendeeId={teacherId} teacherName={teacher?.name} />}
        {student && <StudentVideo studentName={student.name} isSettingModalOpen={isSettingModalOpen} />}
      </div>
      <ButtonContainer>
        <ChatRoomButton />
      </ButtonContainer>
    </VideoPanelContainer>
  );
}
