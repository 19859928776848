import React, { createContext, useContext, useState } from 'react';

const FooterContext = createContext();

const useFooterStatus = () => useContext(FooterContext);

function FooterStatusProvider({ children }) {
  const [isFooterOpen, setIsFooterOpen] = useState(true);

  return <FooterContext.Provider value={{ isFooterOpen, setIsFooterOpen }}>{children}</FooterContext.Provider>;
}


export { FooterStatusProvider, useFooterStatus };