import React, { useCallback, useState } from 'react';
import {
  useRemoteVideoTileState,
  useRosterState,
  useAttendeeStatus,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import PropTypes from 'prop-types';
import { Box, Popover } from '@oneboard/ui-components';
import { Roles, ClassType } from 'constants/index';
import {
  RemoteVideo,
  VideoControlsPanel,
  useMeetingState,
  Reward,
  useMeetingDispatch,
} from '@oneboard/meeting';
import { useQuery } from 'utils/hooks/useQuery';
import { VideoPopover } from 'components';
import { VideoBox } from 'containers';
import Icon from '@onedesign/icon';
import { useGroupContext } from 'providers/GroupProvider';
import { useToolboxAuth } from 'utils/hooks/useToolboxAuth';
import { StyledObserverVideoContainer, PopoverIcon } from './ObserverVideoContainer.style';

export const TeacherControlsPanel = ({ name }) => {
  const { muted } = useToggleLocalMute();

  return (
    <VideoControlsPanel className="videoControlsPanel">
      <Box display="flex">
        <div className="role">{name}</div>
        <Box display="flex" alignItems="center" ml='8px'>
          {
            muted ?
              <Icon name="MicrophoneAltSlashSolid" /> :
              <Icon name="MicrophoneAltSolid" />
          }
        </Box>
      </Box>
    </VideoControlsPanel>
  );
};

TeacherControlsPanel.propTypes = {
  name: PropTypes.string
};

const StudentVideoPopoverContainer = ({ attendeeId, onReward, userId }) => {
  const {
    usersMuteHandler,
  } = useGroupContext();
  const { muted, videoEnabled } = useAttendeeStatus(attendeeId);
  const {
    openVideoAttendee,
    closeVideoAttendee,
    muteAttendee,
    unMuteAttendee,
    sendReward,
  } = useMeetingDispatch();
  const query = useQuery();
  const classType = query?.classType || null;
  const { queryToolboxAuthById, toggleToolboxAuth } = useToolboxAuth();
  const isEnableToolbox = queryToolboxAuthById({ attendeeId });

  const muteHandler = () => {
    muted ? unMuteAttendee({ attendeeId }) : muteAttendee({ attendeeId });

    usersMuteHandler({
      [attendeeId]: !muted
    });
  };

  const videoEnabledHandler = () => {
    videoEnabled ? closeVideoAttendee({ attendeeId }) : openVideoAttendee({ attendeeId });
  };
  const rewardHandler = useCallback((attendeeId) => {
    sendReward({ attendeeId, userId });
    onReward();
  }, []);

  return (
    <VideoPopover placement="left">
      <PopoverIcon onClick={videoEnabledHandler}>
        {
          videoEnabled ? <Icon name="VideoSolid" /> :
            <Icon name="VideoSlashSolid" color="#F94144" />
        }
      </PopoverIcon>
      <PopoverIcon onClick={muteHandler}>
        {
          muted ?
            <Icon name="MicrophoneAltSlashSolid" color="#F94144" /> :
            <Icon name="MicrophoneAltSolid" />
        }
      </PopoverIcon>
      {classType !== ClassType.Single && <PopoverIcon onClick={() => toggleToolboxAuth({ attendeeId })}>
        {
          isEnableToolbox ? <Icon name="ChalkboardOutline" /> :
            <Icon name="ChalkboardSlashSolid" />
        }
      </PopoverIcon>}
      <PopoverIcon className="reward">
        <Icon name="TrophySolid" onClick={() => rewardHandler(attendeeId)} />
      </PopoverIcon>
    </VideoPopover>
  );
};

StudentVideoPopoverContainer.propTypes = {
  attendeeId: PropTypes.string,
  onReward: PropTypes.func,
  userId: PropTypes.string,
};

export const ObserverVideoContainer = ({ className }) => {
  const query = useQuery();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const meetingState = useMeetingState();

  const [isRewardActive, setRewardActive] = useState(false);

  const rewardHandler = useCallback(() => {
    setRewardActive(true);
  }, []);

  const rewardCompletedHandler = useCallback(() => {
    setRewardActive(false);
  }, []);

  return <StyledObserverVideoContainer className={className} data-testid="ObserverVideoContainer">
    {attendees
      .filter(attendee => attendee.role === Roles.Teacher)
      .map(attendee => {
        const attendeeId = attendee.chimeAttendeeId;
        const tileId = attendeeIdToTileId[attendeeId];
        return (
          <Box position="relative" height="180px" px={3} key={attendeeId}>
            <VideoBox
              attendeeId={attendeeId}
              key={attendeeId}>
              <RemoteVideo
                attendeeId={attendeeId}
                tileId={tileId}
              />
            </VideoBox>
          </Box>
        );
      })
    }
    {!(query.role === Roles.Observer && query.classType === ClassType.SyncMultiple) &&
      attendees
        .filter(attendee => attendee.role === Roles.Student)
        .map(attendee => {
          const userId = attendee?.externalUserId;
          const attendeeId = attendee.chimeAttendeeId;
          const tileId = attendeeIdToTileId[attendeeId];
          return (
            <Popover
              placement="left"
              className="customPopover"
              key={attendeeId}
              content={
                <StudentVideoPopoverContainer
                  onReward={rewardHandler}
                  attendeeId={attendeeId}
                  userId={userId}
                />
              }
            >
              <Box position="relative" height="180px" mt={3} px={3}>
                <VideoBox
                  userId={userId}
                  attendeeId={attendeeId}
                  isStudent>
                  <RemoteVideo
                    attendeeId={attendeeId}
                    tileId={tileId}
                  />
                </VideoBox>
              </Box>
            </Popover>
          );
        })
    }
    {
      isRewardActive && (
        <Reward
          rewardType="Trophy"
          onComplete={rewardCompletedHandler}
        />
      )
    }
  </StyledObserverVideoContainer>;
};

ObserverVideoContainer.propTypes = {
  className: PropTypes.string,
};