import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledSyncMainBlock } from './SyncMainBlock.style';

// mainLayout 的 main 區塊，main 的 ref 綁定 notification

export const SyncMainBlock = forwardRef(({ className, children }, ref) => {
  return (
    <StyledSyncMainBlock className={className} data-testid="SyncMainBlock" ref={ref}>
      {children}
    </StyledSyncMainBlock>
  );
});

SyncMainBlock.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};