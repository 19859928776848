import React from 'react';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';

export const OpenCloseButton = () => {
  const { isFooterOpen, setIsFooterOpen } = useFooterStatus();
  const handleFooterOpen = () => {
    setIsFooterOpen(!isFooterOpen);
  };

  return (
    <ToolButton
      icon={<Icon name={isFooterOpen ? 'ChevronDownOutline' : 'ChevronUpOutline'} size='md' color='#637381' />}
      onClick={handleFooterOpen}
    >
      {isFooterOpen && '收合'}
    </ToolButton>
  );
};
