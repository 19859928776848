import { useState, useEffect, useMemo, useRef } from 'react';
import { useRosterState, useAudioVideo, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { DefaultModality } from 'amazon-chime-sdk-js';
import {
  useMeetingState,
  useMeetingDispatch
} from '@oneboard/meeting';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { useInterval } from 'ahooks';
import { Roles } from 'constants/index';

const useOrderRosterState = () => {
  const { addOrderRosters, removeOrderRosters, updateOrderRosters } = useMeetingDispatch();
  const audioVideo = useAudioVideo();
  const meetingManager = useMeetingManager();
  const { context: { stagedAttendeeIds, orderRosters, role } } = useMeetingState();
  const rosterRef = useRef({});

  // 下台id順序放最後
  const unstagedHandler = (attendeeId) => {
    const unstagedArr = orderRosters.filter(roster => roster.chimeAttendeeId === attendeeId);
    const baseRosters = orderRosters.filter(roster => roster.chimeAttendeeId !== attendeeId);
    updateOrderRosters({ orderRosters: [...baseRosters, ...unstagedArr] });
  };

  // 輪播 handler
  const carouselRosterHandler = () => {
    const unstaged = orderRosters.filter(attendee => !stagedAttendeeIds.includes(attendee.chimeAttendeeId));
    const moveAttendee = unstaged.filter((_, index) => index === 0);
    const nextUnstaged = unstaged.filter((_, index) => index !== 0);
    const staged = orderRosters.filter(attendee => stagedAttendeeIds.includes(attendee.chimeAttendeeId));

    const _roster = { orderRosters: [...staged, ...nextUnstaged, ...moveAttendee] };
    updateOrderRosters(_roster);
  };

  useEffect(() => {
    if (role === Roles.Student ) return;
    if (!audioVideo) {
      return;
    }

    const rosterUpdateCallback = async (
      chimeAttendeeId,
      present,
      externalUserId
    ) => {
      if (!present) {
        delete rosterRef.current[chimeAttendeeId];
        removeOrderRosters({ chimeAttendeeId });
        return;
      }

      const attendeeId = new DefaultModality(chimeAttendeeId).base();
      if (attendeeId !== chimeAttendeeId) {
        return;
      }

      const inRoster = rosterRef.current[chimeAttendeeId];
      if (inRoster) {
        return;
      }

      let attendee = { chimeAttendeeId };

      if (externalUserId) {
        attendee.externalUserId = externalUserId;
      }

      rosterRef.current[attendeeId] = attendee;

      if (meetingManager.getAttendee) {
        const externalData = await meetingManager.getAttendee(
          attendeeId,
          externalUserId
        );

        // Make sure that the attendee is still on the roster
        if (!rosterRef.current[attendeeId]) {
          return;
        }
        attendee = { ...attendee, ...externalData };

        if (attendee.role === Roles.Student) {
          addOrderRosters({ roster: attendee });
        }
      }
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      rosterRef.current = {};
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo]);

  return {
    unstagedHandler,
    carouselRosterHandler,
    rosterMap: rosterRef.current,
  };
};

export const useVideoListCarousel = () => {
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const teacher = attendees.find(attendee => attendee.role === Roles.Teacher);
  const [carouselTime, updateCarouselTime] = useState(10);
  const [carouselSwitch, setCarouselSwitch] = useState(false);
  const [carouselFlag, setCarouselFlag] = useState(false);
  const [interval, setInterval] = useState(null);
  const { carouselRosterHandler, unstagedHandler, rosterMap } = useOrderRosterState();
  const { context: { stagedAttendeeIds, orderRosters } } = useMeetingState();

  // 整理列表
  const sliceVideoList = (list, stagedAttendeeIds) => {
    const unStaged = list.filter(attendee => !stagedAttendeeIds.includes(attendee.chimeAttendeeId));
    let staged = [];

    if (stagedAttendeeIds.length > 0 && list.length !== 0) {
      staged = stagedAttendeeIds.filter(id => rosterMap[id]).map(id => rosterMap[id]);
    }
    const max = MAX_STUDENT_VIDEO_COUNT - staged.length;
    const currentList = [...staged, ...unStaged.slice(0, max)];

    return currentList;
  };

  // 輪播
  const videoListCarousel = () => {
    if (stagedAttendeeIds.length === MAX_STUDENT_VIDEO_COUNT) return;
    carouselRosterHandler();
  };

  useInterval(
    () => videoListCarousel(),
    interval,
    { immediate: false },
  );

  const videoListCarouselStart = (time = carouselTime) => {
    if (!carouselFlag) return;
    setInterval(time * 1000);
    setCarouselSwitch(true);
  };

  const videoListCarouselStop = () => {
    setInterval(null);
    setCarouselSwitch(false);
  };

  const toggleCarouselSwitch = () => setCarouselSwitch(prev => !prev);

  useEffect(() => {
    if (!carouselFlag || orderRosters.length <= MAX_STUDENT_VIDEO_COUNT) {
      videoListCarouselStop();
    } else {
      videoListCarouselStart();
    }
  }, [carouselFlag, orderRosters]);

  useEffect(() => {
    if (orderRosters.length === 0) return;
    if (orderRosters.length > MAX_STUDENT_VIDEO_COUNT) {
      setCarouselFlag(true);
    } else {
      setCarouselFlag(false);
    }
  }, [orderRosters]);

  const videoList = useMemo(() => {
    // 吉得堡版本要過濾掉老師上台
    const nextStagedAttendeeIds = stagedAttendeeIds.filter(id => teacher ? id !== teacher.chimeAttendeeId : id);
    return sliceVideoList(orderRosters, nextStagedAttendeeIds);
  }, [orderRosters, stagedAttendeeIds]);

  return {
    videoList,
    carouselTime,
    carouselSwitch,
    videoListCarouselStart,
    videoListCarouselStop,
    toggleCarouselSwitch,
    updateCarouselTime,
    unstagedHandler
  };
};
