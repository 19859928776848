import styled from 'styled-components';

export const StyledRaiseHandControl = styled.div.attrs(props => ({
  className: props.className
}))`
  position: relative;
  .ant-popover{
    z-index: 10;
  }
`;

export const StyledRaiseHandPanel = styled.div`
  width: 240px;
  background-color: #FFF;
  padding: 16px 0;
  border-radius: 8px;
  
  >.content {
    max-height: 240px;
    margin-bottom: 16px;
    overflow-y: auto;
  }

  >.action {
    margin: 0 24px;
  }

  .btn {
    width: 100%;
    padding: 8px;
    text-align: center;
    background-color: #FFF;
    color: #EC7963;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid rgba(255, 114, 87, 0.48);
    &:hover {
      background:  #FFEDDD;
    }
  }
  `;

export const StyledRaiseHandBtn = styled.div`
  max-width: 120px;
  padding: 16px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  background-color: #232323;
  color: #fff;
  border-radius: 39px;
  cursor: pointer;
  
  .sync & {
    height: 24px;
    min-width: 24px;
    padding: ${({ isRaised }) => isRaised ? '0 16px' : 0};
    width: 100%;
    color: #212324;
    box-shadow: 0 8px 24px 0 rgb(0 0 0 / 8%);
    background: #fff;

    &:hover {
      background: rgba(33, 35, 36, 0.1); 
    }
  }
  
  >.studentAmount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 62px;
    font-size: 16px;
    > span{
      flex: 1;
      text-align: right;
      margin: 0 4px;
      
      &.raiseAmount {
        color: #EC7963;
      }
    }
  }
  
  
`;

export const StyledRaiseCell = styled.div`
.raiseHandList {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin: 0 12px;
    cursor: pointer;
    &:hover {
      margin: 0 12px;
      border-radius: 8px;
      background-color: #FFEDDD;
      .close {
        visibility: visible;
      }
    }
    .icon {
      flex: 1;
      color: #EC7963;
      font-size: 0;
    }
    .userName{
      flex: 3;
      color: #637381;
      font-size: 14px;
    }
    .close {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      color: #686868;
      font-size: 0;
      width: 24px;
      height: 24px;
      visibility: hidden;
      /* &:hover {
        color: #fff;
      } */
    }
  }
`;
