import styled from 'styled-components';

export const StyledContentShareLayout = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
  .contentShareWrap {
    height: 100%;
    border: 5px solid #EC7963;
  }

  .toolboxWrap {
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    background-color: #161C27;
  }

  .controlBlock {
    display: flex;
    align-items: center;
  }
`;