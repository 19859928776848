import { useState } from 'react';

export const useMeetingModal = () => {
  const [meetingModalState, setMeetingModalState] = useState(false);
  const openModal = () => setMeetingModalState(true);
  const closeModal = () => setMeetingModalState(false);
  const toggleModal = () => setMeetingModalState(prev => !prev);
  const refreshHandler = () => window.location.reload();

  return {
    meetingModalState,
    openModal,
    closeModal,
    toggleModal,
    refreshHandler,
  };
};
