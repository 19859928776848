import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import Icon from '@onedesign/icon';
import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from 'utils/hooks/useQuery';
import { useResource } from 'utils/hooks/useResource';
import { Box } from '@oneboard/ui-components';
import { PRODUCT_TYPE } from 'constants/index';
import { getBookIds } from 'services/books';
import { StyledTeachingMaterialModal, StyledList } from './TeachingMaterialModal.style';
import { t } from '../../utils/i18n';

const { TabPane } = Tabs;


// const data = [
//   {
//     type: 'image',
//     label: '課件名稱-圖片',
//     sources: [
//       'https://test.oneclass.com.tw/course/pdf/1.png',
//       'https://test.oneclass.com.tw/course/pdf/2.png',
//       'https://test.oneclass.com.tw/course/pdf/3.png',
//       'https://test.oneclass.com.tw/course/pdf/4.png',
//       'https://test.oneclass.com.tw/course/pdf/5.png',
//       'https://test.oneclass.com.tw/course/pdf/6.png',
//       'https://test.oneclass.com.tw/course/pdf/7.png',
//       'https://test.oneclass.com.tw/course/pdf/8.png',
//       'https://test.oneclass.com.tw/course/pdf/9.png',
//       'https://test.oneclass.com.tw/course/pdf/10.png',
//       'https://test.oneclass.com.tw/course/pdf/11.png',
//       'https://test.oneclass.com.tw/course/pdf/12.png',
//       'https://test.oneclass.com.tw/course/pdf/13.png',
//       'https://test.oneclass.com.tw/course/pdf/14.png',
//       'https://test.oneclass.com.tw/course/pdf/15.png',
//       'https://test.oneclass.com.tw/course/pdf/16.png'
//     ]
//   },
//   {
//     type: 'video',
//     label: '課件-影片',
//     sources: 'https://test.oneclass.com.tw/course/1_L6.mp4'
//   },
//   {
//     type: 'audio',
//     label: '課件-音檔',
//     sources: 'https://test.oneclass.com.tw/course/L6DA03.mp3'
//   }
// ];

export const TeachingMaterialModal = ({ className, onClose, onChange, currentBookId }) => {
  const query = useQuery();
  const bookIds = query?.bookIds || null;
  const [bookList, setBookList] = useState([]);
  const [exampleBookList, setExampleBookList] = useState([]);

  const [activeIndex, setActiveIndex] = useState(null);

  const { resourceIds, errorMessage, loading } = useResource();
  const [resourceList, setResourceList] = useState([]);
  const isOnelink = query.productType === PRODUCT_TYPE.onelink;

  const clickListHandler = (index, value) => {
    setActiveIndex(index);
    onChange(value);
  };

  const getBookJson = (bookIds) => {
    return Promise.all(bookIds.map(bookId => {
      return fetch(`https://cdn.oneclass.com.tw/uploadoutput/${bookId}/book.json`).then(res => res.json());
    }));
  };

  const fetchBookList = async () => {
    const books = await getBookJson(bookIds);
    setBookList(books.map(book => ({
      bookId: book.bookId,
      label: book.display_name,
      type: 'onebook',
    })));
  };

  const fetchExampleBookList = async () => {
    const res = await getBookIds();
    if (res && res.length > 0) {
      const nextBookIds = res.map(item => item.bookId);

      const books = await getBookJson(nextBookIds);
      setExampleBookList(books.map(book => ({
        bookId: book.bookId,
        label: book.display_name,
        type: 'onebook',
      })));
    }
  };

  const fetchResourceList = async () => {
    const books = await getBookJson(resourceIds);
    setResourceList(books.map(book => ({
      bookId: book.bookId,
      label: book.display_name,
      type: 'onebook',
    })));
  };

  useEffect(() => {
    if (!bookIds) return;
    fetchBookList();
    fetchExampleBookList();
  }, []);

  useEffect(() => {
    if (!resourceIds) return;
    fetchResourceList();
  }, [resourceIds]);

  return (
    <StyledTeachingMaterialModal className={className} data-testid="TeachingMaterialModal">
      <div className="head">
        <div className="title">{t('containers.teachingMaterialModal.courseware', '課件')}</div>
        <div className="closeBtn" onClick={onClose}>
          <Icon name='XmarkOutline' color='#A1A4B1' size='xs' />
        </div>
      </div>
      <div className="content">
        <Tabs defaultActiveKey={0} >
          {
            <TabPane tab={t('containers.teachingMaterialModal.classMaterial', '上課教材')} key="0">
              {
                (!bookList || bookList.length === 0) && (
                  <Box display="flex" justifyContent="center">
                    {t('containers.teachingMaterialModal.noTextbookAvailable', '尚無可使用的教材')}
                  </Box>
                )
              }
              {
                bookList.length > 0 && bookList.map(book => (
                  <StyledList
                    key={book.bookId}
                    onClick={() => onChange(book)}
                    className={book.bookId === currentBookId ? 'active' : null}
                  >
                    {book.label}
                  </StyledList>
                ))
              }
            </TabPane>
          }
          {
            isOnelink && (
              <TabPane tab={t('containers.teachingMaterialModal.eduResources', '教學資源')} key="1">
                {
                  loading ? (
                    <Box display="flex" justifyContent="center">
                      <LoadingOutlined style={{ color: '#A1A4B1' }} />
                    </Box>
                  ) :
                    (
                      <>
                        {errorMessage && <Box display="flex" justifyContent="center" style={{ color: '#fff' }}>{errorMessage}</Box>}
                        {(!errorMessage && (!resourceIds || resourceIds.length === 0)) && (
                          <Box display="flex" justifyContent="center">
                            {t('containers.teachingMaterialModal.noTextbookAvailable', '尚無可使用的教材')}
                          </Box>
                        )
                        }
                        {(resourceIds && resourceIds.length !== 0) && resourceList.map(book => (
                          <StyledList
                            key={book.bookId}
                            onClick={() => onChange(book)}
                            className={book.bookId === currentBookId ? 'active' : null}
                          >
                            {book.label}
                          </StyledList>
                        ))
                        }
                      </>
                    )
                }
              </TabPane>
            )
          }
          <TabPane tab={t('containers.teachingMaterialModal.textbookExample', '教材範例')} key="2">
            {exampleBookList.map((item, index) => {
              return (
                <StyledList
                  className={activeIndex === index ? 'active' : null}
                  onClick={() => clickListHandler(index, item)}
                  key={index}>
                  {item.label}
                </StyledList>
              );
            })}
          </TabPane>
        </Tabs>
      </div>
    </StyledTeachingMaterialModal>
  );
};

TeachingMaterialModal.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  currentBookId: PropTypes.string,
};