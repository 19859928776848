import React from 'react';
import { StyledInput } from './Input.style';

export const Input = ({ 
  className, 
  placeholder = '請填寫品牌名稱',
  value,
  onChange,
  disabled = false
}) => {
return (
    <StyledInput 
      className={className}
      placeholder={placeholder}
      value={value} 
      onChange={onChange} 
      allowClear={true} 
      data-testid="Input"
      disabled={disabled}>
    </StyledInput>
  );
};