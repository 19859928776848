import React, { useState } from 'react';
import { Input, Form, Button, Row, Col, message, Select } from 'antd';
import { Loading, Box } from '@oneboard/ui-components';
import { shortId } from 'utils';
import { urlFormat } from 'utils/url';
import { ClassType, Roles } from 'constants/index';
import { createCourse } from 'services/course';
import { StyledCreateMeetingPage } from './CreateMeetingPage.style';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};


const ClassTypeMap = {
  [ClassType.Single]: '1 v 1',
  [ClassType.Group]: '1 v 6',
  [ClassType.Multiple]: '1 v more',
  [ClassType.SyncSingle]: 'sync-single',
  [ClassType.SyncMultiple]: 'sync-multiple'
};

export const CreateMeetingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [urls, setUrl] = useState({
    teacherUrl: '',
    studentUrl: '',
    advisorUrl: '',
    observerUrl: ''
  });

  const createUrl = (role, formData) => {

    let query = {
      role,
      classType: formData.classType
    };

    switch (role) {
      case Roles.Teacher:
        query.userName = formData.teacherName;
        query.bookIds = formData.bookIds && formData.bookIds.split(',').map(bookId => bookId.trim());
        break;
      case Roles.Student:
        query.userName = formData.studentName;
        break;
      case Roles.Advisor:
        query.userName = formData.advisorName;
        query.bookIds = formData.bookIds && formData.bookIds.split(',').map(bookId => bookId.trim());
        break;
      case Roles.Observer:
      default:
        query.userName = shortId();
        return urlFormat({
          pathname: `${formData.meetingId}/${Roles.Observer}`,
          query
        });
    }

    return urlFormat({
      pathname: `${formData.meetingId}/setup`,
      query
    });
  };


  const onFinish = async (values) => {
    setIsLoading(true);
    const { courseId: meetingId } = await createCourse(values);
    if (!meetingId) {
      alert('課程創建失敗');
      return;
    }
    values.meetingId = meetingId;
    const teacherUrl = createUrl(Roles.Teacher, values);
    const studentUrl = createUrl(Roles.Student, values);
    const advisorUrl = createUrl(Roles.Advisor, values);
    const observerUrl = createUrl(Roles.Observer, values);
    setIsLoading(false);
    setUrl(prev => ({
      ...prev,
      teacherUrl,
      studentUrl,
      advisorUrl,
      observerUrl,
    }));
  };

  const copyHandler = (text) => {
    navigator.clipboard.writeText(text).then(function() {
      message.success('複製成功！');
    }, function(err) {
      message.error(`Could not copy text: ${err}`);
    });
  };

  const copyAllHandler = () => {
    const text = `
    老師連結： ${urls.teacherUrl}

    學生連結： ${urls.studentUrl}
    
    顧問連結： ${urls.advisorUrl}
    
    觀察者連結： ${urls.observerUrl}
    `;
    navigator.clipboard.writeText(text).then(function() {
      message.success('複製成功！');
    }, function(err) {
      message.error(`Could not copy text: ${err}`);
    });
  };

  return (
    <StyledCreateMeetingPage data-testid="CreateMeetingPage">
      <Row justify="center">
        <Col span={12}>
          <Form
            {...layout}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label="課程名稱"
              name="courseName"
              rules={[{ required: true, message: '請輸入課程名稱' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="老師姓名"
              name="teacherName"
              rules={[{ required: true, message: '請輸入老師姓名' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="書本Id"
              name="bookIds"
              rules={[{ required: false, message: '請輸入老師姓名' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="學生姓名"
              name="studentName"
              rules={[{ required: true, message: '請輸入學生姓名' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="顧問姓名"
              name="advisorName"
              rules={[{ required: true, message: '請輸入顧問姓名' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="課程類型"
              name="classType"
              rules={[{ required: true, message: '請選擇課程類型' }]}
            >
              <Select>
                {
                  Object.values(ClassType).map((type, index) => (
                    <Select.Option value={type} key={index}>{ClassTypeMap[type]}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Box display="flex" justifyContent="center">
                <Button type="primary" htmlType="submit" loading={isLoading}>
                    產生連結
                </Button>
              </Box>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={18}>
          <Input.Search
            addonBefore="老師連結"
            value={urls.teacherUrl}
            placeholder="input search text"
            enterButton="複製"
            size="large"
            onSearch={() => copyHandler(urls.teacherUrl)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore="學生連結"
            value={urls.studentUrl}
            placeholder="input search text"
            enterButton="複製"
            size="large"
            onSearch={() => copyHandler(urls.studentUrl)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore="顧問連結"
            value={urls.advisorUrl}
            placeholder="input search text"
            enterButton="複製"
            size="large"
            onSearch={() => copyHandler(urls.advisorUrl)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore="觀察者連結"
            value={urls.observerUrl}
            placeholder="input search text"
            enterButton="複製"
            size="large"
            onSearch={() => copyHandler(urls.observerUrl)}
          />
        </Col>
        <Col span={10}>
          <Box display="flex" justifyContent="center">
            <Button type="primary" onClick={copyAllHandler}>複製全部</Button>
          </Box>
        </Col>
      </Row>

    </StyledCreateMeetingPage>
  );
};