import React, { useState, useRef, useEffect } from 'react';
import Icon from '@onedesign/icon';
import { Box, FullButton, Select } from '@oneboard/ui-components';
import { useDeviceDetectDispatch } from '../../providers/DeviceDetectProvider';
import { Input } from '../../components/Input';
import { StyledIntro } from './Intro.style';

const detectItems = [
  {
    icon: 'WifiSolid',
    name: '網路'
  },
  {
    icon: 'VideoSolid',
    name: '視訊'
  },
  {
    icon: 'MicrophoneAltSolid',
    name: '麥克風'
  },
  {
    icon: 'HeadsetSolid',
    name: '耳機/喇叭'
  },
]

const deviceTypes = [
  '請選擇使用裝置',
  '筆記型電腦',
  '桌上型電腦',
  '蘋果電腦（筆電、桌機）',
  '平板',
  '手機',
  '不清楚'
];

const boardItems = ['請選擇品牌', 'Apple', '三星', '華為', '小米', '其他'];

const osItems = ['請選擇系統', 'ios系統', '安卓系統'];

export const Intro = ({ className }) => {
  const { startDetect, updateDevice } = useDeviceDetectDispatch();

  const [deviceVal, setDeviceVal] = useState(null);
  const [tableVal, setTableVal] = useState(null);
  const [mobileVal, setMobileVal] = useState(null);
  const otherVal = useRef(null);
  
  const otherInputHandler = e => otherVal.current = e.target.value
  
  const startDetectHandler = () => {
    const data = {};

    if (deviceVal === '手機') data.mobileSystem = mobileVal;
    
    if (deviceVal === '平板') data.tableBoard = tableVal;

    if (deviceVal === '平板' && tableVal === '其他') data.otherBoard = otherVal.current;

    if (deviceVal !== null) {
      data.type = deviceVal
      updateDevice(data)
    };

    startDetect();
  }

  useEffect(() => {
    if (deviceVal === null) return
    if (deviceVal !== '平板') {
      otherVal.current = '';
      setTableVal('');
    }
  }, [deviceVal]);

  useEffect(() => {
    if (tableVal === null) return
    if (tableVal !== '其他') otherVal.current = ''
  }, [tableVal]);

return (
    <StyledIntro className={className} data-testid="Intro">
      <div className="desc">
        為了維持良好的上課品質，需要對您的電腦設備進行四大檢測。<br />
        若您設備已準備好，請點擊［開始檢測］。
      </div>
      <div className="detectBlock">
        {detectItems.map((obj) => (
          <div className="detectItems" key={obj.name}>
            <div className="item">
              <div className="icon">
                <Icon name={obj.icon} size='xl' color="#8A94A6" />
              </div>
              <div className="name">{obj.name}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="content">
        <Select className="select" defaultValue='請選擇使用裝置' onChange={setDeviceVal}>
          {deviceTypes.map((item) => <Select.Option 
            value={item} 
            disabled={item === '請選擇使用裝置'} 
            key={item}>
              {item}
            </Select.Option>)}
        </Select>
        {deviceVal === '平板' && <Box mt={3}>
          <Select className="select" defaultValue='請選擇品牌' onChange={setTableVal}>
            {boardItems.map((item) => <Select.Option 
              value={item} 
              disabled={item === '請選擇品牌'} 
              key={item}>
                {item}
              </Select.Option>)}
          </Select>
        </Box>}
        {deviceVal === '手機' && <Box mt={3}>
          <Select className="select" defaultValue='請選擇系統' onChange={setMobileVal}>
            {osItems.map((item) => <Select.Option 
              value={item} 
              disabled={item === '請選擇系統'} 
              key={item}>
                {item}
              </Select.Option>)}
          </Select>
        </Box>}
        {(deviceVal === '平板' && tableVal === '其他') && <Box mt={3}>
          <Input onChange={otherInputHandler} />
        </Box>}
        <Box mt={4}>
          <div className="action">
            <FullButton onClick={startDetectHandler}>開始檢測</FullButton>
          </div>  
        </Box>
      </div>
    </StyledIntro>
  );
};