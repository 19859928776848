import styled from 'styled-components';

export const StyledAuditVideoList = styled.div.attrs(props => ({
  className: props.className
}))`
  width: 240px;

  .remoteVideoBox{
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    + .remoteVideoBox{
        margin-top: 4px;
    }
  }
  .insideVideo{
    position: relative;
    height: 200px;
  }
`;

export const UiInfoPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 8px 8px 8px 16px;
  background-color: #33353A;
  
  > .name {
    color: #fff;
    font-size: 14px;
    line-height: 16px;
  }
`;