import React from 'react';
import { useDeviceDetectState } from '../../providers/DeviceDetectProvider';
import { Steps } from 'antd';
import { StyledStepsBar } from './StepsBar.style';

const { Step } = Steps;

const stepsItems = [
  {
    title: '網路檢測',
    value: 'Network',
    order: 0
  },
  {
    title: '攝像頭檢測',
    value: 'Video',
    order: 1
  },
  {
    title: '麥克風檢測',
    value: 'Microphone',
    order: 2
  },
  {
    title: '播放設備檢測',
    value: 'Audio',
    order: 3
  },
  {
    title: '檢測結果',
    value: 'Result',
    order: 4
  }
]

export const StepsBar = ({ className }) => {
  const deviceDetectState = useDeviceDetectState();
  const { Steps: StepsVal } = deviceDetectState.value;
  const currentStep = stepsItems.find(item => item.value === StepsVal)?.order;

return (
    <StyledStepsBar 
      className={className}
      data-testid="StepsBar"
      >
      <Steps 
        current={currentStep} 
        labelPlacement='vertical'
        responsive={false}
        >
          {stepsItems.map(item => <Step title={item.title} key={item.value} />)}
      </Steps>
    </StyledStepsBar>
  );
};