export const MAX_STUDENT_VIDEO_COUNT = 6;


export const EXAMPLE_BOOK_IDS = [
  'cf1bfb14eb242c8c',
  'cd1c47dcef9933a1',
  'e3a4d68b92602905',
  'c02884ff55c67902',
  'b0313d3e1deeed6a',
  '6c2d9ed2afce0467',
  '867a69c196a29faf',
  'db95ae650e21a0c4',
];
