import { useEffect, useState } from 'react';
import { getLinkResourcesList, getMmsTeacherResourcesList } from 'services/resource';
import { isLogin } from 'utils/login';
import { useQuery } from 'utils/hooks/useQuery';
import { PRODUCT_TYPE } from 'constants/index';
import { useParams } from 'react-router-dom';

export const useResource = () => {
  const query = useQuery();
  const { meetingId } = useParams();
  const organizationId = query.organizationId || null;
  const productType = query.productType || null;
  const isOnelink = productType === PRODUCT_TYPE.onelink;
  const isMMSTeacher = productType === PRODUCT_TYPE.mmsTeacher;
  const [loading, setLoading] = useState(false);
  const [resourceIds, setResourceIds] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const init = async () => {
    if (organizationId !== null && isLogin) {
      if (isOnelink) {
        try {
          setLoading(true);
          const { status, data, error } = await getLinkResourcesList({ organizationId });

          if (status === 'success' && data) {
            const ids = data.resources.map(resource => resource.resourceId);
            setLoading(false);
            setResourceIds(ids);
          }

          if (status === 'failure') {
            setLoading(false);
            setErrorMessage(error.message);
          }
        } catch (error) {
          setLoading(false);
          setErrorMessage(error);
        }
      }

      if (isMMSTeacher) {
        try {
          setLoading(true);
          const { status, data, error } = await getMmsTeacherResourcesList({ organizationId, courseId: meetingId });

          if (status === 'success' && data) {
            const ids = data.resourceIds;
            setLoading(false);
            setResourceIds(ids);
          }

          if (status === 'failure') {
            setLoading(false);
            setErrorMessage(error.message);
          }
        } catch (error) {
          setLoading(false);
          setErrorMessage(error);
        }
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return {
    resourceIds,
    loading,
    errorMessage
  };
};