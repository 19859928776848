import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useRemoteVideoTileState,
  useRosterState,
  useAttendeeStatus,
  useToggleLocalMute,
  useLocalVideo,
} from 'amazon-chime-sdk-component-library-react';
import { Box, Popover } from '@oneboard/ui-components';
import { Roles, ClassType, PRODUCT_TYPE } from 'constants/index';
import {
  LocalVideo,
  RemoteVideo,
  useMeetingDispatch,
  useMeetingState,
  Reward,
  MeetingStates,
  MeetingRoles,
} from '@oneboard/meeting';
import { VideoPopover, TilePausedBox } from 'components';
import { VideoBox } from 'containers';
import classnames from 'classnames';
import Icon from '@onedesign/icon';
import { useGroupContext } from 'providers/GroupProvider';
import { useQuery } from 'utils/hooks/useQuery';
import { useToolboxAuth } from 'utils/hooks/useToolboxAuth';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useStudentInfoContext } from 'providers/StudentInfoProvider';
import { useInsertPicture } from 'providers/InsertPictureProvider';
import { Insert_Picture_State, Insert_Picture_Event } from 'machines/InsertPictureMachine';
import { StyledVideoSingleList, PopoverIcon } from './VideoSingleList.style';

const SharerVideoPopoverContainer = () => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();

  return (
    <VideoPopover placement='left'>
      <PopoverIcon onClick={toggleVideo}>
        {isVideoEnabled ? <Icon name='VideoSolid' /> : <Icon name='VideoSlashSolid' color='#F94144' />}
      </PopoverIcon>
      <PopoverIcon onClick={toggleMute}>
        {muted ? <Icon name='MicrophoneAltSlashSolid' color='#F94144' /> : <Icon name='MicrophoneAltSolid' />}
      </PopoverIcon>
    </VideoPopover>
  );
};

const StudentVideoPopoverContainer = ({ attendeeId, userId, studentName }) => {
  const { usersMuteHandler } = useGroupContext();
  const { muted, videoEnabled } = useAttendeeStatus(attendeeId);
  const {
    openVideoAttendee,
    closeVideoAttendee,
    muteAttendee,
    unMuteAttendee,
    sendReward,
    sendRewardFromAdvisor,
    updateReward,
    updateRewardFromAdvisor,
    openVideoAttendeeFromAdvisor,
    closeVideoAttendeeFromAdvisor,
    muteAttendeeFromAdvisor,
    unMuteAttendeeFromAdvisor,
  } = useMeetingDispatch();
  const query = useQuery();
  const classType = query?.classType || null;
  const meetingState = useMeetingState();
  const { role } = meetingState.context;
  const { queryToolboxAuthById, toggleToolboxAuth } = useToolboxAuth();
  const isEnableToolbox = queryToolboxAuthById({ attendeeId });
  const { openStudentInfo } = useStudentInfoContext();
  const isTeacherRewardActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
  });
  const isAdvisorRewardActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
  });

  const muteHandler = () => {
    if (role === Roles.Teacher) {
      muted ? unMuteAttendee({ attendeeId }) : muteAttendee({ attendeeId });
    }

    if (role === Roles.Advisor) {
      muted ? unMuteAttendeeFromAdvisor({ attendeeId }) : muteAttendeeFromAdvisor({ attendeeId });
    }

    usersMuteHandler({
      [attendeeId]: !muted,
    });
  };

  const videoEnabledHandler = () => {
    if (role === Roles.Teacher) {
      videoEnabled ? closeVideoAttendee({ attendeeId }) : openVideoAttendee({ attendeeId });
    }

    if (role === Roles.Advisor) {
      videoEnabled ? closeVideoAttendeeFromAdvisor({ attendeeId }) : openVideoAttendeeFromAdvisor({ attendeeId });
    }
  };

  const rewardHandler = ({ attendeeId, userId, studentName }) => {
    if (isTeacherRewardActive || isAdvisorRewardActive) return;
    if (role === Roles.Advisor) {
      updateRewardFromAdvisor({ userId, studentName });
      sendRewardFromAdvisor({ attendeeId, userId });
    } else {
      updateReward({ userId, studentName });
      sendReward({ attendeeId, userId, studentName });
    }
  };

  return (
    <VideoPopover placement='left'>
      <PopoverIcon onClick={videoEnabledHandler}>
        {videoEnabled ? <Icon name='VideoSolid' /> : <Icon name='VideoSlashSolid' color='#F94144' />}
      </PopoverIcon>
      <PopoverIcon onClick={muteHandler}>
        {muted ? <Icon name='MicrophoneAltSlashSolid' color='#F94144' /> : <Icon name='MicrophoneAltSolid' />}
      </PopoverIcon>
      {classType !== ClassType.Single && (
        <PopoverIcon onClick={() => toggleToolboxAuth({ attendeeId })}>
          {isEnableToolbox ? <Icon name='ChalkboardOutline' /> : <Icon name='ChalkboardSlashSolid' />}
        </PopoverIcon>
      )}
      <PopoverIcon className='reward'>
        <Icon name='TrophySolid' onClick={() => rewardHandler({ attendeeId, userId, studentName })} />
      </PopoverIcon>
      {query.productType === PRODUCT_TYPE.liveoneclass &&
        query.role === Roles.Teacher &&
        classType === ClassType.SyncSingle && (
          <PopoverIcon>
            <Icon name='AddressBookSolid' onClick={() => openStudentInfo(userId)} />
          </PopoverIcon>
        )}
    </VideoPopover>
  );
};

StudentVideoPopoverContainer.propTypes = {
  attendeeId: PropTypes.string,
  userId: PropTypes.string,
  studentName: PropTypes.string,
};

const RemotePopoverContainer = ({ attendeeId }) => {
  const { usersMuteHandler } = useGroupContext();
  const { muted, videoEnabled } = useAttendeeStatus(attendeeId);
  const {
    openVideoAttendee,
    closeVideoAttendee,
    muteAttendee,
    unMuteAttendee,
    openVideoAttendeeFromAdvisor,
    closeVideoAttendeeFromAdvisor,
    muteAttendeeFromAdvisor,
    unMuteAttendeeFromAdvisor,
  } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { role } = meetingState.context;

  const muteHandler = () => {
    if (role === Roles.Teacher) {
      muted ? unMuteAttendee({ attendeeId }) : muteAttendee({ attendeeId });
    }

    if (role === Roles.Advisor) {
      muted ? unMuteAttendeeFromAdvisor({ attendeeId }) : muteAttendeeFromAdvisor({ attendeeId });
    }

    usersMuteHandler({
      [attendeeId]: !muted,
    });
  };

  const videoEnabledHandler = () => {
    if (role === Roles.Teacher) {
      videoEnabled ? closeVideoAttendee({ attendeeId }) : openVideoAttendee({ attendeeId });
    }

    if (role === Roles.Advisor) {
      videoEnabled ? closeVideoAttendeeFromAdvisor({ attendeeId }) : openVideoAttendeeFromAdvisor({ attendeeId });
    }
  };

  return (
    <VideoPopover placement='left'>
      <PopoverIcon onClick={videoEnabledHandler}>
        {videoEnabled ? <Icon name='VideoSolid' /> : <Icon name='VideoSlashSolid' color='#F94144' />}
      </PopoverIcon>
      <PopoverIcon onClick={muteHandler}>
        {muted ? <Icon name='MicrophoneAltSlashSolid' color='#F94144' /> : <Icon name='MicrophoneAltSolid' />}
      </PopoverIcon>
    </VideoPopover>
  );
};

RemotePopoverContainer.propTypes = {
  attendeeId: PropTypes.string,
};

export const VideoSingleList = ({ className }) => {
  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const meetingState = useMeetingState();
  const {
    attendeeId: selfAttendeeId,
    videoIsMirroring,
    stagedAttendeeIds,
    objectedAttendeeIds,
    userId,
  } = meetingState.context;
  const query = useQuery();
  const classType = query.classType;
  const { isBreakoutRoom } = useBreakoutMeeting();
  const { pauseReward } = useMeetingDispatch();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { state: insertPictureState, send: insertPictureSend } = useInsertPicture();
  const isEnd = insertPictureState.matches(Insert_Picture_State.End);
  const advisor = attendees.find(
    (attendee) => attendee.chimeAttendeeId !== selfAttendeeId && attendee.role === Roles.Advisor
  );
  const { videoEnabled: advisorVideoEnabled } = useAttendeeStatus(advisor && advisor?.chimeAttendeeId);

  const isTeacherRewardActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
  });

  const isAdvisorRewardActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
  });

  const rewardCompletedHandler = useCallback(() => {
    pauseReward({ userId });
  }, []);

  const isTilePaused = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.TileState}.${MeetingStates.Paused}`,
  });

  useEffect(() => {
    if (isEnd && !isVideoEnabled) {
      toggleVideo();
      insertPictureSend({
        type: Insert_Picture_Event.Idle,
      });
    }
  }, [isEnd, isVideoEnabled]);

  return (
    <StyledVideoSingleList className={className} data-testid='VideoSingleList'>
      <Popover
        placement='left'
        className='customPopover'
        content={<SharerVideoPopoverContainer attendeeId={selfAttendeeId} />}
      >
        <Box
          position='relative'
          className={classnames({
            videoBoxWrap: true,
            [classType]: classType,
            isBreakoutRoom: !!isBreakoutRoom,
          })}
        >
          <VideoBox attendeeId={selfAttendeeId} key={selfAttendeeId} isRatio>
            {!isTilePaused ? (
              <LocalVideo
                isShowNameplate={
                  stagedAttendeeIds.includes(selfAttendeeId) || objectedAttendeeIds.includes(selfAttendeeId)
                }
                className={classnames({
                  isFlip: !videoIsMirroring,
                })}
                attendeeId={selfAttendeeId}
                isRatio
              />
            ) : (
              <TilePausedBox />
            )}
          </VideoBox>
        </Box>
      </Popover>

      {/* 學生 */}
      {classType !== ClassType.SyncMultiple &&
        attendees
          .filter((attendee) => attendee.role === Roles.Student)
          .map((attendee) => {
            const userId = attendee?.externalUserId;
            const studentName = attendee.name;
            const attendeeId = attendee.chimeAttendeeId;
            const tileId = attendeeIdToTileId[attendeeId];
            return (
              <Popover
                placement='left'
                className='customPopover'
                key={attendeeId}
                content={
                  <StudentVideoPopoverContainer attendeeId={attendeeId} userId={userId} studentName={studentName} />
                }
              >
                <Box position='relative' className={`videoBoxWrap ${classType}`} mt={3}>
                  <VideoBox attendeeId={attendeeId} isStudent userId={userId} isRatio>
                    <RemoteVideo
                      isShowNameplate={objectedAttendeeIds.includes(attendeeId)}
                      attendeeId={attendeeId}
                      tileId={tileId}
                      isRatio
                    />
                  </VideoBox>
                </Box>
              </Popover>
            );
          })}

      {/* 顧問 */}
      {classType === ClassType.SyncMultiple &&
        !!advisor &&
        advisorVideoEnabled &&
        (() => {
          const userId = advisor?.externalUserId;
          const attendeeId = advisor.chimeAttendeeId;
          const tileId = attendeeIdToTileId[attendeeId];
          return (
            <Popover
              placement='left'
              className='customPopover'
              key={attendeeId}
              content={<RemotePopoverContainer attendeeId={attendeeId} />}
            >
              <Box
                position='relative'
                className={classnames({
                  videoBoxWrap: true,
                  [classType]: !!classType,
                })}
                mt={3}
              >
                <VideoBox attendeeId={attendeeId} userId={userId} isRatio>
                  <RemoteVideo
                    isShowNameplate={objectedAttendeeIds.includes(attendeeId)}
                    attendeeId={attendeeId}
                    tileId={tileId}
                    isRatio
                  />
                </VideoBox>
              </Box>
            </Popover>
          );
        })()}

      {/* 老師 */}
      {classType === ClassType.SyncMultiple &&
        attendees
          .filter((attendee) => attendee.chimeAttendeeId !== selfAttendeeId && attendee.role === Roles.Teacher)
          .map((attendee) => {
            const userId = attendee?.externalUserId;
            const attendeeId = attendee.chimeAttendeeId;
            const tileId = attendeeIdToTileId[attendeeId];
            return (
              <Popover
                placement='left'
                className='customPopover'
                key={attendeeId}
                content={<RemotePopoverContainer attendeeId={attendeeId} />}
              >
                <Box
                  position='relative'
                  className={classnames({
                    videoBoxWrap: true,
                    [classType]: !!classType,
                  })}
                  mt={3}
                >
                  <VideoBox attendeeId={attendeeId} userId={userId} isRatio>
                    <RemoteVideo
                      isShowNameplate={objectedAttendeeIds.includes(attendeeId)}
                      attendeeId={attendeeId}
                      tileId={tileId}
                      isRatio
                    />
                  </VideoBox>
                </Box>
              </Popover>
            );
          })}

      {classType !== ClassType.SyncMultiple && (isTeacherRewardActive || isAdvisorRewardActive) && (
        <Reward rewardType='Trophy' onComplete={rewardCompletedHandler} />
      )}
    </StyledVideoSingleList>
  );
};

VideoSingleList.propTypes = {
  className: PropTypes.string,
};
