import React, { useState } from 'react';
import { Box, FullButton, Select } from '@oneboard/ui-components';
import { useDeviceDetectDispatch, useDeviceDetectState } from '../../providers/DeviceDetectProvider';
import { InfoInput } from '../../components/InfoInput';
import Icon from '@onedesign/icon';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { StyledResultStep } from './ResultStep.style';

const deviceType = ['video', 'microphone', 'audio'];

const networkUnit = {
  speedDown: 'Mbps',
  speedUp: 'Mbps',
  speedPing: 'ms',
  speedJitter: 'ms',
};

const roleType = ['請選擇身份', '學生', '老師'];

const constantsMap = {
  user: {
    name: '姓名',
    phone: '聯絡電話',
    role: '身份'
  },
  equipment: {
    video: '視訊',
    microphone: '麥克風',
    audio: '播放設備' 
  },
  device: {
    type: '使用設備',
    mobileSystem: '手機系統',
    tableBoard: '平板品牌',
    otherBoard: '其他品牌'
  },
  network: {
    ip: 'IP位置',
    speedDown: '網路下載',
    speedUp: '網路上傳',
    speedPing: '網路PING',
    speedJitter: '網路抖動',
    type: '連線方式',
    source: '網路來源',
    other: '其他'
  },
  audio: {
    device: '播放裝置',
    otherBoard: '其他品牌'
  },
} 

const combineMessage = (storeData) => {
  const msgArray = [];
  const stepCategory = Object.keys(constantsMap);

  stepCategory.forEach(category => {
    const categoryItems = Object.keys(constantsMap[category]);
    
    categoryItems.forEach(item => {
      let data = '';

      switch (category) {
        case 'equipment':
          data = `${constantsMap[category][item]}：${storeData[category][item] ? '正常' : '不正常'}`
          break;
        case 'network':
          data = `${constantsMap[category][item]}：${storeData[category][item] ? storeData[category][item] : ''} ${networkUnit[item] ? networkUnit[item] : ''}`
          break;
        default:
          data = `${constantsMap[category][item]}：${storeData[category][item] ? storeData[category][item] : ''}`
          break;
      }
      
      msgArray.push(data)
    })
  })
  msgArray.push(`userAgent：${navigator.userAgent}`);

  const bxMsg = msgArray.join('#BR#');

  return bxMsg
};

const formatMessage = (msg) => {
  return msg.replace(/#BR#/g, `\n`)
}

export const ResultStep = ({ className, postBitrix, postBitrixOrder, orderId }) => {
  const { resetState } = useDeviceDetectDispatch();
  const deviceDetectState = useDeviceDetectState();
  const { 
    equipment: equipmentData, 
    audio: audioData, 
    network: networkData, 
    device: deviceData
  } = deviceDetectState.context;

  const [nameVal, setNameVal] = useState('');
  const [phoneVal, setPhoneVal] = useState('');
  const [roleVal, setRoleVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const submitHandler = async () => {
    if ( isLoading ) return
    
    setIsLoading(true);
    
    const userInfo = {
      name: nameVal,
      phone: phoneVal,
      role: roleVal
    };
    const bitrixData = {
      user: userInfo,
      equipment: equipmentData, 
      audio: audioData, 
      network: networkData, 
      device: deviceData
    };
    
    const message = combineMessage(bitrixData);
    
    await postBitrix({ message }).catch(error => console.error(error));
    
    if (orderId) {
      const nextMessage = formatMessage(message);

      await postBitrixOrder({
        orderId,
        testTime: dayjs().format(),
        testData: nextMessage
      }).catch(error => console.error(error)); 
    }

    alert('檢測結果已送出，若您的設備異常，請點擊右下角「萬客服」，將由專人為您服務！');
    setIsLoading(false);
    setIsSubmit(true);
  };

  return (
    <StyledResultStep className={className} data-testid="ResultStep">
      <div className="resultBlock">
        <div className="itemWrap info">
          <div className="title">檢測結果</div>
          <div className="content">
            <Box mt={3}>
              <div className="resultTable">
                {deviceType.map(item => (
                  <div className="item" key={item}>
                    <div className="head">{constantsMap.equipment[item]}</div>
                    <div className="content">
                      {equipmentData[item] ? <Icon name="CheckCircleSolid" size='lg' color="#38CB89" /> : 
                      <Icon name="XmarkCircleSolid" size='lg' color="#FF5630" />}
                    </div>
                  </div>
                ))}
              </div>
            </Box>
            <Box mt={3}>
              <div className="resultSingleTable">
                <div className="head">設備清單</div>
                <div className="content">
                  <div className="list">{`${constantsMap.network.type}：${networkData.type ? networkData.type : ''}`}</div>
                  <div className="list">{`${constantsMap.network.source}：${networkData.source ? networkData.source : ''}`}</div>
                  <div className="list">{`${constantsMap.device.type}：${deviceData.type ? deviceData.type : ''}`}</div>
                  <div className="list">{`${constantsMap.audio.device}：${audioData.device ? audioData.device : ''}`}</div>
                </div>
              </div>
            </Box>
            <Box mt={3}>
              <div className="resultSingleTable">
                <div className="head">網路檢測結果</div>
                <div className="content">
                  <div className="list">{`${constantsMap.network.ip}：${networkData.ip}`}</div>
                  <div className="list">{`${constantsMap.network.speedDown}：${networkData.speedDown} ${networkUnit.speedDown}`}</div>
                  <div className="list">{`${constantsMap.network.speedUp}：${networkData.speedUp} ${networkUnit.speedUp}`}</div>
                  <div className="list">{`${constantsMap.network.speedPing}：${networkData.speedPing} ${networkUnit.speedPing}`}</div>
                  <div className="list">{`${constantsMap.network.speedJitter}：${networkData.speedJitter} ${networkUnit.speedJitter}`}</div>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <div className="itemWrap">
          <Box>
            <InfoInput placeholder="姓名" disabled={isSubmit} onChange={e => setNameVal(e.target.value)} />
            <Box mt={3}>
              <InfoInput placeholder="連絡電話" disabled={isSubmit} onChange={e => setPhoneVal(e.target.value)} />
            </Box>
            <Box mt={3}>
              <Select className="select" defaultValue='請選擇身份' disabled={isSubmit} onChange={setRoleVal}>
                {roleType.map((item) => <Select.Option 
                  value={item} 
                  disabled={item === '請選擇身份'} 
                  key={item}>
                    {item}
                  </Select.Option>)}
              </Select>
            </Box>
            {/* <Box mt={1}>
              <div className="errorMsg">請先填寫姓名與手機再提交</div>
            </Box> */}
          </Box>
          <div className="notice">
            
          </div>
        </div>
      </div>
      <Box mt={4}>
        <div className="question">
          <div className="title">
            請確認檢測結果並提交報告，<br />
            若設備異常，客服人員會主動聯繫。
          </div>
          <div className="action">
            {!isSubmit && <div className="btn primary">
              <FullButton onClick={submitHandler} disabled={(nameVal === '' || phoneVal === '')}>
                {isLoading ? <LoadingOutlined /> : '提交'}
              </FullButton>
            </div>}
            <div className="btn secondly">
              <FullButton.Secondly onClick={resetState}>重新檢測</FullButton.Secondly>
            </div>
          </div>  
        </div>
      </Box>
    </StyledResultStep>
  );
};