import React, { createContext, useContext } from 'react';
import { useRewards } from './useRewards';

export const StateContext = createContext();

export const useRewardsContext = () => useContext(StateContext);

export const RewardsProvider = ({ children }) => {
  const RewardsContext = useRewards();

  return (
    <StateContext.Provider value={RewardsContext}>
      {children}
    </StateContext.Provider>
  );
};