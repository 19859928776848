import styled from 'styled-components';

export const StyledResourceModal = styled.div.attrs(props => ({
  className: props.className
}))`
/* Code Here */
`;

export const StyledModalContent = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  background-color: #fff;
`;

export const StyledResourceBox = styled.div`
    width: 100%;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 0;
      border-radius: 8px;
      background-color: #919EAB29;
      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #EC7963;
        margin: 0 8px;
      }
      .seriesTitle {
        margin: 0 8px;
        flex: 1;
        font-size: 16px;
        color: #2F3746;
        font-weight: 500;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      height: 300px;
      
      .dottedLine {
        width: 28px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1px;
        height: 100%;
        background: repeating-linear-gradient(to top, #919EAB3D, #919EAB3D 3px, #FFFFFF 3px ,#FFFFFF 6px);
        margin: 0 13px;
      }
      .list {
        overflow-y: auto;
        position: relative;
        flex: 1;
      }
    }
`;

export const StyledResourceItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    font-size: 16px;
    padding: 8px 16px 8px 8px;
    color: ${({ active }) => active ? '#EC7963' : '#8A94A6'};
    font-weight: 400;
    &:hover {
      cursor: pointer;
      background-color: #919EAB14;
    }

    .label {
      padding: 1px 8px;
      color: #B95648;
      background-color: #EC796329;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 700;
      flex: 0 0 64px;
    }
`;