import React from 'react';
import PropTypes from 'prop-types';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { Roles } from 'constants/index';
import { Box, Switch } from '@oneboard/ui-components';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { useVideoListContext } from 'providers/VideoListProvider';
import { CancelButton, SendButton } from 'components/NewOneBoard/common/MessageButton';
import DeviceSelection from 'components/NewOneBoard/Footer/component/SettingButton/common/DeviceSelection';
import styled from 'styled-components';
import ToolModal from 'components/NewOneBoard/common/ToolModal';

const ButtonContainer = styled(Box)`
  display: flex;
  height: 68px;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const ModalText = styled.p`
  color: #637381;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 8px;
  align-self: center;
  margin: 0;
`;

const SwitchContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 0;
`;

const SelectContainer = styled(SwitchContainer)``;

const timeSpacing = [10, 15, 20];

export const VideoListCarouselPanel = ({ className, onClose }) => {
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const {
    videoList: videoStudentList,
    toggleCarouselSwitch,
    carouselSwitch: carouselSwitchState,
    updateCarouselTime,
    carouselTime,
    videoListCarouselStart,
    videoListCarouselStop,
  } = useVideoListContext();
  const videoListCarouselSwitchHandler = (state) => toggleCarouselSwitch(state);

  const carouselSecondHandler = (e) => {
    updateCarouselTime(e.target.value);
  };

  const onSubmitHandler = () => {
    updateCarouselTime(carouselTime);
    carouselSwitchState ? videoListCarouselStart(carouselTime) : videoListCarouselStop();
    onClose();
  };

  return (
    <ToolModal
      active={true}
      onClose={onClose}
      title='輪流上台設定'
      showBottomContainer={true}
      height='260px'
      footerHeight='69px'
      justifyContent={'flex-start'}
      footerContent={
        <ButtonContainer>
          <CancelButton className='ghost' onClick={onClose}>
            取消
          </CancelButton>
          <SendButton onClick={onSubmitHandler}>開始</SendButton>
        </ButtonContainer>
      }
    >
      <SwitchContainer>
        <ModalText>設定開關</ModalText>
        <Switch
          checked={carouselSwitchState}
          disabled={students.length > MAX_STUDENT_VIDEO_COUNT ? false : true}
          onChange={videoListCarouselSwitchHandler}
        />
      </SwitchContainer>
      {carouselSwitchState && (
        <SelectContainer>
          <ModalText>每幾秒輪換</ModalText>
          <DeviceSelection defaultValue={carouselTime} onChange={carouselSecondHandler} className='select'>
            {timeSpacing.map((space) => (
              <option value={space} key={space}>
                {space}
              </option>
            ))}
          </DeviceSelection>
        </SelectContainer>
      )}
    </ToolModal>
  );
};

VideoListCarouselPanel.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};
