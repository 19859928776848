import styled from 'styled-components';
import { Box } from '@oneboard/ui-components';

export const StyledQuizModal = styled.div`
  position: ${({ showConfirmModal }) => `${!showConfirmModal && 'absolute'}`};
  top: ${({ position }) => `${position?.top}px`};
  left: ${({ position }) => `${position?.left}px`};

  .ghost {
    background-color: transparent;
  }

  > .wrapper {
    border-radius: 20px;
    background-color: #fff;
    color: #637381;
    padding: 16px 24px 0;
    display: flex;
    flex-direction: column;

    > .body {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      overflow: hidden;
      margin: 0 12px;
      height: 346px;
    }

    .closeButton {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
`;

export const StyledOptionSelectView = styled.div`
  width: 480px;
  > .options {
    font-size: 16px;
    margin-top: 16px;
    > .label {
      margin-bottom: 8px;
    }
  }

  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 116px;
    font-size: 16px;
    border-radius: 16px;
    margin-top: 16px;
    color: #8b90a0;
  }

  > .action {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledOptionView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ clickable }) => (clickable ? 'auto' : 'none')};
  > .option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: #f4f6f8;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 700;

    &.active {
      background-color: #d0f2ff;
      color: #07b2d8;
    }
  }
`;

export const StyledQuizProgressingView = styled.div`
  margin-top: 10px;
  > .content {
    display: flex;
    justify-content: center;
    align-items: stretch;
    > .userListWrapper {
      position: relative;

      &:after {
        width: 8px;
        height: 8px;
        background-color: #ec7963;
        z-index: 1;
      }

      &:before {
        width: 32px;
        height: 24px;
        background-color: #33353a;
      }
    }

    > .statusBoxWrapper {
      height: 306px;
      > .label {
        margin-bottom: 20px;
      }

      .correctAnswerBox {
      }
    }
  }

  .primary {
    width: 100%;
    background-color: #ec7963;
  }
`;

export const StyledUserList = styled.div`
  position: relative;
  width: 100%;
  min-width: 320px;
  height: 500px;
  height: 100%;
  padding: 0 32px 0 0;
  background: #fff;

  > .listHeader {
    display: flex;
    justify-content: space-between;
    color: #919eab;
    padding: 0 10px 8px;
  }

  > .listBody {
    height: 100%;
    max-height: 280px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 64px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c0c0c0;
      opacity: 0.48;
      border-radius: 12px;
    }

    > .listItem {
      display: flex;
      justify-content: space-between;
      color: #637381;
      padding: 8px 10px;
    }
  }
`;

export const StatusBox = styled(Box)`
  padding: 16px;
  background-color: #f4f6f8;
  border-radius: 20px;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${({ disabled }) => (disabled ? '#8B90A0' : '#637381')};
  margin-top: 8px;

  &.correctStatus {
    > .count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 24px;
        color: ${({ disabled }) => (disabled ? '#8B90A0' : '#07B2D8')};
      }
    }
  }

  > .count {
    font-weight: 700;
    color: #8b90a0;
    span {
      font-size: 24px;
      color: #637381;
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  font-size: 18px;
  font-weight: 700;
  color: #212b36;
`;

export const StyledCloseModal = styled.div`
  width: 600px;
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);

  > .wrapper {
    border-radius: 12px;
    background-color: #fff;
    color: #d6dae0;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    > .body {
      display: flex;
      flex-direction: column;
      padding: 8px 0;
      overflow: hidden;
      color: #637381;
    }

    .closeButton {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
`;
