import styled from 'styled-components';

export const StyledInsertPictureModal = styled.div.attrs((props) => ({
  className: props.className,
}))`
  /* Code Here */
  .canvasBox {
    display: none;
  }
  .imageBox {
    > img {
      width: 100%;
    }
  }
`;

export const StyledUploadIconBox = styled.div`
  width: 190px;
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  cursor: pointer;
  color: #637381;
  .text {
    font-size: 20px;
  }
  &:hover {
    color: #ff7257;
    background-color: rgba(255, 114, 87, 0.08);
    border: 1px solid rgba(255, 114, 87, 0.48);
  }
`;

export const OperateButton = styled.div`
  cursor: pointer;
  margin: 0 36px;
  .icon {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ type }) => (type === 'primary' ? '#EC7963' : 'transparent')};
    border: 3px solid ${({ color }) => color};
    border-radius: 50%;
    background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'transparent')};
  }
  .text {
    font-size: 14px;
    text-align: center;
    margin-top: 4px;
    font-weight: 500;
    color: ${({ color }) => color};
  }
`;

export const SnapButton = styled.div`
  .icon {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    border: 4px solid ${({ color }) => color};
    border-radius: 50%;
    color: ${({ color }) => color && color};
    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
`;

export const SnapButtonBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  .snapButton {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    .icon {
      width: 80px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 3px solid ${({ color }) => color};
      border-radius: 50%;
      color: #fff;
    }
  }
  &:hover .snapButton {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export const StyledVideoContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledVideoBox = styled.div`
  width: 100%;
  padding-top: 56.25%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledCountdown = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '${({ count }) => count}';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 166px;
    height: 166px;
    font-size: 64px;
    background-color: #d9d9d980;
    border: 5px solid #d9d9d9;
    border-radius: 50%;
  }
`;

export const StyledNoticeTip = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;
  box-sizing: border-box;
  width: 276px;
  padding: 12px 16px;
  background-color: #4c99a5;
  color: #fff;
  font-size: 14px;
  border-radius: 8px;

  > ul {
    padding-left: 1.4rem;
    margin: 0;
    list-style: decimal;
  }
  .knowButton {
    color: #4c99a5;
    background-color: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .close {
    cursor: pointer;
  }
`;

export const StyledImgBox = styled.div`
  text-align: center;
  > img {
    width: 70%;
  }
`;
