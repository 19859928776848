import styled from 'styled-components';

export const CancelButton = styled.button`
  display: flex;
  width: fit-content;
  height: 36px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #eaeaea;
    border-color: rgba(145, 158, 171, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  }
`;

export const SendButton = styled(CancelButton)`
  color: ${({ disabled }) => (disabled ? '#919EABCC' : '#fff')};
  border: none;
  background-color: ${({ disabled }) => (disabled ? 'rgba(145, 158, 171, 0.24)' : '#ec7963')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: ${({ disabled }) => (disabled ? '#919EABCC' : '#fff')};
    background-color: ${({ disabled }) => (disabled ? 'rgba(145, 158, 171, 0.24)' : '#ec7963')};
    box-shadow: ${({ disabled }) => (disabled ? 'unset' : '0 2px 4px 0 rgba(0, 0, 0, 0.15);')};
  }
`;
