import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useRemoteVideoTileState,
  VideoGrid,
  useRosterState
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingDispatch,
  useMeetingState,
  RemoteVideo,
  LocalVideo,
} from '@oneboard/meeting';
import { Box } from '@oneboard/ui-components';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { MeetingVideoResizableRect } from 'components';
import { useGroupContext } from 'providers/GroupProvider';
import { StyledStagedVideo } from './StagedVideo.style';

export const StagedVideo = ({ className, stagedAttendeeIds, objectMode }) => {
  const { context: { attendeeId: selfAttendeeId, videoIsMirroring } } = useMeetingState();
  const { unstageAttendee, muteAttendee, unMuteAttendee } = useMeetingDispatch();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { roster } = useRosterState();

  const {
    usersMuteState,
  } = useGroupContext();

  const videoClickHandler = useCallback((attendeeId) => {
    unstageAttendee({ attendeeId });
    const isMuted = usersMuteState[attendeeId];
    isMuted ? muteAttendee({ attendeeId }) : unMuteAttendee({ attendeeId });
  }, []);

  const size = stagedAttendeeIds.length || 0;
  return (
    <StyledStagedVideo className={className} data-testid="StagedVideo">
      {objectMode ?
        <>
          {stagedAttendeeIds.includes(selfAttendeeId) &&
            <MeetingVideoResizableRect onClose={() => videoClickHandler(selfAttendeeId)} chimeAttendeeObj={roster[selfAttendeeId]} >
              {(ref) => <LocalVideo
                ref={ref}
                attendeeId={selfAttendeeId}
                className={!videoIsMirroring ? 'isFlip' : ''}
              />}
            </MeetingVideoResizableRect>}
          {stagedAttendeeIds
            .filter(attendeeId => attendeeId !== selfAttendeeId)
            .filter((_, index) => index < MAX_STUDENT_VIDEO_COUNT)
            .map(attendeeId => {
              return (
                <MeetingVideoResizableRect onClose={() => videoClickHandler(attendeeId)} chimeAttendeeObj={roster[attendeeId]}>
                  {(ref) => <RemoteVideo
                    key={attendeeId}
                    attendeeId={attendeeId}
                    tileId={attendeeIdToTileId[attendeeId]}
                    ref={ref}
                  />}
                </MeetingVideoResizableRect>
              );
            })
          }
        </> :
        <Box width="100%" height="100%" position='absolute' zIndex='15'>
          <VideoGrid
            size={size}
          >
            {
              stagedAttendeeIds.includes(selfAttendeeId) && (
                <LocalVideo
                  attendeeId={selfAttendeeId}
                  className={!videoIsMirroring ? 'isFlip' : ''}
                  onClick={() => videoClickHandler(selfAttendeeId)}
                  position="static"
                />
              )
            }
            {
              stagedAttendeeIds
                .filter(attendeeId => attendeeId !== selfAttendeeId)
                .filter((_, index) => index < MAX_STUDENT_VIDEO_COUNT)
                .map(attendeeId =>
                  <RemoteVideo
                    key={attendeeId}
                    attendeeId={attendeeId}
                    tileId={attendeeIdToTileId[attendeeId]}
                    position="static"
                    onClick={() => videoClickHandler(attendeeId)}
                  />
                )
            }
          </VideoGrid>
        </Box>}
    </StyledStagedVideo>
  );
};

StagedVideo.propTypes = {
  className: PropTypes.string,
  stagedAttendeeIds: PropTypes.array,
  objectMode: PropTypes.bool
};