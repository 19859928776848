import * as colors from './colorBase';

export default {
  scale: colors.$scale,

  // Pure functional
  text: {
    primary: colors.$gray1,
    secondary: colors.$gray3,
    tertiary: colors.$gray3,
    placeholder: colors.$gray5,
    disabled: colors.$gray5,
    link: colors.$blue3,
    danger: colors.$red4,
    success: colors.$green2,
    warning: colors.$yellow2,
    white: colors.$white,
  },
  icon: {
    primary: colors.$gray1,
    secondary: colors.$gray4,
    tertiary: colors.$gray5,
    info: colors.$blue2,
    danger: colors.$red4,
    success: colors.$green2,
    warning: colors.$yellow2,
  },
  border: {
    primary: colors.$gray6,
    secondary: colors.$gray7,
    tertiary: colors.$gray4,
    overlay: colors.$gray6,
    info: colors.$blue4,
    danger: colors.$blue4,
    success: colors.$green3,
    warning: colors.$yellow4,
  },
  bg: {
    primary: colors.$gray9,
    secondary: colors.$gray9,
    tertiary: colors.$gray8,
    info: colors.$blue4,
    danger: colors.$red4,
    success: colors.$green4,
    warning: colors.$yellow4,
  },
  state: {
    hover: {
      primaryBg: colors.$blue5,
      primaryBorder: colors.$blue4,
      primaryText: colors.$gray0,
      primaryIcon: colors.$gray0,
      secondaryBg: colors.$gray8,
      secondaryBorder: colors.$gray8,
    },

    selected: {
      primaryBg: colors.$blue5,
      primaryBorder: colors.$blue4,
      primaryText: colors.$gray0,
      primaryIcon: colors.$gray0,
    },

    focus: {
      border: colors.$blue4,
      shadow: colors.$blue8,
    },
  },

  // Components
  btn: {
    text: colors.$gray1,
    bg: colors.$gray3,
    border: colors.$gray6,

    hoverBg: colors.$gray6,
    hoverBorder: colors.$gray3,

    activeBg: colors.$gray6,
    activeBorder: colors.$gray4,

    focusBg: colors.$gray7,
    focusBorder: colors.$gray3,

    /* states */
    primary: {
      text: colors.$white,
      bg: colors.$green5,
      border: colors.$green4,

      hoverBg: colors.$green4,
      hoverBorder: colors.$green3,

      selectedBg: colors.$green5,

      disabledText: colors.$white,
      disabledBg: colors.$green5,
      disabledBorder: 'transparent',

      focusBg: colors.$green5,
      focusBorder: colors.$green3,

      icon: colors.$white,
    },

    outline: {
      text: colors.$blue3,

      hoverText: colors.$blue3,
      hoverBg: colors.$gray6,
      hoverBorder: colors.$blue3,

      selectedText: colors.$white,
      selectedBg: colors.$blue7,
      selectedBorder: colors.$white,

      disabledText: colors.$blue3,
      disabledBg: colors.$gray9,

      focusBorder: colors.$blue3,
    },

    danger: {
      text: colors.$red4,

      hoverText: colors.$white,
      hoverBg: colors.$red5,
      hoverBorder: colors.$red4,

      selectedText: colors.$white,
      selectedBg: colors.$red6,
      selectedBorder: colors.$white,

      disabledText: colors.$red4,
      disabledBg: colors.$gray9,

      focusBorder: colors.$red4,

      icon: colors.$red4,
      hoverIcon: colors.$white,
    },
  },

  label: {
    border: colors.$gray6,

    primary: {
      text: colors.$white,
      border: colors.$gray4,
      bg: colors.$orange2,
    },

    secondary: {
      text: colors.$gray3,
      border: colors.$gray6,
    },

    info: {
      text: colors.$blue4,
      border: colors.$blue6,
    },

    success: {
      text: colors.$green3,
      border: colors.$green5,
    },

    warning: {
      text: colors.$yellow2,
      border: colors.$yellow4,
    },

    danger: {
      text: colors.$red4,
      border: colors.$red6,
    },
  },

  input: {
    bg: colors.$gray9,
    border: colors.$gray7,

    disabledBg: colors.$gray8,
    disabledBorder: colors.$gray6,

    warningBorder: colors.$yellow3,
    errorBorder: colors.$red4,

    tooltip: {
      success: {
        text: colors.$green2,
        bg: colors.$green4,
        border: colors.$green4,
      },

      warning: {
        text: colors.$yellow2,
        bg: colors.$yellow4,
        border: colors.$yellow4,
      },

      error: {
        text: colors.$red3,
        bg: colors.$red4,
        border: colors.$red4,
      },
    },
  },

  // App level
};
