import styled from 'styled-components';

export const StyledWaitingAnimation = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #161C27;
`;

export const AnimationWrap = styled.div`
  width: 829px;
  height: 572px;
  background-image: url('/images/waitingWrap.png');
  background-size: cover;
  background-position-x: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`;

export const Animation = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 65%;
  .title {
    position: absolute;
    bottom: 20px;
    z-index: 1;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
  }
`;