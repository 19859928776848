import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Picker from 'emoji-picker-react';
import Icon from '@onedesign/icon';
import { Popover } from '@oneboard/ui-components';
import { StyledEmojiButton } from './EmojiButton.style';

export const EmojiButton = ({ insertEmojiHandler }) => {
  const [visibleState, setVisibleState] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    insertEmojiHandler(emojiObject);
    hideHandler();
  };

  const hideHandler = () => setVisibleState(false);

  const handleVisibleChange = (visible) => setVisibleState(visible);

  return (
    <StyledEmojiButton data-testid='EmojiPicker'>
      <Popover
        trigger='click'
        visible={visibleState}
        onVisibleChange={handleVisibleChange}
        content={<Picker onEmojiClick={onEmojiClick} />}
      >
        <Icon name='SmileOutline' size='md' />
      </Popover>
    </StyledEmojiButton>
  );
};

EmojiButton.propTypes = {
  insertEmojiHandler: PropTypes.func,
};
