import styled from 'styled-components';

export const StyledRemoteVideo = styled.div.attrs((props) => ({
  className: props.className,
}))`
  position: ${({ position }) => position ?? 'absolute'};
  top: 0;
  width: 100%;
  height: 100%;
  background: #c9eafb;
  display: flex;
  align-items: center;
  justify-content: center;

  .objectMode & {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 150px;
    left: 150px;
  }

  .tileBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledNameBox = styled.div`
  width: 64px;
  height: 64px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 64px;
  cursor: default;
`;
