import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { Tooltip } from 'antd';
import { FileUploader } from 'components';
import { IconBox } from './ScenesImageUploadButton.style';
import { t } from '@oneboard/meeting/src/utils/i18n';

export const ScenesImageUploadButton = ({ service, onUploaded }) => {

  const updateBackgroundImage = ({ fileName, url }) => {
    const sceneState = service.state.sceneState;
    const img = new Image();

    img.onload = function () {
      service.putScenes(sceneState.contextPath, [{
        name: fileName,
        ppt: {
          src: url,
          width: this.width,
          height: this.height
        }
      }]);

      const targetIndex = sceneState.scenes.length - 1;
      service.setSceneIndex(targetIndex);
      onUploaded();
    };
    img.src = url;

  };

  return (
    <FileUploader onUploaded={updateBackgroundImage}>
      <Tooltip placement="top" title={t('components.scenesImageUploadButton.uploadPhoto', '上傳相片至教材')}>
        <IconBox>
          <Icon name="ImageSolid" size="xs" />
        </IconBox>
      </Tooltip>
    </FileUploader>
  );
};

ScenesImageUploadButton.propTypes = {
  service: PropTypes.object,
  onUploaded: PropTypes.func,
};