import React, { useCallback, useState, useMemo } from 'react';
import {
  useLocalVideo,
  useToggleLocalMute,
  ContentShareControl,
  Sound,
  Camera,
  Microphone,
  useVideoInputs,
  useAudioInputs,
  useLocalAudioOutput,
  useAudioOutputs,
  ControlBar,
  ControlBarButton,
  darkTheme,
  useContentShareState,
  useContentShareControls,
  useMeetingManager,
  useBackgroundBlur,
  useBackgroundReplacement,
} from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';
import { Box, Popover } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import { CustomerService } from 'components';
import { useMeetingState, useMeetingDispatch } from '../../providers/MeetingProvider';
import {
  StyledSettingPopoverButton,
  ShareContentTipTitle,
  ShareContentTipContent,
  StyledIcon,
} from './SettingPopoverButton.style';
import { Tooltip } from 'antd';
import UserBgIcon from './icon/awsicon_userbg.svg';
import BanSolid from './icon/ban_solid.svg';
import UserSolid from './icon/user_solid.svg';
import OneclassIcon from './icon/oneclass_icon.svg';
import { t } from 'utils/i18n';

const videoInputConfig = {
  additionalDevices: false,
};

const audioInputConfig = {
  additionalDevices: false,
};

export const isOptionActive = (meetingManagerDeviceId, currentDeviceId) => {
  if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

const VideoInputControl = ({ label, onClick }) => {
  const meetingManager = useMeetingManager();
  const { devices, selectedDevice } = useVideoInputs(videoInputConfig);
  const { isVideoEnabled } = useLocalVideo();

  const dropdownOptions = devices.map((device) => ({
    children: <span>{device.label}</span>,
    checked: isOptionActive(selectedDevice, device.deviceId),
    onClick: () => meetingManager.startVideoInputDevice(device.deviceId),
  }));

  return (
    <ControlBarButton
      icon={<Camera disabled={!isVideoEnabled} />}
      onClick={onClick}
      label={label}
      popOver={dropdownOptions}
    />
  );
};

const AudioInputControl = ({ label, onClick, muted }) => {
  const meetingManager = useMeetingManager();
  const { devices, selectedDevice } = useAudioInputs(audioInputConfig);

  const dropdownOptions = devices.map((device) => ({
    children: <span>{device.label}</span>,
    checked: isOptionActive(selectedDevice, device.deviceId),
    onClick: () => meetingManager.startAudioInputDevice(device.deviceId),
  }));

  return (
    <ControlBarButton icon={<Microphone muted={muted} />} onClick={onClick} label={label} popOver={dropdownOptions} />
  );
};

const AudioOutputControl = ({ label, onClick, disabled }) => {
  const meetingManager = useMeetingManager();
  const { devices, selectedDevice } = useAudioOutputs(audioInputConfig);

  const dropdownOptions = devices.map((device) => ({
    children: <span>{device.label}</span>,
    checked: isOptionActive(selectedDevice, device.deviceId),
    onClick: () => meetingManager.startAudioOutputDevice(device.deviceId),
  }));

  return (
    <ControlBarButton icon={<Sound disabled={disabled} />} onClick={onClick} label={label} popOver={dropdownOptions} />
  );
};

const VideoEffectControl = ({ label, onClick, disabled }) => {
  const { clearVideoBackgroundEffect, videoBlurBackgroundEffect, videoBackgroundImageEffect } = useMeetingDispatch();

  const effectClickHandler = (index) => {
    const effects = [clearVideoBackgroundEffect, videoBlurBackgroundEffect, videoBackgroundImageEffect];
    effects[index]();
  };

  const dropdownOptions = useMemo(() => {
    return [
      {
        children: (
          <StyledIcon stroke='#A1A4B1'>
            <BanSolid />
          </StyledIcon>
        ),
        onClick: () => effectClickHandler(0),
      },
      {
        children: (
          <StyledIcon stroke='#A1A4B1'>
            <UserSolid />
          </StyledIcon>
        ),
        onClick: () => effectClickHandler(1),
      },
      {
        children: (
          <StyledIcon>
            <OneclassIcon />
          </StyledIcon>
        ),
        onClick: () => effectClickHandler(2),
      },
    ];
  }, []);

  return <ControlBarButton icon={<UserBgIcon />} onClick={onClick} label={label} popOver={dropdownOptions} />;
};

const SettingControl = ({ label }) => {
  return (
    <Tooltip title={t('components.settingPopoverButton.contactCustomerService', '聯絡客服')} placement='right'>
      <ControlBarButton icon={<CustomerService />} label={label} />
    </Tooltip>
  );
};

const ControlList = () => {
  const { context } = useMeetingState();
  const isStudent = context.role === 'student';
  const isTeacher = context.role === 'teacher';
  const { isBackgroundBlurSupported } = useBackgroundBlur();
  const { isBackgroundReplacementSupported } = useBackgroundReplacement();

  const style = `
    position: initial;
    height: auto;
    svg[data-testid="popover-check"] { 
      color: #000;
    }
  `;

  const { toggleVideo } = useLocalVideo();
  const { toggleMute, muted } = useToggleLocalMute();
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();

  const clickHandler = () => {
    !isStudent && toggleVideo();
  };

  const clickAudioInputHandler = () => {
    !isStudent && toggleMute();
  };

  const clickAudioOutputHandler = () => {
    !isStudent && toggleAudio();
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <ControlBar showLabels layout='right' css={style}>
        <AudioInputControl muted={muted} onClick={clickAudioInputHandler} />
        <AudioOutputControl label='' disabled={!isAudioOn} onClick={clickAudioOutputHandler} />
        <VideoInputControl label='' onClick={clickHandler} />
        {!isStudent && <ContentShareControl label='' />}
        {isBackgroundBlurSupported && isBackgroundReplacementSupported && !isTeacher && <VideoEffectControl label='' />}
        <SettingControl />
      </ControlBar>
    </ThemeProvider>
  );
};

export const SettingPopoverButton = ({ className, placement = 'top', disabledPopover = false }) => {
  const { isLocalUserSharing } = useContentShareState();
  const { toggleContentShare } = useContentShareControls();

  const [isOpen, setOpen] = useState(false);
  const togglePopOverHandler = useCallback(() => {
    setOpen((isOpen) => !isOpen);
  }, []);

  return (
    <Popover
      trigger='hover'
      placement={placement}
      title={
        <ShareContentTipTitle>
          {t('components.settingPopoverButton.shareScreenAndStartClass', '分享你的螢幕立即上課')}
        </ShareContentTipTitle>
      }
      visible={!disabledPopover && !isLocalUserSharing}
      content={
        <ShareContentTipContent>
          <div>{t('components.settingPopoverButton.noOneSharing', '目前無人進行分享')}</div>
          <div>{t('components.settingPopoverButton.joinUsingScreenShare', '使用「螢幕分享」和學生一起上課！')}</div>
          <button className='button' onClick={toggleContentShare}>
            {t('components.settingPopoverButton.shareNow', '立即分享')}
          </button>
        </ShareContentTipContent>
      }
    >
      <Popover placement={placement} arrow={false} content={<ControlList />}>
        <StyledSettingPopoverButton className={className} data-testid='SettingPopoverButton'>
          <Box className='iconButton' onClick={togglePopOverHandler}>
            <Icon name='CogSolid' size='sm' />
          </Box>
        </StyledSettingPopoverButton>
      </Popover>
    </Popover>
  );
};
