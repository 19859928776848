const PAPER_URL = process.env.REACT_APP_ONEPAPER_API_DOMAIN;

export const getQuestionsData = (payload) => {
  const apiUrl = `${PAPER_URL}/api/Resource/GetQuestions`;

  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('API Error:', error);
      throw error;
    });
};
