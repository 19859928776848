import styled from 'styled-components';

export const StyledSceneTab = styled.div.attrs(props => ({
className: props.className
}))`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  background-color: #f5f5f9;
  .ant-tabs-top > .ant-tabs-nav{
    margin: 0;
  }
`;


export const StyledInitTab = styled.div`
  min-width: 90px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  border: 1px solid ${({ isActive }) => isActive ? '#1890FF' : '#fff'};
  cursor: pointer;
  background-color: ${({ isActive }) => isActive ? '#EBF2FF' : '#fff'};
    &:hover {
      border-color: #EBF2FF;
      background-color: #EBF2FF;
    }
`;

export const StyledGap = styled.div`
  min-width: 2px;
  height: 16px;
  display: flex;
  background-color: #C4C4C4;
  margin: 0 8px;
`;

export const StyledTabs = styled.div`
  overflow-x: auto;
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTabPane = styled.div`
  max-width: 280px;
  height: 40px;
  padding: 8px 16px;
  margin: 0 4px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  border: 1px solid ${({ isActive }) => isActive ? '#1890FF' : '#C1C7D0'};
  cursor: pointer;
  background-color: ${({ isActive }) => isActive ? '#EBF2FF' : '#fff'};
    .label {
      margin-right: 8px;
      white-space: nowrap;
      overflow : hidden;
      text-overflow : ellipsis;
    }
    .icon {
      margin-left: 8px;
    }
    &:hover {
      background-color: #EBF2FF;
    }
    &:first-of-type {
      margin: 0 4px 0 0;
    }
    &:last-of-type {
      margin: 0 0 0 4px;
    }
`;
