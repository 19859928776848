import React from 'react';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import styled from 'styled-components';
import { Popover } from '@oneboard/ui-components';
import { useGroupContext } from 'providers/GroupProvider';
import { useMeetingDispatch } from '@oneboard/meeting';
import { t } from 'utils/i18n';

const MuteOption = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 0;
  width: 196px;
  .optionList {
    display: flex;
    margin: 0 16px;
    padding: 12px 16px;
    color: #637381;
    cursor: pointer;
    border-radius: 15px;

    &:hover {
      background-color: #ffeddd;
    }
  }
  .listIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .listContent {
    font-size: 14px;
    margin-left: 8px;
    line-height: 24px;
    flex: 1;

    .customerService {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
`;

const MuteAllContent = ({ muteDefaultControl }) => {
  const { usersMuteState, usersMuteHandler } = useGroupContext();
  const { muteAllAttendees, unMuteAllAttendees } = useMeetingDispatch();

  const unMuteAllHandler = () => {
    unMuteAllAttendees();
    muteDefaultControl(false);
    let usersKeys = Object.keys(usersMuteState);
    let newUsersState = {};
    usersKeys.forEach((user) => {
      newUsersState[user] = false;
    });
    usersMuteHandler(newUsersState);
  };

  const muteAllHandler = () => {
    muteAllAttendees();
    muteDefaultControl(true);
    let usersKeys = Object.keys(usersMuteState);
    let newUsersState = {};
    usersKeys.forEach((user) => {
      newUsersState[user] = true;
    });
    usersMuteHandler(newUsersState);
  };

  return (
    <MuteOption>
      <div className='optionList' onClick={unMuteAllHandler}>
        <div className='listIcon'>
          <Icon name='MicrophoneAltSolid' />
        </div>
        <div className='listContent'>{t('mainPage.teacher.syncMultiple.turnOnAllMicrophone', '開啟全體麥克風')}</div>
      </div>
      <div className='optionList' onClick={muteAllHandler}>
        <div className='listIcon'>
          <Icon name='MicrophoneAltSlashSolid' />
        </div>
        <div className='listContent'>{t('mainPage.teacher.syncMultiple.turnOffAllMicrophone', '關閉全體麥克風')}</div>
      </div>
    </MuteOption>
  );
};

const AllMicroButtonWrapper = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 56px;
  height: 56px;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.16);
  &:hover {
    background-color: #f2f2f2;
  }
`;

export default function AllMicroButton({ muteDefault, muteDefaultControl }) {
  const { isFooterOpen } = useFooterStatus();
  return (
    <>
      <Popover
        content={<MuteAllContent muteDefault={muteDefault} muteDefaultControl={muteDefaultControl} />}
        trigger='click'
        placement='top'
        arrow={true}
      >
        <AllMicroButtonWrapper isFooterOpen={isFooterOpen}>
          <ButtonContainer>
            {muteDefault ? (
              <Icon name='UserVolumeMuteSolid' size='xl' color='#EC7963' />
            ) : (
              <Icon name='UserVolumeSolid' size='xl' color='#8A94A6' />
            )}
          </ButtonContainer>
        </AllMicroButtonWrapper>
      </Popover>
    </>
  );
}
