import React from 'react';
import { Button } from 'antd';
import { Box } from '@oneboard/ui-components';
import { useBitrixService } from 'utils/hooks/useBitrix';
import { StyledFallbackComponent } from './FallbackComponent.style';

export const FallbackComponent = ({ className }) => {
  const { url: bitrixUrl } = useBitrixService();
  return (
    <StyledFallbackComponent className={className} data-testid="FallbackComponent">
      <div className="wrap">
        <div className="textContent">
          <div className="oops">
            Oops!
          </div>
          <div className="title">
            啊呀！網頁出錯了
          </div>
          <div className="content">
            <div>
              您可以嘗試重新整理網頁，或聯繫客服人員處理。
            </div>
            <div>
              客服專線：<span className="phone">(02)8226-3699</span>
            </div>
            <Box mt={1}>
              <Button type="primary" href={bitrixUrl} target="_blank">即時線上客服</Button>
            </Box>
          </div>
        </div>
        <div className="imgContent">
          <img src="/images/sorry.png" alt="" />
        </div>
      </div>
    </StyledFallbackComponent>
  );
};