import { useQuery } from 'utils/hooks/useQuery';

export const useCheckDevice = () => {
  const query = useQuery();
  const notCheckCam = query.checkCam === 'false';
  const notCheckMic = query.checkMic === 'false';

  const allNotCheck = notCheckCam && notCheckMic;

  return {
    allNotCheck,
  };
};
