import React from 'react';
import PropTypes from 'prop-types';
import { ClassType } from 'constants/index';
import { useRemoteVideoTileState, useAttendeeStatus } from 'amazon-chime-sdk-component-library-react';
import VideoWrapper from '../common/VideoWrapper';
import VideoContainer from '../common/VideoContainer';
import VideoContentContainer from '../common/VideoContentContainer';
import VolumeContainer from '../common/VolumeContainer';
import IconWrapper from '../../common/IconWrapper';
import StyledImage from '../../common/StyledImage';
import UserName from '../common/UserName';
import { RemoteVideoV2,useMeetingState } from '@oneboard/meeting';
import NotOpen from '../common/NotOpen';
import { MicroBlack } from '../../common/StyledIcon';
import VolumeIndicator from '../common/VolumeIndicator';
import Icon from '@onedesign/icon';
import StudentIcon from 'assets/newOneBoard/images/student_icon.png';
import { useWindowWidth } from '../Student/hooks/useWindowWidth';

export default function StudentVideo({ studentId, studentName, studentRole, queryToolboxAuthById }) {
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { muted: studentMuted, videoEnabled: studentVideoEnabled } = useAttendeeStatus(studentId);
  const studentToolboxAuth = queryToolboxAuthById({ attendeeId: studentId });
  const windowWidth = useWindowWidth();
  const meetingState = useMeetingState();
  const { courseType } = meetingState.context;
  const totalVolumeLevel = windowWidth < 1224 ? 8 : 10;

  return (
    <>
      {(courseType === ClassType.SyncSingle && studentId) && (
        <VideoContainer>
          <VideoWrapper>
            {studentVideoEnabled ? (
              <RemoteVideoV2 attendeeId={studentId} tileId={attendeeIdToTileId[studentId]} />
            ) : (
              <NotOpen />
            )}
          </VideoWrapper>
          <VideoContentContainer>
            <StyledImage src={StudentIcon} alt='name icon' width={28} height={28} />
            <UserName>{studentName}</UserName>
            <IconWrapper isEnabled={studentVideoEnabled}>
              <Icon name={studentVideoEnabled ? 'VideoSolid' : 'VideoSlashSolid'} size='xxs' color='#FFFFFF' />
            </IconWrapper>
            <IconWrapper isEnabled={!studentMuted}>
              <Icon name={studentMuted ? 'MicrophoneAltSlashSolid' : 'MicrophoneAltSolid'} size='xs' color='#FFFFFF' />
            </IconWrapper>
            <IconWrapper isEnabled={studentToolboxAuth} userType={studentRole}>
              {studentToolboxAuth ? (
                <Icon name='ChalkboardOutline' size='xs' color='#FFFFFF' />
              ) : (
                <Icon name='ChalkboardSlashSolid' size='xs' color='#FFFFFF' />
              )}
            </IconWrapper>
          </VideoContentContainer>
          <VolumeContainer>
            <MicroBlack />
            <VolumeIndicator attendeeId={studentId} totalVolumeLevel={totalVolumeLevel} />
          </VolumeContainer>
        </VideoContainer>
      )}
    </>
  );
}

StudentVideo.propTypes = {
  studentId: PropTypes.string.isRequired,
  studentName: PropTypes.string.isRequired,
  studentRole: PropTypes.string.isRequired,
  queryToolboxAuthById: PropTypes.func.isRequired,
};
