
import queryString from 'query-string';
import { Roles } from 'constants/index';
import { shortId } from 'utils';

const BASE_URL = process.env.REACT_APP_ONEBOARD_API_URL;

export const startRecordVideo = async ({ meetingId, breakoutId = '' }) => {

  const parsed = queryString.parse(window.location.search);
  const { classType } = parsed;

  const stringified = queryString.stringify({
    classType,
    role: Roles.Observer,
    userName: 'recordObserver',
    userId: shortId()
  });

  const recordurl = breakoutId ?
    `${process.env.REACT_APP_HOSTING_DOMAIN}/${meetingId}/breakout/${breakoutId}/${Roles.Observer}?${stringified}` :
    `${process.env.REACT_APP_HOSTING_DOMAIN}/${meetingId}/${Roles.Observer}?${stringified}`;

  return await fetch(`${BASE_URL}/agora/start_record`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      roomId: breakoutId ? breakoutId : meetingId,
      recordurl
    })
  });
};


export const stopRecordVideo = async ({ meetingId }) => {
  return await fetch(`${BASE_URL}/agora/stop_record`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      roomId: meetingId,
    })
  });
};

export const recordVideoInfo = async ({ meetingId }) => {
  return await fetch(`${BASE_URL}/agora/get_recording_times?roomId=${meetingId}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
    },
  });
};