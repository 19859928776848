import styled from 'styled-components';

export const StyledSyncWhiteboard = styled.div.attrs(props => ({
  className: props.className
}))`
  position: relative;
  width: 100%;
  height: 100%;
  .loadingWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  iframe#IframeBridge {
    z-index: -1;
  }

  &.clicker {
    iframe#IframeBridge {
      z-index: 0;
    } 
  }
`;

export const StyledToolBox = styled.div`
  display: ${({ show }) => show ? 'block' : 'none'};
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #212324;
  box-shadow: 0 8px 24px 0 rgb(0 0 0 / 8%);
  cursor: pointer;
  background: #fff;

  &:hover {
    background: rgba(33, 35, 36, 0.1);
  }
`;
