import useWebSocket, { ReadyState } from 'react-use-websocket';

const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL;

export const ReadyStateMap = ReadyState;

export const useSocket = ({ roomId, userId }) => {
  return useWebSocket(SOCKET_URL, {
    queryParams: {
      roomId,
      userId
    },
    share: true,
    shouldReconnect: (closeEvent) => true,
    retryOnError: true
  });
};