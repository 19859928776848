/**
 * 取得登入的token
 */
const getNaniToken = () => {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)nani_oneclass_login_token\s*=\s*([^;]*).*$)|^.*$/, '$1');

  if (token) {
    const jwt = JSON.parse(token).jwt;
    return jwt;
  }
};

export const naniToken = getNaniToken();

export const isLogin = () => naniToken ? true : false;