import { fetch } from './index';
import { API_STATUS } from 'constants/index';
import { trampolines } from 'utils/optimization';

const MICROSERVICE_URL = process.env.REACT_APP_MICROSERVICE_API_DOMAIN;
/**
 * 取得課程資料
 * @param {string[]} courseId - course id
 * @returns
 */
export const getCourse = (courseId) => {
  return fetch(`${MICROSERVICE_URL}/external/course/${courseId}`);
};

/**
 * 取得叢書教材
 * @param {Object} params
 * @param {number} params.limit
 * @param {number} params.skip
 * @param {string[]} params.keywords
 * @param {string[]} params.seriesCodes - 叢書代碼
 * @param {string[]} params.ids - 教材書本id
 * @returns
 */
export const getResource = (params) => {
  return fetch(`${MICROSERVICE_URL}/external/tag/resource`, params);
};

/**
 * 取得叢書
 * @param {Object} params
 * @param {number} params.limit
 * @param {number} params.skip
 * @param {string[]} params.keywords
 * @param {string[]} params.courseTags - 課程標籤
 * @param {string} params.ownerId
 * @returns
 */
export const getSeries = (params) => {
  return fetch(`${MICROSERVICE_URL}/external/tag/series`, params);
};

export const fetchTagsByRecursion = async ({ fetchFunc, params, limit = 100 }) => {
  const _params = {
    ...params,
    skip: 0,
    limit,
  };

  const response = await fetchFunc(_params);
  const { status, data, error } = response;

  if (error) return Promise.reject(new Error(error.message));

  if (status === API_STATUS.SUCCESS && data) {
    const { tags, total } = data;
    const allTags = [...tags]; // 存儲所有資料的陣列
    const totalPages = Math.ceil(total / limit); // 總頁數

    const fetchData = async (page, accTags = allTags) => {
      if (page > totalPages) {
        return accTags;
      }
      _params.skip = (page - 1) * limit;
      _params.limit = limit;

      const response = await fetchFunc(_params);
      const { status, data, error } = response;

      if (error) return Promise.reject(new Error(error.message));

      if (status === API_STATUS.SUCCESS && data) {
        const { tags } = data;
        const newTags = [...accTags, ...tags];

        return trampolines(fetchData)(page + 1, newTags);
      } else {
        throw new Error('取得失敗');
      }
    };

    return trampolines(fetchData)(2);
  } else {
    throw new Error('取得失敗');
  }
};
