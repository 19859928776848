import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { StyledRemoteVideo, StyledNameBox } from './RemoteVideo.style';
import {
  RemoteVideo as ChimeRemoteVideo,
  useRosterState,
  useAttendeeStatus,
} from 'amazon-chime-sdk-component-library-react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

/**
 * @param {string} className
 * @param {string} attendeeId
 * @param {number} tileId
 * @param {function} onClick
 * @param {boolean} isShowNameplate
 * @param {object} position
 * @param {boolean} isEnableMask - show streaming mask
 */
export const RemoteVideo = forwardRef(
  ({ className, tileId, attendeeId, onClick, isShowNameplate, position, isEnableMask }, ref) => {
    const { roster } = useRosterState();
    const attendee = roster[attendeeId];
    const { videoEnabled } = useAttendeeStatus(attendeeId);

    return (
      <StyledRemoteVideo
        className={className}
        data-testid='RemoteVideo'
        ref={ref}
        position={position}
        onClick={onClick}
      >
        {isEnableMask ? (
          <StyledImage src={'/streaming.png'} alt='streaming icon' width={64} height={64} />
        ) : (
          <>
            {videoEnabled && !isShowNameplate ? (
              <ChimeRemoteVideo tileId={tileId} />
            ) : (
              <div className='tileBox'>
                <StyledNameBox bgColor={attendee?.color}>{attendee?.name?.slice(0, 1)}</StyledNameBox>
              </div>
            )}
          </>
        )}
      </StyledRemoteVideo>
    );
  }
);

RemoteVideo.propTypes = {
  className: PropTypes.string,
  attendeeId: PropTypes.string,
  tileId: PropTypes.number,
  onClick: PropTypes.func,
  isShowNameplate: PropTypes.bool,
};
