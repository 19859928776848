import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ResizableRect } from 'components';
import { StyledWorldWallContainer, ControlWrap } from './WorldWallContainer.style';

const ControlBox = forwardRef(({ children, className }, ref) => {
  return (
    <ControlWrap className={className} ref={ref}>
      {children}
    </ControlWrap>
  );
});

ControlBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export const WorldWallContainer = ({ className, url }) => {
  return (
    <StyledWorldWallContainer className={className} data-testid="WorldWallContainer">
      <ResizableRect key={url}>
        {(ref) => (
          <ControlBox key={url} ref={ref} className="iframeBox">
            <iframe title='worldWall' src={url} frameborder="0" />
          </ControlBox>
        )}
      </ResizableRect>
    </StyledWorldWallContainer>
  );
};

WorldWallContainer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
};