import styled from 'styled-components';

export const StyledSyncSingle = styled.div`
  height: ${({ networkNotice }) => networkNotice ? 'calc(100% - 60px)' : '100%'};
  
  .auditListLayout {
    position: absolute;
    top: 0;
    right: 0;
    z-index:100;
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #212324;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
  background: #fff;

  &:hover {
    background: rgba(33, 35, 36, 0.1);
  }
`;

export const NotificationLayout = styled.div`
  position: relative;
  z-index: 10;
  padding: 12px 8px;
  background-color: #161C27;
`;

export const StyledPrivateMessageButton = styled.button`
  width: 56px;
  height: 56px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

export const StyledAwardButton = styled.button`
  width: 56px;
  height: 56px;
  background-color: rgba(217, 217, 217, 1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;