import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCountDown } from 'utils/hooks/useCountDown';
import { useGroupContext } from 'providers/GroupProvider';
import {
  useMeetingDispatch,
  useMeetingState
} from '@oneboard/meeting';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import Icon from '@onedesign/icon';
import { Box, Popover } from '@oneboard/ui-components';
import { Roles } from 'constants/index';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import {
  StyledBreakoutRoomNotification,
  StyledSubRoomNotification,
  StyledSubRoomInvite,
  StyledSingleInvite,
  StyledMultipleInvite,
  StyledBreakoutRoomResultNotification,
  StyledMemberList,
} from './BreakoutRoomNotification.style';

export const BreakoutRoomNotification = ({ className, meetingId }) => {
  const {
    toggleBreakoutRoomSidebar,
    breakoutRoomIsLeaving,
    toggleBreakoutRoomIsLeaving,
    BreakoutRoomState,
    changeBreakoutRoomStep
  } = useGroupContext();
  const { countDownNumber, startCountDown, endCountDown } = useCountDown();

  useEffect(() => {
    if (breakoutRoomIsLeaving) {
      startCountDown();
    }
  }, [breakoutRoomIsLeaving]);

  useEffect(() => {
    if (countDownNumber !== 0) return;
    endCountDown();
    changeBreakoutRoomStep(BreakoutRoomState.setting);
    toggleBreakoutRoomIsLeaving();
  }, [countDownNumber]);

  return (
    <StyledBreakoutRoomNotification className={className} isLeaving={breakoutRoomIsLeaving} data-testid="BreakoutRoomNotification">
      {!breakoutRoomIsLeaving && <div className="title">分組討論進行中</div>}
      {breakoutRoomIsLeaving && <div className="title">分組討論即將於 {countDownNumber} 秒後關閉</div>}
      <div className="action">
        <div className="btn" onClick={toggleBreakoutRoomSidebar}>查看分組討論</div>
      </div>
    </StyledBreakoutRoomNotification>
  );
};

BreakoutRoomNotification.propTypes = {
  className: PropTypes.string,
  meetingId: PropTypes.string,
};

const SubRoomInvite = ({ inviteData = [] }) => {
  const [contentSwitch, setContentSwitch] = useState(false);
  const { redirectToMainMeeting, goToBreakoutMeeting } = useBreakoutMeeting();
  const meetingState = useMeetingState();
  const { callTeacherGroups } = meetingState.context;
  const { removeCallTeacherGroups } = useMeetingDispatch();
  const breakoutRoomStorage = window.localStorage;

  const toggleContentSwitch = () => setContentSwitch(prev => !prev);

  const backToMainMeetingHandler = () => {
    breakoutRoomStorage.setItem('callTeacherData', JSON.stringify(callTeacherGroups));
    redirectToMainMeeting();
  };

  const goToBreakoutMeetingHandler = (breakoutId) => {
    removeCallTeacherGroups({ breakoutRoomId: breakoutId });
    const callTeacherGroupsData = callTeacherGroups.filter(group => group.breakoutRoomId !== breakoutId);
    breakoutRoomStorage.setItem('callTeacherData', JSON.stringify(callTeacherGroupsData));
    goToBreakoutMeeting({ breakoutId });
  };

  return (
    <StyledSubRoomInvite>
      { inviteData.length === 1 && <StyledSingleInvite>
        <div className="title">收到{inviteData[0].groupName}分組討論加入請求</div>
        <div className="action">
          <div className="btn primary" onClick={backToMainMeetingHandler}>返回主教室</div>
          <div className="btn" onClick={() => goToBreakoutMeetingHandler(inviteData[0].breakoutRoomId)}>加入討論</div>
        </div>
      </StyledSingleInvite>}
      {inviteData.length > 1 && <StyledMultipleInvite isOpen={contentSwitch}>
        <div className="head">
          <div className="title">收到多組加入請求</div>
          <div className="action">
            <div className="btn primary" onClick={backToMainMeetingHandler}>返回主教室</div>
            <div className="btn" onClick={toggleContentSwitch}>{contentSwitch ? '隱藏' : '顯示'}</div>
          </div>
        </div>
        {contentSwitch && <div className="content">
          {inviteData.map(group => {
            return <div className="inviteList" key={group.breakoutRoomId}>
              <div className="listTitle">{group.groupName}分組討論邀請</div>
              <div className="listAction">
                <div className="btn" onClick={() => goToBreakoutMeetingHandler(group.breakoutRoomId)}>
                  加入討論
                </div>
              </div>
            </div>;
          })}
        </div>}
      </StyledMultipleInvite>}
    </StyledSubRoomInvite>
  );
};

SubRoomInvite.propTypes = {
  inviteData: PropTypes.array,
};
export const TeacherSubRoomNotification = ({ className, title }) => {
  const { redirectToMainMeeting, isBreakoutRoom } = useBreakoutMeeting();
  const { countDownNumber, startCountDown, endCountDown } = useCountDown();
  const meetingState = useMeetingState();
  const { callTeacherGroups } = meetingState.context;
  const {
    breakoutRoomIsLeaving,
  } = useGroupContext();
  const breakoutRoomStorage = window.localStorage;

  const backToMainMeetingHandler = () => {
    breakoutRoomStorage.setItem('callTeacherData', JSON.stringify(callTeacherGroups));
    redirectToMainMeeting();
  };

  useEffect(() => {
    if (breakoutRoomIsLeaving) {
      startCountDown();
    }
  }, [breakoutRoomIsLeaving]);

  useEffect(() => {
    if (countDownNumber !== 0) return;
    endCountDown();
    if (isBreakoutRoom) {
      redirectToMainMeeting();
    }
  }, [countDownNumber]);

  return (
    <>
      {!breakoutRoomIsLeaving && callTeacherGroups.length === 0 && <StyledSubRoomNotification className={className} isLeaving={breakoutRoomIsLeaving}>
        <div className="title">{title}</div>
        <div className="action">
          <div className="btn primary" onClick={backToMainMeetingHandler}>返回主教室</div>
        </div>
      </StyledSubRoomNotification>}
      {breakoutRoomIsLeaving && <StyledSubRoomNotification className={className} isLeaving={breakoutRoomIsLeaving}>
        <div className="title">分組討論即將於{countDownNumber}秒後關閉</div>
        <div className="action">
          <div className="btn primary" onClick={backToMainMeetingHandler}>返回主教室</div>
        </div>
      </StyledSubRoomNotification>}
      {!breakoutRoomIsLeaving && callTeacherGroups.length > 0 && <SubRoomInvite inviteData={callTeacherGroups} />}
    </>
  );
};

TeacherSubRoomNotification.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export const StudentMainRoomNotification = ({ className, roomInfo, isLeaving = false }) => {
  const { redirectToBreakoutMeeting } = useBreakoutMeeting();
  const { countDownNumber, startCountDown, endCountDown } = useCountDown();
  const { breakoutMeetingEnd } = useMeetingDispatch();
  const {
    BreakoutRoomState,
    changeBreakoutRoomStep
  } = useGroupContext();

  useEffect(() => {
    if (isLeaving) {
      startCountDown();
    }
  }, [isLeaving]);

  useEffect(() => {
    if (countDownNumber !== 0) return;
    endCountDown();
    breakoutMeetingEnd();
    changeBreakoutRoomStep(BreakoutRoomState.setting);
  }, [countDownNumber]);

  return (
    <StyledBreakoutRoomNotification className={className} isLeaving={isLeaving}>
      {!isLeaving && (
        <>
          <div className="title">您獲邀加入{roomInfo?.groupName}</div>
          <div className="action">
            <div className="btn primary" onClick={redirectToBreakoutMeeting}>加入</div>
          </div>
        </>
      )}
      {isLeaving && <div className="title">分組討論即將於{countDownNumber}秒後關閉</div>}
    </StyledBreakoutRoomNotification>
  );
};

StudentMainRoomNotification.propTypes = {
  className: PropTypes.string,
  roomInfo: PropTypes.object,
  onGroupInvite: PropTypes.func,
  isLeaving: PropTypes.bool,
};

export const StudentSubRoomNotification = ({ className, title, onGroupInvite, isLeaving = false }) => {
  const { redirectToMainMeeting } = useBreakoutMeeting();
  const { countDownNumber, startCountDown, endCountDown } = useCountDown();
  const { breakoutMeetingEnd } = useMeetingDispatch();

  useEffect(() => {
    if (isLeaving) {
      startCountDown();
    }
  }, [isLeaving]);

  useEffect(() => {
    if (countDownNumber !== 0) return;
    endCountDown();
    breakoutMeetingEnd();
  }, [countDownNumber]);

  return (
    <StyledSubRoomNotification className={className} isLeaving={isLeaving}>
      {!isLeaving && <div className="title">{title}</div>}
      {isLeaving && <div className="title">分組討論即將於{countDownNumber}秒後關閉</div>}
      <div className="action">
        {!isLeaving && <div className="btn" onClick={onGroupInvite}>邀請老師加入</div>}
        <div className="btn primary" onClick={redirectToMainMeeting}>返回主教室</div>
      </div>
    </StyledSubRoomNotification>
  );
};

StudentSubRoomNotification.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onGroupInvite: PropTypes.func,
  isLeaving: PropTypes.bool,
};

const MemberList = ({ members, onClick }) => {
  const meetingState = useMeetingState();
  const { stagedAttendeeIds } = meetingState.context;
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = useMemo(() => attendees.filter(attendee => attendee.role === Roles.Student), [attendees]);

  return (
    <StyledMemberList>
      {members && members
        .filter(member => member.chimeAttendeeId)
        .map(member => {
          const { userId, userName, chimeAttendeeId } = member;
          const isOnStaged = stagedAttendeeIds.includes(chimeAttendeeId);
          const isExist = students.map(student => student.chimeAttendeeId).includes(chimeAttendeeId);

          return (
            <div className="list" key={userId}>
              <div className="content">{userName}</div>
              <div className="action">
                {isExist && <button
                  onClick={() => onClick({ isOnStaged, attendeeId: chimeAttendeeId })}
                  className={`btn ${isOnStaged ? 'isOnStaged' : ''}`}
                >
                  {isOnStaged ? '已上台' : '上台'}
                </button>}
              </div>
            </div>
          );
        })
      }
    </StyledMemberList>
  );
};

MemberList.propTypes = {
  members: PropTypes.array,
  onClick: PropTypes.func
};

export const BreakoutRoomResultNotification = ({ className, service }) => {
  const {
    breakoutRoomResultState,
    breakoutRoomResultActions,
    breakoutRoomResultData,
    resultReviewActions,
    setBreakoutRoomIsEndState,
    breakoutRoomResultGroup,
    setBreakoutRoomResultGroup,
    breakoutRoomResultGroupInfo,
    setBreakoutRoomResultGroupInfo,
    removeSceneQueue,
    sceneTabsData
  } = useGroupContext();
  const { roster } = useRosterState();
  const { stageAttendee, unstageAttendee, muteAttendee, unMuteAttendee } = useMeetingDispatch();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = useMemo(() => attendees.filter(attendee => attendee.role === Roles.Student), [attendees]);
  const [membersData, setMembersData] = useState([]);
  const [sceneStateIndex, setSceneStateIndex] = useState(0);
  const { scaleToFit } = useZoomControl();

  const closeHandler = () => {
    const { queue, sceneMap } = sceneTabsData;
    const resultIndex = queue.findIndex(() => breakoutRoomResultData.courseId);

    const isExistQueue = queue.find(() => breakoutRoomResultData.courseId);

    if (isExistQueue) {
      let nextBookId = '';

      if (queue.length === 1) {
        nextBookId = 'init';
      } else if (resultIndex !== queue.length - 1 ) {
        nextBookId = queue[resultIndex + 1];
        const nextPage = sceneMap[nextBookId].page;
        service.setScenePath(`/${nextBookId}/${nextPage}`);
      } else {
        nextBookId = queue[resultIndex - 1];
        const nextPage = sceneMap[nextBookId].page;
        service.setScenePath(`/${nextBookId}/${nextPage}`);
      }
      removeSceneQueue({ bookId: breakoutRoomResultData.courseId, nextBookId });
      scaleToFit();
    }

    service.removeScenes(`/${breakoutRoomResultData.courseId}`);
    resultReviewActions.setFalse();
    breakoutRoomResultActions.setFalse();
    setBreakoutRoomIsEndState();
    setBreakoutRoomResultGroup([]);
    setBreakoutRoomResultGroupInfo({});
  };

  const backTotalResultHandler = () => {
    breakoutRoomResultActions.setTrue();
  };

  const combineMembers = (members) => {
    return members.map(member => {
      const { userId } = member;
      return {
        ...member,
        chimeAttendeeId: students.filter(student => student.userId === userId)[0]?.chimeAttendeeId || ''
      };
    });
  };

  const toggleStageHandler = ({ isOnStaged, attendeeId }) => {
    if (isOnStaged) {
      muteAttendee({ attendeeId });
      unstageAttendee({ attendeeId });
      return;
    }
    isOnStaged ? muteAttendee({ attendeeId }) : unMuteAttendee({ attendeeId }) ;
    isOnStaged ? unstageAttendee({ attendeeId }) : stageAttendee({ attendeeId });
  };

  useEffect(() => {
    if (!breakoutRoomResultGroupInfo) return;
    const members = breakoutRoomResultGroupInfo?.members || [];
    setMembersData(members);
  }, [breakoutRoomResultGroupInfo]);

  useEffect(() => {
    if (breakoutRoomResultGroup.length === 0) return;
    setBreakoutRoomResultGroupInfo(breakoutRoomResultGroup[sceneStateIndex]);
  }, [sceneStateIndex]);

  useEffect(() => {
    if (!service) return;
    service.callbacks.on('onRoomStateChanged', function(state) {
      if (state.sceneState) {
        setSceneStateIndex(state.sceneState.index);
      }
    });
  }, [service]);

  return (
    <StyledBreakoutRoomResultNotification className={className}>
      <Box display="flex" alignItems="center">
        {breakoutRoomResultState && <div className="title">分組討論結果{` - ${breakoutRoomResultData.startTime}`}</div>}
        {!breakoutRoomResultState && (
          <>
            <div className="title">{breakoutRoomResultGroupInfo?.groupName} 討論結果</div>
            <Box ml={2}>
              <Popover
                arrow={true}
                trigger="click"
                placement="bottomLeft"
                content={<MemberList members={membersData} onClick={toggleStageHandler} />}
              >
                <div className="memberTip">
                  <Icon name="InfoSolid" color='#fff' size="xxs" />
                </div>
              </Popover>
            </Box>
          </>
        )}
      </Box>
      <div className="action">
        {!breakoutRoomResultState && <div className="btn primary" onClick={backTotalResultHandler}>返回所有分組結果</div>}
        {breakoutRoomResultState && <div className="btn primary" onClick={closeHandler}>關閉討論結果</div>}
      </div>
    </StyledBreakoutRoomResultNotification>
  );
};

BreakoutRoomResultNotification.propTypes = {
  className: PropTypes.string,
  service: PropTypes.object,
};
