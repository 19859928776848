import styled from 'styled-components';

export const StyledSyncStagedVideoList = styled.div.attrs(props => ({
  className: props.className
}))`
  width: 100%;
  height: 100%;

  .videoListWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    >div {
      width: ${({ isObserver }) => isObserver ? 'calc(100% / 6)' : 'calc(100% / 5)'};
      margin: 8px;
    }
  }
`;