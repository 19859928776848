import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRosterState, useAttendeeStatus } from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch, useMeetingState, MeetingStates, MeetingRoles } from '@oneboard/meeting';
import { Box } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import { useScrollbar } from 'utils/hooks/useScrollbar';
import {
  StyleUsersModal,
  StyleHeader,
  StyledTeacher,
  StyledUserCell,
  StyledModalHead,
  StyledModalBody,
  StyledRewards,
  StyledStage,
  StyledName,
  StyleLiteUsersModal
} from './UsersModal.style';
import { Roles } from 'constants/index';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useGroupContext } from 'providers/GroupProvider';
import { useToolboxAuth } from 'utils/hooks/useToolboxAuth';

const UserCell = ({ attendeeId, name, toggleVideoHandler, toggleStageHandler, userId }) => {
  const {
    usersMuteHandler,
  } = useGroupContext();
  const { videoList: videoStudentList } = useVideoListContext();

  const meetingState = useMeetingState();
  const { context, role } = meetingState;
  const { stagedAttendeeIds, raisedHandAttendeeIds, rewards } = context;
  const {
    muteAttendee,
    unMuteAttendee,
    sendReward,
    updateReward,
    updateRewardFromAdvisor
  } = useMeetingDispatch();
  const {
    muted,
    videoEnabled
  } = useAttendeeStatus(attendeeId);
  const isRewardAnimateActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`
  }) || meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`
  });
  const isOnStaged = stagedAttendeeIds.includes(attendeeId);

  const muteHandler = ({ attendeeId, isMuted }) => {
    isMuted ? unMuteAttendee({ attendeeId }) : muteAttendee({ attendeeId });
    usersMuteHandler({
      [attendeeId]: !isMuted
    });
  };

  const toggleStage = () => toggleStageHandler({ isOnStaged, attendeeId });

  const isRaiseHand = raisedHandAttendeeIds.includes(attendeeId);

  const { queryToolboxAuthById, toggleToolboxAuth } = useToolboxAuth();
  const isEnableToolbox = queryToolboxAuthById({ attendeeId });

  const rewardHandler = ({ attendeeId, userId, studentName }) => {
    const rewardInStage = videoStudentList.find(video => video.chimeAttendeeId === attendeeId);

    if (isRewardAnimateActive) return;

    if (role === Roles.Advisor) {
      updateRewardFromAdvisor({ userId, studentName });
    } else {
      updateReward({ userId, studentName });
    }

    if (rewardInStage) sendReward({ attendeeId, userId, studentName });
  };

  return (
    <StyledUserCell>
      <StyledName flex={1} active={isOnStaged}>{name}</StyledName>
      <StyledRewards onClick={() => rewardHandler({ attendeeId, userId, studentName: name })}>
        <Icon name="TrophySolid" />
        <Box textAlign="center" width="40px" px={2}>{rewards[userId] || 0}</Box>
      </StyledRewards>
      <Box className="iconGroup" flex={1} display="flex">
        <Box display="flex" alignItem="center" onClick={() => muteHandler({ attendeeId, isMuted: muted })}>
          {
            muted ? <Icon name="MicrophoneAltSlashSolid" /> :
              <Icon name="MicrophoneAltSolid" />
          }
        </Box>
        <Box display="flex" alignItem="center" ml={1} onClick={() => toggleVideoHandler({ attendeeId, isShowVideo: videoEnabled })}>
          {
            videoEnabled ? <Icon name="VideoSolid" /> :
              <Icon name="VideoSlashSolid" />
          }
        </Box>
        <Box display="flex" alignItem="center" ml={1} onClick={() => toggleToolboxAuth({ attendeeId })}>
          {
            isEnableToolbox ? <Icon name="ChalkboardOutline" /> :
              <Icon name="ChalkboardSlashSolid" />
          }
        </Box>
      </Box>
      <StyledStage
        onClick={toggleStage}
        isOnStaged={isOnStaged}
        isRaiseHand={isRaiseHand}
      >
        <Icon name="RaisehandSolid" />
      </StyledStage>
    </StyledUserCell>
  );
};

UserCell.propTypes = {
  attendeeId: PropTypes.string,
  name: PropTypes.string,
  toggleVideoHandler: PropTypes.func,
  toggleStageHandler: PropTypes.func,
  userId: PropTypes.string,
};

export const UsersModal = ({ onClose }) => {
  const { videoListCarouselStop, carouselSwitch, toggleCarouselSwitch, unstagedHandler } = useVideoListContext();
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const teacher = useMemo(() => attendees.find(attendee => attendee.role === 'teacher'), [attendees]);
  const students = useMemo(() => attendees.filter(attendee => attendee.role === Roles.Student), [attendees]);
  const { checkScrollable } = useScrollbar();
  const ref = useRef();
  const { toggleStageFullModal } = useGroupContext();
  const [isScrollable, setScrollable] = useState(false);
  const [isOnstageFull, setOnStageFull] = useState(false);

  const state = useMeetingState();
  const { context } = state;
  const { stagedAttendeeIds } = context;
  const { muteAttendee, unMuteAttendee, stageAttendee, unstageAttendee, openVideoAttendee } = useMeetingDispatch();

  const toggleVideoHandler = ({ isShowVideo }) => {
    if (isShowVideo) return;

    if (isOnstageFull) {
      toggleStageFullModal();
      return;
    }
  };

  const toggleStageHandler = ({ isOnStaged, attendeeId }) => {
    if (isOnstageFull) {
      if (isOnStaged) {
        muteAttendee({ attendeeId });
        unstageAttendee({ attendeeId });
        return;
      } else {
        toggleStageFullModal();
        return;
      }
    }

    isOnStaged ? unstageAttendee({ attendeeId }) : stageAttendee({ attendeeId });
    isOnStaged ? muteAttendee({ attendeeId }) : unMuteAttendee({ attendeeId });

    unstagedHandler({ attendeeId });
    openVideoAttendee({ attendeeId });
  };

  useEffect(() => {
    if (stagedAttendeeIds.length === MAX_STUDENT_VIDEO_COUNT) {
      setOnStageFull(true);
    } else {
      setOnStageFull(false);
    }
  }, [stagedAttendeeIds.length]);

  useEffect(() => {
    setScrollable(checkScrollable(ref.current));
  }, [attendees]);

  useEffect(() => {
    const lastCarouselSwitch = carouselSwitch;
    if (lastCarouselSwitch) {
      videoListCarouselStop();
      toggleCarouselSwitch();
    }
  }, []);

  return (
    <StyleUsersModal>
      <StyleHeader>
        {`成員列表(${students?.length})`}
        <div className="close" onClick={onClose}>
          <Icon name='XmarkOutline' size='xs' />
        </div>
      </StyleHeader>
      <StyledTeacher>
        教師：{teacher?.name}
      </StyledTeacher>
      <StyledModalHead>
        <Box flex="1">姓名</Box>
        <Box flex="1">獎勵</Box>
        <Box flex="2">設備狀態</Box>
        <Box flex="0.5">上台</Box>
      </StyledModalHead>
      <StyledModalBody ref={ref} isScrollable={isScrollable}>
        {
          students
            .map(student => (
              <UserCell
                key={student.chimeAttendeeId}
                attendeeId={student.chimeAttendeeId}
                userId={student?.externalUserId}
                name={student.name}
                toggleVideoHandler={toggleVideoHandler}
                toggleStageHandler={toggleStageHandler}
              />
            ))
        }
      </StyledModalBody>
    </StyleUsersModal>
  );
};

UsersModal.propTypes = {
  onClose: PropTypes.func,
};

const LiteUserCell = ({ name }) => {
  return (
    <StyledUserCell>
      <StyledName flex={4}>{name}</StyledName>
    </StyledUserCell>
  );
};

LiteUserCell.propTypes = {
  name: PropTypes.string,
};

export const LiteUsersModal = ({ onClose }) => {
  const { videoListCarouselStop, carouselSwitch, toggleCarouselSwitch } = useVideoListContext();
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = useMemo(() => attendees.filter(attendee => attendee.role === Roles.Student), [attendees]);
  const { checkScrollable } = useScrollbar();
  const ref = useRef();
  const { toggleStageFullModal } = useGroupContext();
  const [isScrollable, setScrollable] = useState(false);
  const [isOnstageFull, setOnStageFull] = useState(false);

  const state = useMeetingState();
  const { context } = state;
  const { stagedAttendeeIds } = context;

  const toggleVideoHandler = ({ isShowVideo }) => {
    if (isShowVideo) return;

    if (isOnstageFull) {
      toggleStageFullModal();
      return;
    }
  };

  useEffect(() => {
    if (stagedAttendeeIds.length === MAX_STUDENT_VIDEO_COUNT) {
      setOnStageFull(true);
    } else {
      setOnStageFull(false);
    }
  }, [stagedAttendeeIds.length]);

  useEffect(() => {
    setScrollable(checkScrollable(ref.current));
  }, [attendees]);

  useEffect(() => {
    const lastCarouselSwitch = carouselSwitch;
    if (lastCarouselSwitch) {
      videoListCarouselStop();
      toggleCarouselSwitch();
    }
  }, []);

  return (
    <StyleLiteUsersModal>
      <StyleHeader>
        {`成員列表(${students?.length})`}
        <div className="close" onClick={onClose}>
          <Icon name='XmarkOutline' size='xs' />
        </div>
      </StyleHeader>
      <StyledModalHead>
        <Box flex={4}>姓名</Box>
        {/* <Box flex={6}>設備狀態</Box> */}
      </StyledModalHead>
      <StyledModalBody ref={ref} isScrollable={isScrollable}>
        {
          students
            .map(student => (
              <LiteUserCell
                key={student.chimeAttendeeId}
                attendeeId={student.chimeAttendeeId}
                userId={student?.externalUserId}
                name={student.name}
                toggleVideoHandler={toggleVideoHandler}
              />
            ))
        }
      </StyledModalBody>
    </StyleLiteUsersModal>
  );
};

LiteUsersModal.propTypes = {
  onClose: PropTypes.func,
};

export const AdvisorUsersModal = ({ onClose }) => {
  const {
    stageAttendeeFromAdvisor,
    unstageAttendeeFromAdvisor,
    muteAttendeeFromAdvisor,
    unMuteAttendeeFromAdvisor,
    updateRewardFromAdvisor,
    sendRewardFromAdvisor,
  } = useMeetingDispatch();
  const { toggleStageFullModal } = useGroupContext();
  const meetingState = useMeetingState();
  const { context } = meetingState;
  const { stagedAttendeeIds, videoList: videoStudentList } = context;
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const teacher = useMemo(() => attendees.find(attendee => attendee.role === Roles.Teacher), [attendees]);
  const students = useMemo(() => attendees.filter(attendee => attendee.role === Roles.Student), [attendees]);
  const ref = useRef();

  const isOnstageFull = stagedAttendeeIds.length === MAX_STUDENT_VIDEO_COUNT;
  const isRewardAnimateActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`
  }) || meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`
  });

  const attendeesList = useMemo(() => {
    if (!!teacher && students) {
      return [teacher, ...students];
    }
    return [];
  }, [teacher, students]);

  const stageHandler = ({ attendeeId, isOnStaged }) => {
    if (isOnstageFull) {
      if (isOnStaged) {
        muteAttendeeFromAdvisor({ attendeeId });
        unstageAttendeeFromAdvisor({ attendeeId });
        return;
      } else {
        toggleStageFullModal();
        return;
      }
    }

    isOnStaged ? unstageAttendeeFromAdvisor({ attendeeId }) : stageAttendeeFromAdvisor({ attendeeId });
    isOnStaged ? muteAttendeeFromAdvisor({ attendeeId }) : unMuteAttendeeFromAdvisor({ attendeeId });
  };

  const rewardHandler = ({ attendeeId, userId, studentName }) => {
    const rewardInStage = videoStudentList.find(video => video.chimeAttendeeId === attendeeId);

    if (isRewardAnimateActive) return;
    updateRewardFromAdvisor({ userId, studentName });
    if (rewardInStage) {
      sendRewardFromAdvisor({ attendeeId, userId });
    }
  };

  const muteHandler = ({ attendeeId, isMuted }) => {
    isMuted ? unMuteAttendeeFromAdvisor({ attendeeId }) : muteAttendeeFromAdvisor({ attendeeId });
  };

  return (
    <StyleUsersModal>
      <StyleHeader>
        {`成員列表(${attendeesList.length})`}
        <div className="close" onClick={onClose}>
          <Icon name='XmarkOutline' size='xs' />
        </div>
      </StyleHeader>
      {!!teacher && (
        <>
          <StyledModalHead>
            <Box flex="1">老師姓名</Box>
            <Box flex="1">設備狀態</Box>
          </StyledModalHead>
          <StyledModalBody ref={ref}>
            <AdvisorUserCell
              key={teacher.chimeAttendeeId}
              user={teacher}
              role={Roles.Teacher}
            />
          </StyledModalBody>
        </>
      )}
      <StyledModalHead>
        <Box flex="1.5">姓名</Box>
        <Box flex="1">獎勵</Box>
        <Box flex="2">設備狀態</Box>
        <Box flex="0.5">上台</Box>
      </StyledModalHead>
      <StyledModalBody ref={ref} style={{ height: '100%' }}>
        {students.length > 0 &&
          students
            .map(attendee => (
              <AdvisorUserCell
                key={attendee.chimeAttendeeId}
                user={attendee}
                stageHandler={stageHandler}
                rewardHandler={rewardHandler}
                muteHandler={muteHandler}
              />
            ))
        }
      </StyledModalBody>
    </StyleUsersModal>
  );
};

AdvisorUsersModal.propTypes = {
  onClose: PropTypes.func,
};

const AdvisorUserCell = ({ user, role = Roles.Student, stageHandler, rewardHandler, muteHandler }) => {
  const meetingState = useMeetingState();
  const { context } = meetingState;
  const { stagedAttendeeIds, raisedHandAttendeeIds, rewards } = context;
  const { chimeAttendeeId: attendeeId, userId, name } = user;
  const {
    muted,
    videoEnabled
  } = useAttendeeStatus(attendeeId);

  const isOnStaged = stagedAttendeeIds.includes(attendeeId);
  const isRaiseHand = raisedHandAttendeeIds.includes(attendeeId);

  const toggleStage = () => stageHandler({ isOnStaged, attendeeId });

  const toggleReward = () => rewardHandler({ attendeeId, userId, studentName: name });

  const toggleMute = () => muteHandler({ attendeeId, isMuted: muted });

  return (
    <StyledUserCell>
      <StyledName flex={role === Roles.Student ? 1.5 : 1}>{name}</StyledName>
      {role === Roles.Student && <StyledRewards onClick={toggleReward}>
        <Icon name="TrophySolid" />
        <Box textAlign="center" width="40px" px={2}>{rewards[userId] || 0}</Box>
      </StyledRewards>}
      <Box className="iconGroup" flex={role === Roles.Student ? 2 : 1} display="flex">
        <Box display="flex" alignItem="center" onClick={toggleMute}>
          {
            muted ? <Icon name="MicrophoneAltSlashSolid" /> :
              <Icon name="MicrophoneAltSolid" />
          }
        </Box>
        <Box display="flex" alignItem="center" ml={1}>
          {
            videoEnabled ? <Icon name="VideoSolid" /> :
              <Icon name="VideoSlashSolid" />
          }
        </Box>
      </Box>
      {role === Roles.Student && <StyledStage
        onClick={toggleStage}
        isOnStaged={isOnStaged}
        isRaiseHand={isRaiseHand}
      >
        <Icon name="RaisehandSolid" />
      </StyledStage>}
    </StyledUserCell>
  );
};

AdvisorUserCell.propTypes = {
  role: PropTypes.string,
  user: PropTypes.object,
  stageHandler: PropTypes.func,
  rewardHandler: PropTypes.func,
  muteHandler: PropTypes.func,
};
