import React, { useState, useEffect } from 'react';
import { useLocalVideo, useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import { Canvas, useCanvasDispatch } from '@oneboard/whiteboard';
import {
  LinkModal,
  WhiteboardFrame,
  SingleControlBar,
  TeachingMaterialContainer,
  AuditVideoList,
  RightContainer,
} from 'containers';
import { MainLayout } from 'layouts';
import MainPageHeader from './Header';
import { StagedVideo } from 'components';
import { Roles, NOTIFICATION_TYPE, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { VideoListProvider } from 'providers/VideoListProvider';
import { useNotification } from 'utils/hooks/useNotification';
import { usePrevious } from 'ahooks';
import { complementarySet } from 'utils/array';
import { OnebookFrame, StyledGroupPage } from './Single.style';

export const Single = () => {
  const query = useQuery();
  const [bookId, setBookId] = useState(query?.bookIds ? query.bookIds[0] : '');
  const meetingState = useMeetingState();
  const { roomId, userName, stagedAttendeeIds } = meetingState.context;

  const [videos, setVideos] = useState([]);
  const [audios, setAudios] = useState([]);
  const [iframeGroup, setIframeGroup] = useState([]);
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const studentsPrevious = usePrevious(students) || [];

  const { loadTeachingMaterials } = useCanvasDispatch();
  const loadTeachingMaterialsHandler = (materials) => {
    switch (materials.type) {
      case 'image':
        loadTeachingMaterials({ materials: materials.sources });
        break;
      case 'video':
        setVideos((prev) => [...prev, ...[materials]]);
        break;
      case 'audio':
        setAudios((prev) => [...prev, ...[materials]]);
        break;
      case 'iframe':
        setIframeGroup((prev) => [...prev, ...[materials]]);
        break;
      case 'onebook':
        setBookId(materials.bookId);
        break;
      default:
        break;
    }
  };

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'video':
        setVideos((prev) => prev.filter((item) => item.sources !== material.sources));
        break;
      case 'audio':
        setAudios((prev) => prev.filter((item) => item.sources !== material.sources));
        break;
      case 'iframe':
        setIframeGroup((prev) => prev.filter((item) => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  const { isVideoEnabled, toggleVideo } = useLocalVideo();

  useEffect(() => {
    if (!isVideoEnabled && query.role !== Roles.Advisor) {
      toggleVideo();
    }
  }, []);

  const [isLinkModalShow, setLinkModalShow] = useState(false);
  const toggleLinkModal = () => setLinkModalShow((prev) => !prev);
  const submitLinkHandle = (link) => {
    const params = {
      label: 'website',
      sources: link,
      type: 'iframe',
    };
    loadTeachingMaterialsHandler(params);
  };

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.ENTER,
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.LEAVE,
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  const { endWhiteboard } = useMeetingDispatch();
  const [isWhiteboardOpen, setIsWhiteboardOpen] = useState(false);
  const handleWhiteboardToolClick = () => {
    setIsWhiteboardOpen((prev) => {
      const isNextOpen = !prev;
      if (!isNextOpen) {
        endWhiteboard();
      }

      return isNextOpen;
    });
  };

  return (
    <VideoListProvider>
      <StyledGroupPage>
        <MainLayout
          right={
            <RightContainer>
              <MainPageHeader />
            </RightContainer>
          }
          mode='single'
        >
          {bookId && <OnebookFrame src={`${process.env.REACT_APP_READER_URL}/${bookId}?beforeunload=false`} />}

          {stagedAttendeeIds.length > 0 && <StagedVideo stagedAttendeeIds={stagedAttendeeIds} objectMode={true} />}

          {isWhiteboardOpen && (
            <WhiteboardFrame
              roomId={roomId}
              role={query?.role}
              userName={userName}
              isShowClose={true}
              toggleWhiteboardShow={handleWhiteboardToolClick}
            />
          )}

          <TeachingMaterialContainer
            videos={videos}
            audios={audios}
            iframeGroup={iframeGroup}
            onClose={closeTeachingMaterialHandler}
          />
          <Canvas />
          <div className='auditListLayout'>
            <AuditVideoList />
          </div>
          <SingleControlBar
            bookId={bookId}
            onLinkToolClick={toggleLinkModal}
            isWhiteboardOpen={isWhiteboardOpen}
            toggleWhiteboardShow={handleWhiteboardToolClick}
            loadTeachingMaterials={loadTeachingMaterialsHandler}
          />
          {isLinkModalShow && <LinkModal onClose={toggleLinkModal} submit={submitLinkHandle} />}
        </MainLayout>
      </StyledGroupPage>
    </VideoListProvider>
  );
};
