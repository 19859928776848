import React from 'react';
import {
  ChatRoom,
} from '@oneboard/meeting';
import { StyledRightContainer } from './RightContainer.style';

export const RightContainer = ({ className, children }) => {
  return (
    <StyledRightContainer className={className} data-testid="RightContainer">
      <div className="videoWrap">
        {children}
      </div>
      <div className="chatWrap">
        <ChatRoom />
      </div>
    </StyledRightContainer>
  );
};