import React from 'react';
import PropTypes from 'prop-types';
import {
  useLocalVideo,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import { Box, Popover } from '@oneboard/ui-components';
import {
  LocalVideo,
  useMeetingState,
  ChatRoom,
} from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { VideoPopover } from 'components';
import { VideoBox } from 'containers';
import { StyledSyncRightContainer, PopoverIcon } from './SyncRightContainer.style';

const LocalVideoPopoverContainer = () => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();

  return (
    <VideoPopover placement="left">
      <PopoverIcon onClick={toggleVideo}>
        {
          isVideoEnabled ? <Icon name="VideoSolid" /> :
            <Icon name="VideoSlashSolid" color="#F94144" />
        }
      </PopoverIcon>
      <PopoverIcon onClick={toggleMute}>
        {
          muted ?
            <Icon name="MicrophoneAltSlashSolid" color="#F94144" /> :
            <Icon name="MicrophoneAltSolid" />
        }
      </PopoverIcon>
    </VideoPopover>
  );
};


export const SyncRightContainer = ({ className }) => {
  const meetingState = useMeetingState();
  const { attendeeId: selfAttendeeId, videoIsMirroring, stagedAttendeeIds } = meetingState.context;

  return (
    <StyledSyncRightContainer className={className} data-testid="SyncRightContainer">
      <div className="videoWrap">
        <Popover
          placement="left"
          className="customPopover"
          content={<LocalVideoPopoverContainer />}
        >
          <Box position="relative" height="180px" px={3}>
            <VideoBox
              attendeeId={selfAttendeeId}>
              <LocalVideo
                isShowNameplate={stagedAttendeeIds.includes(selfAttendeeId)}
                className={!videoIsMirroring ? 'isFlip' : ''}
                attendeeId={selfAttendeeId}
              />
            </VideoBox>
          </Box>
        </Popover>
      </div>
      <div className="chatRoomLayout">
        <ChatRoom />
      </div>
    </StyledSyncRightContainer>
  );
};

SyncRightContainer.propTypes = {
  className: PropTypes.string,
};