import React, { useState, useCallback, useMemo, createRef, useEffect, useRef } from 'react';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import PropTypes from 'prop-types';
import { Select, Button, Box, CheckBox } from '@oneboard/ui-components';
import { useQuizService } from 'utils/hooks/useQuiz';
import { OptionView } from './OptionView';
import {
  StyledQuizModal,
  StyledOptionSelectView,
  StyledQuizProgressingView,
  StyledUserList,
  StatusBox,
  StyledHeader,
  StyledCloseModal
} from './QuizModal.style';
import checkList from 'assets/images/quiz/check-list.svg';
import { Roles } from 'constants/index';
import { ResizableRect } from 'components';
import Icon from '@onedesign/icon';
import { useSize } from 'ahooks';

const Options = [1, 2, 3, 4, 5];

const OptionSelectView = () => {
  const { closeQuiz, startQuiz } = useQuizService();
  const [count, setCount] = useState(null);

  const startHandler = useCallback((count) => () => {
    startQuiz({ count });
  }, []);

  return (
    <StyledOptionSelectView>
      <div className="options">
        <div className="label">選項數量</div>
        <Select onChange={setCount}>
          <Select.Option value={null}>請選擇</Select.Option>
          {
            Options.map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)
          }
        </Select>
      </div>
      <Box className="preview">
        <OptionView count={count} />
      </Box>
      <div className="action">
        <Box mr="8px">
          <Button className="ghost" onClick={closeQuiz}>取消</Button>
        </Box>
        <Button disabled={!count} onClick={startHandler(count)}>開始</Button>
      </div>
    </StyledOptionSelectView>
  );
};


const CheckListBox = () => {
  const { endQuiz } = useQuizService();

  return (
    <StatusBox display="flex" alignItems="center" mt="24px" height="260px">
      <Box width="100%">
        <Box className="image" display="flex" justifyContent="center">
          <img src={checkList} alt="" />
        </Box>
        <Box
          className="image"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="80%"
          margin="auto"
        >
          <Button className="primary" onClick={endQuiz}>提前結束作答</Button>
        </Box>
      </Box>
    </StatusBox>
  );
};

const CorrectStatusBox = ({ disabled, count, onCheck, checked }) => {

  return (
    <StatusBox className="correctStatus" width="140px" disabled={disabled}>
      <Box className="count">
        <span>{disabled ? '-' : count}</span>
        {!disabled && <CheckBox onChange={(e) => onCheck(e.target.checked)} checked={checked} />}
      </Box>
      答對人數
    </StatusBox>
  );
};

CorrectStatusBox.propTypes = {
  disabled: PropTypes.bool,
  count: PropTypes.number,
  onCheck: PropTypes.func,
  checked: PropTypes.bool
};

const CorrectAnswerBox = ({ value, onChange }) => {
  const { quizContext, reviewQuiz } = useQuizService();
  const { optionCount } = quizContext;

  const changeHandler = value => {
    onChange(value);
    reviewQuiz();
  };

  return (
    <Box className="correctAnswerBox">
      <Box my={3}>正確答案</Box>
      <div className="options">
        <OptionView count={optionCount} clickable value={value} onChange={changeHandler} />
      </div>
    </Box>
  );
};

CorrectAnswerBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

const AnswerStatus = ({ answer, showAnswer }) => {
  if (!answer) return <span style={{ color: '#F44336' }}>未作答</span>;
  if (!showAnswer && answer) return <span>已作答</span>;
  if (showAnswer && answer) return <span>{answer}</span>;
};

AnswerStatus.propTypes = {
  answer: PropTypes.any,
  showAnswer: PropTypes.bool,
};

const QuizProgressingView = () => {
  const { States, teacherQuizStateMatches, quizContext } = useQuizService();
  const { roster } = useRosterState();
  const students = useMemo(() => Object.values(roster).filter(attendee => attendee.role === Roles.Student), [roster]);

  const { attendeeAnswers } = quizContext;
  const answers = Object.values(attendeeAnswers);
  const checkAnswer = (answers) => answers.filter(answer => answer === correctAnswer);

  const [correctAnswer, setCorrectAnswer] = useState();
  const [showCorrectAnswerOnly, setShowCorrectAnswerOnly] = useState(false);

  const studentsWithAnswer = useMemo(() => students.map(student => ({
    ...student,
    answer: attendeeAnswers[student.chimeAttendeeId] || null
  })).filter(student => showCorrectAnswerOnly ? student.answer === correctAnswer : true)
    , [students, showCorrectAnswerOnly, correctAnswer, attendeeAnswers]);

  return (
    <StyledQuizProgressingView>
      <div className="content">
        <Box className="userListWrapper" width="60%" mr={5}>
          <StyledUserList>
            <div className="listHeader">
              <span>學生姓名</span>
              <span>答題狀態</span>
            </div>
            <div className="listBody">
              {
                studentsWithAnswer.map(student => (
                  <div className="listItem">
                    <span>{student?.name}</span>
                    <AnswerStatus
                      showAnswer={[States.End, States.Reviewing].some(teacherQuizStateMatches)}
                      answer={student.answer}
                    />
                  </div>
                ))
              }
            </div>
          </StyledUserList>
        </Box>
        <Box className="statusBoxWrapper" width="40%" display="flex" flexDirection="column" justifyContent="flex-end">
          <div className="status">
            <Box mb={3}>答題狀態</Box>
            <Box display="flex">
              <StatusBox width="140px">
                <Box className="count">
                  <span>{answers.length}</span>/{students.length}
                </Box>
                作答人數
              </StatusBox>
              <CorrectStatusBox
                count={checkAnswer(answers).length}
                disabled={!correctAnswer}
                checked={showCorrectAnswerOnly}
                onCheck={setShowCorrectAnswerOnly}
              />
            </Box>
          </div>
          <div className="actions">
            {teacherQuizStateMatches(States.Starting) ? (
              <CheckListBox />
            ) : [States.End, States.Reviewing].some(teacherQuizStateMatches) ? (
              <CorrectAnswerBox onChange={setCorrectAnswer} value={correctAnswer} />
            ) : null}
          </div>
        </Box>
      </div>
    </StyledQuizProgressingView>
  );
};

const TeacherQuizModalView = () => {
  const { States, teacherQuizStateMatches } = useQuizService();

  const isQuizProgressing = [States.Starting, States.End, States.Reviewing].some(
    teacherQuizStateMatches
  );

  return (
    <>
      {teacherQuizStateMatches(States.Setting) ? (
        <OptionSelectView />
      ) : isQuizProgressing ? (
        <QuizProgressingView />
      ) : null}
    </>
  );
};

const StudentQuizModal = () => {
  const { attendeeId, quizContext, answerQuiz } = useQuizService();
  const { optionCount } = quizContext;
  const [answer, setAnswer] = useState();

  const submitHandler = () => {
    answerQuiz({
      attendeeId,
      answer
    });
  };

  return (
    <div className="options">
      <OptionView count={optionCount} clickable value={answer} onChange={setAnswer} />
      <Box display="flex" justifyContent="flex-end" mt={6}>
        <Button onClick={submitHandler} disabled={!answer}>送出</Button>
      </Box>
    </div>
  );
};

export const QuizModal = ({ role }) => {
  const { closeQuiz, teacherQuizStateMatches, States } = useQuizService();

  const isTeacher = role === Roles.Teacher;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const resizableRef = createRef();
  const [parentDom, setParentDom] = useState(null);
  const parentSize = useSize(parentDom);
  const wrapperRef = useRef();
  const wrapperSize = useSize(wrapperRef.current);
  const [modalPosition, setModalPosition] = useState();

  const showConfirmModalHandler = () => {
    [States.Starting, States.End].some(
      teacherQuizStateMatches
    ) ? setShowConfirmModal(true) : closeQuiz();
  };

  const hideConfirmModalHandler = () => setShowConfirmModal(false);
  const closeHandler = () => {
    isTeacher && closeQuiz();
  };

  useEffect(() => {
    if (!resizableRef.current || !wrapperRef.current) return;

    setParentDom(resizableRef.current.parentNode);
  }, [resizableRef, wrapperRef]);

  useEffect(() => {
    if (!parentSize || !wrapperSize || !wrapperSize?.width || !parentSize?.width) return;

    setModalPosition({
      top: parentSize.height / 2 - wrapperSize.height / 2,
      left: parentSize.width / 2 - wrapperSize.width / 2,
    });
  }, [wrapperSize, parentSize]);

  return (
    <ResizableRect
      ref={resizableRef}
      resizable={false}
      isBorder={false}
    >
      {(ref) => (
        <StyledQuizModal ref={ref} position={modalPosition} data-testid="QuizModal">
          <Box className="wrapper" ref={wrapperRef}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
              <StyledHeader>答題小工具</StyledHeader>
              {isTeacher && <Box className="closeButton" onClick={showConfirmModalHandler}>
                <Icon name='XmarkOutline' />
              </Box>}
            </Box>
            <div className="body">
              {
                isTeacher ? <TeacherQuizModalView /> : <StudentQuizModal />
              }
            </div>
          </Box>
          {
            showConfirmModal && (
              <StyledCloseModal>
                <Box className="wrapper">
                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                    <StyledHeader>關閉答題工具</StyledHeader>
                    {isTeacher && <Box className="closeButton" onClick={hideConfirmModalHandler}>
                      <Icon name='XmarkOutline' />
                    </Box>}
                  </Box>
                  <div className="body">
                    關閉答題工具後，此次作答將重新開始，是否確認結束答題工具?
                    <Box display="flex" justifyContent="flex-end" mt={10}>
                      <Button className="ghost" onClick={hideConfirmModalHandler}>取消</Button>
                      <Button onClick={closeHandler}>確定結束</Button>
                    </Box>
                  </div>
                </Box>
              </StyledCloseModal>
            )
          }
        </StyledQuizModal>
      )}
    </ResizableRect>
  );
};

QuizModal.propTypes = {
  role: PropTypes.string,
};