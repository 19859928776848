import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useRemoteVideoTileState,
  useAttendeeStatus,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { VideoPopover } from 'components';
import { VideoBox } from 'containers';
import { Tooltip } from 'antd';
import { Box, Popover } from '@oneboard/ui-components';
import {
  RemoteVideo,
  MeetingStates,
  MeetingRoles,
  useMeetingState,
  useMeetingDispatch,
} from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { useWhiteboard } from '@oneboard/whiteboard';
import { Roles, ClassType, PRODUCT_TYPE } from 'constants/index';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useGroupContext } from 'providers/GroupProvider';
import { useToolboxAuth } from 'utils/hooks/useToolboxAuth';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useQuery } from 'utils/hooks/useQuery';
import { useStudentInfoContext } from 'providers/StudentInfoProvider';
import { StyledSyncVideoList, VideoBoxWrap, PopoverIcon } from './SyncVideoList.style';

const StudentVideoBox = ({ attendeeId, children }) => {
  const {
    openVideoAttendee,
    closeVideoAttendee,
    openVideoAttendeeFromAdvisor,
    closeVideoAttendeeFromAdvisor
  } = useMeetingDispatch();
  const { videoEnabled } = useAttendeeStatus(attendeeId);

  // 發 action 給學生關視訊
  useEffect(() => {
    if (!videoEnabled) {
      openVideoAttendee({ attendeeId });
      openVideoAttendeeFromAdvisor({ attendeeId });
    }
    return function cleanup() {
      closeVideoAttendee({ attendeeId });
      closeVideoAttendeeFromAdvisor({ attendeeId });
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
};

StudentVideoBox.propTypes = {
  attendeeId: PropTypes.string,
  children: PropTypes.node,
};

const StudentVideoPopoverContainer = ({ attendeeId, userId, studentName }) => {
  const { unstagedHandler } = useVideoListContext();
  const {
    usersMuteHandler,
    usersMuteState
  } = useGroupContext();
  const { muted, videoEnabled } = useAttendeeStatus(attendeeId);
  const meetingState = useMeetingState();
  const { context } = meetingState;
  const isRewardAnimateActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`
  }) || meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`
  });
  const { stagedAttendeeIds, role } = context;
  const {
    openVideoAttendee,
    closeVideoAttendee,
    muteAttendee,
    unMuteAttendee,
    sendReward,
    stageAttendee,
    unstageAttendee,
    stageAttendeeFromAdvisor,
    unstageAttendeeFromAdvisor,
    updateReward,
    sendRewardFromAdvisor,
    updateRewardFromAdvisor,
    openVideoAttendeeFromAdvisor,
    closeVideoAttendeeFromAdvisor,
    muteAttendeeFromAdvisor,
    unMuteAttendeeFromAdvisor,
  } = useMeetingDispatch();
  const { queryToolboxAuthById, toggleToolboxAuth } = useToolboxAuth();
  const isEnableToolbox = queryToolboxAuthById({ attendeeId });
  const { isBreakoutRoom } = useBreakoutMeeting();
  const { openStudentInfo } = useStudentInfoContext();
  const query = useQuery();

  const muteHandler = () => {
    if (role === Roles.Advisor) {
      muted ? unMuteAttendeeFromAdvisor({ attendeeId }) : muteAttendeeFromAdvisor({ attendeeId });
    } else {
      muted ? unMuteAttendee({ attendeeId }) : muteAttendee({ attendeeId });
    }

    usersMuteHandler({
      [attendeeId]: !muted
    });
  };

  const videoEnabledHandler = () => {
    if (role === Roles.Advisor) {
      videoEnabled ? closeVideoAttendeeFromAdvisor({ attendeeId }) : openVideoAttendeeFromAdvisor({ attendeeId });
    } else {
      videoEnabled ? closeVideoAttendee({ attendeeId }) : openVideoAttendee({ attendeeId });
    }
  };

  const rewardHandler = () => {
    if (isRewardAnimateActive) return;

    if (role === Roles.Advisor) {
      updateRewardFromAdvisor({ userId, studentName });
      sendRewardFromAdvisor({ attendeeId, userId });
    } else {
      updateReward({ userId, studentName });
      sendReward({ attendeeId, userId, studentName });
    }
  };

  const onStagedHandler = () => {
    if (stagedAttendeeIds.includes(attendeeId)) {
      const isMuted = usersMuteState[attendeeId];
      if (role === Roles.Advisor) {
        unstageAttendeeFromAdvisor({ attendeeId });
        isMuted ? muteAttendeeFromAdvisor({ attendeeId }) : unMuteAttendeeFromAdvisor({ attendeeId });
      } else {
        unstageAttendee({ attendeeId });
        isMuted ? muteAttendee({ attendeeId }) : unMuteAttendee({ attendeeId });
      }
      // 輪播下台 handler
      unstagedHandler(attendeeId);
    } else {
      if (role === Roles.Advisor) {
        stageAttendeeFromAdvisor({ attendeeId });
        unMuteAttendeeFromAdvisor({ attendeeId });
      } else {
        stageAttendee({ attendeeId });
        unMuteAttendee({ attendeeId });
      }
    }
  };

  return (
    <VideoPopover>
      {!isBreakoutRoom && <PopoverIcon onClick={onStagedHandler}>
        {stagedAttendeeIds.includes(attendeeId) ? <Icon name="ThumbtackSolid" color="#EC7963" /> : <Icon name="ThumbtackSolid" />}
      </PopoverIcon>}
      <PopoverIcon onClick={videoEnabledHandler}>
        {
          videoEnabled ? <Icon name="VideoSolid" /> :
            <Icon name="VideoSlashSolid" color="#F94144" />
        }
      </PopoverIcon>
      <PopoverIcon onClick={muteHandler}>
        {
          muted ?
            <Icon name="MicrophoneAltSlashSolid" color="#F94144" /> :
            <Icon name="MicrophoneAltSolid" />
        }
      </PopoverIcon>
      {!isBreakoutRoom && <>
        <PopoverIcon onClick={() => toggleToolboxAuth({ attendeeId })}>
          {
            isEnableToolbox ? <Icon name="ChalkboardOutline" /> :
              <Icon name="ChalkboardSlashSolid" />
          }
        </PopoverIcon>
        <PopoverIcon className="reward">
          <Icon name="TrophySolid" onClick={() => rewardHandler(attendeeId)} />
        </PopoverIcon>
        {query.productType === PRODUCT_TYPE.liveoneclass && query.role === Roles.Teacher && query.classType === ClassType.SyncMultiple && <PopoverIcon>
          <Icon name="AddressBookSolid" onClick={() => openStudentInfo(userId)} />
        </PopoverIcon>}
      </>}
    </VideoPopover>
  );
};

StudentVideoPopoverContainer.propTypes = {
  attendeeId: PropTypes.string,
  userId: PropTypes.string,
  studentName: PropTypes.string,
};

export const SyncVideoList = ({ className, videoList }) => {
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { context } = useMeetingState();
  const { unObjectedAttendee } = useMeetingDispatch();
  const { attendeeId: selfAttendeeId, stagedAttendeeIds, objectedAttendeeIds } = context;
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = useMemo(() => attendees.filter(attendee => attendee.role === Roles.Student), [attendees]);

  const { service: whiteboardService } = useWhiteboard();
  const objectedVideoIdentifierMap = useRef({});

  useEffect(() => {
    if (!objectedAttendeeIds?.length) return;

    objectedAttendeeIds.forEach((attendeeId) => {
      if (!objectedVideoIdentifierMap.current[attendeeId]) {
        const identifier = whiteboardService.insertPlugin('controllableVideo', {
          width: 150,
          height: 150,
          attributes: {
            attendeeId
          }
        });
        objectedVideoIdentifierMap.current[attendeeId] = identifier;
      }
    });

  }, [objectedAttendeeIds]);

  useEffect(() => {
    const studentsAttendeeIds = students.map(student => student.chimeAttendeeId);

    objectedAttendeeIds.forEach(attendeeId => {
      if (!studentsAttendeeIds.includes(attendeeId)) {
        unObjectedAttendee({ attendeeId });

        if (whiteboardService) {
          whiteboardService.removePlugin(objectedVideoIdentifierMap.current[attendeeId]);
        }

        objectedVideoIdentifierMap.current[attendeeId] = null;

      }
    });

  }, [students]);

  useEffect(() => {
    if (!whiteboardService) return;
    function onReceivedChatMessage(event) {
      const attendeeId = event.payload.attendeeId;
      unObjectedAttendee({ attendeeId: attendeeId || selfAttendeeId });
      objectedVideoIdentifierMap.current[attendeeId] = null;
    }

    whiteboardService.addMagixEventListener('deleteObjectedVideo', onReceivedChatMessage);

    return () => {
      whiteboardService.removeMagixEventListener('deleteObjectedVideo', onReceivedChatMessage);
    };
  }, [whiteboardService]);

  return (
    <StyledSyncVideoList className={className} data-testid="SyncVideoList">
      {stagedAttendeeIds.length > 0 && <div className="stagedTipWrap">
        <Tooltip placement="bottomLeft" title={
          <span>釘選上台的學生<br />將同步顯示於其他學生的視窗中</span>
        }>
          <div className="stagedTip">
            <Icon name="ThumbtackSolid" color="#fff" size="xxs" />
          </div>
        </Tooltip>
      </div>}
      {
        videoList
          .map((attendee) => {
            const studentName = attendee.name;
            const userId = attendee?.externalUserId;
            const attendeeId = attendee.chimeAttendeeId;
            const tileId = attendeeIdToTileId[attendeeId];
            let isLast = false;
            if (stagedAttendeeIds.length > 0) {
              isLast = stagedAttendeeIds[stagedAttendeeIds.length - 1] === attendeeId;
            }
            return (
              <Box display="flex" key={attendeeId}>
                <StudentVideoBox attendeeId={attendeeId}>
                  <Popover
                    placement="bottom"
                    className="customPopover"
                    content={
                      <StudentVideoPopoverContainer
                        attendeeId={attendeeId}
                        userId={userId}
                        studentName={studentName}
                      />}
                  >
                    <VideoBoxWrap isStage={stagedAttendeeIds.includes(attendeeId)} isLast={isLast}>
                      <VideoBox
                        attendeeId={attendeeId}
                        userId={userId}
                        isStudent
                      >
                        <RemoteVideo
                          isShowNameplate={objectedAttendeeIds.includes(attendeeId)}
                          attendeeId={attendeeId}
                          tileId={tileId}
                        />
                      </VideoBox>
                    </VideoBoxWrap>
                  </Popover>
                </StudentVideoBox>
              </Box>
            );
          })
      }
    </StyledSyncVideoList>
  );
};

SyncVideoList.propTypes = {
  className: PropTypes.string,
  videoList: PropTypes.array
};
