import { useState } from 'react';

export const useStudentInfo = () => {
  const [userId, setUserId] = useState(null);
  const [studentInfoState, setStudentInfoState] = useState(false);


  const openStudentInfo = (id) => {
      setUserId(id);
      setStudentInfoState(true);
  };

  const toggleStudentInfo = () => setStudentInfoState(prev => !prev);

  return {
    userId,
    studentInfoState,
    openStudentInfo,
    toggleStudentInfo
  };
};