import { useCallback, useEffect, useState } from 'react';
import firebaseEnvConfig from 'firebaseConfig.json';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = firebaseEnvConfig[process.env.REACT_APP_ENV];

const APP_NAME = 'oneboard';

export const useFirebase = (config = firebaseConfig) => {
  const [storage, setStorage] = useState(null);

  const initialize = useCallback(() => {
    let app;
    if (!firebase.apps.some((app) => app.name === APP_NAME)) {
      app = firebase.initializeApp(config, APP_NAME);
    } else {
      app = firebase.app(APP_NAME);
    }
    app
      .auth()
      .signInAnonymously()
      .then(() => {
        setStorage(app.storage());
      })
      .catch((err) => console.error('sign in anonymously error', err));
  }, [config, setStorage]);

  useEffect(() => {
    if (!config) return;
    initialize();
  }, [config, initialize]);

  return { storage };
};
