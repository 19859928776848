import styled from 'styled-components';

export const StyledToolBox = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .uploadPopover {
    background-color: #fff;
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: #212324;
  box-shadow: 0 8px 24px 0 rgb(0 0 0 / 8%);
  cursor: pointer;

  &:hover {
    background: rgba(33, 35, 36, 0.1);
  }
  .settingPopoverButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #212324;
  }
`;
