import React from 'react';
import { StyledResourceBox, StyledResource, StyledResourceItem } from '../LevelList.style';

const difficultyLevels = [
  { code: 'Begin', name: '易' },
  { code: 'Basic', name: '中偏易' },
  { code: 'Intermediate', name: '中' },
  { code: 'Advanced', name: '中偏難' },
  { code: 'Expert', name: '難' },
];

const Level = ({ selectedLevel, setSelectedLevel }) => {
  const clickHandler = (selectedLevel) => {
    setSelectedLevel(selectedLevel);
  };

  return (
    <StyledResource>
      {difficultyLevels.map((level) => (
        <StyledResourceBox key={level.code}>
          <div className='content'>
            <div className='list'>
              <StyledResourceItem
                key={level.code}
                onClick={() => clickHandler(level)}
                active={selectedLevel && selectedLevel.code === level.code}
              >
                {level.name}
              </StyledResourceItem>
            </div>
          </div>
        </StyledResourceBox>
      ))}
    </StyledResource>
  );
};

export default Level;
