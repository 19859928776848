import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Loading } from '@oneboard/ui-components';
import {
  ContentShare,
  useToggleLocalMute,
  useRosterState,
  useLocalVideo,
  useRemoteVideoTileState,
  VideoGrid,
  useContentShareState,
  useAudioVideo
} from 'amazon-chime-sdk-component-library-react';
import { WhiteboardFrame, QuizModal } from 'containers';
import { CommentsIcon, MediaMetrics, CustomerService } from 'components';
import { StyledStudentMainPage, UiChatRoomLayout, ModalTitle, ToolBtn } from './Group.style';
import Icon from '@onedesign/icon';
import { Roles } from 'constants/index';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import {
  useMeetingDispatch,
  SettingPopoverButton,
  MeetingStates,
  MeetingRoles,
  useMeetingState,
  ChatRoom,
  LocalVideo,
  RemoteVideo,
} from '@oneboard/meeting';
import { useQuizService } from 'utils/hooks/useQuiz';
import { ContentShareLayout } from 'layouts';

const checkSameSharingAttendeeId = (sharingAttendeeId, attendeeId) => {
  if (!sharingAttendeeId) return false;
  return sharingAttendeeId.indexOf(attendeeId) > -1;
};

export const Group = ({ role = Roles.Student }) => {
  const { tiles, attendeeIdToTileId } = useRemoteVideoTileState();
  const { toggleVideo, isVideoEnabled } = useLocalVideo();
  const { raiseHand, lowerHand, noFocus } = useMeetingDispatch();
  const { muted, toggleMute } = useToggleLocalMute();
  const { sharingAttendeeId } = useContentShareState();
  const [chatRoomState, setChatRoomState] = useState(false);
  const audioVideo = useAudioVideo();
  const tileIds = useMemo(() => Object.values(attendeeIdToTileId), [attendeeIdToTileId]);

  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const hasOtherRole = attendees.find(attendee => attendee.role === Roles.Teacher || attendee.role === Roles.Advisor);

  useEffect(() => {
    if (!muted) {
      toggleMute();
    }
  }, []);

  useEffect(() => {
    if (sharingAttendeeId) {
      tileIds.forEach((tileId) => {
        audioVideo.pauseVideoTile(tileId);
      });
    } else {
      tileIds.forEach((tileId) => {
        audioVideo.unpauseVideoTile(tileId);
      });
    }
  }, [tileIds, sharingAttendeeId]);

  useEffect(() => {
    const length = tiles.length;
    if (!isVideoEnabled && hasOtherRole && length <= MAX_STUDENT_VIDEO_COUNT) {
      toggleVideo();
    }
  }, [tiles, hasOtherRole]);

  const meetingState = useMeetingState();
  const { roomId, attendeeId: selfAttendeeId, whiteboardingAttendeeIds, userName } = meetingState.context;

  const isWhiteboarding = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.Whiteboard}.${MeetingStates.Whiteboarding}`
  });

  const whiteboardTargetId = whiteboardingAttendeeIds.length === 1 ? whiteboardingAttendeeIds[0] : '';
  const whiteboardTargetUserName = whiteboardTargetId && roster[whiteboardTargetId] ? roster[whiteboardTargetId]?.name : userName;

  const toggleChatRoomState = () => setChatRoomState(prev => !prev);

  const isRaisingHand = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.RaiseHand}.${MeetingStates.RaisingHand}`
  });

  const toggleHand = useCallback(() => {
    const handler = isRaisingHand ? lowerHand : raiseHand;
    handler({ attendeeId: selfAttendeeId });
  }, [isRaisingHand, selfAttendeeId]);

  const size = attendees.filter(attendee => attendee.role !== Roles.Observer).length || 0;

  const noFocusHandler = () => {
    document.addEventListener('visibilitychange', function() {
      if (document.hidden) {
        noFocus({
          userName,
          key: selfAttendeeId
        });
      }
    });
  };

  useEffect(() => {
    noFocusHandler();
  }, []);

  const { studentQuizStateMatches, States } = useQuizService();

  if (checkSameSharingAttendeeId(sharingAttendeeId, selfAttendeeId)) {
    return (
      <Modal width="860px" closeIcon={false}>
        <Box my={3} display="flex" justifyContent="center" alignItems="center">
          <Loading />
        </Box>
        <ModalTitle>螢幕分享中</ModalTitle>
      </Modal>
    );
  }

  return (
    <StyledStudentMainPage data-testid="StudentMainPage">
      <Box display="flex" width="100%" height="100%">
        {!hasOtherRole ? (
          <Modal width="860px" closeIcon={false}>
            <Box my={3} display="flex" justifyContent="center">
              <Loading />
            </Box>
            <ModalTitle>等待老師上課中</ModalTitle>
          </Modal>
        ) : (!sharingAttendeeId ?
          <VideoGrid size={size}>
            <LocalVideo attendeeId={selfAttendeeId} />
            {
              attendees
                .filter(attendee => attendee.chimeAttendeeId !== selfAttendeeId)
                .filter(attendee => attendee.role !== Roles.Observer)
                .filter((_, index) => index < MAX_STUDENT_VIDEO_COUNT - 1)
                .map(attendee => {
                  const attendeeId = attendee.chimeAttendeeId;
                  return <RemoteVideo
                    key={attendeeId}
                    attendeeId={attendeeId}
                    tileId={attendeeIdToTileId[attendeeId]}
                  />;
                })
            }
          </VideoGrid> :
          <ContentShareLayout
            contentShare={<ContentShare />}
            left={
              <>
                <ToolBtn>
                  <SettingPopoverButton className="settingPopoverButton" disabledPopover/>
                </ToolBtn>
                <ToolBtn>
                  <CustomerService />
                </ToolBtn>
                <ToolBtn>
                  <MediaMetrics/>
                </ToolBtn>
              </>
            }
            right={
              <>
                <ToolBtn active={isRaisingHand} onClick={() => toggleHand()} >
                  <Icon name="RaisehandSolid" size='sm'/>
                </ToolBtn>
                {/* <ToolBtn onClick={toggleChatRoomState}>
                  <CommentsIcon isChatRoomOpen={chatRoomState} />
                </ToolBtn> */}
              </>
            }
          >
          </ContentShareLayout>
        )}

        <UiChatRoomLayout active={chatRoomState}>
          <ChatRoom onClose={toggleChatRoomState} />
        </UiChatRoomLayout>
        {isWhiteboarding && <WhiteboardFrame
          roomId={roomId}
          role={role}
          attendeeId={whiteboardTargetId}
          userName={whiteboardTargetUserName}
        />}
      </Box>
      {
        studentQuizStateMatches(States.Starting) && (
          <QuizModal role={Roles.Student}/>
        )
      }
    </StyledStudentMainPage>
  );
};

Group.propTypes = {
  role: PropTypes.string
};