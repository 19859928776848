import styled from 'styled-components';
import { mediaQueries } from '../../utils/theme';

export const StyledIntro = styled.div.attrs(props => ({
className: props.className
}))`
  margin-top: 60px;
  .content {
    max-width: 300px;
    width: 100%;
    margin: 56px auto 0;
  }
  
  .desc {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    color: #fff;
  }
  .detectBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${mediaQueries.small} {
    .desc {
      text-align: left;
    }
    .detectBlock {
      display: none;
    }
  }
  
  .detectItems {
    margin-top: 32px;
    >.item {
        width: 120px;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          background-color: #2F3746;
          border-radius: 90px;
        }
        .name {
          margin-top: 10px;
          font-size: 16px;
          line-height: 23px;
          text-align: center;
          color: #B0B7C3;
        }
      }
  }
`;