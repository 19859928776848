import styled from 'styled-components';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import StyledImage from 'components/NewOneBoard/common/StyledImage';
import Monster from 'assets/newOneBoard/images/monster.png';
import { useFooterStatus } from 'providers/FooterStatusProvider';

const TrophyContainer = styled.div`
  display: flex;
  align-items: ${({ isFooterOpen }) => (isFooterOpen ? ' flex-end' : 'center')};
  height: 50px;
  border-radius: 16px;
  background-color: ${({ isFooterOpen }) => (isFooterOpen ? '#f9fafb' : 'transparent')};
  position: relative;
  padding: ${({ isFooterOpen }) => isFooterOpen && '8px 8px 0 8px'};
`;

const TrophyNumber = styled.div`
  display: flex;
  margin: 0 0 4px 8px;
  gap: 4px;
`;

const TrophyTitle = styled.div`
  display: inline-flex;
  height: 20px;
  padding: 0px 16px;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  border-radius: ${({ isFooterOpen }) => (isFooterOpen ? '16px 16px 0px 16px' : '16px')};
  background: #ec7963;
  color: #fff;
  position: ${({ isFooterOpen }) => (isFooterOpen ? 'absolute' : null)};
  top: 0;
  right: 0;
  font-size: 12px;
`;

const RewardNumber = styled.p`
  color: #ffdc8a;
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  line-height: 20px;
`;

export const RewardCount = () => {
  const { roster } = useRosterState();
  const { isFooterOpen } = useFooterStatus();
  const student = Object.values(roster).find((attendee) => attendee.role === 'student');
  const {
    context: { rewards },
  } = useMeetingState();

  const rewardCount = student ? rewards[student.userId] || 0 : 0;
  const trophies = Array.from({ length: 5 }, (_, index) =>
    index < rewardCount ? (
      <Icon key={index} name='TrophySolid' size='md' color='#FEC902' />
    ) : (
      <Icon key={index} name='TrophySolid' size='md' color='#E3E3E3' />
    )
  );

  return (
    <TrophyContainer isFooterOpen={isFooterOpen}>
      <TrophyTitle isFooterOpen={isFooterOpen}>
        學生獎盃數 <RewardNumber>{rewardCount}</RewardNumber>
      </TrophyTitle>
      {isFooterOpen && (
        <>
          <StyledImage src={Monster} alt='monster icon' width={38} height={40} />
          <TrophyNumber>{trophies}</TrophyNumber>
        </>
      )}
    </TrophyContainer>
  );
};
