import styled from 'styled-components';

export const StyledMediaMetrics = styled.div.attrs(props => ({
  className: props.className
}))`
  width: 100%;
  height: 100%;
  >.iconBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &.isGood {
      color: #5EB762;
    }

    &.isPoor {
      color: #fff;
    }

    &.isStop {
      color: #FFA31A;
    }
  }
`;

export const StyledMediaMetricsInfo = styled.div`
  width: 140px;
  padding: 16px 18px;
  border-radius: 8px;
  background-color: #232323;
  font-size: 11px;

  .infoList {
    display: flex;
    align-items: center;
    justify-content: space-between;

    +.infoList {
      margin-top: 8px;
    }
  }

  .infoTitle {
    color: #8B8B8B;
  }

  .infoContent {
    color: #fff;
    &.isGood {
      color: #5EB762;
    }

    &.isPoor {
      color: #fff;
    }

    &.isStop {
      color: #FFA31A;
    }
  }
`;