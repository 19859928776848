import React from 'react';
import { StyledTestList } from './LevelList.style';
import Level from './components/Level';

export const LevelList = ({ selectedLevel, setSelectedLevel, pd = '16px' }) => {
  return (
    <StyledTestList width={'100%'} padding={pd}>
      <div>
        <Level selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} />
      </div>
    </StyledTestList>
  );
};
