import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormField, Input, Select, PrimaryButton } from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch } from '@oneboard/meeting';
import { StyledLoginPage } from './LoginPage.style';

export const LoginPage = ({ className }) => {
  const location = useLocation();
  const history = useHistory();
  const { joinMeeting } = useMeetingDispatch();


  const [roomId, setRoomId] = useState('');
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState('student');

  const submitHandler = async (e) => {
    e.preventDefault();
    await joinMeeting({
      roomId,
      userName,
      role,
    });
    history.push({
      pathname: `${role}/setup`,
      search: location.search
    });
  };

  return (
    <StyledLoginPage className={className} data-testid="LoginPage">
      <form onSubmit={submitHandler}>
        <FormField
          field={Input}
          label="輸入房間名稱"
          value={roomId}
          fieldProps={{ name: 'firstName', placeholder: 'ex: MyEnglishClass' }}
          onChange={e => setRoomId(e.target.value)}
          layout="stack"
        />
        <FormField
          field={Input}
          label="輸入使用者名稱"
          value={userName}
          fieldProps={{ name: 'firstName', placeholder: 'ex: 王小明' }}
          onChange={e => setUserName(e.target.value)}
          layout="stack"
        />
        <FormField
          field={Select}
          label="請選擇身份"
          value={role}
          fieldProps={{
            name: 'role',
            options: [
              { value: 'student', label: '學生' },
              { value: 'teacher', label: '老師' },
            ]
          }}
          onChange={e => setRole(e.target.value)}
          layout="stack"
        />
        <PrimaryButton label="進入課程" type="submit" />
      </form>
    </StyledLoginPage>
  );
};