const BASE_URL = process.env.REACT_APP_ONEBOARD_API_URL;

export const postMessage = async ({ courseId, userId, message }) => {
  try {
    const response = await fetch(`${BASE_URL}/oneboard/course/message`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        courseId,
        userId,
        message
      })
    }).then(res => res.json());
    return response;
  } catch (error) {
    console.log('error:', error);
  }
};