import styled from 'styled-components';

export const StyledSyncMainLayout = styled.div.attrs(props => ({
  className: props.className
}))`
  display: grid;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
    
  &.single {
    grid-template-rows: 1fr minmax(0, 4fr);
    grid-template-areas: 'main top' 'main right';
    grid-template-columns: minmax(560px, 1fr) 280px;
  }

  &.syncMultiple {
    grid-template-rows: 1fr minmax(0, 4fr);
    grid-template-areas: 'top right' 'main right';
    grid-template-columns: minmax(560px, 1fr) 280px;
  }

  > .main {
    position: relative;
  }

  > .right {
    background-color: #232323;
  }

  > .top {
    height: 100%;
    background-color: #232323;
    z-index: 1;
  }
`;