import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useLocalVideo, useRosterState, useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { Box } from '@oneboard/ui-components';
import { ChatRoom, RemoteVideo, LocalVideo, } from '@oneboard/meeting';
import { MainLayout } from 'layouts';
import { Roles } from 'constants/index';
import { SyncWhiteboard, SyncToolBox } from 'containers';


const TeacherVideo = () => {
  const query = useQuery();
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const teacher = Object.values(roster).find(attendee => attendee.role === 'teacher');

  return (
    <Box width="100%">
      {
        query.role === 'teacher' ? (
          <LocalVideo attendeeId={teacher?.chimeAttendeeId} />
        ) : teacher?.chimeAttendeeId ? (
          <RemoteVideo
            attendeeId={teacher.chimeAttendeeId}
            tileId={attendeeIdToTileId[teacher?.chimeAttendeeId]}
          />
        ) : null
      }
    </Box>
  );
};

export const Multiple = () => {
  const { meetingId } = useParams();
  const query = useQuery();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();

  useEffect(() => {
    if (!isVideoEnabled && query.role === Roles.Teacher) {
      toggleVideo();
    }
  }, []);

  return (
    <MainLayout
      top={<TeacherVideo />}
      right={<ChatRoom />}
      mode='single'
    >
      <SyncWhiteboard toolBox={<SyncToolBox meetingId={meetingId} />} />
    </MainLayout>
  );
};