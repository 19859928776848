import styled from 'styled-components';

export const StyledEmojiButton = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #637381;
`;
