import axios from 'axios';

const BITRIX_URL = process.env.REACT_APP_BITRIX_URL;
const BITRIX_CHAT_ID = process.env.REACT_APP_BITRIX_CHAT_ID;
const BITRIX_ORDER_URL = process.env.REACT_APP_BITRIX_ORDER_URL;


export const postBitrixNotify = async ({ message }) => {
  try {
    return await fetch(`${BITRIX_URL}/oneboard/notify`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        'chatId': BITRIX_CHAT_ID,
        'message': message
      })
    });
  } catch (error) {
    console.log('error:', error);
  }
};

export const postBitrixOrderNotify = async ({ orderId, testTime, testData }) => {
  const fieldsTime = 'FIELDS[UF_CRM_1646314320]';
  const fieldsData = 'FIELDS[UF_CRM_1646912844]';

  return axios.get(BITRIX_ORDER_URL, {
        params: {
          id: orderId,
          [fieldsTime]: testTime,
          [fieldsData]: testData
        }
      }
    )
    .catch((error) => { console.error(error); });
};

