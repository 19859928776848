import React from 'react';
import { Modal, Box, Button } from '@oneboard/ui-components';

export const WarningModal = ({ isOpen, onClose, headerText, bodyText }) => {
  if (!isOpen) return null;

  return (
    <Modal header={headerText} closeIcon={true} onClose={onClose}>
      {bodyText}
      <Box display='flex' justifyContent='flex-end' mt={8}>
        <Button onClick={onClose}>我知道了</Button>
      </Box>
    </Modal>
  );
};
