import styled from 'styled-components';

export const StyledClassroomPerformanceButton = styled.div.attrs(props => ({
className: props.className
}))`
/* Code Here */
`;

export const StyledAwardButton = styled.button`
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

export const StyledPopContent = styled.div`
  position: relative;
  color: #fff;
  font-size: 14px;
  line-height: 24px;

  .actions {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
  }
  .note {
    color: #fff;
    opacity: .8;
  }

  .btn {
    color: #4C99A5;
    background-color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const StyledRecordBox = styled.div`
  .ant-popover-arrow-content::before {
    background-color: #4C99A5;
  }
`;