import React from 'react';
import { useRosterState, useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from '@oneboard/meeting';
import { ClassType } from 'constants/index';
import RemoteVideo from './RemoteVideo';
import { useToolboxAuth } from 'utils/hooks/useToolboxAuth';
import AdvisorVideo from './AdvisorVideo';
import ChatRoomButton from 'components/NewOneBoard/ChatRoom/ChatRoomButton';
import RaiseHandButton from 'components/NewOneBoard/RaiseHand/RaiseHandButton';
import VideoPanelContainer from 'components/NewOneBoard/VideoInfo/common/VideoPanelContainer';
import ButtonContainer from 'components/NewOneBoard/VideoInfo/common/ButtonContainer';

export function AdvisorVideoPanel({ isSettingModalOpen }) {
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { queryToolboxAuthById, toggleToolboxAuth } = useToolboxAuth();
  const meetingState = useMeetingState();
  const { courseType } = meetingState.context;
  const advisors = Object.values(roster).filter((attendee) => attendee.role === 'advisor');
  const student = Object.values(roster).find((attendee) => attendee.role === 'student');
  const studentAttendeeId = student?.chimeAttendeeId;
  const currentAdvisor = advisors[0];

  if (!currentAdvisor) {
    return null;
  }

  return (
    <VideoPanelContainer>
      <div>
        <AdvisorVideo
          userType={currentAdvisor.role}
          advisorName={currentAdvisor.name}
          isSettingModalOpen={isSettingModalOpen}
        />
        {courseType !== ClassType.SyncMultiple && studentAttendeeId && (
          <RemoteVideo
            userType={currentAdvisor.role}
            tileId={attendeeIdToTileId[studentAttendeeId]}
            attendeeId={studentAttendeeId}
            studentName={student.name}
            toggleToolboxAuth={toggleToolboxAuth}
            queryToolboxAuthById={queryToolboxAuthById}
          />
        )}
      </div>
      <ButtonContainer>
        {courseType === (ClassType.SyncMultiple || ClassType.SyncStreaming) && <RaiseHandButton />}
        <ChatRoomButton />
      </ButtonContainer>
    </VideoPanelContainer>
  );
}
