import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  useLocalVideo,
  useRosterState,
  useToggleLocalMute,
  useContentShareState,
  ContentShare,
} from 'amazon-chime-sdk-component-library-react';
import { useMeetingState, useMeetingDispatch } from '@oneboard/meeting';
import {
  TeachingMaterialContainer,
  SyncWhiteboard,
  SyncToolBox,
  SyncStagedVideoList,
  RightContainer,
} from 'containers';
import { MainLayout } from 'layouts';
import MainPageHeader from './Header';
import { Roles, NOTIFICATION_TYPE, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { useNotification } from 'utils/hooks/useNotification';
import { usePrevious } from 'ahooks';
import { complementarySet } from 'utils/array';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { getMultiUserRecord } from 'services/userRecord';
import { StyledSyncObserver, SyncContent, ContentShareContainer } from './SyncObserver.style';

export const SyncObserver = ({ meetingId }) => {
  const { assignMultiUserRewards } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { stagedAttendeeIds, attendeeId: selfAttendeeId, roomId } = meetingState.context;
  const { muted, toggleMute } = useToggleLocalMute();
  const { service: whiteboardService } = useWhiteboard();
  const { scaleToFit } = useZoomControl();

  const [iframeGroup, setIframeGroup] = useState([]);
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter((attendee) => attendee.role === Roles.Student);
  const studentsPrevious = usePrevious(students) || [];

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup((prev) => prev.filter((item) => item.sources !== material.sources));
        break;
      default:
        break;
    }
  };

  const { isVideoEnabled, toggleVideo } = useLocalVideo();

  useEffect(() => {
    if (isVideoEnabled) {
      toggleVideo();
    }

    if (!muted) {
      toggleMute();
    }
  }, []);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.ENTER,
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.LEAVE,
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  useEffect(() => {
    if (!whiteboardService) return;

    whiteboardService
      .setWritable(false)
      .then(function () {})
      .catch(function (err) {
        console.log('whiteboardServiceErr', err.message);
      });
  }, [whiteboardService]);

  useEffect(() => {
    if (!whiteboardService) return;

    const handler = () => {
      scaleToFit();
    };

    whiteboardService.addMagixEventListener('changeBookId', handler);
    whiteboardService.addMagixEventListener('closeViewMode', handler);

    return () => {
      whiteboardService.removeMagixEventListener('changeBookId', handler);
      whiteboardService.removeMagixEventListener('closeViewMode', handler);
    };
  }, [whiteboardService]);

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(() => sharingAttendeeId?.split('#')[0] || '', [sharingAttendeeId]);
  const isShowContentShare = useMemo(
    () => contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  useEffect(() => {
    // get 獎盃紀錄
    (async () => {
      const multiRecord = await getMultiUserRecord({
        courseId: roomId,
      });
      const rewardsMap = {};

      if (!multiRecord) return;
      multiRecord.forEach((item) => (rewardsMap[item.userId] = item.summary?.trophy || 0));

      assignMultiUserRewards({ rewardsMap });
    })();
  }, []);

  return (
    <StyledSyncObserver>
      <MainLayout
        right={
          <RightContainer>
            <MainPageHeader />
          </RightContainer>
        }
        mode='single'
      >
        <SyncContent>
          {stagedAttendeeIds.length > 0 && (
            <div className='stagedVideoListLayout'>
              <SyncStagedVideoList />
            </div>
          )}
          <SyncWhiteboard toolBox={<SyncToolBox meetingId={meetingId} />} />

          {isShowContentShare && (
            <ContentShareContainer>
              <ContentShare />
            </ContentShareContainer>
          )}

          <TeachingMaterialContainer iframeGroup={iframeGroup} onClose={closeTeachingMaterialHandler} />
        </SyncContent>
      </MainLayout>
    </StyledSyncObserver>
  );
};

SyncObserver.propTypes = {
  meetingId: PropTypes.string,
};
