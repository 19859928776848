import styled from 'styled-components';
import { mediaQueries } from '../../utils/theme';

export const StyledVideoStep = styled.div.attrs(props => ({
className: props.className
}))`
  .videoBlock {
    height: 100%;
    background-color: #161C27;
    border-radius: 15px;
    padding: 52px 20px;
  }

  .videoStepWrap {
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
  }
  .videoBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    video {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
  .question {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .title {
      flex: 1 1 0px;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
    }
    .action {
      display: flex;
      flex: 1 1 0px;
    }
    ${mediaQueries.small} {
      flex-direction: column;
      .title {
        text-align: center;
      }
      .action {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
`;