import styled from 'styled-components';
import { mediaQueries } from '../../utils/theme';

export const StyledResultStep = styled.div.attrs(props => ({
className: props.className
}))`
  .resultBlock {
    display: flex;
    justify-content: center;
    height: 60vh;
    background-color: #161C27;
    border-radius: 15px;
    padding: 52px 20px;
    overflow: hidden;

    .itemWrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 20px;
      flex: 1 1 0px;
      &.info {
        overflow-y: scroll;
      }
      .title {
        color: #fff;
        font-weight: 500;
        font-size: 24px;
        text-align: center;
      }
      .resultTable {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border: 2px solid #2F3746;
        border-top: 2px solid #161C27;
        .item {
          flex: 1 1 0px;
          +.item {
            .head {
              border-left: 2px solid #161C27;
            }
            .content {
              border-left: 2px solid #2F3746;
            }
          }
        }
        .head {
          color: #fff;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          background-color: #2F3746;
          line-height: 29px;
        }
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
        }
      }
      .resultSingleTable {
        border: 2px solid #2F3746;
        .head {
          color: #fff;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          background-color: #2F3746;
          line-height: 29px;
        }
        .content {
          padding: 12px;
          .list {
            font-size: 12px;
            color: #fff;
          }
        }
      }
      .notice {
        font-weight: 500;
        font-size: 16px;
        line-height: 29px;
        color: #FFFFFF;
      }
      .errorMsg {
        color: #FF5630;
        font-size: 14px;
      }
    }
    ${mediaQueries.small} {
      display: block;
      flex-direction: column;
      padding: 40px 20px;
      max-height: none;
      height: auto;

      .itemWrap {
        width: 100%;
        padding: 0;
        &.info {
          overflow-y: visible;
        }
        +.itemWrap{
          margin-top: 20px;
        }
        .notice {
          margin-top: 40px;
          text-align: center;
        }
      }
    }
  }
  .question {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .title {
      flex: 1 1 0px;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
    }
    .action {
      display: flex;
      justify-content: flex-end;
      flex: 1 1 0px;

      .btn {
        width: calc(50% - 5px);
        &.primary {
          order: 1;
          margin-left: 10px
        }
        &.secondly {
          order: 0;
        }
      }
    }
  }
  ${mediaQueries.small} {
    .question {
      display: block;

      .title {
        text-align: center;
      }
      .action {
        display: block;
        margin-top: 24px;
        width: 100%;
        .btn {
          width: 100%;
          &.primary {
            margin-left: 0;
          }
          +.btn {
            margin-top: 10px;
          }
        }
      }
    }
  }
`;