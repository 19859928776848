import styled from 'styled-components';

const BackgroundButton = styled.div`
  display: flex;
  width: 88px;
  height: 49px;
  padding: 13px 0px 12px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid ${({ active }) => (active ? '#EC7963' : '#232A37')};
  cursor: pointer;
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'none')};
  background-size: cover;
  background-position: center;
`;

export default BackgroundButton;
