import styled from 'styled-components';

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: #212324;
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
  background: #fff;

  &:hover {
    background: rgba(33, 35, 36, 0.1);
  }
`;
