import styled from 'styled-components';
import { mediaQueries } from '../../utils/theme';

export const StyledNetworkStep = styled.div.attrs(props => ({
className: props.className
}))`
  .content {
    display: flex;
    height: 60vh;
    padding: 20px;
    border-radius: 15px;
    overflow: hidden;
    background-color: #161C27;

    .itemWrap {
      flex: 1 1 0px;
      &.iframe {
        overflow-y: scroll;
      }
      &.form {
        padding: 0 0 0 10px;
      }
      .notice {
        font-weight: 500;
        font-size: 16px;
        line-height: 29px;
        color: #FFFFFF;
      }
    }
    ${mediaQueries.small} {
      display: block;
      height: auto;

      .itemWrap {
        width: 100%;
        &.iframe {
          overflow-y: visible;
        }
        &.form {
          padding: 0;
        }
        +.itemWrap{
          margin-top: 20px;
        }
        .notice {
          text-align: center;
        }
      }
    }
  }
  .action {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
`;