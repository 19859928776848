import { useEffect, useState, useRef } from 'react';

function getOperatingSystem(window) {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = 'Not known';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

function getBrowser(window) {
  let currentBrowser = 'Not known';
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
  else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
  else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
  else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
  else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
  else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
  else { console.log('Others'); }

  return currentBrowser;
}

export const useUserAgent = () => {
  const [os, setOS] = useState();
  const [currentBrowser, setBrowser] = useState();
  const currentUserAgent = useRef();

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const operatingSystem = getOperatingSystem(window);
    const browser = getBrowser(window);
    setOS(operatingSystem);
    setBrowser(browser);
    currentUserAgent.current = userAgent;
  }, []);

  return { os, currentBrowser, currentUserAgent: currentUserAgent.current };
};