import React from 'react';
import { StyledTestList } from './QuestionTypeList.style';
import QuestionType from './components/QuestionType';

export const QuestionTypeList = ({ selectedType, setSelectedType, pd = '16px' }) => {
  return (
    <StyledTestList width={'100%'} padding={pd}>
      <div>
        <QuestionType selectedType={selectedType} setSelectedType={setSelectedType} />
      </div>
    </StyledTestList>
  );
};
