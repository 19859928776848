import { curry, pipe, set, lensIndex } from 'ramda';


// 交集
export const intersectionSet = (arr1, arr2) => {
  let set1 = new Set(arr1);
  let set2 = new Set(arr2);

  let result = arr1.filter((e) => {
    return set2.has(e);
  });

  return result;
};

// 補集
export const complementarySet = (arr1, arr2) => {
  let set1 = new Set(arr1);
  let set2 = new Set(arr2);

  let result = [...arr1.filter( x => !set2.has(x) ),
    ...arr2.filter( y => !set1.has(y) )];

  return result;
};


export const swap = curry((index1, index2, list) => {
  if (index1 < 0 || index2 < 0 || index1 > list.length - 1 || index2 > list.length - 1) {
    return list; // index out of bound
  }
  const value1 = list[index1];
  const value2 = list[index2];
  return pipe(
    set(lensIndex(index1), value2),
    set(lensIndex(index2), value1)
  )(list);
});

export const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};