import React from 'react';
import {
  useRosterState,
  useRemoteVideoTileState,
  VideoTile
} from 'amazon-chime-sdk-component-library-react';
import {
  RemoteVideo,
  useMeetingState,
} from '@oneboard/meeting';
import { Roles } from 'constants/index';
import { StyledAuditVideoList, UiInfoPanel } from './AuditVideoList.style';

export const AuditVideoList = ({ className }) => {
  const state = useMeetingState();
  const { context } = state;
  const { roster } = useRosterState();
  const { attendeeId: selfAttendeeId, role } = context;
  const attendees = Object.values(roster);
  const { attendeeIdToTileId } = useRemoteVideoTileState();

  return (
    <StyledAuditVideoList className={className} data-testid="AuditVideoList">
      {
        attendees
          .filter(attendee => attendee.chimeAttendeeId !== selfAttendeeId && attendee.role !== Roles.Student)
          .filter(attendee => {
            let data = null;
            if (role === Roles.Student || role === Roles.Observer) {
              if (attendee.role !== Roles.Teacher) {
                data = attendee;
              }
            } else {
              data = attendee;
            }
            return data;
          })
          .filter(attendee => {
            const attendeeId = attendee.chimeAttendeeId;
            const tileId = attendeeIdToTileId[attendeeId];
            return tileId ? attendee : null;
          })
          .map(attendee => {
            const attendeeId = attendee.chimeAttendeeId;
            const tileId = attendeeIdToTileId[attendeeId];
            return (
              context.stagedAttendeeIds.includes(attendeeId) ?
                <VideoTile key={attendeeId} /> :
                <div className="remoteVideoBox" key={attendeeId}>
                  <div className="insideVideo">
                    <RemoteVideo
                      tileId={tileId}
                      attendeeId={attendeeId}
                    />
                  </div>
                  <UiInfoPanel>
                    <div className="name">{attendee.name}</div>
                  </UiInfoPanel>
                </div>
            );
          })
      }
    </StyledAuditVideoList>
  );
};