import { useEffect } from 'react';
import { useWhiteboard } from '@oneboard/whiteboard';
import { IframeBridge } from '@oneboard/iframe-bridge';

export const useIframeBridge = () => {
  const { service: whiteboardService } = useWhiteboard();

  useEffect(() => {
    if (!whiteboardService) return;

    function handler(state) {
      if (state.sceneState?.scenePath.indexOf('book_iframe_') > -1) {
        const bookId = state.sceneState.contextPath.split('book_iframe_')[1];
        const url = `${process.env.REACT_APP_ONEBOARD_READER_DOMAIN}/${bookId}/${state.sceneState.index}`;
        let bridge = whiteboardService.getInvisiblePlugin(IframeBridge.kind);
        if (bridge) {
          bridge.setAttributes({
            url,
            displaySceneDir: state.sceneState.contextPath,
          });
        }
      }
    }

    whiteboardService.callbacks.on('onRoomStateChanged', handler);

    return () => whiteboardService.callbacks.off('onRoomStateChanged', handler);
  }, [whiteboardService]);

};