import {
  createContext,
  useContext,
} from 'react';
import { useInterpret } from '@xstate/react';
import { useActor } from '@xstate/react';
import insertPictureMachine from 'machines/InsertPictureMachine';

const InsertPictureContext = createContext({});

const InsertPictureProvider = ({ children }) => {
  const insertPictureService = useInterpret(insertPictureMachine);

  return (
    <InsertPictureContext.Provider value={{ insertPictureService }}>
      {children}
    </InsertPictureContext.Provider>
  );
};

const useInsertPicture = () => {
  const globalServices = useContext(InsertPictureContext);
  const [state] = useActor(globalServices.insertPictureService);
  const { send } = globalServices.insertPictureService;

  return { state, send };
};

export { InsertPictureProvider, useInsertPicture };