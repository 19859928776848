import { default as colorMap } from 'constants/colors/colorMap';

const animation = {
  easeOutCubic: 'cubic-bezier(0.33, 1, 0.68, 1)'
};

const breakpoints = ['767.98px', '991.98px', '1199.98px'];

const radii = ['0', '3px', '6px', '100px'];

const fontSizes = ['12px', '14px', '16px', '20px', '24px', '32px', '40px', '48px'];

const space = ['0', '4px', '8px', '16px', '24px', '32px', '40px', '48px', '64px', '80px', '96px', '112px', '128px'];

const theme = {
  animation,
  breakpoints,
  radii,
  fontSizes,
  space,
  colors: colorMap
};

export default theme;
