import styled from 'styled-components';

export const StyledNetworkNotification = styled.div.attrs(props => ({
  className: props.className
  }))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 8px;
  padding: 6px 20px;
  line-height: 24px;

  .content {
    font-size: 14px;
    color: #fff;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;