import styled from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 195px;
  border-radius: 16px;
  overflow: hidden;
`;

export default VideoWrapper;
