import styled from 'styled-components';

const VideoAudioButton = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;
  background: ${({ active }) => (active ? 'rgba(255, 223, 202, 0.60)' : 'transparent')};
  cursor: pointer;
`;

export default VideoAudioButton;
