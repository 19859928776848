import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { StyledNetworkNotification } from './NetworkNotification.style';

const colorMap = {
  poor: '#FFAB00',
  stop: '#FF5630'
};

export const NetworkNotification = ({ networkStatus, onClose }) => {

return (
  <StyledNetworkNotification data-testid="NetworkNotification" bgColor={colorMap[networkStatus]}>
    <div className="content">
      {networkStatus === 'poor' && '很抱歉，設備的狀況可能會影響到良好的上課體驗，建議重新檢查設備，並使用最新版 Chrome 瀏覽器，以確保最佳的學習環境'}
      {networkStatus === 'stop' && '需重新檢查網路設備'}
    </div>
    <div className="close" onClick={onClose}>
      <Icon name="XmarkOutline" size='xs' color='#fff' />
    </div>
  </StyledNetworkNotification>
  );
};

NetworkNotification.propTypes = {
  networkStatus: PropTypes.string,
  onClose: PropTypes.func,
};