import styled from 'styled-components';

export const StyledLoginPage = styled.div.attrs(props => ({
  className: props.className
}))`
  display: flex;
  justify-content: center;
  width: 60%;
  height: 60%;
  margin: 40px auto;
`;