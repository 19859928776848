import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAudioVideo, useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { Popover } from '@oneboard/ui-components';
import { Roles, ClassType } from 'constants/index';
import { StyledMediaMetrics, StyledMediaMetricsInfo } from './MediaMetrics.style';
import { t } from '../../utils/i18n';

const filterConnectionState = (state) => {
  let connectionText = '';
  switch (state) {
    case 'isPoor':
      connectionText = t('containers.mediaMetrics.connectionText.poor', '不佳');
      break;
    case 'isStop':
      connectionText = t('containers.mediaMetrics.connectionText.stopped', '失敗');
      break;
    default:
      connectionText = t('containers.mediaMetrics.connectionText.good', '良好');
      break;
  }
  return connectionText;
};

const MediaMetricsInfo = ({ className, connectionState, streamDelayMs, packetLossPercent }) => {
  return (
    <StyledMediaMetricsInfo className={className}>
      <div className='infoList'>
        <div className='infoTitle'>{t('containers.mediaMetrics.networkDelay', '網路延遲')}:</div>
        <div className='infoContent'>{`${streamDelayMs} ms`}</div>
      </div>
      <div className='infoList'>
        <div className='infoTitle'>{t('containers.mediaMetrics.packetLostRate', '丟包率')}:</div>
        <div className='infoContent'>{`${packetLossPercent} %`}</div>
      </div>
      <div className='infoList'>
        <div className='infoTitle'>{t('containers.mediaMetrics.connectionQuality', '連線品質')}:</div>
        <div className={`infoContent ${connectionState}`}>{filterConnectionState(connectionState)}</div>
      </div>
    </StyledMediaMetricsInfo>
  );
};

MediaMetricsInfo.propTypes = {
  className: PropTypes.string,
  connectionState: PropTypes.string,
  streamDelayMs: PropTypes.any,
  packetLossPercent: PropTypes.any,
};

export const MediaMetrics = ({ className, placement = 'top' }) => {
  const state = useMeetingState();
  const { context } = state;
  const { role, courseType } = context;
  const audioVideo = useAudioVideo();
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const teacher = attendees.find((attendee) => attendee.role === Roles.Teacher);
  const student = attendees.find((attendee) => attendee.role === Roles.Student);

  const [videoStreamMetrics, setVideoStreamMetrics] = useState(null);
  const [streamDelayMs, setStreamDelayMs] = useState(0);
  const [packetLossPercent, setPacketLossPercent] = useState(0);
  const [connectionState, setConnectionState] = useState('isGood');

  useEffect(() => {
    if (!videoStreamMetrics) return;
    if (!student) return;
    if (!teacher) return;
    if (!videoStreamMetrics[student.chimeAttendeeId]) return;
    if (!videoStreamMetrics[teacher.chimeAttendeeId]) return;

    let streamSsrc = null;
    let streamMetricsInfo = null;

    if (role === Roles.Student) {
      streamSsrc = Object.keys(videoStreamMetrics[teacher.chimeAttendeeId]);
      streamMetricsInfo = videoStreamMetrics[teacher.chimeAttendeeId][streamSsrc[0]];
      setPacketLossPercent(streamMetricsInfo.videoDownstreamPacketLossPercent?.toFixed(2));
    } else {
      if (courseType === ClassType.SyncSingle || courseType === ClassType.Single) {
        streamSsrc = Object.keys(videoStreamMetrics[student.chimeAttendeeId]);
        streamMetricsInfo = videoStreamMetrics[student.chimeAttendeeId][streamSsrc[0]];
        setPacketLossPercent(streamMetricsInfo?.videoDownstreamPacketLossPercent?.toFixed(2));
      }
    }
  }, [videoStreamMetrics, student, teacher]);

  useEffect(() => {
    if (!audioVideo) return;
    const observer = {
      audioVideoDidStartConnecting: () => {
        setConnectionState('isGood');
      },
      connectionDidBecomeGood: () => {
        setConnectionState('isGood');
      },
      connectionDidBecomePoor: () => {
        setConnectionState('isPoor');
      },
      connectionDidSuggestStopVideo: () => {
        setConnectionState('isStop');
      },
      metricsDidReceive: (clientMetricsReport) => {
        const metricsReport = clientMetricsReport.getObservableMetrics();
        const videoMetricsReport = clientMetricsReport.getObservableVideoMetrics();
        setStreamDelayMs(metricsReport.audioSpeakerDelayMs);
        setVideoStreamMetrics(videoMetricsReport);
      },
    };

    if (audioVideo) {
      audioVideo.addObserver(observer);
    }

    return () => {
      audioVideo.removeObserver(observer);
    };
  }, [audioVideo]);

  return (
    <StyledMediaMetrics className={className} data-testid='MediaMetrics'>
      <Popover
        arrow='true'
        content={
          <MediaMetricsInfo
            connectionState={connectionState}
            streamDelayMs={streamDelayMs}
            packetLossPercent={packetLossPercent}
          />
        }
        placement={placement}
      >
        <div className={`iconBtn ${connectionState}`}>
          <Icon name='WifiSolid' size='sm' />
        </div>
      </Popover>
    </StyledMediaMetrics>
  );
};

MediaMetrics.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
};
