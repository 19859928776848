import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { Box } from '@oneboard/ui-components';
import { useCanvasDispatch, useCanvasState } from '@oneboard/whiteboard';
import {
  SettingPopoverButton
} from '@oneboard/meeting';
import { StyledViewToolBar, ToolBtn, TextView, DividingLine, GroupToolBox } from './ViewToolBar.style';

const ZoomControl = () => {
  const state = useCanvasState();
  const { context: { canvas } } = state;
  const [zoomVal, setZoomVal] = useState(100);

  const zoomReductionHandler = () => {
    canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    canvas.zoomToPoint({ x: canvas.width / 2, y: canvas.height / 2 }, 1);
    setZoomVal(100);
  };

  const zoomOutHandler = () => {
    const zoom = canvas.getZoom();
    let newZoom = 0;

    if (zoom <= 1) return;

    newZoom = parseFloat((zoom - 0.1).toPrecision(12));
    if (newZoom === 1) {
      canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    } else {
      canvas.zoomToPoint({ x: canvas.width / 2, y: canvas.height / 2 }, newZoom);
    }
    setZoomVal(Math.round(newZoom * 100));
  };

  const zoomInHandler = () => {
    const zoom = canvas.getZoom();
    let newZoom = 0;

    if (zoom >= 6) return;

    newZoom = parseFloat((zoom + 0.1).toPrecision(12));
    canvas.zoomToPoint({ x: canvas.width / 2, y: canvas.height / 2 }, newZoom);
    setZoomVal(Math.round(newZoom * 100));
  };

  useEffect(() => {
    if (!canvas) return;

    canvas.on('mouse:wheel', function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.9 ** delta;
      if (zoom > 6) zoom = 6;
      if (zoom < 1) zoom = 1;
      if (zoom === 1) {
        this.setViewportTransform([1, 0, 0, 1, 0, 0]);
      } else {
        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      }
      setZoomVal(Math.round(zoom * 100));
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  }, [canvas]);

  return (
    <GroupToolBox>
      <Box mr='8px'>
        {/* 還原 zoom 100% */}
        <ToolBtn onClick={zoomReductionHandler} title='100%'>
          <Icon name='ExpandSolid' size='xs' />
        </ToolBtn>
      </Box>
      {/* 縮小 */}
      <ToolBtn onClick={zoomOutHandler} title='縮小'>
        <Icon name='MinusSolid' size='xs' />
      </ToolBtn>
      <TextView>{zoomVal}%</TextView>
      {/* 放大 */}
      <ToolBtn onClick={zoomInHandler} title='放大'>
        <Icon name='PlusSolid' size='xs' />
      </ToolBtn>
    </GroupToolBox>
  );
};


export const ViewToolBar = ({ className, mode = 'group' }) => {
  const state = useCanvasState();
  const {
    setPageIndex,
    createNewPages
  } = useCanvasDispatch();

  const { context: { pageIndex, annotations } } = state;

  const prevPage = () => setPageIndex(pageIndex - 1);
  const nextPage = () => setPageIndex(pageIndex + 1);

  return (
    <StyledViewToolBar className={className} data-testid="ViewToolBar">
      {/* 放大縮小 */}
      <ZoomControl />
      <DividingLine />
      {/* 頁數 */}
      <GroupToolBox>
        {/* 上一頁 */}
        <ToolBtn onClick={prevPage} title='上一頁'>
          <Icon name="ChevronLeftOutline" size='xs'/>
        </ToolBtn>
        <TextView>{`${pageIndex + 1} / ${annotations.length}`}</TextView>
        {/* 下一頁 */}
        <ToolBtn onClick={nextPage} title='下一頁'>
          <Icon name="ChevronRightOutline" size='xs'/>
        </ToolBtn>
        {/* 新增一頁 */}
        <Box ml='8px'>
          <ToolBtn onClick={createNewPages} title='新增頁面'>
            <Icon name="FileAddSolid" size='sm'/>
          </ToolBtn>
        </Box>
      </GroupToolBox>
      <DividingLine />
      <Box ml='4px'>
        <ToolBtn title='設定'>
          <SettingPopoverButton className="settingPopoverButton" />
        </ToolBtn>
      </Box>
    </StyledViewToolBar>
  );
};

ViewToolBar.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.string
};