import { useState } from 'react';
import { useInterval } from 'ahooks';

export const useCountDown = (sec = 30) => {
  const [countDownNumber, setCountDownNumber] = useState(sec);
  const [interval, setInterval] = useState(null);

  const countDownHandler = () => setCountDownNumber(prev => prev - 1);

  const startCountDown = () => setInterval(1000);

  const endCountDown = () => setInterval(null);

  useInterval(
    () => countDownHandler(),
    interval,
    { immediate: true },
  );

  return {
    countDownNumber,
    startCountDown,
    endCountDown,
  };
};
