import styled from 'styled-components';

export const StyleDeviceNotFound = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 55%;
  background: #232323;
  border: 1px solid #33353a;
  border-radius: 4px;

  > .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #fff;
    font-size: 14px;
  }
`;

export const StylePreviewVideo = styled.div`
  .isFlip {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .mirrorCheckbox {
    color: #212b36;
  }
`;
