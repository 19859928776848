import { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'utils/hooks/useQuery';
import qs from 'qs';

export const Exception = () => {
  const { meetingId } = useParams();
  const query = useQuery();
  const { verified } = query;
  const history = useHistory();
  const location = useLocation();
  const originQuery = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    arrayFormat: 'indices',
    decoder: c => decodeURIComponent(c)
  });
  const getCourseInfo = async () => {
    try {
      if (!verified) {
        history.push({
          pathname: '/notFound',
        });
        return;
      }

      if (
        query.role !== originQuery.role ||
        query.userId !== originQuery.userId ||
        query.classType !== originQuery.classType
      ) {
        const searchParams = new URLSearchParams(query);
        searchParams.delete('verified');
        history.push({
          pathname: `/${meetingId}/setup`,
          search: `?${searchParams.toString()}`
        });
        window.location.reload();
        return;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getCourseInfo();
  }, []);

  return <></>;
};