import styled from 'styled-components';

export const StyledBreakoutRoomIntro = styled.div.attrs(props => ({
  className: props.className
}))`
  height: 100%;
`;

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 36px;
  width: 100%;
  height: 100%;
  background-color: #161C27;
  border-radius: 20px;
  box-shadow: 0px 50px 77px rgba(3, 8, 17, 0.22);

  .head {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    border-bottom: 1px solid #2F3746;
    .title {
      font-size: 20px;
      color: #fff;
    }
    .close {
      position: absolute;
      left: 32px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #B0B7C3;
      cursor: pointer;
    }
  }
  .content {
    height: 100%;
    padding: 36px 36px 0 36px;
    overflow-y: auto;
    .optionList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #232A37;
      border-radius: 15px;
      padding: 20px;
      cursor: pointer;
      &:hover {
          opacity: 0.8;
        }
      +.optionList {
        margin-top: 24px;
      }

      >.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;

        &.blue {
          background-color: #377DFF;
        }
        &.green {
          background-color: #38CB89;
        }
        &.record {
          background-color: rgba(55, 125, 255, .3);
        }
      }
      .optionContent {
        margin-left: 12px;
        >.title {
          font-size: 14px;
          color: #fff;
        }
        >.desc {
          font-size: 12px;
          color: #8A94A6;
        }
      }
    }
  }
`;

export const StyledRecordList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #232A37;
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
  &:hover {
      opacity: 0.8;
    }
  &+& {
    margin-top: 24px;
  }
  >.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;

    &.blue {
      background-color: #377DFF;
    }
    &.green {
      background-color: #38CB89;
    }
    &.record {
      background-color: rgba(55, 125, 255, .3);
    }
  }
  .optionContent {
    margin-left: 12px;
    >.title {
      font-size: 14px;
      color: #fff;
    }
    >.desc {
      font-size: 12px;
      color: #8A94A6;
    }
  }
`;