import React, { createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';

import { machine, ActionTypes } from './machine';

const StateContext = createContext();
const DispatchContext = createContext(() => {});

export const DeviceDetectProvider = ({ children }) => {
  const [state, send] = useMachine(machine);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={send}>
          {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useDeviceDetectState = () => useContext(StateContext);

export const useDeviceDetectDispatch = () => {

  const dispatch = useContext(DispatchContext);

  const resetState = useCallback(() => {
    dispatch({
      type: ActionTypes.Reset,
    });
  }, []);
  
  const startDetect = useCallback(() => {
    dispatch({
      type: ActionTypes.StartDetect,
    });
  }, []);

  const goVideo = useCallback(() => {
    dispatch({
      type: ActionTypes.GoVideo,
    });
  }, []);

  const goMicrophone = useCallback(() => {
    dispatch({
      type: ActionTypes.GoMicrophone,
    });
  }, []);

  const goAudio = useCallback(() => {
    dispatch({
      type: ActionTypes.GoAudio,
    });
  }, []);

  const goResult = useCallback(() => {
    dispatch({
      type: ActionTypes.GoResult,
    });
  }, []);

  const updateDevice = useCallback((device) => {
    dispatch({
      type: ActionTypes.UpdateDevice,
      payload: { device }
    });
  }, []);

  const updateNetwork = useCallback((network) => {
    dispatch({
      type: ActionTypes.UpdateNetwork,
      payload: { network }
    });
  }, []);

  const updateAudio = useCallback((audio) => {
    dispatch({
      type: ActionTypes.UpdateAudio,
      payload: { audio }
    });
  }, []);

  const updateEquipment = useCallback((equipment) => {
    dispatch({
      type: ActionTypes.UpdateEquipment,
      payload: { equipment }
    });
  }, []);

  return { 
    resetState,
    startDetect,
    goVideo,
    goMicrophone,
    goAudio,
    goResult,
    updateDevice,
    updateNetwork,
    updateAudio,
    updateEquipment
  };
}

DeviceDetectProvider.propTypes = {
  children: PropTypes.node,
};