import styled from 'styled-components';

export const StyledBreakoutRoomResult = styled.div.attrs(props => ({
  className: props.className
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 34px;
  background-color: rgba(22, 28, 39, .95);
  z-index: 6;
  .wrap {
    position: relative;
    max-width: 1024px;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    margin: 0 auto;
    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      background: #2F3746;
      color: #fff;
      border-radius: 50%;
      cursor: pointer;

      &.prev {
        left: -30px;
      }

      &.next {
        right: -30px;
      }
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
`;

export const StyledCardBox = styled.div`
  padding: 12px;
  width: 33.33%;
  .content {
    position: relative;
    height: ${({ height }) => `${(height - 72) / 2 - 24}px`};
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      .overlay {
        display: flex;
      }
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba(22, 28, 39, .4);
      &:after {
        content:'檢視';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        background-color: #394F8A;
        color: #fff;
        font-size: 20px;
        border-radius: 50%;
      }
    }
    
    .groupName {
      position: absolute;
      left: 10px;
      bottom: 0;
      color: #2F3746;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
      font-size: 16px;
      line-height: 35px;
      font-weight: 600;
    }
    .memberTip {
      position: absolute;
      right: 10px;
      bottom: 8px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FFAB00;
      border-radius: 50%;
    }
  }
`;

