import styled from 'styled-components';
import { mediaQueries } from '../../utils/theme';

export const StyledAudioStep = styled.div.attrs(props => ({
className: props.className
}))`
  .audioBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 440px;
    height: 100%;
    padding: 52px 20px;
    background-color: #161C27;
    border-radius: 15px;
    
    .itemWrap {
      padding: 0 20px;
      flex: 1 1 0px;

      &.playBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .desc {
        margin-top: 36px;
        font-weight: 500;
        font-size: 24px;
        color: #fff;
      }
    }
    ${mediaQueries.small} {
      flex-direction: column;
      padding: 40px 20px;
      .itemWrap {
        width: 100%;
        padding: 0;
        +.itemWrap{
          margin-top: 20px;
        }
      }
    }
    .playBth {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 96px;
      background-color: transparent;
      border: 2px solid #232A37;
      border-radius: 90px;
      cursor: pointer;
    }
  }
  .question {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .title {
      flex: 1 1 0px;
      color: #fff;
      font-weight: 500;
      font-size: 16px;
    }
    .action {
      display: flex;
      flex: 1 1 0px;
    }
    ${mediaQueries.small} {
      flex-direction: column;
      .title {
        text-align: center;
      }
      .action {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
`;

export const StyledAudioAnimationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;