import styled from 'styled-components';

export const StyledCleanConfirmModal = styled.div.attrs((props) => ({
  className: props.className,
}))`
  .close {
    background-color: transparent;
    /* margin-right: 30px; */
    transition: 0.35s;

    /* &:hover {
      background-color: #33353a;
    } */
  }
`;
